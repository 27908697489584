import { Catalog } from 'src/models/Catalog';
import { Stock } from 'src/models/Stock';
import { AccountModel } from 'src/store/accounts/types';

export enum ConnectionIdentifierKey {
  SupplierAssignedNumber = 'SupplierAssignedNumber'
}

export enum OrganizationType {
  Supplier = 'Supplier',
  Retailer = 'Retailer'
}

export enum ConnectStatus {
  Approved = 'Approved',
  Disapproved = 'Disapproved',
  Processing = 'Processing'
}

export enum InterfaceMethod {
  Sftp = 'Sftp',
  Ftp = 'Ftp',
  LtxFtp = 'LtxFtp',
  Email = 'Email',
  Manual = 'Manual'
}

export enum InterfaceType {
  CatalogReceive = 'CatalogReceive',
  CatalogSend = 'CatalogSend',
  OrderReceive = 'OrderReceive',
  OrderSend = 'OrderSend'
}

export enum OrganizationReadinessStatus {
  Announced = 'Announced',
  ComingSoon = 'ComingSoon',
  Ready = 'Ready',
  Hidden = 'Hidden'
}

export interface Organization {
  id: string;
  name: string;
  email: string;
  // iban: string;
  // btwNumber: string;
  houseNumber: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
  telephoneNumber: string;
  organizationType: OrganizationType;
  isAdmin: boolean;
  imageURL: string;
  image: any;
  description: string;
  website: string;
  softwareId: string;
  maturityStatus: number;
  connectStatus?: ConnectStatus;
  connectionDate?: Date;
  creationDate: Date;
  subscriptionId: string;
  subscriptionName?: string;
  numberOfLiveConnections: number;
  lastTrafficDate?: Date;
  supportsCatalogData: OrganizationReadinessStatus;
  supportsOrderData: OrganizationReadinessStatus;
  supportsStockData: OrganizationReadinessStatus;
  accountStatus: OrganizationReadinessStatus;
  ordersTrafficCounter: number;
}

export const EmptyOrganization: Organization = {
  id: '',
  email: '',
  name: '',
  // iban: '',
  // btwNumber: '',
  houseNumber: '',
  street: '',
  postalCode: '',
  city: '',
  country: '',
  telephoneNumber: '',
  organizationType: OrganizationType.Retailer,
  isAdmin: false,
  imageURL: '',
  image: undefined,
  description: '',
  website: '',
  softwareId: '',
  maturityStatus: 100,
  connectStatus: undefined,
  creationDate: new Date(),
  subscriptionId: '',
  subscriptionName: '',
  numberOfLiveConnections: 0,
  supportsCatalogData: OrganizationReadinessStatus.Announced,
  supportsOrderData: OrganizationReadinessStatus.Announced,
  supportsStockData: OrganizationReadinessStatus.Announced,
  accountStatus: OrganizationReadinessStatus.Announced,
  ordersTrafficCounter: 0
};

export type OrganizationModel = {
  organization: Organization;
  catalogs: Catalog[];
  stocks: Stock[];
  identifiers: OrganizationIdentifier[];
};

export type OrganizationAccessTokenModel = { 
  accessToken: string
}

export type OrganizationManageModel = {
  organization: Organization;
  accounts: AccountModel[];
  catalogs: Catalog[];
  stocks: Stock[];
  connections: OrganizationConnectionModel[];
  identifiers: OrganizationIdentifier[];
  interfaces: OrganizationInterface[];
  settings: OrganizationInterfaceSetting[];
};

export type OrganizationConnectionModel = {
  organizationId: string;
  organizationName: string;
  creationDate: Date;
};

export type DataConnection = {
  id: string;
  sourceOrganizationId: string;
  targetOrganizationId: string;
  creationDate: Date;
  catalogId: string;
  stockId: string;
  downloads: number;
  lastDownloadDate?: Date;
  active: boolean;
};

export type OrganizationConnection = {
  id: string;
  sourceOrganizationId: string;
  targetOrganizationId: string;
  creationDate: Date;
  lastTrafficDate?: Date;
  deleted: boolean;
};

export type OrganizationInterface = {
  id: string;
  method: InterfaceMethod;
  type: InterfaceType;
  settings: Object;
};

export type OrganizationInterfaceSetting = {
  organizationInterfaceId: string;
  key: string;
  value: string;
};

export type MyOrganizationModel = {
  organization: Organization;
  catalogs: Catalog[];
  stocks: Stock[];
  catalogConnections: DataConnection[];
  stockConnections: DataConnection[];
  organizationConnections: OrganizationConnection[];
  interfaces: OrganizationInterface[];
  settings: OrganizationInterfaceSetting[];
  identifiers: OrganizationIdentifier[];
};

export const EmptyOrganizationModel: OrganizationModel = {
  organization: EmptyOrganization,
  catalogs: [],
  stocks: [],
  identifiers: [],
};

export const EmptyMyOrganizationModel: MyOrganizationModel = {
  organization: EmptyOrganization,
  catalogs: [],
  stocks: [],
  catalogConnections: [],
  stockConnections: [],
  organizationConnections: [],
  interfaces: [],
  settings: [],
  identifiers: [],
};

export type OrganizationIdentifier = {
  id: string;
  type: string;
  value: string;
};

export type OrderInterfaceSettingsModel = {
  method: InterfaceMethod;
  ftpUsername?: string;
  ftpPassword?: string;
  emailAddress?: string;
};

export type ConnectionIdentifier = {
  id: string;
  organizationConnectionId: string;
  value: string;
  softwareId?: string;
  creationDate: Date;
};
