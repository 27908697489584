import { Dispatch } from 'redux';
import { EditAccountForm } from 'src/pages/myltx/AccountPage';

import { commandAction, requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError } from 'src/helpers/apiClient';
import { AccountSetting, AccountSettingForm } from '../authentication/types';
import { FetchStatus } from '../catalogs/actions';
import { EditAccountData } from './../../models/domain/EditAccount';
import {
  checkEmailAvailable,
  createAccount,
  createAccountForOrganization,
  deleteAccount,
  editAccount,
  editManageAccount,
  getAccount,
  getMyAccount,
  getSettings,
  listAccounts,
  resetPassword,
  saveSettings,
  sendPasswordReset,
  verifyEmailCode
} from './api';
import {
  AccountDetails,
  AccountEditModel,
  AccountListModel,
  AccountModel,
  ActionTypes,
  CreateAccountFormData,
  RegisterAccountFormData
} from './types';

export type EditAccountSuccess = {
  type: ActionTypes.EDIT_ACCOUNT;
  payload: {};
};

export type SaveSettingsSuccess = {
  type: ActionTypes.SAVE_SETTINGS;
  payload: {};
};

export type StoreRegisterDataSuccess = {
  type: ActionTypes.STORE_REGISTER_DATA;
  status: FetchStatus;
  payload: { data: RegisterAccountFormData };
};

export type GetSettingsAction = {
  type: ActionTypes.GET_SETTINGS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    settings?: AccountSetting[];
  };
};

export type ListAccountsAction = {
  type: ActionTypes.LIST_ACCOUNTS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    accounts?: AccountListModel[];
  };
};

export type GetAccountAction = {
  type: ActionTypes.GET_ACCOUNT;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    account?: AccountDetails;
  };
};

export type GetMyAccountAction = {
  type: ActionTypes.GET_MY_ACCOUNT;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    account?: AccountModel;
  };
};

export const storeNewData = (data: RegisterAccountFormData): StoreRegisterDataSuccess => ({
  type: ActionTypes.STORE_REGISTER_DATA,
  status: 'success',
  payload: { data }
});

export const getSettingsAction = (
  status: FetchStatus,
  data?: AccountSetting[],
  errors?: ApiError[]
): GetSettingsAction => ({
  type: ActionTypes.GET_SETTINGS,
  status,
  errors,
  payload: {
    settings: data
  }
});

export const listAccountsAction = (
  status: FetchStatus,
  data?: AccountListModel[],
  errors?: ApiError[]
): ListAccountsAction => ({
  type: ActionTypes.LIST_ACCOUNTS,
  status,
  errors,
  payload: {
    accounts: data
  }
});

export const getAccountAction = (
  status: FetchStatus,
  data?: AccountDetails,
  errors?: ApiError[]
): GetAccountAction => ({
  type: ActionTypes.GET_ACCOUNT,
  status,
  errors,
  payload: {
    account: data
  }
});

export const getMyAccountAction = (
  status: FetchStatus,
  data?: AccountModel,
  errors?: ApiError[]
): GetMyAccountAction => ({
  type: ActionTypes.GET_MY_ACCOUNT,
  status,
  errors,
  payload: {
    account: data
  }
});

export const createAccountForOrganizationThunk = (formData: CreateAccountFormData) => {
  const command = {
    CommandName: 'CreateAccountForOrganization',
    Email: formData.email,
    FirstName: formData.firstName,
    LastName: formData.lastName,
    Password: formData.password,
    OrganizationId: formData.organization
  };

  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.CREATE_ACCOUNT_FOR_ORGANIZATION, errors),
    () => createAccountForOrganization(command)
  );
};

export const createAccountThunk = (formData: RegisterAccountFormData) => {
  const command = {
    CommandName: 'CreateAccount',
    Email: formData.email,
    FirstName: formData.firstName,
    LastName: formData.lastName,
    Password: formData.password,
    CompanyName: formData.name,
    CompanyDescription: formData.description,
    CompanyEmail: formData.companyEmail,
    OrganizationType: formData.organizationType,
    Street: formData.street,
    HouseNumber: formData.houseNumber,
    Postal: formData.postalCode,
    City: formData.city,
    Country: formData.country,
    TelephoneNumber: formData.telephoneNumber,
    Website: formData.website,
    Language: formData.language
    // Iban: formData.iban,
    // Btw: formData.btwNumber
  };

  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.CREATE_ACCOUNT, errors),
    () => createAccount(command, formData.image)
  );
};

export const EditAccountThunk = (accountForm: EditAccountForm) => {
  const accountData: EditAccountData = {
    Id: accountForm.id,
    Password: accountForm.password
  };

  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.EDIT_ACCOUNT, errors),
    () => editAccount(accountData)
  );
};

export const SaveSettingsThunk = (settingsData: AccountSettingForm[]) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.SAVE_SETTINGS, errors),
    () => saveSettings(settingsData)
  );
};

export const GetSettingsThunk = () => {
  return requestApiThunk(getSettingsAction, () => getSettings());
};

export const checkEmailAvailableThunk = (email: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.CHECK_EMAIL_AVAILABLE, errors),
    () => checkEmailAvailable(email)
  );
};

export const verifyEmailCodeThunk = (code: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.VERIFY_EMAIL_CODE, errors),
    () => verifyEmailCode(code)
  );
};

export const sendPasswordResetThunk = (email: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.SEND_PASSWORD_RESET, errors),
    () => sendPasswordReset(email)
  );
};

export const resetPasswordThunk = (code: string, password: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.SEND_PASSWORD_RESET, errors),
    () => resetPassword(code, password)
  );
};

export const listAccountsThunk = () => {
  return requestApiThunk(listAccountsAction, () => listAccounts());
};

export const getAccountThunk = (id: string) => {
  return requestApiThunk(getAccountAction, () => getAccount(id));
};

export const getMyAccountThunk = () => {
  return requestApiThunk(getMyAccountAction, () => getMyAccount());
};

export const deleteAccountThunk = (id: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.DELETE_ACCOUNT, errors),
    () => deleteAccount(id),
    listAccountsThunk()
  );
};

export const editManageAccountThunk = (editModel: AccountEditModel) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.EDIT_MANAGE_ACCOUNT, errors),
    () => editManageAccount(editModel),
    getAccountThunk(editModel.id)
  );
};

export type Action =
  | StoreRegisterDataSuccess
  | GetSettingsAction
  | ListAccountsAction
  | GetAccountAction
  | GetMyAccountAction;
