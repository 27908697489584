import styled from '@emotion/styled';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { XActionButton, XSecActionButton } from 'src/components/layout/XActionButton';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { XFailPanel, XPanel, XSuccessPanel } from 'src/components/layout/XPanel';
import { XPaper, XPaperTitle } from 'src/components/layout/XPaper';
import { XSubTitle, XTitle } from 'src/components/layout/XPaperHeader';
import { XRow, XRowCell } from 'src/components/layout/XRow';
import { interfaceMethods } from 'src/constants/OrganizationInterface/InterfaceSetting';
import { InterfaceMethod, InterfaceType, OrganizationInterface } from 'src/models/Organization';
import ErrorIcon from '@material-ui/icons/Warning';
import SuccessIcon from '@material-ui/icons/Check';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';

type OwnProps = {
  title: string;
  subTitle: string;
  interface?: OrganizationInterface;
  type: InterfaceType;
  editPath?: string;
  deleteVisible: boolean;
  onDelete: (id: string) => void;
};

type AllProps = OwnProps & RouteComponentProps;

const InterfaceCard: React.SFC<AllProps> = props => {
  const configure = () => {
    if (props.interface === undefined) {
      props.history.push(props.editPath + '?type=' + props.type);
    } else {
      // /my/software/interface
      props.history.push(props.editPath + '/' + props.interface.id);
    }
  };

  const viewSettings = (settings: Array<{ key: string; desc: string }>) => {
    return settings.map(s => {
      if (s.key.includes("Password")){
        return (
          <div key={s.key}>
            <strong>{s.desc}</strong>: •••••••••
          </div>
        );
      }
      return (
        <div key={s.key}>
          <strong>{s.desc}</strong>: {props.interface?.settings[s.key]}
        </div>
      );
    });
  };

  const onInterfaceDelete = () => {
    if (props.interface != null) {
      props.onDelete(props.interface.id);
    } 
  };

  const renderSettings = () => {
    switch (props.interface?.method) {
      case InterfaceMethod.Ftp:
        return viewSettings([
          { key: 'FtpAddress', desc: i18n.t('software.FtpLocation') },
          { key: 'FtpUsername', desc: i18n.t('account.userName') },
          { key: 'LtxPassword', desc: i18n.t('account.password') }
        ]);
      case InterfaceMethod.Sftp:
        return viewSettings([
          { key: 'SftpAddress', desc: i18n.t('software.SftpLocation') },
          { key: 'SftpUsername', desc: i18n.t('account.userName') },
          { key: 'SftpPassword', desc: i18n.t('account.password') }
        ]);
      case InterfaceMethod.LtxFtp:
        return viewSettings([
          { key: 'LtxFtpAddress', desc: i18n.t('software.FtpLocation') },
          { key: 'LtxFtpUsername', desc: i18n.t('account.userName') },
          { key: 'LtxFtpPassword', desc: i18n.t('account.password') }
        ]);
      case InterfaceMethod.Email:
        return viewSettings([{ key: 'EmailAddress', desc: 'E-mailadres' }]);
      case InterfaceMethod.Manual:
        return viewSettings([]);
    }
    return null;
  };

  return (
    <XPaper style={{ marginTop: '15px' }}>
      <XPaperTitle style={{ display: 'flex' }}>
        <XRowCell>
          <XTitle>{props.title}</XTitle>
          <XSubTitle>{props.subTitle}</XSubTitle>
        </XRowCell>
        {props.editPath !== undefined && (
          <XActionStrip>
            {props.interface === undefined ? (
              <Translation>
                {t => (
                  <XActionButton style={{ margin: '5px' }} onClick={() => configure()}>
                    {t('adminOrganizations.install')}
                  </XActionButton>
                )}
              </Translation>
            ) : (
              <Translation>
                  {t => (
                    <div>
                      <XSecActionButton style={{ margin: '5px' }} onClick={() => configure()}>
                    {t('adminOrganizations.Edit')}
                      </XSecActionButton>
                      {props.deleteVisible == true && ( <XSecActionButton onClick={() => onInterfaceDelete()}>
                        {'Delete'}
                      </XSecActionButton>
                      )}
                      </div>
                )}
              </Translation>
            )}
          </XActionStrip>
        )}
      </XPaperTitle>
      <Wrapper>
        {props.interface === undefined ? (
          <div>
            <Translation>{t => <div>{t('adminOrganizations.notSetUpYet')}</div>}</Translation>
          </div>
        ) : (
          <div>
            <XRow>
              <XRowCell>
                <Translation>
                  {t => (
                    <XTitle>
                      {interfaceMethods.map(x =>
                        x.key === props.interface?.method
                          ? x.key === InterfaceMethod.Manual
                            ? t('tooltip.Manual')
                            : x.description
                          : ''
                      )}
                    </XTitle>
                  )}
                </Translation>
                {renderSettings()}
              </XRowCell>
              <XRowCell style={{ textAlign: 'right' }}>
                <Translation>
                  {t => (
                    <strong>
                      {props.interface?.settings?.['FileFormat']} {t('software.Format')}
                    </strong>
                  )}
                </Translation>
              </XRowCell>
            </XRow>

            {props.interface?.method === InterfaceMethod.LtxFtp ? (
              props.interface?.settings?.['LtxFtpIsActive'] ? (
                <XSuccessPanel style={{ marginTop: '10px' }}>
                  <div>
                    <SuccessIcon
                      style={{
                        height: '22px',
                        width: '22px',
                        margin: '0 10px'
                      }}
                    />
                  </div>
                  <Translation>
                    {t => <div> {t('adminOrganizations.thisFTPHasBeenPreparedByArxis')} </div>}
                  </Translation>
                </XSuccessPanel>
              ) : (
                <XPanel style={{ marginTop: '10px' }}>
                  <div>
                    <ErrorIcon
                      style={{
                        height: '22px',
                        width: '22px',
                        margin: '0 10px'
                      }}
                    />
                  </div>
                  <Translation>
                    {t => <div>{t('adminOrganizations.thisFTPWillSoonBePreparedByArxis')}</div>}
                  </Translation>
                </XPanel>
              )
            ) : null}
          </div>
        )}
      </Wrapper>
    </XPaper>
  );
};

export default withRouter(InterfaceCard);

const Wrapper = styled('div')`
  background: #f5f5f5;
  border-radius: 6px;
  border-width-left: 3px;
  border-color: #38b9ec;
  padding: 20px;
`;
