import styled from '@emotion/styled';
import * as React from 'react';
import XForm, { IField } from 'src/components/layout/XForm';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { EditAccountForm } from '../AccountPage';
import i18n from '../../../i18n';

type OwnProps = {
  account?: AuthenticationAccount | EditAccountForm;
  registration: boolean;
  data?: any;
  margin?: boolean;
  inputErrors?: any;
  submitHandler?: () => void;
  callback: (name: string, value: string) => any;
};

type AllProps = OwnProps;

class AccountDetails extends React.Component<AllProps> {
  public render() {
    let fields: IField[] = [
      {
        title: i18n.t('account.emailAdress'),
        name: 'email',
        readonly: true,
        required: true,
        maxLength: 255
      },
      {
        title: i18n.t('account.password'),
        type: 'password',
        name: 'password',
        required: true,
        maxLength: 255
      },
      {
        title: i18n.t('account.confirmPass'),
        type: 'password',
        name: 'passwordValid',
        required: true,
        maxLength: 255
      }
    ];

    if (this.props.registration) {
      // registration
      fields = [
        {
          title: i18n.t('account.emailAdress'),
          name: 'email',
          readonly: false,
          required: true,
          maxLength: 255
        },
        {
          title: i18n.t('account.firstName'),
          name: 'firstName',
          fullWidth: false,
          required: true,
          maxLength: 255
        },
        {
          title: i18n.t('account.lastName'),
          name: 'lastName',
          fullWidth: false,
          required: true,
          maxLength: 255
        },
        {
          title: i18n.t('account.password'),
          type: 'password',
          name: 'password',
          required: true,
          maxLength: 255
        },
        {
          title: i18n.t('account.confirmPass'),
          type: 'password',
          name: 'passwordValid',
          required: true,
          maxLength: 255
        }
      ];
    }

    return (
      <XForm
        data={this.props.data ? this.props.data : this.props.account}
        inputErrors={this.props.inputErrors}
        margin={this.props.margin}
        fields={fields}
        verticalAlign={true}
        submitHandler={this.props.submitHandler}
        callback={this.props.callback}
      />
    );
  }
}

export default AccountDetails;
