import styled from '@emotion/styled';
import * as React from 'react';

type OwnProps = {
  style?: any;
  onScrollBottom?: () => void;
};

type AllProps = OwnProps;

type State = {
  overflowing: boolean;
  clientHeight: number;
};

class XScrollView extends React.Component<AllProps, State> {
  private listView: React.RefObject<HTMLDivElement>;

  constructor(props: AllProps) {
    super(props);
    this.listView = React.createRef();
    this.state = {
      overflowing: false,
      clientHeight: 0
    };
  }

  public componentDidMount() {
    if (!this.listView.current) return;

    this.setState({
      overflowing:
        this.listView.current.scrollTop + this.listView.current.clientHeight <
        this.listView.current.scrollHeight,
      clientHeight: this.listView.current.clientHeight
    });
  }

  public componentDidUpdate(prevProps, prevState) {
    if (!this.listView.current) return;

    const clientHeight = this.listView.current.clientHeight;
    if (clientHeight === prevState.clientHeight) return;

    this.setState({
      overflowing:
        this.listView.current.scrollTop + this.listView.current.clientHeight <
        this.listView.current.scrollHeight,
      clientHeight
    });
  }

  public handleScroll(e: any) {
    if (!this.listView.current) return;

    if (
      this.props.onScrollBottom &&
      this.listView.current.scrollTop + this.listView.current.clientHeight >
        this.listView.current.scrollHeight - 30
    )
      this.props.onScrollBottom();

    this.setState({
      overflowing:
        this.listView.current.scrollTop + this.listView.current.clientHeight <
        this.listView.current.scrollHeight
    });
  }

  public render() {
    return (
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          flex: '1',
          display: 'flex',
          ...this.props.style
        }}>
        <div
          style={{ overflowY: 'auto', flex: '1' }}
          ref={this.listView}
          onScroll={e => this.handleScroll(e)}>
          {this.props.children}
        </div>
        {this.state.overflowing && <OverflowFade />}
      </div>
    );
  }
}

export default XScrollView;

const OverflowFade = styled.div`
  position: absolute;
  bottom: 0;
  right: 11px;
  left: 0;
  flex: 0;
  height: 40px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
`;
