import { Action, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError } from 'src/helpers/apiClient';
import { authenticate, authenticateImpersonated } from './api';
import { ActionTypes, AuthenticationAccount, AuthenticationState } from './types';

export type FetchStatus = 'request' | 'success' | 'failure';

export type LoginAction = {
  type: ActionTypes.LOGIN;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    data?: AuthenticationAccount;
  };
};

const login = (
  status: FetchStatus,
  data?: AuthenticationAccount,
  errors?: ApiError[]
): LoginAction => ({
  type: ActionTypes.LOGIN,
  status,
  errors,
  payload: {
    data
  }
});

export const loginRequestThunk = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(login('request'));

    const result = await authenticate(email, password);

    if (result.IsSuccess) {
      sessionStorage.setItem('auth_token', result.JsonResponse.token);
      dispatch(login('success', result.JsonResponse));
    } else {
      dispatch(
        login('failure', undefined, [
          {
            errorCode: result.StatusCode,
            errorDescription: ''
          }
        ])
      );
    }
  };
};

export const loginImpersonatedRequestThunk = (token: string, impersonatedEmail: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(login('request'));

    const result = await authenticateImpersonated(token, impersonatedEmail);

    if (result.IsSuccess) {
      sessionStorage.setItem('auth_token', result.JsonResponse.token);
      sessionStorage.setItem('impersonated', true.toString());
      dispatch(login('success', result.JsonResponse));
    } else {
      dispatch(
        login('failure', undefined, [
          {
            errorCode: result.StatusCode,
            errorDescription: ''
          }
        ])
      );
    }
  };
};

export const logoutThunk = () => {
  return async (dispatch: Dispatch) => {
    dispatch(logout());
  };
};

type Logout = {
  type: ActionTypes.LOGOUT;
};

export const logout = (): Logout => ({
  type: ActionTypes.LOGOUT
});

export type AuthenticationAction = LoginAction | Logout;
