import { Dispatch } from 'redux';
import { CatalogMessageModel, Message } from 'src/models/Message';
import { OrderMessage } from 'src/models/OrderMessage';

import { requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError } from 'src/helpers/apiClient';
import { listCatalogMessages, listMessages, listOrderMessages, listOrganizationCatalogMessages, listSentOrderMessages } from './api';
import { ActionTypes } from './types';

export type FetchStatus = 'request' | 'success' | 'failure';

export interface GetMessagesAction {
  type: ActionTypes.GET_MESSAGES;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { messages?: Message[] };
}

export interface GetCatalogMessagesAction {
  type: ActionTypes.GET_CATALOG_MESSAGES;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { catalogMessages?: CatalogMessageModel[] };
}

export interface GetOrganizationCatalogMessagesAction {
  type: ActionTypes.GET_ORGANIZATION_CATALOG_MESSAGES;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { catalogMessages?: CatalogMessageModel[] };
}

export interface GetSentOrderMessagesAction {
  type: ActionTypes.GET_SENT_ORDER_MESSAGES;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { orderMessages?: OrderMessage[] };
}

export interface GetOrderMessagesAction {
  type: ActionTypes.GET_ORDER_MESSAGES;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { orderMessages?: OrderMessage[] };
}

const getMessages = (
  status: FetchStatus,
  data?: Message[],
  errors?: ApiError[]
): GetMessagesAction => ({
  type: ActionTypes.GET_MESSAGES,
  status,
  errors,
  payload: {
    messages: data
  }
});

const getCatalogMessages = (
  status: FetchStatus,
  data?: CatalogMessageModel[],
  errors?: ApiError[]
): GetCatalogMessagesAction => ({
  type: ActionTypes.GET_CATALOG_MESSAGES,
  status,
  errors,
  payload: {
    catalogMessages: data
  }
});

const getOrganizationCatalogMessages = (
  status: FetchStatus,
  data?: CatalogMessageModel[],
  errors?: ApiError[]
): GetOrganizationCatalogMessagesAction => ({
  type: ActionTypes.GET_ORGANIZATION_CATALOG_MESSAGES,
  status,
  errors,
  payload: {
    catalogMessages: data
  }
});

const getSentOrderMessages = (
  status: FetchStatus,
  data?: OrderMessage[],
  errors?: ApiError[]
): GetSentOrderMessagesAction => ({
  type: ActionTypes.GET_SENT_ORDER_MESSAGES,
  status,
  errors,
  payload: {
    orderMessages: data
  }
});

const getOrderMessages = (
  status: FetchStatus,
  data?: OrderMessage[],
  errors?: ApiError[]
): GetOrderMessagesAction => ({
  type: ActionTypes.GET_ORDER_MESSAGES,
  status,
  errors,
  payload: {
    orderMessages: data
  }
});


export const getMessagesThunk = (organizationId: string) => {
  return requestApiThunk(getMessages, () => listMessages(organizationId));
};

export const getCatalogMessagesThunk = (catalogId: string) => {
  return requestApiThunk(getCatalogMessages, () => listCatalogMessages( catalogId));
};

export const getOrganizationCatalogMessagesThunk = () => {
  return requestApiThunk(getOrganizationCatalogMessages, () => listOrganizationCatalogMessages());
};

export const getSentOrderMessagesThunk = () => {
  return requestApiThunk(getSentOrderMessages, () => listSentOrderMessages());
};

export const getOrderMessagesThunk = () => {
  return requestApiThunk(getOrderMessages, () => listOrderMessages());
};

export type Action =
  | GetMessagesAction
  | GetCatalogMessagesAction
  | GetOrganizationCatalogMessagesAction
  | GetSentOrderMessagesAction
  | GetOrderMessagesAction;
