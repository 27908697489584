import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import XButton, { XButtonStyle } from './XButton';
import { XContainer } from './XContainer';
import { XRow } from './XRow';
import { Translation } from 'react-i18next';

type OwnProps = {
  onSave?: any;
  onCancel?: any;
  saveText?: string;
  cancelText?: string;
  enabled?: boolean;
  enabledSave?: boolean;
  loading?: boolean;
  style?: any;
};

type AllProps = OwnProps;

const XFormButtons: React.SFC<AllProps> = props => {
  let saveState = true;
  if (props.enabledSave !== undefined || props.loading !== undefined) {
    if (props.enabledSave !== undefined) saveState = props.enabledSave === true;
    else if (props.loading !== undefined) saveState = props.loading === false;
  } else if (props.enabled !== undefined) saveState = props.enabled === true;

  return (
    <XContainer style={props.style}>
      <XRow space={true}>
        <Translation>
          {t => (
            <XButton
              staticcolor="#fff"
              textcolor="#676767"
              onClick={props.onCancel}
              active={props.enabled}>
              {props.cancelText ? props.cancelText : t('tooltip.cancel')}
            </XButton>
          )}
        </Translation>
        <Translation>
          {t => (
            <XButton
              colorscheme={XButtonStyle.Primary}
              onClick={() => (saveState ? props.onSave() : undefined)}
              active={saveState}
              loading={props.loading}>
              {props.saveText ? props.saveText : t('tooltip.save')}
            </XButton>
          )}
        </Translation>
      </XRow>
    </XContainer>
  );
};

export default XFormButtons;
