import styled from '@emotion/styled';
import * as React from 'react';

type OwnProps = {
  message?: string;
  size?: number;
  image?: any;
};

type AllProps = OwnProps;

const XNoContent: React.SFC<AllProps> = props => {
  return (
    <NoItems {...props}>
      <div>
        {props.image && <img src={props.image} />}
        { props.message && <div><h3>{ props.message}</h3></div>}
        <div>{props.children}</div>
      </div>
    </NoItems>
  );
};

export default XNoContent;

const NoItems = styled.div<OwnProps>`
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #888;
  & img {
    width: ${props => (props.size ? props.size + 'px' : '150px')};
    height: ${props => (props.size ? props.size + 'px' : '150px')};
  }
  & b {
    color: #666666
  }
 
  & h3 {
    font-size: 15px;
    font-weight: 600;
    color: #222222;
  }

  & a {
    text-decoration: underline;
    cursor: pointer;
  }
`;
