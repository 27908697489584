import styled from '@emotion/styled';
import InboxIcon from '@material-ui/icons/Inbox';
import DataIcon from '@material-ui/icons/SettingsEthernet';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Catalog } from 'src/models/Catalog';
import { Message } from 'src/models/Message';
import { OrganizationModel } from 'src/models/Organization';
import { RequestModel } from 'src/models/Request';
import { NavPortalPages } from 'src/pages/Routes';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { getMyCatalogsRequiringAction } from 'src/store/catalogs/selectors';
import { getMyOrganizationThunk } from 'src/store/organizations/actions';
import { getRequestsThunk } from 'src/store/requests/actions';
import { activeIncommingRequest } from 'src/store/requests/selectors';

import Logo from 'src/style/images/logos/main.svg';
import { mediaQuery } from 'src/style/MediaQueries';
import XBadge from '../layout/XBadge';
import XButton from '../layout/XButton';

import SearchIcon from '../../style/images/icons/Search.svg';
import UserIcon from '../../style/images/icons/User.svg';
import ShareIcon from '../../style/images/icons/Share.svg';
import PurchaseOrderIcon from '../../style/images/icons/PO.svg';

import i18n from 'src/i18n';

type StateProps = {
  messages: Message[];
  account: AuthenticationAccount;
  activeIncommingRequest: RequestModel[];
  catalogRequiringAction: Catalog[];
};

type DispatchProps = {
  getRequests: () => void;
  getMyOrganization: () => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

class XNavigationBar extends React.Component<AllProps> {
  public async componentDidMount() {
    this.props.getRequests();
    this.props.getMyOrganization();
  }

  public getName(name: string) {
    switch (name) {
      case 'Organization':
        return i18n.t('nav.myArxis');
      case 'Account':
        return i18n.t('nav.myProfile');
      case 'Explore':
        return i18n.t('nav.explore');
    }
    return name;
  }

  public getIcon(path: string, active: boolean) {
    switch (path) {
      case '/app/explore':
        return <Search active={active} image={SearchIcon} />;
      case '/app/profile':
        return <Account active={active} image={UserIcon} />;
      case '/app/dealer-portal':
        return <PurchaseOrder active={active} image={PurchaseOrderIcon} />;
      case '/app/manage':
        return <Data />;
      case '/app/my':
        const requestCount = this.props.activeIncommingRequest.length;
        const catalogCount = this.props.catalogRequiringAction.length;
        const totalCount = requestCount + catalogCount;
        return (
          <div>
            {totalCount > 0 && <XBadge count={totalCount} />}
            <Exchange active={active} image={ShareIcon} />
          </div>
        );
      case '/app/inbox':
        return (
          <div>
            <Inbox />
          </div>
        );
    }
    return null;
  }

  public render() {
    const pages = NavPortalPages.filter(
      p =>
        p.shouldShowCallback === undefined ||
        p.shouldShowCallback(this.props.account.isAdmin, this.props.account.organizationType)
    );

    return (
      <NavBarContainer>
        <NavBarContent>
          {pages.map(route => {
            const active = this.props.location.pathname.startsWith(route.path);
            return route.path === '/app/dealer-portal' &&
              window.location.href.match(/(arxis.io)/) ? null : (
              <NavLink key={route.path} to={route.path}>
                <div style={{ position: 'relative' }}>
                  <XButton
                    textcolor={active ? '#E7217B' : '#737373'}
                    staticcolor="#fff"
                    width="100%"
                    applyPadding={false}>
                    <NavBarButtonContent>
                      {this.getIcon(route.path, active)}
                      <NavLabel id="navLabel">{route.title && this.getName(route.title)}</NavLabel>
                    </NavBarButtonContent>
                  </XButton>
                  {active && (
                    <ArrowLeftIcon
                      style={{
                        color: active && '#E7217B',
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        top: '50%',
                        right: '-15px'
                      }}
                    />
                  )}
                </div>
              </NavLink>
            );
          })}
        </NavBarContent>
      </NavBarContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    messages: state.messages.messages,
    account: state.authentication.account,
    activeIncommingRequest: activeIncommingRequest(state),
    catalogRequiringAction: getMyCatalogsRequiringAction(state)
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getRequests: () => dispatch(getRequestsThunk()),
  getMyOrganization: () => dispatch(getMyOrganizationThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(XNavigationBar);

const NavBarContainer = styled('div')({
  backgroundColor: '#fff',
  width: '100%',
  position: 'relative'
});
const NavLabel = styled('label')({
  letterSpacing: 'normal'
});

const NavBarContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  [mediaQuery[0]]: {
    flexDirection: 'column'
  }
});

const NavBarButtonContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '8px',
  '& #navLabel': {
    textTransform: 'none',
    textDecoration: 'none',

    fontSize: '10px',
    alignSelf: 'center'
  }
});

const NavLink = styled(Link)({
  flex: 1,
  textAlign: 'center',
  textTransform: 'none',
  textDecoration: 'none'
});

const Data = styled(DataIcon)<any>({}, (props: any) => ({
  width: '30px',
  height: '30px',
  alignSelf: 'center'
}));

const Inbox = styled(InboxIcon)({
  width: '35px',
  height: '35px',
  alignSelf: 'center'
});

const Exchange = styled('div')<any>({}, (props: any) => ({
  backgroundColor: props.active ? '#E7217B' : '#737373',
  mask: 'url(' + props.image + ') no-repeat center',
  width: '30px',
  height: '30px',
  margin: 'auto'
}));

const Search = styled('div')<any>({}, (props: any) => ({
  backgroundColor: props.active ? '#E7217B' : '#737373',
  mask: 'url(' + props.image + ') no-repeat center',
  width: '30px',
  height: '30px',
  margin: 'auto'
}));

const Account = styled('div')<any>({}, (props: any) => ({
  backgroundColor: props.active ? '#E7217B' : '#737373',
  mask: 'url(' + props.image + ') no-repeat center',
  width: '30px',
  height: '30px',
  margin: 'auto'
}));

const PurchaseOrder = styled('div')<any>({}, (props: any) => ({
  backgroundColor: props.active ? '#E7217B' : '#737373',
  mask: 'url(' + props.image + ') no-repeat center',
  width: '30px',
  height: '30px',
  margin: 'auto'
}));
