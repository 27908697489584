import { Dispatch } from 'redux';
import { requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError } from 'src/helpers/apiClient';

import { Software } from '../../models/Software';
import { listSoftware } from './api';
import { ActionTypes } from './types';

type FetchStatus = 'request' | 'success' | 'failure';

export type GetSoftwareAction = {
  type: ActionTypes.GET_SOFTWARE;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { software?: Software[] };
};

const getSoftwareAction = (
  status: FetchStatus,
  data?: Software[],
  errors?: ApiError[]
): GetSoftwareAction => ({
  type: ActionTypes.GET_SOFTWARE,
  status,
  errors,
  payload: {
    software: data
  }
});

export const getSoftwareThunk = () => {
  return requestApiThunk(getSoftwareAction, listSoftware);
};

export const sortSoftware = (a: Software, b: Software) => {
  if (a.creator === 'Bloemert') return 1;
  return a.creator.localeCompare(b.creator);
};

export type Action = GetSoftwareAction;
