import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { timingSafeEqual } from 'crypto';
import * as React from 'react';
import { Translation } from 'react-i18next';
import i18n from 'src/i18n';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { XContainer } from 'src/components/layout/XContainer';
import XForm from 'src/components/layout/XForm';
import XFormButtons from 'src/components/layout/XFormButtons';
import XImageEditor from 'src/components/layout/XImageEditor';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../store';
import * as accountActions from '../../store/accounts/actions';
import * as accountSelects from '../../store/accounts/selectors';
import { RegisterAccountFormData } from '../../store/accounts/types';
import OrganizationDetails from '../myltx/account/OrganizationDetails';

type StateProps = { data: RegisterAccountFormData };

type DispatchProps = {
  storeInformation: (data: RegisterAccountFormData) => void;
  createAccount: (param: RegisterAccountFormData) => Promise<ApiResult>;
  showNotification: (message: string, type: MessageType) => void;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps;

type State = {
  formData: RegisterAccountFormData;
  inputErrors: any;
  waitingRegistration: boolean;
};

class AdditionalInfoPage extends React.Component<AllProps, State> {
  editor: any;
  setEditorRef: (editor: any) => any;

  constructor(props: AllProps) {
    super(props);
    this.setEditorRef = editor => (this.editor = editor);

    this.state = {
      waitingRegistration: false,
      formData: props.data,
      inputErrors: {
        imageURL: false,
        description: false,
        website: false
        // iban: false,
        // btwNumber: false
      }
    };
  }

  public componentDidMount() {
    if (!this.state.formData.email || this.state.formData.email === '')
      this.props.history.push('/register');
  }

  public handleFormChange = (name: string, value: any) => {
    const formData = Object.assign({}, this.state.formData);
    formData[name] = value;
    this.setState({ formData });
  };

  public previousPage = () => {
    this.props.history.goBack();
  };

  public async storeForm() {
    const inputErrors = Object.assign({}, this.state.inputErrors);
    const valid = true;
    // any error checks here?
    this.setState({ inputErrors });

    const data = this.state.formData;
    const imgResult = await this.editor.getResult();
    if (imgResult) {
      data.image = imgResult;
      this.handleFormChange('image', imgResult);
    }

    if (valid) {
      this.setState({ waitingRegistration: true });
      this.props.storeInformation(data);
      this.props.createAccount(data).then(result => {
        this.setState({ waitingRegistration: false });
        if (result.IsSuccess) {
          this.props.history.push('/confirmationemailsent');
        } else {
          if (result.Errors && result.Errors[0].errorCode === 'LTX3020')
            this.props.showNotification(i18n.t('register.emailAlreadyInUse'), 'error');
          else
            this.props.showNotification(
              i18n.t('register.error') + mapError(result.Errors),
              'error'
            );
        }
      });
    }
  }

  public render() {
    return (
      <Translation>
        {t => (
          <div>
            <LogoLabel> {t('register.companyLogo')} </LogoLabel>
            <XImageEditor
              ref={this.setEditorRef}
              image={this.state.formData.image}
              width={200}
              height={200}
              style={{ margin: '15px' }}
              onChange={blob => {
                this.handleFormChange('image', blob);
              }}
            />

            <XForm
              data={this.state.formData}
              inputErrors={this.state.inputErrors}
              submitHandler={async () => await this.storeForm()}
              callback={this.handleFormChange}
              margin={false}
              fields={[
                { title: t('account.desc'), name: 'description', multiline: true, maxLength: 5000 },
                { title: 'Website', name: 'website', maxLength: 255 }
                // { title: 'IBAN-nummer', name: 'iban' },
                // { title: 'Btw-nummer', name: 'btwNumber' }
              ]}
            />
            <XFormButtons
              onCancel={this.previousPage}
              onSave={async () => await this.storeForm()}
              cancelText={t('tooltip.PREVIOUS')}
              style={{ padding: '15px 0 0 0' }}
              saveText={t('register.Register')}
              enabled={!this.state.waitingRegistration}
              loading={this.state.waitingRegistration}
            />
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  data: accountSelects.getRegisterState(state.account)
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type: MessageType) =>
    dispatch(showNotification(message, type)),
  storeInformation: (data: RegisterAccountFormData) => dispatch(accountActions.storeNewData(data)),
  createAccount: (param: RegisterAccountFormData) =>
    dispatch(accountActions.createAccountThunk(param))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoPage);

export const RegisterLoginContent = styled('div')({
  margin: '25px',
  display: 'flex',
  flexDirection: 'column',
  height: '75%'
});

const LogoLabel = styled.div`
  padding: 0 15px;
  color: #aaa;
`;
