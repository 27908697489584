import styled from '@emotion/styled';
import { LinearProgress } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Clear';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import XCatalogRow from 'src/components/domain/XCatalogRow';
import { XContainer } from 'src/components/layout/XContainer';
import { XImage, XImageContainer } from 'src/components/layout/XImage';
import XPageContent from 'src/components/layout/XPageContent';
import XForm from 'src/components/layout/XForm';
import XPopup from 'src/components/layout/XPopup';
import { XPaper, XPaperText, XPaperTitle } from 'src/components/layout/XPaper';
import { XRow, XRowCell } from 'src/components/layout/XRow';
import XSelectItem from 'src/components/layout/XSelectItem';
import XTable, { TableRow } from 'src/components/layout/XTable';
import { ApiResult } from 'src/helpers/apiClient';
import { Catalog, PublicationStatus } from 'src/models/Catalog';
import {
  MyOrganizationModel,
  OrganizationModel,
  ConnectionIdentifier,
  OrganizationConnection
} from 'src/models/Organization';
import { ApplicationState } from 'src/store';
import * as catalogActions from 'src/store/catalogs/actions';
import * as messageActions from 'src/store/messages/actions';
import { showConfirmation, showNotification } from 'src/store/layout/actions';
import * as organizationActions from 'src/store/organizations/actions';

import XErrorContent from 'src/components/domain/XErrorContent';
import XOrganizationTitle from 'src/components/domain/XOrganizationTitle';
import XScrollView from 'src/components/layout/XScrollView';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { mapError } from 'src/helpers/errorMap';
import { getImageUrl } from 'src/helpers/formatHelpers';
import { MessageType } from 'src/store/layout/types';
import { Software } from 'src/models/Software';
import {
  deleteConnectionIdentifierThunk,
  getConnectionIdentifiersThunk
} from 'src/store/organizations/actions';
import { getSoftwareThunk } from 'src/store/software/actions';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
import { CatalogMessageModel } from '../../../models/Message';

type StateProps = {
  catalogMessages?: CatalogMessageModel[];
  myOrganization?: MyOrganizationModel;
  organizationModel?: OrganizationModel;
  connectionIdentifiers: ConnectionIdentifier[];
  software: Software[];
  loading: boolean;
};

type DispatchProps = {
  getOrganizationCatalogMessages: () => void;
  showNotification: (message: string, type: MessageType) => void;
  getOrganization: (organizationId: string) => Promise<ApiResult>;
  getMyOrganization: () => void;
  editAvailableCatalog: (
    targetOrganizationId: string,
    catalogId: string,
    available: boolean
  ) => Promise<ApiResult>;
  getConnectionIdentifiers: (organizationId: string) => void;
  addConnectionIdentifier: (
    organizationConnetionId: string,
    value: string,
    softwareId: string
  ) => Promise<ApiResult>;
  deleteConnectionIdentifier: (id: string) => Promise<ApiResult>;
  showConfirm: (title: string, message: string, onConfirm: () => void) => void;
  getSoftware(): () => void;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps<{ id: string }>;

type State = {
  showAddConnectionIdentifier: boolean;
  newConnectionIdentifier: {
    value: string;
    softwareId: string;
  };
  editing: boolean;
};

class ConnectionPage extends React.Component<AllProps, State> {
  public constructor(props: AllProps) {
    super(props);
    this.state = {
      showAddConnectionIdentifier: false,
      newConnectionIdentifier: {
        value: '',
        softwareId: ''
      },
      editing: false
    };
  }

  public componentDidMount() {
    this.props.getOrganization(this.props.match.params.id).then(result => {
      if (!result.IsSuccess && result.StatusCode === 401) this.props.history.push('/app/start');
    });

    this.props.getMyOrganization();
    this.props.getConnectionIdentifiers(this.props.match.params.id);
    this.props.getSoftware();
    this.props.getOrganizationCatalogMessages();
  }

  public toggleAvailableCatalog = (catalog: Catalog, state: boolean) => {
    if (!this.props.organizationModel) return;

    this.props
      .editAvailableCatalog(this.props.organizationModel.organization.id, catalog.id, state)
      .then(result => {
        if (result.IsSuccess) {
          this.props.showNotification(i18n.t('notification.availabilityAdjusted'), 'success');
          this.setState({ editing: false });
        } else
          this.props.showNotification(
            i18n.t('notification.adjustingAvailabilityError') + mapError(result.Errors),
            'error'
          );
      });
  };

  public showAddConnectionIdentifier() {
    this.setState({ showAddConnectionIdentifier: true });
  }

  public addConnectionIdentifier() {
    if (!this.state.newConnectionIdentifier.value) return;

    // set button to loading
    this.props
      .addConnectionIdentifier(
        this.getConnectionId(),
        this.state.newConnectionIdentifier.value,
        this.state.newConnectionIdentifier.softwareId
      )
      .then(result => {
        if (result.IsSuccess) {
          // reload identifiers
          this.props.getConnectionIdentifiers(this.props.match.params.id);
          // show notification
          this.setState({
            showAddConnectionIdentifier: false,
            newConnectionIdentifier: { value: '', softwareId: '' }
          });
          this.props.showNotification(i18n.t('notification.idNumAdded'), 'success');
        } else
          this.props.showNotification(
            i18n.t('notification.idNumAddingError') + mapError(result.Errors),
            'error'
          );
      });
  }

  public removeConnectionIdentifier(identifier: ConnectionIdentifier) {
    this.props.showConfirm(
      i18n.t('confirmation.deleteIdentificationNumber'),
      i18n.t('confirmation.sureToDeleteIdNum'),
      () => {
        this.props.deleteConnectionIdentifier(identifier.id).then(apiResult => {
          if (apiResult.IsSuccess) {
            // reload identifiers
            this.props.getConnectionIdentifiers(this.props.match.params.id);
            this.props.showNotification(i18n.t('notification.idNumRemoved'), 'success');
          } else {
            this.props.showNotification(
              i18n.t('notification.somethingWentWrongWhileRemovingIdNum') +
                mapError(apiResult.Errors),
              'error'
            );
          }
        });
      }
    );
  }

  public handleNewConnectionIdentifierChange = (name: any, value: any) => {
    const identifierData = { ...this.state.newConnectionIdentifier };
    identifierData[name] = value;
    this.setState({ newConnectionIdentifier: identifierData });
  };

  public getConnectionId(): string {
    const connection =
      this.props.myOrganization?.organizationConnections?.find(
        dc =>
          dc.sourceOrganizationId === this.props.organizationModel?.organization.id ||
          dc.targetOrganizationId === this.props.organizationModel?.organization.id
      )?.id || '';

    return connection;
  }

  public render() {
    const props = this.props;

    if (props.loading)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <LinearProgress />
          </XPaper>
        </XPageContent>
      );
    else if (!props.organizationModel || !props.myOrganization)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <XErrorContent />
          </XPaper>
        </XPageContent>
      );

    return (
      <ErrorBoundary>
        <XPageContent>
          <Translation>
            {t => (
              <XPopup
                buttonSaveText={t('tooltip.Save')}
                buttonCancelText={t('tooltip.Cancel')}
                cancelOnPagClick={true}
                onSave={() => this.addConnectionIdentifier()}
                onCancel={() => this.setState({ showAddConnectionIdentifier: false })}
                title={t('account.addIdNum')}
                open={this.state.showAddConnectionIdentifier}>
                <div>
                  <XForm
                    data={this.state.newConnectionIdentifier}
                    callback={(name, value) =>
                      this.handleNewConnectionIdentifierChange(name, value)
                    }
                    fields={[
                      // {
                      //   title: 'Software',
                      //   name: 'softwareId',
                      //   required: false,
                      //   type: 'select',
                      //   options: props.software.map(t => {
                      //     return { name: t.name, value: t.id };
                      //   })
                      // },
                      { title: t('tooltip.number'), name: 'value', required: true, maxLength: 255 }
                    ]}
                  />
                </div>
              </XPopup>
            )}
          </Translation>
          <StyledPaper style={{ minHeight: '300px' }}>
            <XRow>
              <XContainer>
                <XImageContainer width={120} height={120}>
                  {props.loading ? (
                    <div />
                  ) : (
                    <XImage
                      src={getImageUrl(props.organizationModel.organization.imageURL, undefined)}
                      alt=""
                      onError={(e: any) => {
                        e.target.onError = null;
                        e.target.src = generateAvatar(
                          props.organizationModel?.organization.name ?? '',
                          120
                        );
                      }}
                    />
                  )}
                </XImageContainer>
              </XContainer>
              <XRowCell>
                <XContainer>
                  <div
                    style={{
                      height: '120px',
                      overflow: 'hidden'
                    }}>
                    <TitleWrapper>
                      <XOrganizationTitle
                        organization={props.organizationModel.organization}
                        fontSize={'16px'}
                        bold={true}
                      />
                    </TitleWrapper>
                    <XPaperText>{props.organizationModel.organization.description}</XPaperText>
                  </div>
                </XContainer>
              </XRowCell>
            </XRow>

            <div style={{ position: 'relative', marginTop: '10px' }}>
              <Translation>
                {t => <XPaperTitle>{t('catalogs.catalogsAvailability')}</XPaperTitle>}
              </Translation>
              {props.myOrganization.catalogs.length === 0 ? (
                <Translation>
                  {t => (
                    <NoItems>
                      {t('catalogs.dontHaveAnyCatalogsToMakeAvailable')}{' '}
                      <Link to="/app/my/catalogs/new">{t('catalogs.publishCatalogNow')}</Link>
                    </NoItems>
                  )}
                </Translation>
              ) : (
                undefined
              )}
              <XScrollView style={{ maxHeight: 'calc(100vh - 330px)', overflowY: 'auto' }}>
                <XTable>
                  {props.myOrganization.catalogs
                    .filter(c => c.publicationStatus == PublicationStatus.Published)
                    .map(obj => {
                      const dataConnection = props.myOrganization?.catalogConnections.find(
                        c =>
                          c.targetOrganizationId === props.organizationModel?.organization.id &&
                          c.catalogId === obj.id
                      );
                      const catalogMessage = props.catalogMessages?.find(
                        x =>
                          x.catalogVersionId === obj.currentVersionId &&
                          x.toOrganizationId === props.organizationModel?.organization.id
                      );
                      return (
                        <XSelectItem
                          key={obj.id}
                          value={dataConnection?.active === true}
                          onToggle={c => {
                            this.toggleAvailableCatalog(obj, c);
                          }}>
                          <XCatalogRow
                            showDownloads={true}
                            downloads={catalogMessage != null? catalogMessage.downloads : 0}
                            link={'/app/my/catalogs/edit/' + obj.id}
                            catalog={obj}
                          />
                        </XSelectItem>
                      );
                    })}
                </XTable>
              </XScrollView>
            </div>
          </StyledPaper>
          <Translation>
            {t => (
              <XPaper style={{ marginTop: '15px' }}>
                <StyledPaperTitle>
                  {t('catalogs.idNumber')}
                  <LinkContent onClick={() => this.showAddConnectionIdentifier()}>
                    <AddCircleIcon style={{ verticalAlign: 'middle' }} />
                  </LinkContent>
                </StyledPaperTitle>
                <XTable headers={[t('tooltip.Value'), '']}>
                  {this.props.connectionIdentifiers.map(cId => (
                    <TableRow key={cId.id}>
                      <div style={{ flex: '1' }}>{cId.value}</div>
                      <div style={{ flex: '1', textAlign: 'right' }}>
                        <DeleteIcon
                          style={{
                            display: 'block',
                            color: '#ed677a',
                            verticalAlign: 'middle',
                            cursor: 'pointer'
                          }}
                          onClick={() => this.removeConnectionIdentifier(cId)}
                        />
                      </div>
                    </TableRow>
                  ))}
                </XTable>
              </XPaper>
            )}
          </Translation>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    catalogMessages: state.messages.catalogMessages,
    myOrganization: state.organizations.myOrganization,
    organizationModel: state.organizations.detail,
    connectionIdentifiers: state.organizations.connectionIdentifiers,
    software: state.software.all,
    loading: state.catalogs.loading
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getOrganizationCatalogMessages: () => dispatch(messageActions.getOrganizationCatalogMessagesThunk()),
  showNotification: (message: string, type: MessageType) =>
    dispatch(showNotification(message, type)),
  getOrganization: (organizationId: string) =>
    dispatch(organizationActions.getOrganizationThunk(organizationId)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk()),
  editAvailableCatalog: (targetOrganizationId: string, catalogId: string, available: boolean) =>
    dispatch(
      catalogActions.editCatalogAvailableThunk([targetOrganizationId], catalogId, available)
    ),
  getConnectionIdentifiers: (organizationId: string) =>
    dispatch(organizationActions.getConnectionIdentifiersThunk(organizationId)),
  addConnectionIdentifier: (organizationConnectionId: string, value: string, softwareId: string) =>
    dispatch(
      organizationActions.addConnectionIdentifierThunk(organizationConnectionId, value, softwareId)
    ),
  deleteConnectionIdentifier: (id: string) => dispatch(deleteConnectionIdentifierThunk(id)),
  showConfirm: (title: string, message: string, onConfirm: () => void) =>
    dispatch(showConfirmation(title, message, onConfirm)),
  getSoftware: () => dispatch(getSoftwareThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionPage);

const NoItems = styled.div`
  padding: 15px;
  & a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledPaper = styled(XPaper)`
  min-height: 300px;
  max-height: calc(100% - 40px);
  overflow-y: auto;
`;

const StyledPaperTitle = styled(XPaperTitle)`
  margin-bottom: 20px;
  position: relative;
`;

const TitleWrapper = styled.div`
  margin: 0 15px;
`;

const LinkContent = styled.div({
  right: '-15px',
  position: 'absolute',
  cursor: 'pointer',
  top: '-15px',
  color: 'rgb(245, 0, 87)'
});
