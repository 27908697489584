import styled from '@emotion/styled';
import * as React from 'react';

import { Link } from 'react-router-dom';
import { formatDateToString } from 'src/helpers/formatHelpers';
import { ConnectRequest, RequestModel } from 'src/models/Request';
import { XSecActionButton, XActionButton } from '../layout/XActionButton';
import { XActionStrip } from '../layout/XActionStrip';
import XButton, { XButtonStyle } from '../layout/XButton';
import { XRowCell } from '../layout/XRow';
import { TableRow } from '../layout/XTable';
import i18n from '../../i18n';
import { Translation } from 'react-i18next';

type OwnProps = {
  requestModel: RequestModel;
  ownOrganizationId: string;
  loadingRequest: boolean;
  onApprove: () => void;
  onDisapprove: () => void;
};

type AllProps = OwnProps;

const statusSwitch = (request: ConnectRequest) => {
  let state = 100;

  if (request.approvedDate !== undefined) {
    state = 200;
  }
  if (request.disapprovedDate !== undefined) {
    state = 300;
  }

  switch (state) {
    case 100:
      return { text: i18n.t('traffic.Pending'), color: 'gray', color2: '#4AC0D7' };
    case 200:
      return { text: i18n.t('traffic.Accepted'), color: '#52bfd4', color2: '#808080' };
    case 300:
      return { text: i18n.t('traffic.turnedDown'), color: '#e7237b', color2: '#808080' };
    default:
      return { text: i18n.t('traffic.notFound'), color: '#000', color2: '#808080' };
  }
};

const XRequestRow: React.FC<AllProps> = props => {
  const ownRequest = props.requestModel.requestOrganization.id === props.ownOrganizationId;
  const needsAnswer =
    !props.requestModel.connectRequest.approvedDate &&
    !props.requestModel.connectRequest.disapprovedDate &&
    !ownRequest;
  const status = statusSwitch(props.requestModel.connectRequest);

  return (
    <TableRow>
      <TitleCell>
        <Link
          to={
            '/app/my/connections/organization/' +
            (ownRequest
              ? props.requestModel.targetOrganization.id
              : props.requestModel.requestOrganization.id)
          }>
          {ownRequest
            ? props.requestModel.targetOrganization.name
            : props.requestModel.requestOrganization.name}
        </Link>
      </TitleCell>
      <RequestCell>
        {formatDateToString(new Date(props.requestModel.connectRequest.requestDate))}
      </RequestCell>
      <XRowCell>
        {needsAnswer ? (
          <div>
            <Translation>
              {t => (
                <XActionStrip className={props.loadingRequest ? 'disabled' : ''}>
                  <XSecActionButton onClick={() => props.onDisapprove()}>
                    {t('traffic.Reject')}
                  </XSecActionButton>
                  <XActionButton onClick={() => props.onApprove()}>
                    {t('traffic.Approve')}
                  </XActionButton>
                </XActionStrip>
              )}
            </Translation>
          </div>
        ) : (
          <StatusText color={status.color}>{status.text}</StatusText>
        )}
      </XRowCell>
    </TableRow>
  );
};

export default XRequestRow;

const TitleCell = styled(XRowCell)`
  line-height: 30px;
`;

const RequestCell = styled(XRowCell)`
  line-height: 30px;
`;

const StatusText = styled.div`
  padding: 0 15px;
  line-height: 24px;
  display: inline-block;
  margin: 5px;
  color: ${props => props.color};
  position: relative;
  font-size: 12px;
  border-radius: 20px;
  border: 1px solid ${props => props.color};
  letter-spacing: 0.6px;
  font-weight: bold;
  text-transform: uppercase;
`;
