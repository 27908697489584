import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Checkbox, FormControlLabel, Switch, SwitchProps } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import * as React from 'react';

type OwnProps = {
  label?: string;
  placeholder?: string;
};

type AllProps = OwnProps & SwitchProps;

const XSwitch: React.SFC<AllProps> = props => {
  return (
    <div>
      <Label
        control={<Switch color="primary" {...props} />}
        label={props.label}
        labelPlacement="start"
      />
    </div>
  );
};

export default XSwitch;

const Label = styled(FormControlLabel)`
  width: calc(100% - 20px);
  & .MuiFormControlLabel-label {
    width: 100%;
  }
`;
