import { Step, StepButton, StepLabel, Stepper } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { XContainer } from 'src/components/layout/XContainer';
import {
  XPaperCenter,
  XPaperContainer,
  XPaperSubtitle,
  XPaperTitle
} from 'src/components/layout/XPaper';
import XPageRouter from 'src/components/shared/XPageRouter';
import { PageRoute } from 'src/models/Route';

import { CatalogFormData } from '../../../store/catalogs/types';
import AccessPage from './WizardAccessPage';
import BasicPage from './WizardBasicPage';
import FilePage from './WizardFilePage';
import ResultPage from './WizardResultPage';
import ValidationPage from './WizardValidationPage';

const wizardPath = '/app/my/catalogs/validate';

export const ValidateFileRoute: PageRoute = {
  path: `${wizardPath}`,
  exact: true,
  component: FilePage,
  title: 'Bestand'
};

export const ValidateValidationRoute: PageRoute = {
  path: `${wizardPath}/validation`,
  exact: true,
  component: ValidationPage,
  title: 'Validatie'
};

const Routes: PageRoute[] = [ValidateFileRoute, ValidateValidationRoute];

type State = {};
type StateProps = { data: CatalogFormData };
type AllProps = RouteComponentProps & StateProps;

class WizardRouter extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
  }

  public render() {
    let active = 0;
    for (let i = 0; i < Routes.length; i++) {
      if (this.props.location.pathname === Routes[i].path) {
        active = i;
      }
    }

    return (
      <XPaperCenter style={{ width: '700px' }}>
        <XContainer style={{ overflowY: 'auto' }}>
          <XPaperTitle>Catalogus valideren </XPaperTitle>
          <XPaperSubtitle>
            Valideer of een catalogus bestand valide is op basis van opbouw en inhoud
          </XPaperSubtitle>
          <XContainer style={{ padding: '30px 15px 15px 15px' }}>
            <XPaperContainer>
              <Stepper
                activeStep={active}
                style={{ backgroundColor: 'transparent' }}
                alternativeLabel={true}>
                {Routes.map((route, index) => {
                  let title: any = route.title;
                  if (index === active) {
                    title = <b>{route.title}</b>;
                  }
                  return (
                    <Step key={index}>
                      <StepButton
                        active={index === active}
                        completed={index < active}
                        onClick={() => {
                          this.props.history.push(route.path);
                        }}>
                        <StepLabel>{title}</StepLabel>
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
            </XPaperContainer>
          </XContainer>
          <XContainer>
            <XPageRouter routes={[...Routes]} {...this.props} />
          </XContainer>
        </XContainer>
      </XPaperCenter>
    );
  }
}

export default WizardRouter;
