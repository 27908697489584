import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './pages/index/locales/en.json';
import translationNL from './pages/index/locales/nl.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      nl: {
        translation: translationNL
      }
    },
    lng: 'nl',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
