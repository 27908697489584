import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

import { Link } from 'react-router-dom';
import { formatDateToString } from 'src/helpers/formatHelpers';
import { Catalog, ProcessingStatus, PublicationStatus } from 'src/models/Catalog';
import { XRow, XRowCell } from '../layout/XRow';
import { TableRow } from '../layout/XTable';
import { getProcessingStatusText, getPublicationStatusText } from 'src/constants/Catalog';
import PublishIcon from '@material-ui/icons/Publish';
import WarningIcon from '@material-ui/icons/Warning';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { Translation } from 'react-i18next';

type OwnProps = {
  catalog: Catalog;
  link?: string;
  showDownloads?: boolean;
  showUploadDate?: boolean;
  showPublicationStatus?: boolean;
  showPublicationDate?: boolean;
  showProcessingStatus?: boolean;
  showProcessingIcon?: boolean;
  downloads?: number;
};

type AllProps = OwnProps;

const XCatalogRow: React.SFC<AllProps> = props => {
  const published = props.catalog.publicationStatus === PublicationStatus.Published;
  const valid =
    props.catalog.processingStatus === ProcessingStatus.Validated ||
    props.catalog.processingStatus === ProcessingStatus.ValidatedWithWarnings;
  const invalid = props.catalog.processingStatus === ProcessingStatus.ValidationFailed;
  const waitingLine = props.catalog.processingStatus === ProcessingStatus.Uploaded;

  const content = (
    <TableRow>
      <StyledCell>{props.catalog.title}</StyledCell>
      {props.showDownloads ? (
        <Translation>
          {t => <StyledCell>{props.downloads ?? props.catalog.downloads}x</StyledCell>}
        </Translation>
      ) : (
        <StyledCell>{props.catalog.organizationName}</StyledCell>
      )}
      {props.showUploadDate ? (
        <StyledCell>{formatDateToString(props.catalog.lastCreationDate)}</StyledCell>
      ) : null}
      {props.showProcessingStatus ? (
        <StyledCell>{getProcessingStatusText(props.catalog.processingStatus)}</StyledCell>
      ) : null}
      {props.showPublicationStatus ? (
        <StyledCell>{getPublicationStatusText(props.catalog.publicationStatus)}</StyledCell>
      ) : null}
      {props.showPublicationDate ? (
        <StyledCell>
          {props.catalog.publicationDate !== undefined
            ? formatDateToString(props.catalog.publicationDate)
            : '-'}
        </StyledCell>
      ) : null}
      {props.showProcessingIcon ? (
        <StyledCell>
          {/* {waitingLine && <HourglassEmptyIcon style={{color: 'orange'}} />}
          {(valid && !published && <PublishIcon style={{color: 'green'}} />)}
          {(invalid && <WarningIcon style={{color: 'red'}} />)} */}
          {waitingLine && <HourglassEmptyIcon />}
          {valid && !published && <PublishIcon />}
          {invalid && <WarningIcon />}
        </StyledCell>
      ) : null}
    </TableRow>
  );

  return props.link ? <Link to={props.link ?? ''}>{content}</Link> : content;
};

export default XCatalogRow;

const StyledCell = styled(XRowCell)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
