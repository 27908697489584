import * as React from 'react';
import XNavigation from 'src/components/layout/XNavigation';
import { XSideBar, XSideBarHeader } from 'src/components/layout/XSideBar';

import { ValidationRoute } from './ValidationRouter';

type OwnProps = {};

type AllProps = OwnProps;

const ValidationSideBar: React.SFC<AllProps> = props => (
  <XSideBar>
    <XSideBarHeader>Validatie</XSideBarHeader>
    <XNavigation routes={[{ name: 'Validatie', route: ValidationRoute.path }]} callback={''} />
  </XSideBar>
);

export default ValidationSideBar;
