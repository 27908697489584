import styled from '@emotion/styled';
import React from 'react';
import { useState } from 'react';
import { Collapse } from 'react-collapse';
import { Translation } from 'react-i18next';
function Question(props) {
  const [opened, setOpen] = useState(false);
  return (
    <div>
      <Card onClick={() => setOpen(!opened)}>
        <div className="question">
          {opened ? '- ' : '+ '}
          {props.question}
        </div>
        <Collapse isOpened={opened}>
          <p
            style={{ margin: 0, padding: '2px 0px 25px 15px' }}
            dangerouslySetInnerHTML={{ __html: props.answer }}
          />
        </Collapse>
      </Card>
    </div>
  );
}

export default () => {
  return (
    <Translation>
      {t => (
        <div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div>
                <H6>{t('faq.general')}</H6>
                <Question question={t('faq.qHowToJoin')} answer={t('faq.aHowToJoin')} />
                <Question question={t('faq.qUnqique')} answer={t('faq.aUnqique')} />
              </div>
              <div>
                <H6>{t('faq.operation')}</H6>
                <Question question={t('faq.qHowDoesItWork')} answer={t('faq.aHowDoesItWork')} />
                <Question question={t('faq.qTransform')} answer={t('faq.aTransform')} />
              </div>
              <div>
                <H6>{t('faq.safety')}</H6>
                <Question
                  question={t('faq.qCatalogProtection')}
                  answer={t('faq.aCatalogProtection')}
                />
                <Question question={t('faq.qConnections')} answer={t('faq.aConnections')} />
              </div>
            </div>
            <div className="col-12  col-lg-6">
              <div>
                <H6>{t('faq.formats')}</H6>
                <Question question={t('faq.qStandards')} answer={t('faq.aStandards')} />
                <Question question={t('faq.qFormats')} answer={t('faq.aFormats')} />
              </div>
              <div>
                <H6>{t('faq.customService')}</H6>
                <Question question={t('faq.qConnector')} answer={t('faq.aConnector')} />
                <Question question={t('faq.qCustomFormat')} answer={t('faq.aCustomFormat')} />
              </div>
              <div>
                <H6>{t('faq.pricing')}</H6>
                <Question question={t('faq.qPrices')} answer={t('faq.aPrices')} />
                <Question question={t('faq.qPayment')} answer={t('faq.aPayment')} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const H6 = styled('h6')({
  fontWeight: 'bold',
  marginTop: '20px',
  marginBottom: '5px'
});

const Card = styled('div')({
  borderRadius: '2px',
  padding: '5px',
  cursor: 'pointer',
  fontSize: '1.1em',
  '& .question': {
    fontWeight: 500
  },
  '& p': {
    marginTop: '5px',
    paddingLeft: '15px',
    borderLeft: '1px solid #eaeaea'
  },
  '& a': {
    fontWeight: 600
  }
});
//export default LTXUsers;
