import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { XActionButton } from 'src/components/layout/XActionButton';
import {
  InterfaceAuthentication,
  InterfaceType,
  InterfaceMethod,
  InterfaceSettingsKey,
  StockModel
} from 'src/models/Stock';
import { saveStockInterface } from 'src/store/stocks/api';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import * as stockActions from 'src/store/stocks/actions';
import { ApiResult } from 'src/helpers/apiClient';
import { MessageType } from 'src/store/layout/types';
import { mapError } from 'src/helpers/errorMap';

type StateProps = {
  stock?: StockModel;
};

type OwnProps = {
  stockId?: string;
};

type DispatchProps = {
  showNotification: (message: string, type?: MessageType) => void;
  getStockById: (id: string) => Promise<ApiResult>;
};

type AllProps = OwnProps & DispatchProps & StateProps;

const ApiInterfaceMethod: FC<AllProps> = props => {
  const [url, setUrl] = useState('');
  const [authentication, setAuthentication] = useState(`${InterfaceAuthentication.None}`);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');

  const history = useHistory();

  useEffect(() => {
    props.stockId &&
      props.getStockById(props.stockId).then(() => {
        if (!!props.stock?.interfaces.length) {
          if (props.stock?.interfaces[0].method === InterfaceMethod.Api) {
            setUrl(`${props.stock?.interfaces[0].settings.Url}`);
            setAuthentication(`${props.stock?.interfaces[0].authentication}`);
            setUserName(`${props.stock?.interfaces[0].settings.Username}`);
            setPassword(`${props.stock?.interfaces[0].settings.Password}`);
            setToken(`${props.stock?.interfaces[0].settings.Token}`);
          }
        }
      });
  }, []);

  const onChangeHanlder = (e: any) => {
    setAuthentication(e.target.value);
  };

  const stockInterface = {
    method: InterfaceMethod.Api,
    type: InterfaceType.StockSend,
    authentication: authentication,
    id: props.stock ? (props.stock.interfaces.length > 0 ? props.stock.interfaces[0].id : '') : '',
    settings: {
      [InterfaceSettingsKey.Url]: url,
      [InterfaceSettingsKey.Username]: userName,
      [InterfaceSettingsKey.Password]: password,
      [InterfaceSettingsKey.Token]: token,
      [InterfaceSettingsKey.PortalName]: ''
    }
  };

  const saveInterface = async () => {
    const apiResult = await saveStockInterface(stockInterface, props.stockId);
    if (apiResult.IsSuccess) {
      history.push(`/app/my/stocks/edit/${props.stockId}`);
    } else {
      props.showNotification('Error' + mapError(apiResult.Errors), 'error');
    }
  };

  return (
    <div>
      <TextField value={url} onChange={e => setUrl(e.target.value)} label="Full Url" fullWidth />
      <XSelectAuth>
        <span>Select type of authentication:</span>
        <FormControl style={{ width: '50%', marginTop: '0.5rem' }}>
          <Select value={authentication} onChange={onChangeHanlder}>
            {Object.keys(InterfaceAuthentication).map(key => (
              <MenuItem key={key} value={InterfaceAuthentication[key]}>
                {InterfaceAuthentication[key]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </XSelectAuth>
      {authentication === InterfaceAuthentication.UsernamePassword && (
        <XAuthInputs>
          <TextField
            value={userName}
            onChange={e => setUserName(e.target.value)}
            label="User name"
          />
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
            type='password'
            style={{ marginTop: '1rem' }}
          />
        </XAuthInputs>
      )}
      {authentication === InterfaceAuthentication.BasicAuth && (
        <XAuthInputs>
          <TextField
            value={userName}
            onChange={e => setUserName(e.target.value)}
            label="User name"
          />
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="Password"
            style={{ marginTop: '1rem' }}
            type='password'
          />
        </XAuthInputs>
      )}
      {authentication === InterfaceAuthentication.JWTAuth && (
        <TextField
          value={token}
          onChange={e => setToken(e.target.value)}
          label="Token"
          fullWidth
          style={{ marginTop: '1rem' }}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2rem' }}>
        <XActionButton onClick={saveInterface} style={{ marginLeft: 'auto' }}>
          Save
        </XActionButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    stock: state.stocks.data.detail
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type)),
  getStockById: (id: string) => dispatch(stockActions.getStockThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiInterfaceMethod);

const XSelectAuth = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const XAuthInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 50%;
`;
