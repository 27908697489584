import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { TextField, FormControl, Select, MenuItem, Checkbox } from '@material-ui/core';
import { InterfaceType, InterfaceUpdateInterval } from 'src/models/Catalog';
import { ApplicationState } from '../../../store';
import { FileFormat } from 'src/models/FileFormat';
import { OrganizationModel } from 'src/models/Organization';
import * as catalogActions from '../../../store/catalogs/actions';
import * as organizationActions from 'src/store/organizations/actions';
import { CatalogHeaderFormData } from '../../../store/catalogs/types';
import { ApiResult } from 'src/helpers/apiClient';
import { XSecActionButton, XSubmitButton } from 'src/components/layout/XActionButton';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { mapError } from 'src/helpers/errorMap';
import i18n from '../../../i18n';
import { Translation } from 'react-i18next';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
import CatalogInterface from './CatalogInterface';
import XFileFormatSelect from 'src/components/domain/XFileFormatSelect';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import { XPaper } from 'src/components/layout/XPaper';
import XPageContent from 'src/components/layout/XPageContent';

type StateProps = {
  fileFormats: FileFormat[];
  myOrganization?: OrganizationModel;
};

type DispatchProps = {
  getFileFormats: () => void;
  getMyOrganization: () => void;
  addCatalogHeader: (data: CatalogHeaderFormData) => Promise<ApiResult>;
  showNotification: (message: string, type?: MessageType) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

const AddNewCatalogPage: FC<AllProps> = props => {
  const [fileFormatId, setFileFormatId] = useState('');
  const [fileFormatVersionId, setFileFormatVersionId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [organizationId] = useState(`${props.myOrganization?.organization.id}`);
  const [interfaceType, setInterfaceType] = useState(InterfaceType.CatalogSend);
  const [interfaceMethod, setInterfaceMethod] = useState('');
  const [interfaceAuthentication, setInterfaceAuthentication] = useState('None');
  const [url, setUrl] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [url2, setUrl2] = useState('');
  const [userName2, setUserName2] = useState('');
  const [password2, setPassword2] = useState('');
  const [useMultipleFiles, setUseMultipleFiles] = useState (false);
  const [portalName, setPortalName] = useState('');
  const [token, setToken] = useState('');
  const [autoUpdate, setAutoUpdate] = useState(false);
  const [updateTimeInterval, setUpdateTimeInterval] = useState('');
  const [uploadType, setUploadType] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filter, setFilter] = useState('');

  const availableFormats = ['IDM', 'CSV', 'ECAT', 'LTX', 'Artex', 'eCatVeldeman', 'Cr8tif', 'IMS'];

  const formats = props.fileFormats.filter(ar => availableFormats.find(rm => rm === ar.name));

  useEffect(() => {
    props.getFileFormats();
    props.getMyOrganization();
  }, []);

  useEffect(() => {
    props.fileFormats.map(
      f =>
        f.versions.length <= 1 &&
        f.versions.map(v => v.fileFormatId === fileFormatId && setFileFormatVersionId(v.id))
    );
  }, [fileFormatId]);

  const onChangeType = (event: any) => {
    setFileFormatId(event.target.value);
  };

  const onChangeVersion = (event: any) => {
    setFileFormatVersionId(event.target.value);
  };

  const typeSelectHandler = (type: string, specType?: string) => {
    if (type !== 'Specific') {
      setUploadType(type);
      setInterfaceMethod(type);
    } else {
      setUploadType('Specific');
      specType && setInterfaceMethod(specType);
    }
  };

  const saveCatalogHeader = async (e: any) => {
    e.preventDefault();
    const apiResult = await props.addCatalogHeader({
      organizationId,
      title,
      description,
      fileFormatId,
      fileFormatVersionId,
      interfaceType,
      interfaceMethod,
      interfaceAuthentication,
      settings: {
        Url: url,
        Username: userName,
        Password: password,
        Url2: url2,
        Username2: userName2,
        Password2: password2,
        PortalName: portalName,
        Token: token,
        AutoUpdate: autoUpdate,
        UpdateTimeInterval: updateTimeInterval,
        FilterType: filterType,
        Filter: filter
      }
    });
    if (apiResult.IsSuccess) {
      props.history.push(`/app/my/catalogs/edit/${apiResult.JsonResponse.entityId}`);
    } else {
      props.showNotification(
        i18n.t('notification.savingCatalogHeader') + mapError(apiResult.Errors),
        'error'
      );
    }
  };

  const onChangeUpdateIntervalHandler = (e: any) => {
    setUpdateTimeInterval(e.target.value);
  };

  const checkBoxHandler = (e: any) => {
    if (e.key === 'Enter') {
      setAutoUpdate(prevState => !prevState);
    } else return;
  };

  return (
    <ErrorBoundary>
      <XPageContent style={{ width: '44rem', height: 'fit-content' }}>
        <XPaper style={{ height: 'fit-content', maxHeight: 'fit-content' }}>
          <XPaperHeader header={i18n.t('catalogs.addNewCatalog')} />
          <form>
            <div>
              <XContent>
                <Translation>
                  {t => (
                    <XCatalogInputs>
                      <TextField
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        style={{ marginBottom: '1rem' }}
                        label={t('tooltip.Title')}></TextField>
                      <TextField
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        label={t('tooltip.Description')}
                        style={{ marginBottom: '1rem' }}
                        multiline
                        rows={1}
                      />
                    </XCatalogInputs>
                  )}
                </Translation>
              </XContent>
            </div>
            <XNewCatalog>
              <div style={{ display: 'flex' }}>
                <CatalogInterface
                  setInterfaceMethod={setInterfaceMethod}
                  setInterfaceAuthentication={setInterfaceAuthentication}
                  interfaceAuthentication={interfaceAuthentication}
                  typeSelectHandler={typeSelectHandler}
                  uploadType={uploadType}
                  url={url}
                  setUrl={setUrl}
                  password={password}
                  setPassword={setPassword}
                  userName={userName}
                  setUserName={setUserName}
                  url2={url2}
                  setUrl2={setUrl2}
                  password2={password2}
                  setPassword2={setPassword2}
                  userName2={userName2}
                  setUserName2={setUserName2}
                  setUseMultipleFiles={setUseMultipleFiles}
                  useMultipleFiles={useMultipleFiles}
                  token={token}
                  setPortalName={setPortalName}
                  portalName={portalName}
                  setToken={setToken}
                  autoUpdate={autoUpdate}
                  setAutoUpdate={setAutoUpdate}
                  updateTimeInterval={updateTimeInterval}
                  setUpdateTimeInterval={setUpdateTimeInterval}
                  filterType={filterType}
                  setFilterType={setFilterType}
                  filter={filter}
                  setFilter={setFilter}
                />
                <div style={{ minWidth: '15rem' }}>
                  <div style={{ padding: '0 2rem' }}>
                    <div>
                      {!!uploadType && uploadType !== 'Specific' && (
                        <XFileFormatSelect
                          fileFormatId={fileFormatId}
                          fileFormatVersionId={fileFormatVersionId}
                          onChangeVersion={onChangeVersion}
                          onChangeType={onChangeType}
                          formats={formats}
                        />
                      )}
                      {!!uploadType && uploadType !== 'Manual' && uploadType !== 'ArxisApi' && (
                        <>
                          <XTextInput>
                            <Checkbox
                              checked={autoUpdate}
                              onChange={e => setAutoUpdate(e.target.checked)}
                              onKeyPress={e => checkBoxHandler(e)}
                            />
                            <span>Catalog auto update</span>
                          </XTextInput>
                          {autoUpdate && (
                            <XTextInput>
                              <div>Select update interval:</div>
                              <FormControl style={{ width: '50%', marginTop: '0.5rem' }}>
                                <Select
                                  value={updateTimeInterval}
                                  onChange={onChangeUpdateIntervalHandler}>
                                  {Object.keys(InterfaceUpdateInterval).map(key => (
                                    <MenuItem key={key} value={InterfaceUpdateInterval[key]}>
                                      {key}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </XTextInput>
                          )}
                        </>
                      )}
                    </div>
                  </div>{' '}
                </div>
              </div>
              <div style={{ padding: '0 1rem 0 1rem', marginTop: '1rem' }}>
                <Translation>
                  {t => (
                    <XButtonsContainer>
                      <XSecActionButton onClick={() => props.history.goBack()}>
                        {t('tooltip.Cancel')}
                      </XSecActionButton>
                      <XSubmitButton type="submit" onClick={saveCatalogHeader}>
                        {t('catalogs.saveCatalog')}
                      </XSubmitButton>
                    </XButtonsContainer>
                  )}
                </Translation>
              </div>
            </XNewCatalog>
          </form>
        </XPaper>
      </XPageContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    fileFormats: state.catalogs.fileFormats.sort((a, b) => a.name.localeCompare(b.name)),
    myOrganization: state.organizations.myOrganization
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getFileFormats: () => dispatch(catalogActions.getFileFormatsThunk()),
  addCatalogHeader: (data: CatalogHeaderFormData) =>
    dispatch(catalogActions.addCatalogHeaderThunk(data)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk()),
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCatalogPage);

const XNewCatalog = styled.div`
  height: fit-content;
  border-radius: 6px;
  background-color: white;
`;

const XContent = styled.div`
  padding: 0 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  & p {
    margin: 0.25rem 0;
  }
`;

const XCatalogInputs = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

const XButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

const XTextInput = styled.div`
  margin-bottom: 1rem;
`;
