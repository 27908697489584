import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Checkbox } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import * as React from 'react';

type OwnProps = {
  label?: string;
  placeholder?: string;
};

type AllProps = OwnProps & CheckboxProps;

const XCheckbox: React.SFC<AllProps> = props => {
  return (
    <div>
      {props.readOnly ? (
        props.checked ? (
          <Check style={{ padding: '4px', fontSize: '28px' }} />
        ) : (
          <Clear style={{ padding: '4px', fontSize: '28px' }} />
        )
      ) : (
        <Label>
          <StyledCheckbox {...props} />
          {props.label}
        </Label>
      )}
    </div>
  );
};

export default XCheckbox;

const Label = styled('label')({
  marginTop: '4px',
  cursor: 'pointer',
  userSelect: 'none'
});

const StyledCheckbox = styled(Checkbox)({
  marginRight: '5px',
  width: '28px',
  height: '28px'
});
