import { routerMiddleware, routerReducer, RouterState } from 'react-router-redux';
import { Action, AnyAction, applyMiddleware, combineReducers, createStore, Dispatch } from 'redux';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

import { history } from '../history';
import { apiClient } from 'src/helpers/apiClient';
import { AccountReducer } from './accounts/reducer';
import { AccountState } from './accounts/types';
import { AuthenticationReducer } from './authentication/reducer';
import { AuthenticationState } from './authentication/types';
import { CatalogReducer } from './catalogs/reducer';
import { CatalogState } from './catalogs/types';
import { DataReducer } from './data/reducer';
import { DataState } from './data/types';
import { LayoutReducer } from './layout/reducer';
import { LayoutState } from './layout/types';
import { MessageReducer } from './messages/reducer';
import { MessageState } from './messages/types';
import { OrganizationGroupReducer } from './organizationgroups/reducer';
import { OrganizationGroupsState } from './organizationgroups/types';
import { OrganizationReducer } from './organizations/reducer';
import { OrganizationState } from './organizations/types';
import { RequestReducer } from './requests/reducer';
import { RequestStateModel } from './requests/types';
import { SoftwareReducer } from './software/reducer';
import { SoftwareState } from './software/types';
import { SubscriptionState } from './subscriptions/types';
import { SubscriptionReducer } from './subscriptions/reducer';
import { LanguageState } from './language/types';
import { LanguageReducer } from './language/reducer';
import { StockState } from './stocks/types';
import { StockReducer } from './stocks/reducer';
import { OrdersState } from './purchase-order/types'
import { PurchaseOrderReducer } from './purchase-order/reducer'

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

export interface ApplicationState {
  catalogs: CatalogState;
  requests: RequestStateModel;
  data: DataState;

  messages: MessageState;
  software: SoftwareState;

  account: AccountState;
  organizations: OrganizationState;

  organizationGroups: OrganizationGroupsState;

  layout: LayoutState;

  authentication: AuthenticationState;
  router: RouterState;

  subscriptions: SubscriptionState;
  language: LanguageState;
  stocks: StockState;
  purchaseOrder: OrdersState;
}

const uiVersion = process.env.REACT_APP_UI_VERSION ?? '1';
const persistVersion = parseInt(uiVersion, 10);

if (localStorage) {
  const localStorageState = localStorage.getItem('persist:root');
  const state = JSON.parse(localStorageState as string);
  if (state) {
    const parsedInner = JSON.parse(state._persist);
    if (parsedInner.version !== persistVersion) {
      console.log('New release, clearing persisted state. New version: ' + persistVersion);
      localStorage.clear();
    }
  }
}

const persistConfig = {
  key: 'root',
  storage: storageSession,
  version: persistVersion,
  blacklist: ['account', 'authentication', 'organizations']
};

const accountPersistConfig = {
  key: 'account',
  storage: storageSession,
  version: persistVersion,
  blacklist: ['accountFormData']
};

const authenticationPersistConfig = {
  key: 'authentication',
  storage: storageSession,
  version: persistVersion,
  blacklist: ['authenticationError']
};

const organizationPersistConfig = {
  key: 'organizations',
  storage: storageSession,
  version: persistVersion,
  blacklist: ['formData']
};

const organizationReducer = new OrganizationReducer();
const catalogReducer = new CatalogReducer();
const dataReducer = new DataReducer();
const requestReducer = new RequestReducer();
const messageReducer = new MessageReducer();
const softwareReducer = new SoftwareReducer();
const accountReducer = new AccountReducer();
const organizationGroupReducer = new OrganizationGroupReducer();
const authenticationReducer = new AuthenticationReducer();
const layoutReducer = new LayoutReducer();
const subscriptionsReducer = new SubscriptionReducer();
const languageReducer = new LanguageReducer();
const stockReducer = new StockReducer();
const purchaseOrderReducer = new PurchaseOrderReducer();

export const appReducer = combineReducers({
  messages: (state, action) => messageReducer.handleState(state, action),
  software: (state, action) => softwareReducer.handleState(state, action),
  data: (state, action) => dataReducer.handleState(state, action),

  organizations: persistReducer(organizationPersistConfig, (state, action) =>
    organizationReducer.handleState(state, action)
  ),
  organizationGroups: (state, action) => organizationGroupReducer.handleState(state, action),
  catalogs: (state, action) => catalogReducer.handleState(state, action),
  requests: (state, action) => requestReducer.handleState(state, action),

  account: persistReducer(accountPersistConfig, (state, action) =>
    accountReducer.handleState(state, action)
  ),
  authentication: persistReducer(authenticationPersistConfig, (state, action) =>
    authenticationReducer.handleState(state, action)
  ),

  router: routerReducer,
  layout: (state, action) => layoutReducer.handleState(state, action),
  subscriptions: (state, action) => subscriptionsReducer.handleState(state, action),
  language: (state, action) => languageReducer.handleState(state, action),
  stocks: (state, action) => stockReducer.handleState(state, action),
  purchaseOrder: (state, action) => purchaseOrderReducer.handleState(state, action)
});

const rootReducer = (state, action) => {
  if (action.type === '[auth] LOGOUT') {
    sessionStorage.clear();

    // Clear local storage also, just to be sure
    localStorage.clear();
  }

  return appReducer(state, action);
};

apiClient.setConfig({
  onTokenExpired: () => {
    rootReducer(undefined, { type: '[auth] LOGOUT' });
    window.location.href =
      window.location.protocol + '//' + window.location.host + '/login?sessionExpired=1';
  }
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWare: any[] = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') middleWare.push(logger);

export const store = createStore(persistedReducer, undefined, applyMiddleware(...middleWare));

export const persistor = persistStore(store);
