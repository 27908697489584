import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { XContainer } from '../layout/XContainer';
import XStockLarge from './XStockLarge';
import XStockSmall from './XStockSmall';

type OwnProps = {
  stock: any;
  size: 'small' | 'large';
  onClick?: (param: string) => void;
};

type AllProps = OwnProps & RouteComponentProps;

const XCatalog: React.FC<AllProps> = props => {
  const route = '/app/stock/' + props.stock.id;
  return (
    // <Link key={props.stock.id} to={route}>
    <XContainer>
      {props.size === 'large' ? <XStockLarge {...props} /> : <XStockSmall {...props} />}
    </XContainer>
    // </Link>
  );
};

export default withRouter(XCatalog);
