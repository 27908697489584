import * as React from 'react';
import { connect } from 'react-redux';

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import XCatalog from 'src/components/domain/XCatalog';
import XNoContent from 'src/components/domain/XNoContent';
import XGrid from 'src/components/layout/XGrid';
import XSmallButton from 'src/components/layout/XSmallButton';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import { Catalog, PublicationStatus } from 'src/models/Catalog';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { CatalogSort, getCatalogsThunk } from 'src/store/catalogs/actions';

import GridIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import { XImage, XImageContainer, XImageOverlay } from 'src/components/layout/XImage';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import XScrollView from 'src/components/layout/XScrollView';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { formatDateToString, getImageUrl } from 'src/helpers/formatHelpers';
import { changeView } from 'src/store/layout/actions';
import { ViewType } from 'src/store/layout/types';
import EmptyImage from '../../style/images/empty.svg';
import { LinearProgress, Switch, Tooltip } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { XSecActionButton } from 'src/components/layout/XActionButton';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import XSearchBar from 'src/components/layout/XSearchBar';
import { Translation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  catalogs: Catalog[];
  account: AuthenticationAccount;
  loading: boolean;
  view: ViewType;
};

type DispatchProps = {
  getCatalogs(sort: CatalogSort): () => void;
  changeView(type: ViewType): () => void;
};

type OwnProps = {};

type State = {
  currentOffset: number;
  search: string;
};

type AllProps = StateProps & DispatchProps & OwnProps;

class CatalogsPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      currentOffset: 0,
      search: ''
    };
  }

  public componentDidMount() {
    this.props.getCatalogs('all');
  }

  public switchView(view) {
    this.props.changeView(view);
  }

  public handleScrollBottom() {
    if (this.state.currentOffset >= this.props.catalogs.length) return;
    this.setState({ currentOffset: this.state.currentOffset + 30 });
  }

  public render() {
    const pageSize = 1000;
    const hasDownloadedCatalogs = this.props.catalogs.some(x => x.downloaded);
    const catalogs = this.props.catalogs
      .filter(
        c =>
          this.state.search.length === 0 ||
          c.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      )
      // Only show the actual published catalogs of suppliers
      .filter(c => c.publicationStatus == PublicationStatus.Published);
    const paged = catalogs.slice(0, this.state.currentOffset + pageSize);
    return (
      <ErrorBoundary>
        <XPageContent>
          <StyledPaper style={{ height: catalogs.length === 0 ? '600px' : '' }}>
            <Translation>
              {t => (
                <XPaperHeader
                  header={t('common.Catalogs')}
                  count={catalogs.length}
                  subheader={t(t('catalogs.allCatalogs'))}
                />
              )}
            </Translation>
            {this.props.catalogs.length !== 0 && (
              <XFilterBar style={{ paddingBottom: '15px' }}>
                <div style={{ flex: 1, paddingRight: '5px' }}>
                  <Translation>
                    {t => (
                      <XSearchBar
                        placeholder={`Filter  ${t('common.catalogs')}`}
                        onChange={e =>
                          this.setState({ search: e.target.value.toLowerCase() })
                        }></XSearchBar>
                    )}
                  </Translation>
                </div>
                <Translation>
                  {t => (
                    <XSecActionButton
                      onClick={() => this.switchView(this.props.view === 'list' ? 'grid' : 'list')}>
                      {this.props.view === 'list' ? (
                        <GridIcon style={{ marginRight: '5px' }} />
                      ) : (
                        <ListIcon style={{ marginRight: '5px' }} />
                      )}
                      {this.props.view === 'list' ? 'Grid' : t('organizations.List')}
                    </XSecActionButton>
                  )}
                </Translation>
              </XFilterBar>
            )}

            {this.props.catalogs.length !== 0 && paged.length === 0 && (
              <Translation>
                {t => <XNoContent message={t('catalogs.noCatalogsFound')} />}
              </Translation>
            )}
            {!this.state.search.length && this.props.catalogs.length === 0 && (
              <XNoContent image={EmptyImage}>
                <div>
                  <h3 style={{ fontSize: '15px', color: '#222222' }}>
                    Je hebt nog geen beschikbare catalogi
                  </h3>
                </div>
                <div style={{ textAlign: 'left', marginTop: '30px' }}>
                  <div>
                    <b>Waarom zie ik geen catalogi?</b>
                  </div>
                  <div>1. Je hebt nog geen connectie met 1 of meerdere leveranciers</div>
                  <div>2. Je levanciers hebben nog geen catalogi gepubliceerd</div>
                  <div>3. Je hebt nog geen toegang gekregen tot 1 of meerdere catalogi</div>
                  <div style={{ marginTop: '20px', marginBottom: '5px' }}>
                    <b>Wat kan ik doen?</b>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      <Link to="/app/explore/connections">
                        <Translation>
                          {t => <XSmallButton text={t('connections.connWithSupp')} />}
                        </Translation>
                      </Link>
                    </div>
                  </div>
                </div>
              </XNoContent>
            )}
            <XScrollView onScrollBottom={() => this.handleScrollBottom()}>
              {this.props.view === 'grid' ? (
                <XGrid
                  columns={3}
                  style={{
                    padding: '0'
                  }}>
                  {this.props.loading ? (
                    Array.from({ length: 9 }).map((_, idx) => (
                      <XCatalogSmallSkeletton>
                        <Skeleton variant="rect" width={75} height={75} />
                        <div
                          style={{
                            marginLeft: '10px',
                            height: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                          }}>
                          <Skeleton variant="rect" width={150} height={15} />
                          <Skeleton variant="rect" width={150} height={15} />
                          <Skeleton variant="rect" width={150} height={15} />
                        </div>
                      </XCatalogSmallSkeletton>
                    ))
                  ) : (
                    <>
                      {paged.map(dp => (
                        <XCatalog key={dp.id} catalog={dp} size="small" />
                      ))}
                    </>
                  )}
                </XGrid>
              ) : (
                <div style={{ padding: '15px' }}>
                  {this.props.loading ? (
                    Array.from({ length: 6 }).map((_, idx) => (
                      <CatalogRow key={idx}>
                        <CatalogImage>
                          <Skeleton variant="rect" width={60} height={60} />
                        </CatalogImage>
                        <CatalogCell>
                          <Skeleton />
                        </CatalogCell>
                      </CatalogRow>
                    ))
                  ) : (
                    <>
                      {paged.map(c => (
                        <CatalogRow key={c.id}>
                          <CatalogImage>
                            <XImageContainer width={60} height={60}>
                              <XImage
                                src={getImageUrl(c.imageURL, c.organizationImageURL)}
                                alt=""
                                onError={(e: any) => {
                                  e.target.onError = null;
                                  e.target.src = generateAvatar(c.title, 60);
                                }}
                              />
                              {c.downloaded && (
                                <Translation>
                                  {t => (
                                    <Tooltip title={t('tooltip.prevDownloadedCatalog') + ' '}>
                                      <XImageOverlay>
                                        <CloudDownload />
                                      </XImageOverlay>
                                    </Tooltip>
                                  )}
                                </Translation>
                              )}
                            </XImageContainer>
                          </CatalogImage>
                          <CatalogTitle>
                            <Link to={'/app/explore/catalogs/' + c.id}>{c.title}</Link>
                          </CatalogTitle>
                          <CatalogCell>
                            <Link to={'/app/explore/connections/organization/' + c.organizationId}>
                              {c.organizationName}
                            </Link>
                          </CatalogCell>
                          <CatalogCell>{c.downloads} downloads</CatalogCell>
                          <CatalogCell>{formatDateToString(c.lastCreationDate)}</CatalogCell>
                        </CatalogRow>
                      ))}{' '}
                    </>
                  )}
                </div>
              )}
            </XScrollView>
          </StyledPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    catalogs: (state.catalogs.data.all || []),
    loading: state.catalogs.loading,
    account: state.authentication.account,
    view: state.layout.viewCatalogs
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getCatalogs: (sort: CatalogSort) => dispatch(getCatalogsThunk(sort)),
  changeView: (type: ViewType) => dispatch(changeView('catalogs', type))
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogsPage);

const StyledPaper = styled(XPaper)`
  min-height: 300px;
`;

const CatalogRow = styled.div`
  display: flex;
  margin: 4px 0;
`;

const CatalogImage = styled.div`
  max-width: 75px;
  flex: 1;
`;

const CatalogCell = styled.div`
  flex: 1;
  font-size: 14px;
  padding-top: 4px;
`;

const CatalogTitle = styled(CatalogCell)`
  padding-left: 15px;
  font-weight: bold;
`;

const XCatalogSmallSkeletton = styled.div`
  width: 33.333333333333336%;
  padding: 15px;
  display: flex;
  align-items: start;
`;
