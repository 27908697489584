import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import styled from '@emotion/styled';
import { LinearProgress } from '@material-ui/core';
import XLTXInformation from 'src/components/domain/XLTXInformation';
import { XPaper } from 'src/components/layout/XPaper';
import { ApiResult } from 'src/helpers/apiClient';
import { ApplicationState } from 'src/store';
import { verifyEmailCodeThunk } from 'src/store/accounts/actions';
import Logo from '../../style/images/logos/main.svg';
import { LeftSide, LeftSideInner, LTXLogo, RightSide } from '../login/LoginPage.style';

import XButton, { XButtonStyle } from 'src/components/layout/XButton';
import MailImage from 'src/style/images/success.svg';
import { Translation } from 'react-i18next';

type OwnProps = {};

type State = { confirmResult?: boolean };

type DispatchProps = {
  verifyEmailCode: (code: string) => Promise<ApiResult>;
};

type AllProps = OwnProps & DispatchProps & RouteComponentProps<{ emailCode: string }>;

class ConfirmationPage extends React.Component<AllProps, State> {
  constructor(props: any) {
    super(props);
    this.state = { confirmResult: undefined };
  }

  public componentDidMount() {
    const emailCode = this.props.match.params.emailCode;
    this.props.verifyEmailCode(emailCode).then(result => {
      this.setState({ confirmResult: result.IsSuccess });
      if (emailCode === 'success') this.setState({ confirmResult: true });
    });
  }

  public render() {
    return (
      <LogoutLayout>
        <LeftSide>
          <LeftSideInner>
            <LogoutInner>
              {this.state.confirmResult === undefined ? (
                <div>
                  <LinearProgress />
                </div>
              ) : this.state.confirmResult === false ? (
                <Translation>{t => <div> {t('register.noAccountFoundToActiv')} </div>}</Translation>
              ) : (
                <Translation>
                  {t => (
                    <div>
                      <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                        <img src={MailImage} width="300px" />
                      </div>
                      {t('register.accActivated')}
                      <XButton
                        colorscheme={XButtonStyle.Primary}
                        onClick={e => this.props.history.push('/login')}
                        width="100%">
                        {t('login.login')}
                      </XButton>
                    </div>
                  )}
                </Translation>
              )}
            </LogoutInner>
          </LeftSideInner>
        </LeftSide>
        <RightSide>
          <XLTXInformation />
        </RightSide>

        <LTXLogo src={Logo} />
      </LogoutLayout>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  verifyEmailCode: (code: string) => dispatch(verifyEmailCodeThunk(code))
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);

const LogoutLayout = styled.div`
  height: 100vh;
  overflow: hidden;
  background: rgb(45, 128, 194);
  display: flex;
`;

const LogoutInner = styled(XPaper)`
  padding: 30px;
  text-align: center;
  width: 500px;
  min-height: 40px;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`;
