import * as React from 'react';
import { connect } from 'react-redux';
import { XPaper, XPaperTitle } from 'src/components/layout/XPaper';
import { MyOrganizationModel, Organization, OrganizationIdentifier } from 'src/models/Organization';
import {
  addOrganizationIdentifierThunk,
  deleteOrganizationIdentifierThunk,
  editOrganizationThunk,
  getMyOrganizationThunk,
  getOrganizationIdentifiersThunk
} from 'src/store/organizations/actions';

import styled from '@emotion/styled';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import Skeleton from '@material-ui/lab/Skeleton';
import XForm from 'src/components/layout/XForm';
import XPageContent from 'src/components/layout/XPageContent';
import XPopup from 'src/components/layout/XPopup';
import XTable, { TableRow } from 'src/components/layout/XTable';
import { OrganizationIdentifierType } from 'src/constants/OrganizationIdentifierType';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { showConfirmation, showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../store';
import OrganizationDetails from './account/OrganizationDetails';
import { XRow, XRowCell } from 'src/components/layout/XRow';
import { getImageUrl } from 'src/helpers/formatHelpers';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { XTitle } from 'src/components/layout/XPaperHeader';
import { XFieldDisplay, XKey, XValue } from './AccountPage';
import { Clear, Edit } from '@material-ui/icons';
import { ActionButtons } from 'src/admin/pages/accounts/AccountPage';
import { XActionButton, XSecActionButton } from 'src/components/layout/XActionButton';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { Translation } from 'react-i18next';
import i18n from '../../i18n';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  organization?: MyOrganizationModel;
  identifiers: OrganizationIdentifier[];
  loading: boolean;
};

type State = {
  organizationData?: Organization;
  organizationInputErrors: any;
  showAddIdentifier: boolean;
  newIdentifier: {
    type: string;
    value: string;
  };
  editing: boolean;
};

type DispatchProps = {
  showNotification: (message: string, type?: MessageType) => void;
  getOrganization: () => void;
  showConfirm: (title: string, message: string, onConfirm: () => void) => void;
  getOrganizationIdentifiers: () => Promise<ApiResult>;
  editOrganization: (organization: Organization) => Promise<ApiResult>;
  addOrganizationIdentifier: (type: string, value: string) => Promise<ApiResult>;
  deleteOrganizationIdentifier: (id: string) => Promise<ApiResult>;
};

type AllProps = StateProps & DispatchProps;

class MyOrganizationPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      organizationData: this.props.organization?.organization,
      showAddIdentifier: false,
      newIdentifier: {
        type: '',
        value: ''
      },
      editing: false,
      organizationInputErrors: {
        name: false,
        street: false,
        houseNumber: false,
        postalCode: false,
        city: false,
        country: false
      }
    };
  }

  public componentDidMount() {
    this.props.getOrganization();
    this.props.getOrganizationIdentifiers();
  }

  public componentDidUpdate(oldProps: AllProps) {
    if (oldProps.organization?.organization !== this.props.organization?.organization) {
      this.setState({ organizationData: this.props.organization?.organization });
    }
  }

  public handleOrganizationChange = (name: string, value: any) => {
    if (!this.state.organizationData) return;

    const organizationData = { ...this.state.organizationData };
    organizationData[name] = value;
    this.setState({ organizationData });
  };

  public handleNewIdentifierChange = (name: string, value: any) => {
    const identifierData = { ...this.state.newIdentifier };
    identifierData[name] = value;
    this.setState({ newIdentifier: identifierData });
  };

  public storeOrganization = () => {
    const state = this.state;
    if (!state.organizationData) return;

    const inputErrors = { ...this.state.organizationInputErrors };
    let valid = true;
    Object.keys(state.organizationData).map(key => {
      if (!state.organizationData) return;

      if (key in this.state.organizationInputErrors) {
        if (
          !state.organizationData[key] ||
          state.organizationData[key] === '' ||
          state.organizationData[key] === 0
        ) {
          inputErrors[key] = true;
          valid = false;
        } else {
          inputErrors[key] = false;
        }
      }
    });

    this.setState({ organizationInputErrors: inputErrors });
    if (valid) {
      this.props.editOrganization(state.organizationData).then(result => {
        if (result.IsSuccess) {
          this.props.showNotification(i18n.t('notification.yourOrgHasBeenAdjusted'), 'success');
          this.setState({ editing: false });
        } else
          this.props.showNotification(
            i18n.t('notification.errAdjustingOrg') + mapError(result.Errors),
            'error'
          );
      });
    }
  };

  public showAddIdentifier() {
    this.setState({ showAddIdentifier: true });
  }

  public addIdentifier() {
    if (!this.state.newIdentifier.type || !this.state.newIdentifier.value) return;

    // set button to loading
    this.props
      .addOrganizationIdentifier(this.state.newIdentifier.type, this.state.newIdentifier.value)
      .then(result => {
        if (result.IsSuccess) {
          // show notification
          this.setState({ showAddIdentifier: false, newIdentifier: { type: '', value: '' } });
          this.props.showNotification(i18n.t('notification.idNumAdded'), 'success');
        } else
          this.props.showNotification(
            i18n.t('notification.idNumAddingError') + mapError(result.Errors),
            'error'
          );
      });
  }

  public removeIdentifier(identifier: OrganizationIdentifier) {
    this.props.showConfirm(
      i18n.t('confirmation.deleteIdentificationNumber'),
      i18n.t('confirmation.sureToDeleteIdNum'),
      () => {
        this.props.deleteOrganizationIdentifier(identifier.id).then(apiResult => {
          if (apiResult.IsSuccess)
            this.props.showNotification(i18n.t('notification.idNumRemoved'), 'success');
          else
            this.props.showNotification(
              i18n.t('notification.somethingWentWrongWhileRemovingIdNum') +
                mapError(apiResult.Errors),
              'error'
            );
        });
      }
    );
  }

  public render() {
    const availableAddTypes = OrganizationIdentifierType.filter(
      t => this.props.identifiers.map(i => i.type.toLowerCase()).indexOf(t.toLowerCase()) === -1
    );

    const organization = this.state.organizationData;
    return (
      <ErrorBoundary>
        <XPageContent>
          <Translation>
            {t => (
              <XPopup
                buttonSaveText={availableAddTypes.length > 0 ? t('tooltip.Save') : undefined}
                buttonCancelText={t('tooltip.Cancel')}
                cancelOnPagClick={true}
                onSave={() => this.addIdentifier()}
                onCancel={() => this.setState({ showAddIdentifier: false })}
                title={t('account.addIdNum')}
                open={this.state.showAddIdentifier}>
                <div>
                  {availableAddTypes.length === 0 ? (
                    <span>{'account.noAdditionalIdTypesAvailable'}</span>
                  ) : (
                    <XForm
                      data={this.state.newIdentifier}
                      callback={(name, value) => this.handleNewIdentifierChange(name, value)}
                      fields={[
                        {
                          title: t('account.idType'),
                          name: 'type',
                          required: true,
                          type: 'select',
                          options: availableAddTypes.map(t => {
                            return { name: t, value: t };
                          })
                        },
                        {
                          title: t('tooltip.number'),
                          name: 'value',
                          required: true,
                          maxLength: 255
                        }
                      ]}
                    />
                  )}
                </div>
              </XPopup>
            )}
          </Translation>
          <XPaper>
            <StyledPaperTitle style={{ display: 'flex' }}>
              <XRowCell>
                <Translation>{t => <XTitle>{t('account.myOrganization')}</XTitle>}</Translation>
              </XRowCell>
              {!this.props.loading ? (
                <>
                  {this.state.editing ? (
                    <Translation>
                      {t => (
                        <XActionStrip>
                          <XSecActionButton
                            onClick={() =>
                              this.setState({
                                editing: false,
                                organizationData: this.props.organization?.organization
                              })
                            }>
                            {t('tooltip.Cancel')}
                          </XSecActionButton>
                          <XActionButton onClick={() => this.storeOrganization()}>
                            {t('tooltip.Save')}
                          </XActionButton>
                        </XActionStrip>
                      )}
                    </Translation>
                  ) : (
                    <Translation>
                      {t => (
                        <XActionButton onClick={() => this.setState({ editing: true })}>
                          {t('tooltip.Edit')}
                        </XActionButton>
                      )}
                    </Translation>
                  )}
                </>
              ) : (
                <Skeleton width={57} height={32} />
              )}
            </StyledPaperTitle>
            {this.state.editing && organization !== undefined && (
              <OrganizationDetails
                inputErrors={this.state.organizationInputErrors}
                organization={organization}
                callback={this.handleOrganizationChange}
              />
            )}
            {!this.state.editing && organization !== undefined && (
              <XRow>
                <XRowCell style={{ marginLeft: '15px', padding: '10px' }}>
                  {this.props.loading ? (
                    <Skeleton variant="rect" width={100} height={100} />
                  ) : (
                    <img
                      style={{ width: '100px' }}
                      src={getImageUrl(organization.imageURL)}
                      alt=""
                      onError={(e: any) => {
                        e.target.onError = null;
                        e.target.src = generateAvatar(organization.name, 75);
                      }}
                    />
                  )}

                  <XFieldDisplay style={{ flexDirection: 'column', marginTop: '15px' }}>
                    <Translation>{t => <XKey>{t('account.desc')}</XKey>}</Translation>
                    <XValue>
                      {this.props.loading ? <Skeleton width={100} /> : organization.description}
                    </XValue>
                  </XFieldDisplay>
                </XRowCell>
                <Translation>
                  {t => (
                    <XRowCell style={{ padding: '10px' }}>
                      <XFieldDisplay>
                        <XKey>{t('account.companyName')}</XKey>
                        <XValue>
                          {this.props.loading ? <Skeleton width={100} /> : organization.name}
                        </XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey>{t('account.address')}</XKey>
                        <XValue>
                          {this.props.loading ? (
                            <Skeleton width={100} />
                          ) : (
                            `${organization.street} ${organization.houseNumber}`
                          )}
                        </XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey>{t('address.place')}</XKey>
                        <XValue>
                          {this.props.loading ? (
                            <Skeleton width={100} />
                          ) : (
                            `${organization.postalCode}, ${organization.city}`
                          )}
                        </XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey>{t('address.country')}</XKey>
                        <XValue>
                          {this.props.loading ? <Skeleton width={100} /> : organization.country}
                        </XValue>
                      </XFieldDisplay>
                    </XRowCell>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <XRowCell style={{ padding: '10px' }}>
                      <XFieldDisplay>
                        <XKey>{t('account.orgType')}</XKey>
                        <XValue>
                          {this.props.loading ? (
                            <Skeleton width={100} />
                          ) : (
                            organization.organizationType
                          )}
                        </XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey>{t('contact.phone')}</XKey>
                        <XValue>
                          {this.props.loading ? (
                            <Skeleton width={100} />
                          ) : (
                            organization.telephoneNumber || '-'
                          )}
                        </XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey>Email</XKey>
                        <XValue>
                          {this.props.loading ? (
                            <Skeleton width={100} />
                          ) : (
                            organization.email || '-'
                          )}
                        </XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey>Website</XKey>
                        <XValue>
                          {this.props.loading ? (
                            <Skeleton width={100} />
                          ) : (
                            organization.website || '-'
                          )}
                        </XValue>
                      </XFieldDisplay>
                    </XRowCell>
                  )}
                </Translation>
              </XRow>
            )}
          </XPaper>

          <XPaper style={{ marginTop: '15px' }}>
            <Translation>
              {t => (
                <StyledPaperTitle style={{ display: 'flex' }}>
                  <XRowCell>
                    <XTitle>{t('account.idNumbers')}</XTitle>
                  </XRowCell>
                  {!this.props.loading ? (
                    <XSecActionButton onClick={() => this.showAddIdentifier()}>
                      {t('account.addIdNum')}
                    </XSecActionButton>
                  ) : (
                    <Skeleton width={120} height={32} />
                  )}
                </StyledPaperTitle>
              )}
            </Translation>
            <Translation>
              {t => (
                <XTable headers={['Type', t('tooltip.Value'), '']}>
                  {this.props.identifiers.map(id => (
                    <TableRow key={id.id}>
                      <div style={{ flex: '1' }}>
                        {this.props.loading ? <Skeleton width={120} /> : id.type}
                      </div>
                      <div style={{ flex: '1' }}>
                        {this.props.loading ? <Skeleton width={120} /> : id.value}
                      </div>
                      <div style={{ flex: '1', textAlign: 'right' }}>
                        {!this.props.loading && (
                          <DeleteIcon
                            style={{
                              display: id.type.toLowerCase() === 'arxisid' ? 'none' : 'block',
                              color: '#ed677a',
                              verticalAlign: 'middle',
                              cursor: 'pointer'
                            }}
                            onClick={() => this.removeIdentifier(id)}
                          />
                        )}
                      </div>
                    </TableRow>
                  ))}
                </XTable>
              )}
            </Translation>
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    organization: state.organizations.myOrganization,
    loading: state.organizations.loading,
    identifiers: state.organizations.myIdentifiers
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type)),
  getOrganization: () => dispatch(getMyOrganizationThunk()),
  editOrganization: (organization: Organization) => dispatch(editOrganizationThunk(organization)),
  getOrganizationIdentifiers: () => dispatch(getOrganizationIdentifiersThunk()),
  showConfirm: (title: string, message: string, onConfirm: () => void) =>
    dispatch(showConfirmation(title, message, onConfirm)),
  addOrganizationIdentifier: (type, value) => dispatch(addOrganizationIdentifierThunk(type, value)),
  deleteOrganizationIdentifier: id => dispatch(deleteOrganizationIdentifierThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOrganizationPage);

const StyledPaperTitle = styled(XPaperTitle)`
  margin-bottom: 20px;
  position: relative;
`;
