import { styled } from '@material-ui/core';
import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApplicationState } from 'src/store';
import { getSoftwareThunk, sortSoftware } from 'src/store/software/actions';
import { XImage, XImageContainer } from '../layout/XImage';
import { XRow, XRowCell } from '../layout/XRow';

type OwnProps = {
  softwareId: string;
  onChange: (_: string) => void;
};

type AllProps = StateProps & DispatchProps & OwnProps;

type State = {
  softwareId: string;
};

const logos = {
  LogicTrade: 'logictrade.svg',
  iOne: 'iONE.png',
  eMeubel: 'emeubel.png',
  Furncube: 'furncube.png',
  'Uni-Living': 'uniliving.jpg',
  'JVM Meubi-sol': 'meubisol.png'
};

class XSoftwareSelect extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      softwareId: props.softwareId
    };
  }

  componentDidMount() {
    if (!this.props.software.length) {
      this.props.getSoftware();
    }
  }

  public selectSoftware(softwareId) {
    this.setState({ softwareId });
    this.props.onChange(softwareId);
  }

  public render() {
    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    return (
      <XRow>
        {this.props.software.map(software => (
          <XRowCell>
            <Card
              className={this.state.softwareId === software.id ? 'selected' : ''}
              onClick={() => this.selectSoftware(software.id)}>
              {logos[software.name] && (
                <XImageContainer
                  style={{ background: 'none', width: '100px', height: '100px', border: 'none' }}>
                  <XImage src={require(`src/style/images/software/${logos[software.name]}`)} />
                </XImageContainer>
              )}
              <div></div>
            </Card>
          </XRowCell>
        ))}
        <XRowCell
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: this.state.softwareId === emptyGuid ? '#f5f5f5' : '',
            border: this.state.softwareId === emptyGuid ? '2px solid #cccccc' : ''
          }}>
          <Card onClick={() => this.selectSoftware(emptyGuid)}>
            <div>
              <Translation>{t => <strong> {t('tooltip.different')} </strong>}</Translation>
            </div>
          </Card>
        </XRowCell>
      </XRow>
    );
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
  software: state.software.all.sort((a, b) => sortSoftware(a, b))
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: any) => ({
  getSoftware: () => dispatch(getSoftwareThunk())
});

const Card = styled('div')({
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
  '&.selected': {
    background: '#f5f5f5',
    color: 'black!important',
    border: '2px solid #cccccc'
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(XSoftwareSelect);
