import * as React from 'react';
import { connect } from 'react-redux';

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import XStock from 'src/components/domain/XStock';
import XNoContent from 'src/components/domain/XNoContent';
import XGrid from 'src/components/layout/XGrid';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { Stock, PublicationStatus } from 'src/models/Stock';
import { getStocksThunk } from 'src/store/stocks/actions';

import GridIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import { XImage, XImageContainer, XImageOverlay } from 'src/components/layout/XImage';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import XScrollView from 'src/components/layout/XScrollView';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { formatDateToString, getImageUrl } from 'src/helpers/formatHelpers';
import { changeView } from 'src/store/layout/actions';
import { ViewType } from 'src/store/layout/types';
import EmptyImage from '../../style/images/empty.svg';
import { LinearProgress } from '@material-ui/core';
import { XSecActionButton } from 'src/components/layout/XActionButton';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import XSearchBar from 'src/components/layout/XSearchBar';
import { Translation } from 'react-i18next';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  account: AuthenticationAccount;
  stocks: Stock[];
  loading: boolean;
  view: ViewType;
};

type DispatchProps = {
  changeView(type: ViewType): () => void;
  getStocks(): () => void;
};

type OwnProps = {};

type State = {
  currentOffset: number;
  search: string;
};

type AllProps = StateProps & DispatchProps & OwnProps;

class StocksPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      currentOffset: 0,
      search: ''
    };
  }

  public componentDidMount() {
    this.props.getStocks();
  }

  public switchView(view) {
    this.props.changeView(view);
  }

  public handleScrollBottom() {
    if (this.state.currentOffset >= this.props.stocks.length) return;
    this.setState({ currentOffset: this.state.currentOffset + 30 });
  }

  public render() {
    const stocks = this.props.stocks.filter(
      c =>
        this.state.search.length === 0 ||
        c.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    );

    if (this.props.loading)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <LinearProgress />
          </XPaper>
        </XPageContent>
      );

    const pageSize = 1000;

    const paged = stocks.slice(0, this.state.currentOffset + pageSize);
    return (
      <ErrorBoundary>
        <XPageContent>
          <StyledPaper style={{ height: stocks.length === 0 ? '600px' : '' }}>
            <Translation>
              {t => (
                <XPaperHeader
                  header={t('stock.Stock')}
                  count={stocks.length}
                  subheader={t('stock.belowAreAllAvailableStocks')}
                />
              )}
            </Translation>
            {stocks.length !== 0 && (
              <XFilterBar style={{ paddingBottom: '15px' }}>
                <div style={{ flex: 1, paddingRight: '5px' }}>
                  <Translation>
                    {t => (
                      <XSearchBar
                        placeholder={`Filter  ${t('common.catalogs')}`}
                        onChange={e =>
                          this.setState({ search: e.target.value.toLowerCase() })
                        }></XSearchBar>
                    )}
                  </Translation>
                </div>
                <div style={{ paddingRight: '5px', display: 'flex' }}></div>
                <Translation>
                  {t => (
                    <XSecActionButton
                      onClick={() => this.switchView(this.props.view === 'list' ? 'grid' : 'list')}>
                      {this.props.view === 'list' ? (
                        <GridIcon style={{ marginRight: '5px' }} />
                      ) : (
                        <ListIcon style={{ marginRight: '5px' }} />
                      )}
                      {this.props.view === 'list' ? 'Grid' : t('organizations.List')}
                    </XSecActionButton>
                  )}
                </Translation>
              </XFilterBar>
            )}

            {stocks.length !== 0 && paged.length === 0 && (
              <Translation>{t => <XNoContent message="Not found" />}</Translation>
            )}
            {!this.state.search.length && stocks.length === 0 && (
              <XNoContent image={EmptyImage}>
                <div></div>
              </XNoContent>
            )}
            <XScrollView onScrollBottom={() => this.handleScrollBottom()}>
              {this.props.view === 'grid' ? (
                <XGrid
                  columns={3}
                  style={{
                    padding: '0'
                  }}>
                  {paged.map(dp => (
                    <XStock key={dp.id} stock={dp} size="small" />
                  ))}
                </XGrid>
              ) : (
                <div style={{ padding: '15px' }}>
                  {paged.map(c => (
                    <StockRow key={c.id}>
                      <StockImage>
                        <XImageContainer width={60} height={60}>
                          <XImage
                            src={getImageUrl(c.imageURL)}
                            alt=""
                            onError={(e: any) => {
                              e.target.onError = null;
                              e.target.src = generateAvatar(c.title, 60);
                            }}
                          />
                        </XImageContainer>
                      </StockImage>
                      <StockTitle>
                        {/* <Link to={'/app/stock/' + c.id}>{c.title}</Link> */}
                        {c.title}
                      </StockTitle>
                      <StockCell>
                        {/* <Link to={'/app/connections/organization/' + c.organizationId}>
                          {c.organizationName}
                        </Link> */}
                        {c.organizationName}
                      </StockCell>
                    </StockRow>
                  ))}
                </div>
              )}
            </XScrollView>
          </StyledPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    loading: state.catalogs.data.all === undefined,
    account: state.authentication.account,
    view: state.layout.viewCatalogs,
    stocks: state.stocks.data.all || []
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  changeView: (type: ViewType) => dispatch(changeView('catalogs', type)),
  getStocks: () => dispatch(getStocksThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(StocksPage);

const StyledPaper = styled(XPaper)`
  min-height: 300px;
`;

const StockRow = styled.div`
  display: flex;
  margin: 4px 0;
`;

const StockImage = styled.div`
  max-width: 75px;
  flex: 1;
`;

const StockCell = styled.div`
  flex: 1;
  font-size: 14px;
  padding-top: 4px;
`;

const StockTitle = styled(StockCell)`
  padding-left: 15px;
  font-weight: bold;
`;
