import { AnyAction, Dispatch } from 'redux';
import { commandAction, requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError, ApiResult } from 'src/helpers/apiClient';
import { FileFormat } from 'src/models/FileFormat';
import { Stock, StockModel, StockValidationSummary } from '../../models/Stock';
import { getMyOrganizationThunk } from '../organizations/actions';
import {
  deleteStock,
  editStock,
  editStockAvailable,
  getStock,
  getFileFormats,
  addStock,
  listStocks,
  listSubscribedStocks,
} from './api';
import {
  ActionTypes,
  EditStockFormData,
  AddStockFormData
} from './types';

export type FetchStatus = 'request' | 'success' | 'failure';

export type GetStocksAction = {
  type: ActionTypes.GET_STOCKS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    stocks?: Stock[];
  };
};

export type GetSubscribedStocksAction = {
  type: ActionTypes.GET_SUBSCRIBED_STOCKS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    stocks?: Stock[];
  };
};

export type GetStockAction = {
  type: ActionTypes.GET_STOCK;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    stock?: StockModel;
  };
};

export type GetFileFormatsAction = {
  type: ActionTypes.GET_FILE_FORMATS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    fileFormats?: FileFormat[];
  };
};

export type AddStockAction = {
  type: ActionTypes.ADD_STOCK;
  errors?: ApiError[];
  status: FetchStatus;
};

// Action creators
export const getStockAction = (
  status: FetchStatus,
  data?: StockModel,
  errors?: ApiError[]
): GetStockAction => ({
  type: ActionTypes.GET_STOCK,
  status,
  errors,
  payload: {
    stock: data
  }
});

export const getFileFormatsAction = (
  status: FetchStatus,
  data?: FileFormat[],
  errors?: ApiError[]
): GetFileFormatsAction => ({
  type: ActionTypes.GET_FILE_FORMATS,
  status,
  errors,
  payload: {
    fileFormats: data
  }
});

export const addStockAction = (status: FetchStatus, errors?: ApiError[]): AddStockAction => ({
  type: ActionTypes.ADD_STOCK,
  errors,
  status
});

// Action thunks
export const getStockThunk = (id: string) => {
  return requestApiThunk(getStockAction, () => getStock(id));
};

export const getStocksAction = (
  status: FetchStatus,
  data?: Stock[],
  errors?: ApiError[]
): GetStocksAction => ({
  type: ActionTypes.GET_STOCKS,
  status,
  errors,
  payload: {
    stocks: data
  }
});

export const getSubscribedStocksAction = (
  status: FetchStatus,
  data?: Stock[],
  errors?: ApiError[]
): GetSubscribedStocksAction => ({
  type: ActionTypes.GET_SUBSCRIBED_STOCKS,
  status,
  errors,
  payload: {
    stocks: data
  }
});

export const deleteStockThunk = (id: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.DELETE_STOCK, errors),
    () => deleteStock(id)
  );
};

export const getFileFormatsThunk = () => {
  return requestApiThunk(getFileFormatsAction, getFileFormats);
};

export const editStockThunk = (id: string, formData: EditStockFormData) => {
  return async (dispatch: any) => {
    const catalogResult = await editStock(id, formData);
    if (catalogResult.IsSuccess) dispatch(getStockThunk(id));
    return catalogResult;
  };
};

export const addStockThunk = (addStockFormData: AddStockFormData) => {
  return async (dispatch: Dispatch) => {
    dispatch(addStockAction('request'));
    const result = await addStock(addStockFormData);
    if (result.IsSuccess) {
      dispatch(addStockAction('success'));
    } else {
      dispatch(addStockAction('failure'));
    }

    return result;
  };
};

export const editStockAvailableThunk = (
  organizationIds: string[],
  stockId: string,
  available: boolean
) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.EDIT_STOCK_AVAILABLE, errors),
    () => editStockAvailable(organizationIds, stockId, available),
    getMyOrganizationThunk()
  );
};

// Action thunks
export const getStocksThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(getStocksAction('request'));

    const apiResult = await listStocks();
    if (apiResult.IsSuccess) {
      dispatch(getStocksAction('success',  apiResult.Data));
    } else {
      dispatch(getStocksAction('failure', undefined, apiResult.Errors));
    }
    return apiResult;
  };
};

export const getSubscribedStocksThunk = () => {
  return requestApiThunk(getSubscribedStocksAction, listSubscribedStocks);
};

// Export Action creator types
export type Action = GetStockAction | AddStockAction | GetFileFormatsAction | GetStocksAction | GetSubscribedStocksAction;
