import { InterfaceMethod, InterfaceType, OrganizationType } from 'src/models/Organization';
import i18n from '../../i18n'

export const getInterfaceTypes = (type: OrganizationType | undefined) => {
  switch (type) {
    case OrganizationType.Retailer:
      return [InterfaceType.CatalogReceive, InterfaceType.OrderSend];
      case OrganizationType.Supplier:
        return [InterfaceType.CatalogSend, InterfaceType.OrderReceive];
  }
  return []
};

export const getInterfaceTypesOrder = (type: OrganizationType | undefined) => {
  switch (type) {
    case OrganizationType.Retailer:
      return [InterfaceType.OrderSend];
    case OrganizationType.Supplier:
      return [InterfaceType.OrderReceive];
  }
  return []
};

export const getInterfaceTypesCatalog = (type: OrganizationType | undefined) => {
  switch (type) {
    case OrganizationType.Retailer:
      return [InterfaceType.CatalogReceive];
    case OrganizationType.Supplier:
      return [InterfaceType.CatalogSend];
  }
  return []
};

export const getInterfaceText = (t: InterfaceType | undefined) : {title: string, description: string} => {
  switch (t) {
    case InterfaceType.CatalogSend: 
    return {title: i18n.t('adminOrganizations.sendCatalog'), description: i18n.t('adminOrganizations.howDoYouSendCatalogsToArxis')} 
    case InterfaceType.CatalogReceive: 
    return {title: i18n.t('adminOrganizations.receiveCatalog'), description: i18n.t('adminOrganizations.howDoYouReceiveCatalogsFromArxis')} 
    case InterfaceType.OrderSend: 
    return {title: i18n.t('adminOrganizations.sendingOrders'), description: i18n.t('adminOrganizations.howDoYouSendOrdersToArxis')} 
    case InterfaceType.OrderReceive: 
    return {title: i18n.t('adminOrganizations.receivingOrders'), description: i18n.t('adminOrganizations.howDoYouReceiveOrdersFromArxis')} 
  }

  return {title: '', description: ''}
} 

export const getInterfaceFormats = (t: InterfaceType | undefined) : Array<string> => {
  switch (t) {
    case InterfaceType.CatalogSend:
      return ['CSV', 'ECAT', 'IDM', 'LTX'];
    case InterfaceType.CatalogReceive: 
     return ['ECAT', 'LTX'];
     case InterfaceType.OrderSend: 
     return ['EANCOM', 'LTX', 'UBL'];
     case InterfaceType.OrderReceive: 
      return ['EANCOM', 'LTX', 'UBL','LivingFurn', 'PDF', 'HTML'];
  }
  return [];
}

export const getInterfaceMethods = (t: InterfaceType | undefined) : {key: InterfaceMethod, description: string}[] => {
  const manual = {key: InterfaceMethod.Manual, description: i18n.t('tooltip.Manual')};
  const ltxFtp = {key: InterfaceMethod.LtxFtp, description: 'Arxis FTP'};
  const ftp = { key: InterfaceMethod.Ftp, description: 'Jouw FTP' };
  const sftp = { key: InterfaceMethod.Sftp, description: 'Jouw SFTP' };
  const email = { key: InterfaceMethod.Email, description: 'E-mail' };
  

  switch (t) {
    case InterfaceType.CatalogSend:
      return [manual];
    case InterfaceType.CatalogReceive:
      return [manual, email];
    case InterfaceType.OrderSend:
      return [ltxFtp];
    case InterfaceType.OrderReceive:
      return [ltxFtp, ftp, sftp, email];
  }
  return [];
}
