import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';

const bounce = keyframes`
 0% { transform: scale(0); opacity:0;}
 50% { transform: scale(1.2); opacity:0.4;}
 75% { transform: scale(0.95); opacity:0.7;}
 100% { transform: scale(1);  opacity:1}
 `;

export const LTXLogo = styled('img')({
  position: 'absolute',
  left: '0',
  right: '0',
  top: 'calc(50% - 100px)',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxHeight: '200px',
  maxWidth: '200px',
  animationName: bounce,
  animationDuration: '.6s'
});

export const LoginContainer = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  overflow: 'hidden'
});

export const LoginPaper = styled(Paper)({
  position: 'relative',
  top: '15%',
  backgroundColor: '#fff',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '550px',
  paddingBottom: '100px',
  borderRadius: '0px',
  boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.15)',
  '& header': {
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '15px',
    marginTop: '55px',
    marginLeft: '15px'
  }
});

export const LoginContent = styled('div')({
  margin: '25px',
  display: 'flex',
  flexDirection: 'column'
});

export const LoginInput = styled(Input)({
  margin: '15px',
  marginLeft: '35px',
  width: '75%'
});

export const LoginButtonContainer = styled('div')({
  margin: '15px 15px 30px 15px',
  display: 'flex',
  justifyContent: 'space-between'
});

export const Form = styled(FormControl)({
  width: '100%'
});

export const LoginButton = styled(Button)({
  backgroundColor: '#81cd96',
  width: '115px',
  textTransform: 'none',
  fontFamily: 'Montserrat-Bold'
});

export const LoginPaperFooter = styled('div')({
  position: 'absolute',
  bottom: 0,
  height: '15%',
  width: '100%'
});

export const FooterText = styled('div')({
  display: 'flex',
  'align-content': 'space-between',
  '& div': '6px',
  '& a': { fontWeight: 'bold', fontSize: '13px' }
});

const fadeIn = keyframes`
 0% { transform: translateX(100px); opacity:0;}
 25% { transform: translateX(100px); opacity:0;}
 60% { transform: translateX(-20px); opacity:0.4;}
 83% { transform: translateX(5px); opacity:0.7;}
 100% { transform: translateX(0);  opacity:1}
 `;

export const InformationPaper = styled('div')({
  position: 'relative',
  top: 'calc(50% - 50px)',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '550px',
  height: '100px',
  backgroundColor: 'rgba(0,0,0,0)',

  '& #topHeader': {
    marginTop: '12px',
    fontWeight: 'bold',
    fontSize: '34px',
    lineHeight: '45px',
    color: '#fff',
    animationName: fadeIn,
    animationDuration: '.9s'
  }
});

export const InformationContent = styled('div')({
  width: '100%',
  height: '100vh',

  '& #topLabel': {
    lineHeight: '22px'
  }
});

export const InformationBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '20px',

  '& #blockHeader': {
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#fff'
  },

  '& #blockLabel': {
    fontSize: '12px',
    color: '#fff'
  }
});

export const InformationButtonContainer = styled('div')({
  display: 'inline-block',
  marginTop: '30px',

  '& a': {
    textDecoration: 'none'
  }
});

export const ArrowIcon = styled(ArrowRight)({
  color: '#fff',
  height: '40px',
  width: '40px'
});

export const LeftSide = styled('div')({
  backgroundColor: '#2D80C2',
  width: '50%',
  borderRadius: '0px'
});

export const LeftSideInner = styled.div`
  margin-left: calc(50% - 250px);
  height: 100vh;
  display: flex;
  width: 500px;
  align-items: center;
`;

export const RightSide = styled('div')({
  width: '50%',
  height: 'auto',
  borderRadius: '0px',
  backgroundImage: 'url(' + require('../../style/images/background.jpg') + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
});
