import { Subscription } from '../../models/Subscription';
import { OrganizationType } from 'src/models/Organization';

export enum ActionTypes {  
GET_SUBSCRIPTIONS = '[subscriptions] GET_SUBSCRIPTIONS'
}

export interface SubscriptionState {
  loading: boolean;
  errors?: string;
  subscriptions: Subscription[];
}

export type SubscriptionsListModel = {
  id: string;
  name: string;
  connectionLimit: number;
  organizationType: OrganizationType;
};





