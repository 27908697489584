import * as jwtDecode from 'jwt-decode';
import { API_URL_COMMAND, API_URL_QUERY } from 'src/constants/ApiRoutes';

export type ApiError = {
  errorCode: any;
  errorDescription: string;
};

export type ApiResult<T = any> = {
  IsSuccess: boolean;
  StatusCode: number;

  Response?: Response;
  JsonResponse?: any;
  Errors?: ApiError[];

  Data?: T;
};

export type ApiClientConfig = {
  onTokenExpired: any;
};

let config: ApiClientConfig = {
  onTokenExpired: undefined
};

const setConfig = (newConfig: ApiClientConfig) => {
  config = newConfig;
};

const postCommandAsync = async (command: any, file?: any[]): Promise<ApiResult> => {
  return postDataAsync(API_URL_COMMAND, command, file);
};

const postQueryAsync = async (queryName: string, parameters?: any): Promise<ApiResult> => {
  const query = {
    QueryName: queryName,
    ...parameters
  };

  return postDataAsync(API_URL_QUERY, query);
};

const postDataAsync = async (url: string, data: any, files?: any[]): Promise<ApiResult> => {
  console.log('Posting data to api...');
  const token = sessionStorage.getItem('auth_token') || '';
  
  try {
    const formData = new FormData();
    if (files) {
      for (const file of files) formData.append('file', file);
    }
    formData.append('data', JSON.stringify(data));

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: formData
    });

    if (response.status === 200) {
      const json = await response.json();
      return {
        IsSuccess: true,
        StatusCode: response.status,
        Response: response,
        JsonResponse: json,
        Data: json.data,
        Errors: json.errors
      };
    } else {
      console.log('Api request status code: ' + response.status);

      if (response.status === 401) {
        if (token) {
          const tokenResult = jwtDecode(token);
          const time = new Date().getTime() / 1000;
          if (tokenResult.exp < time) {
            // Token expired
            console.log('token expired');
            config.onTokenExpired();
          }
        }
      }

      const json = await response.json();
      return {
        IsSuccess: false,
        StatusCode: response.status,
        Response: response,
        JsonResponse: json,
        Data: json.data,
        Errors: json.errors
      };
    }
  } catch (err) {
    console.log('[ERROR] Api not available', err);

    return {
      IsSuccess: false,
      StatusCode: 0
    };
  }
};

export const apiClient = {
  setConfig,
  postDataAsync,
  postCommandAsync,
  postQueryAsync
};
