import styled from '@emotion/styled';
import * as React from 'react';
import MediaQuery from 'react-responsive';
import {
  MyOrganizationModel,
  OrganizationModel,
  OrganizationType,
  OrganizationReadinessStatus
} from 'src/models/Organization';
import { ConnectRequest, RequestModel } from 'src/models/Request';
import { CatalogRequestStatus } from 'src/store/requests/api';
import XButton, { XButtonStyle } from '../layout/XButton';
import { XContainer } from '../layout/XContainer';
import XFormButtons from '../layout/XFormButtons';
import XGrid from '../layout/XGrid';
import { XImageContainer } from '../layout/XImage';
import { XImage } from '../layout/XImage';
import { XPaperContainer, XPaperLabel, XPaperText, XPaperTitle } from '../layout/XPaper';
import { XRow, XRowCell } from '../layout/XRow';
import XCatalog from './XCatalog';
import XOrganizationAddress from './XOrganizationAddress';

import { generateAvatar } from 'src/helpers/avatarGenerator';
import { getImageUrl } from 'src/helpers/formatHelpers';
import Logo from '../../style/images/ltxLogos/ltxdefOpaque.svg';
import XOrganizationTitle from './XOrganizationTitle';
import XGoBack from '../shared/XGoBack';
import { Email, EmailOutlined } from '@material-ui/icons';
import { XSecActionButton, XActionButton } from '../layout/XActionButton';
import { XActionStrip } from '../layout/XActionStrip';
import { PublicationStatus } from 'src/models/Catalog';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { InterfaceType } from 'src/models/Organization';
import { Translation } from 'react-i18next';
import i18n from 'src/i18n';

type OwnProps = {
  loadingOrganization: boolean;
  loadingRequest: boolean;
  organization: OrganizationModel;
  catalogViewRequest?: RequestModel;
  myOrganization: MyOrganizationModel;
  history?: any;
  editCatalogOffer: (status: CatalogRequestStatus) => void;
  requestViewCatalogs: () => void;
  cancelRequest: () => void;
};

type AllProps = OwnProps;

const getButtonState = (props: AllProps) => {
  const isAdmin = props.myOrganization.organization.isAdmin;
  const connectRequest = props.catalogViewRequest?.connectRequest;
  const organizationConnection = props.myOrganization.organizationConnections.find(
    dc =>
      (dc.sourceOrganizationId === props.myOrganization.organization.id &&
        dc.targetOrganizationId === props.organization.organization.id) ||
      (dc.targetOrganizationId === props.myOrganization.organization.id &&
        dc.sourceOrganizationId === props.organization.organization.id)
  );

  if (isAdmin)
    return {
      show: false,
      showAvailableCatalogs: false
    };

  if (organizationConnection)
    return {
      show: false,
      showAvailableCatalogs: false
    };

  else if (!connectRequest)
    return {
      show: true,
      active: true,
      color: 'primary',
      text: i18n.t('connections.createRelationship'),
      onClick: () => props.requestViewCatalogs(),
      showAvailableCatalogs: false
    };
  else if (connectRequest.approvedDate) {
    if (organizationConnection)
      return {
        show: false,
        showAvailableCatalogs: true
      };
    else
      return {
        show: true,
        active: true,
        color: 'primary',
        text: i18n.t('connections.createRelationship'),
        onClick: () => props.requestViewCatalogs(),
        showAvailableCatalogs: false
      };
  } else if (connectRequest.disapprovedDate)
    return {
      show: true,
      active: false,
      text: i18n.t('connections.rejected'),
      showAvailableCatalogs: false
    };
  else
    return {
      show: true,
      active: true,
      color: '#ff5b4f',
      text: i18n.t('connections.cRequest'),
      onClick: () => props.cancelRequest(),
      showAvailableCatalogs: false
    };
};

const XSupplierDetails: React.FC<AllProps> = props => {
  const otherRequested =
    props.catalogViewRequest?.connectRequest.requestOrganizationId ===
    props.organization.organization.id;
  const incommingRequest =
    otherRequested &&
    props.catalogViewRequest &&
    !props.catalogViewRequest.connectRequest.approvedDate &&
    !props.catalogViewRequest.connectRequest.disapprovedDate;
  const buttonState = getButtonState(props);

  return (
    <XContainer style={{ position: 'relative' }}>
      <XRow style={{ paddingBottom: '15px' }}>
        <div style={{ paddingTop: '5px', flex: 1 }}>
          <XGoBack history={props.history} />
        </div>
        {props.loadingOrganization ? (
          <Skeleton width={120} />
        ) : (
          <>
            <XActionStrip>
              {props.organization?.organization?.email?.length > 0 && !incommingRequest && (
                <div style={{ paddingRight: '10px' }}>
                  <XSecActionButton
                    href={'mailto:' + props.organization.organization.email}
                    target="_blank">
                    <EmailOutlined style={{ height: '19px', marginRight: '10px' }} />
                    <Translation>{t => t('connections.sendEmail')}</Translation>
                  </XSecActionButton>
                </div>
              )}
            </XActionStrip>
            {buttonState.show && !incommingRequest && !props.myOrganization.organization.isAdmin && (
              <div>
                <XActionButton
                  className={props.loadingRequest ? '' : buttonState.active ? 'disabled' : ''}
                  onClick={() => {
                    if (buttonState.onClick) buttonState.onClick();
                  }}>
                  {buttonState.text}
                  {props.loadingRequest ? '...' : ''}
                </XActionButton>
              </div>
            )}
            {incommingRequest && (
              <XRow style={{ alignItems: 'center', width: 'unset' }}>
                <div style={{ paddingRight: '10px' }}>
                  {props.organization.organization.name} heeft een connectie verzoek verstuurd
                </div>
                <div style={{ paddingRight: '10px' }}>
                  <XSecActionButton
                    className={props.loadingRequest ? '' : buttonState.active ? 'disabled' : ''}
                    onClick={() => {
                      props.editCatalogOffer('disapprove');
                    }}>
                    Afwijzen{props.loadingRequest ? '...' : ''}
                  </XSecActionButton>
                </div>
                <XActionButton
                  className={props.loadingRequest ? '' : buttonState.active ? 'disabled' : ''}
                  onClick={() => {
                    props.editCatalogOffer('approve');
                  }}>
                  Accepteren{props.loadingRequest ? '...' : ''}
                </XActionButton>
              </XRow>
            )}
          </>
        )}
      </XRow>
      <XRow>
        {props.loadingOrganization ? (
          <Skeleton variant="rect" width={100} height={100} />
        ) : (
          <XImageContainer
            style={{ minWidth: '100px', background: 'none' }}
            width={100}
            height={100}>
            <XImage
              src={getImageUrl(props.organization.organization.imageURL)}
              alt=""
              onError={(e: any) => {
                e.target.onError = null;
                e.target.src = generateAvatar(props.organization.organization.name, 100);
              }}
            />
          </XImageContainer>
        )}
        <div style={{ paddingLeft: '20px' }}>
          <TitleWrapper>
            {props.loadingOrganization ? (
              <Skeleton width={160} height={40} />
            ) : (
              <XOrganizationTitle
                organization={props.organization.organization}
                fontSize={'22px'}
                bold={true}
              />
            )}
          </TitleWrapper>
          <XPaperText>
            {props.loadingOrganization ? (
              <Skeleton width={160} />
            ) : (
              props.organization.organization.description
            )}
          </XPaperText>

          <MediaQuery minWidth={800}>
            {props.loadingOrganization ? (
              <div style={{ marginLeft: '15px' }}>
                <Skeleton width={160} />
              </div>
            ) : (
              <XOrganizationAddress organization={props.organization.organization} />
            )}
          </MediaQuery>
        </div>
        <div
          style={{
            marginLeft: '15px',
            paddingLeft: '20px',
            paddingTop: '4px'
          }}>
          <XPaperLabel style={{ marginBottom: '25px' }}>
            Account status: {props.organization?.organization?.accountStatus}
          </XPaperLabel>
          <XPaperTitle>Supported data: </XPaperTitle>
          <XCenter>
            <XPaperText style={{ margin: '5px 15px' }}>
              <XCenter>
                {props.organization?.organization?.supportsCatalogData ===
                OrganizationReadinessStatus.Ready ? (
                  <CheckCircleOutlineOutlinedIcon color="primary" />
                ) : (
                  <RemoveCircleOutlineOutlinedIcon color="disabled" />
                )}
                <XSpan>Catalog</XSpan>
              </XCenter>
            </XPaperText>
            <XPaperText style={{ margin: '5px 15px' }}>
              <XCenter>
                {props.organization?.organization?.supportsStockData ===
                OrganizationReadinessStatus.Ready ? (
                  <CheckCircleOutlineOutlinedIcon color="primary" />
                ) : (
                  <RemoveCircleOutlineOutlinedIcon color="disabled" />
                )}
                <XSpan>Stock</XSpan>
              </XCenter>
            </XPaperText>
            <XPaperText style={{ margin: '5px 15px' }}>
              <XCenter>
                {props.organization?.organization?.supportsOrderData ===
                OrganizationReadinessStatus.Ready ? (
                  <CheckCircleOutlineOutlinedIcon color="primary" />
                ) : (
                  <RemoveCircleOutlineOutlinedIcon color="disabled" />
                )}
                <XSpan>Orders</XSpan>
              </XCenter>
            </XPaperText>
          </XCenter>
        </div>
        <div
          style={{
            marginLeft: '15px',
            paddingTop: '4px'
          }}>
          <TitleWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <div>
                GLN:
                <XPaperLabel>
                  {props.loadingOrganization ? (
                    <Skeleton width={160} />
                  ) : (
                    <> {props.organization?.identifiers?.find(x => x.type === 'GLN')?.value} </>
                  )}
                </XPaperLabel>
              </div>
              <div>
                Arxis ID:
                <XPaperLabel>
                  {props.loadingOrganization ? (
                    <Skeleton width={160} />
                  ) : (
                    <> {props.organization?.identifiers?.find(x => x.type === 'ArxisId')?.value} </>
                  )}
                </XPaperLabel>
              </div>
            </div>
          </TitleWrapper>
        </div>
      </XRow>

      <MediaQuery maxWidth={800}>
        {props.loadingOrganization ? (
          <Skeleton width={160} />
        ) : (
          <div style={{ marginLeft: '-15px', marginTop: '10px' }}>
            <XOrganizationAddress organization={props.organization.organization} />
          </div>
        )}
      </MediaQuery>

      {buttonState.showAvailableCatalogs ? (
        <StyledCatalogsDiv>
          <XPaperContainer>
            <XContainer>
              <Translation>
                {t => <XPaperLabel>{t('catalogs.availableCatalogsFromThisSupplier')}</XPaperLabel>}
              </Translation>
            </XContainer>
          </XPaperContainer>
          <XGrid columns={2}>
            {props.loadingOrganization ? (
              <Skeleton variant="rect" height={75} width={75} />
            ) : (
              <>
                {props.organization?.catalogs?.length === 0 ? (
                  <Translation>
                    {t => <NoItems>{t('catalogs.supplierHasNotMadeAnyCatalogs')}</NoItems>}
                  </Translation>
                ) : (
                  undefined
                )}
                {props.organization.catalogs.slice(0, 16).map(obj => (
                  <XCatalog key={obj.id} catalog={obj} size="small" />
                ))}
              </>
            )}
          </XGrid>
        </StyledCatalogsDiv>
      ) : (
        <div />
      )}
    </XContainer>
  );
};

const StatsLineItem = styled(XRowCell)`
  line-height: 24px;
  flex: 0;
  text-align: right;
  min-width: 250px;
  & > button {
    min-width: 200px;
  }
`;

const StyledRequestButton = styled(XButton)({
  maxWidth: '400px'
});

const StyledCatalogsDiv = styled.div({
  marginTop: '10px'
});

const StyledChoiceTextDiv = styled.div({
  padding: '15px'
});

const StyledChoiceDiv = styled.div({
  marginTop: '10px',
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  borderTop: '1px solid #ccc',
  fontSize: '13px'
});

const NoItems = styled.span`
  padding: 15px;
  & a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TitleWrapper = styled.div`
  margin: 0 15px;
`;

const XCenter = styled.div`
  display: flex;
  align-items: center;
`;

const XSpan = styled.div`
  margin-left: 0.5em;
`;

export default XSupplierDetails;
