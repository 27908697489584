import { PortalPageRoute, PublicPageRoute } from 'src/models/Route';

import XNavigationBar from '../components/shared/XNavigationBar';

import PortalLayout from '../containers/PortalLayout';
import PublicLayout from '../containers/PublicLayout';

import BrowseRouter from './catalogs/CatalogsRouter';
import BrowseSideBar from './catalogs/CatalogsSideBar';

import StocksRouter from './stock/StocksRouter';

import ConnectionsRouter from './connections/ConnectionsRouter';
import ConnectionsSideBar from './connections/ConnectionsSideBar';

import ExploreSideBar from './explore/ExploreSideBar';
import ExploreRouter from './explore/ExploreRouter';

import IndexPage from './index/IndexPage';
import LoginPage from './login/LoginPage';
import LogoutPage from './login/LogoutPage';

import MyLtxRouter from './myltx/MyLtxRouter';
import MyLtxSideBar from './myltx/MyLtxSideBar';

import MyAccountRouter from './myltx/MyAccountRouter';
import MyAccountSideBar from './myltx/MyAccountSideBar';

import ConfirmationPage from './register/ConfirmationPage';
import RegisterRouter from './register/RegisterRouter';

import ConfirmationEmailSentPage from './register/ConfirmationEmailSentPage';
import PasswordForgottenPage from './register/PasswordForgottenPage';
import ResetPasswordPage from './register/ResetPasswordPage';
import ErrorPage from './system/ErrorPage';
import NotFoundPage from './system/NotFoundPage';
import ValidationRouter from './validation/ValidationRouter';
import ValidationSideBar from './validation/ValidationSideBar';
import DomainRedirectPage from './index/pages/DomainRedirectPage';

import OrdersApp from 'src/purchase-order-module/OrdersApp';
import OrdersSideBar from 'src/purchase-order-module/OrdersSideBar';

import { OrganizationType } from 'src/models/Organization';

const portalPageDefaults = {
  exact: false,
  layout: PortalLayout,
  navbar: XNavigationBar
};

export const HomePageRoute: PortalPageRoute = {
  ...portalPageDefaults,
  path: '/app/explore',
  main: ExploreRouter,
  title: 'Explore',
  shouldShowCallback: (isAdmin: boolean) => !isAdmin,
  shouldMapCallback: (isAdmin: boolean) => !isAdmin
};

export const NavPortalPages: PortalPageRoute[] = [
  HomePageRoute,
  {
    ...portalPageDefaults,
    path: '/app/my',
    main: MyLtxRouter,
    sidebar: MyLtxSideBar,
    title: 'Organization',
    shouldShowCallback: (isAdmin: boolean) => !isAdmin,
    shouldMapCallback: (isAdmin: boolean) => !isAdmin
  },
  {
    ...portalPageDefaults,
    path: '/app/profile',
    main: MyAccountRouter,
    sidebar: MyAccountSideBar,
    title: 'Account',
    shouldShowCallback: (isAdmin: boolean) => !isAdmin,
    shouldMapCallback: (isAdmin: boolean) => !isAdmin
  },
  {
    ...portalPageDefaults,
    path: '/app/dealer-portal',
    main: OrdersApp,
    sidebar: OrdersSideBar,
    title: 'Purchase',
    shouldShowCallback: (isAdmin: boolean, organizationType: OrganizationType) =>
      organizationType !== OrganizationType.Supplier || isAdmin
  }
];

export const ValidationPages: PortalPageRoute[] = [
  ...NavPortalPages,
  {
    ...portalPageDefaults,
    path: '/validation',
    main: ValidationRouter,
    sidebar: ValidationSideBar,
    title: 'Validation'
  }
];

export const PortalPages: PortalPageRoute[] = [...ValidationPages];

const publicPageDefaults = {
  exact: false,
  layout: PublicLayout
};

export const LoginPageRoute: PublicPageRoute = {
  ...publicPageDefaults,
  path: '/login/:redirectURL?',
  component: LoginPage
};

export const IndexPageRoute: PublicPageRoute = {
  ...publicPageDefaults,
  path: '/',
  exact: true,
  component: IndexPage
};

export const DomainRedirectPageRoute: PublicPageRoute = {
  ...publicPageDefaults,
  path: '/ltx',
  exact: true,
  component: DomainRedirectPage
};

export const NotFoundPageRoute: PublicPageRoute = {
  ...publicPageDefaults,
  path: '/404',
  component: NotFoundPage
};

export const PublicPages: PublicPageRoute[] = [
  IndexPageRoute,
  DomainRedirectPageRoute,
  {
    ...publicPageDefaults,
    path: '/contact',
    exact: true,
    component: IndexPage
  },
  LoginPageRoute,
  {
    ...publicPageDefaults,
    path: '/ltx',
    exact: true,
    component: DomainRedirectPage
  },
  NotFoundPageRoute,
  {
    ...publicPageDefaults,
    path: '/logout',
    component: LogoutPage
  },
  {
    ...publicPageDefaults,
    path: '/register',
    component: RegisterRouter
  },
  {
    ...publicPageDefaults,
    path: '/confirm/:emailCode',
    component: ConfirmationPage
  },
  {
    ...publicPageDefaults,
    path: '/confirmationemailsent',
    component: ConfirmationEmailSentPage
  },
  {
    ...publicPageDefaults,
    path: '/resetPassword/:code',
    component: ResetPasswordPage
  },
  {
    ...publicPageDefaults,
    path: '/passwordForgotten',
    component: PasswordForgottenPage
  },
  {
    ...publicPageDefaults,
    path: '/500',
    component: ErrorPage
  },

  // Alias urls
  {
    ...publicPageDefaults,
    path: '/signin',
    component: LoginPage
  },
  {
    ...publicPageDefaults,
    path: '/signup',
    component: RegisterRouter
  },
  {
    ...publicPageDefaults,
    path: '/error',
    component: ErrorPage
  }
];
