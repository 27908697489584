import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { FetchStatus } from 'src/store/catalogs/actions';
import { ApiError, ApiResult } from './apiClient';

export const requestApiThunk = (
  action: (status: FetchStatus, data?: any, errors?: ApiError[]) => AnyAction,
  apiCall: () => Promise<ApiResult>,
  continueWith?: (dispatch: Dispatch<AnyAction>) => Promise<ApiResult>
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(action('request'));

    const apiResult = await apiCall();
    if (apiResult.IsSuccess) {
      dispatch(action('success', apiResult.Data));

      if (continueWith) continueWith(dispatch);
    } else {
      dispatch(action('failure', undefined, apiResult.Errors));
    }
    return apiResult;
  };
};

export const commandAction = (
  status: FetchStatus,
  type: any,
  errors?: ApiError[],
  dontCountLoading: boolean = true
): CommandAction => ({
  type,
  status,
  errors,
  dontCountLoading
});

export type CommandAction = {
  type: any;
  status: FetchStatus;
  errors?: ApiError[];
  dontCountLoading: boolean;
};

export const delayExecution = (startTime: number, minimalTime: number, delegate: any) => {
  const dif = new Date().getTime() - startTime;
  if (dif > minimalTime) delegate();
  else window.setTimeout(() => delegate(), minimalTime - dif);
};
