import styled from '@emotion/styled';
import React from 'react';
import { Translation } from 'react-i18next';
import { Organization } from 'src/models/Organization';
import { XContainer } from '../layout/XContainer';
import XField from '../layout/XField';
import XPopup from '../layout/XPopup';
import XSelectItem from '../layout/XSelectItem';
import XOrganizationRow from './XOrganizationRow';

type OwnProps = {
  organizations: Organization[];
  title: string;
  open: boolean;
  saveText?: string;
  allowEmpty?: boolean;
  onSave: (selectedOrganizations: Organization[]) => void;
  onCancel: () => void;
  clearOnClose: boolean;
};

type AllProps = OwnProps;
type State = {
  selected: Organization[];
  search: string;
};

class XOrganizationSelectPopup extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      selected: [],
      search: ''
    };
  }

  public toggleOrganization(organization: Organization, state: boolean) {
    if (state) this.setState({ selected: [...this.state.selected, organization] });
    else {
      const selected = [...this.state.selected];
      selected.splice(selected.indexOf(organization), 1);
      this.setState({ selected });
    }
  }

  public save() {
    const selected = this.state.selected;
    if (this.props.clearOnClose) this.setState({ selected: [] });

    this.setState({ search: '' });
    this.props.onSave(selected);
  }

  public cancel() {
    if (this.props.clearOnClose) this.setState({ selected: [] });
    this.setState({ search: '' });
    this.props.onCancel();
  }

  public render() {
    const organizations = this.props.organizations.filter(
      o => o.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    );
    return (
      <Translation>
        {t => (
          <XPopup
            title={this.props.title}
            open={this.props.open}
            buttonCancelText={t('tooltip.Cancel')}
            buttonSaveText={this.props.saveText ?? t('tooltip.Add')}
            onCancel={() => this.cancel()}
            onSave={() => this.save()}
            saveAvailable={this.props.allowEmpty ? true : this.state.selected.length > 0}>
            <StyledField>
              <XField
                placeholder={t('tooltip.search')}
                name="search"
                onChange={e => this.setState({ search: e.target.value })}
              />
            </StyledField>
            {organizations.length === 0 && (
              <Translation>{t => <div> {t('organizations.noOrgAvailable')} </div>}</Translation>
            )}
            <div style={{ margin: '0 0 0 -15px' }}>
              {organizations.map(org => (
                <div key={org.id} style={{ lineHeight: '28px' }}>
                  <XSelectItem
                    value={this.state.selected.find(o => o.id === org.id) !== undefined}
                    onToggle={state => this.toggleOrganization(org, state)}>
                    <XOrganizationRow organization={org} />
                  </XSelectItem>
                </div>
              ))}
            </div>
          </XPopup>
        )}
      </Translation>
    );
  }
}

export default XOrganizationSelectPopup;

const StyledField = styled.div`
  position: absolute;
  right: -10px;
  top: -56px;
  display: inline-block;
  vertical-align: top;
  width: 200px;
  z-index: 11;
  & input {
    padding: 3px 0 !important;
  }
`;
