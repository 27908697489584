import {
  ActionTypes,
  ConfirmationState,
  MessageType,
  NotificationState,
  SnackbarState,
  ViewType
} from './types';

// action creator types
export type ShowDataForm = {
  type: ActionTypes.SHOW_DATAFORM;
};

export type ShowSnackbar = {
  type: ActionTypes.SHOW_SNACKBAR;
  payload: SnackbarState;
};

export type ShowNotification = {
  type: ActionTypes.SHOW_NOTIFICATION;
  payload: NotificationState;
};

export type ShowConfirmation = {
  type: ActionTypes.SHOW_CONFIRMATION;
  payload: ConfirmationState;
};

export type HideNotification = {
  type: ActionTypes.HIDE_NOTIFICATION;
};

export type HideConfirmation = {
  type: ActionTypes.HIDE_CONFIRMATION;
};

export type ChangeView = {
  type: ActionTypes.CHANGE_VIEW;
  screen: string;
  payload: ViewType;
};

// action creators
export const showSnackbar = (message: string, type: MessageType = 'info'): ShowSnackbar => ({
  type: ActionTypes.SHOW_SNACKBAR,
  payload: {
    show: true,
    message,
    type
  }
});

export const changeView = (screen: string, type: ViewType): ChangeView => ({
  type: ActionTypes.CHANGE_VIEW,
  screen,
  payload: type
});

export const showNotification = (
  message: string,
  type: MessageType = 'info'
): ShowNotification => ({
  type: ActionTypes.SHOW_NOTIFICATION,
  payload: {
    show: true,
    message,
    type
  }
});

export const showConfirmation = (
  title: string,
  message: string,
  onConfirm: () => void
): ShowConfirmation => ({
  type: ActionTypes.SHOW_CONFIRMATION,
  payload: {
    show: true,
    message,
    title,
    onConfirm
  }
});

export const hideNotification = (): HideNotification => ({
  type: ActionTypes.HIDE_NOTIFICATION
});

export const hideConfirmation = () => ({
  type: ActionTypes.HIDE_CONFIRMATION
});

export const showDataForm = (): ShowDataForm => ({
  type: ActionTypes.SHOW_DATAFORM
});

export type Action =
  | ShowDataForm
  | ShowSnackbar
  | ShowNotification
  | ShowConfirmation
  | HideConfirmation
  | HideNotification
  | ChangeView;
