import { apiClient, ApiResult } from '../../helpers/apiClient';

export type CatalogRequestStatus = 'approve' | 'disapprove';

export const listRequests = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListRequests', {});
};

export const listManageRequests = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListManageRequests', {});
};

export const sendRequest = async (
  targetOrganizationId: string,
  catalogIds?: string[]
): Promise<ApiResult> => {
  const command = {
    CommandName: 'CreateRequest',
    TargetOrganizationId: targetOrganizationId,
    AvailableCatalogs: catalogIds ?? []
  };

  return await apiClient.postCommandAsync(command);
};

export const cancelRequest = async (id: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'CancelRequest',
    RequestId: id
  };

  return await apiClient.postCommandAsync(command);
};

export const updateRequest = async (
  catalogRequestId: string,
  status: CatalogRequestStatus
): Promise<ApiResult> => {
  const command = {
    CommandName: 'EditRequest',
    Id: catalogRequestId,
    Status: status
  };

  return await apiClient.postCommandAsync(command);
};
