import { apiClient, ApiResult } from 'src/helpers/apiClient';
import { Catalog } from 'src/models/Catalog';

import { CatalogSort } from './actions';
import { CatalogFormData, EditCatalogFormData, CatalogHeaderFormData, Mapping } from './types';
import { CatalogInterface } from '../../models/Catalog'

export const getCatalog = async (id: string): Promise<ApiResult> => {
  const parameters = {
    Id: id
  };
  return await apiClient.postQueryAsync('GetCatalog', parameters);
};

export const getCatalogMapping = async (id: string | undefined): Promise<ApiResult> => {
  const parameters = {
    MappingId: id
  };
  return await apiClient.postQueryAsync('GetCatalogMapping', parameters);
};

export const saveCatalogMapping = async (Mapping: Mapping, catalogId: string, catalogMappingId: string, catalogVersionId: string ): Promise<ApiResult> => {
  const command = {
    CommandName: 'SaveCatalogMapping',
    CatalogId: catalogId,
    CatalogMappingId: catalogMappingId,
    CatalogVersionId: catalogVersionId,
    Mapping
  };
  return await apiClient.postCommandAsync(command);
}

export const validateCatalogFile = async (
  fileFormat: string,
  fileFormatVersion: string,
  file: File
): Promise<ApiResult> => {
  const command = {
    CommandName: 'ValidateCatalogFile',
    FileFormat: fileFormat,
    FileFormatVersion: fileFormatVersion
  };
  return await apiClient.postCommandAsync(command, [file]);
};

export const convertCatalogFile = async (
  fromFileFormat: string,
  fromFileFormatVersion: string,
  toFileFormat: string,
  toFileFormatVersion: string,
  file: File
): Promise<ApiResult> => {
  const command = {
    CommandName: 'ConvertCatalogFile',
    FromFileFormat: fromFileFormat,
    FromFileFormatVersion: fromFileFormatVersion,
    ToFileFormat: toFileFormat,
    ToFileFormatVersion: toFileFormatVersion
  };
  return await apiClient.postCommandAsync(command, [file]);
};

export const deleteCatalog = async (id: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'DeleteCatalog',
    Id: id
  };
  return await apiClient.postCommandAsync(command);
};

export const editCatalogAvailable = async (
  organizationIds: string[],
  catalogId: string,
  available: boolean
): Promise<ApiResult> => {
  const command = {
    CommandName: 'EditCatalogAvailable',
    OrganizationIds: organizationIds,
    CatalogId: catalogId,
    Available: available
  };
  return await apiClient.postCommandAsync(command);
};

export const editCatalog = async (
  id: string,
  formData: EditCatalogFormData
): Promise<ApiResult> => {
  const command = {
    CommandName: 'EditCatalog',
    CatalogId: id,
    Title: formData.title,
    VersionName: formData.version,
    Description: formData.description,
    StartDate: formData.startDate,
    EndDate: formData.endDate
  };
  return await apiClient.postCommandAsync(command, [formData.image]);
};

export const saveCatalog = async (): Promise<ApiResult> => {
  const command = {
    CommandName: 'SaveCatalog'
  };
  return await apiClient.postCommandAsync(command);
};

export const listCatalogs = async (sort: CatalogSort): Promise<ApiResult> => {
  return getCatalogs('ListCatalogs', {
    Sort: sort
  });
};

export const listSubscribedCatalogs = async (): Promise<ApiResult> => {
  return getCatalogs('ListSubscribedCatalogs', {});
};

const getCatalogs = async (queryName: string, params?: any): Promise<ApiResult> => {
  return await apiClient.postQueryAsync(queryName, params);
};

export const getFileFormats = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListFileFormats', {});
};

export async function publishCatalog(catalogId: string): Promise<ApiResult> {
  const command = {
    CommandName: 'PublishCatalog',
    CatalogId: catalogId
  };

  return await apiClient.postCommandAsync(command);
}

export async function addCatalog(param: CatalogFormData): Promise<ApiResult> {
  const command = {
    CommandName: 'AddCatalog',
    OrganizationId: param.organizationId,
    Title: param.title,
    Description: param.description,
    VersionName: param.versionName,
    Accessibility: param.accessibility,
    Retailers: param.retailers,
    FileFormatId: param.fileFormat,
    FileFormatVersionId: param.fileFormatVersion,
    StartDate: param.startDate,
    EndDate: param.endDate
  };

  return await apiClient.postCommandAsync(command, [param.file, param.image]);
}


export async function addCatalogHeader(param: CatalogHeaderFormData): Promise<ApiResult> {
  const command = {
    CommandName: 'AddCatalogHeader',
    OrganizationId: param.organizationId,
    Title: param.title,
    Description: param.description,
    FileFormatId: param.fileFormatId,
    FileFormatVersionId: param.fileFormatVersionId,
    InterfaceType: param.interfaceType,
    InterfaceMethod: param.interfaceMethod,
    InterfaceAuthentication: param.interfaceAuthentication,
    Settings: param.settings
  };

  return await apiClient.postCommandAsync(command);
}


export const saveCatalogInterface = async (catalogInterface: CatalogInterface, catalogId?: string | undefined): Promise<ApiResult<CatalogInterface>> => {
  const command = {
    CommandName: "SaveCatalogInterface",
    CatalogId: catalogId,
    CatalogInterfaceId: catalogInterface.id,
    InterfaceType: catalogInterface.type,
    InterfaceMethod: catalogInterface.method,
    InterfaceAuthentication: catalogInterface.authentication,
    Settings: catalogInterface.settings
  };

  return await apiClient.postCommandAsync(command);
};

export const fetchCatalogFile = async (catalogId: string | undefined, file?: File[]): Promise<ApiResult> => {
  const command = {
    CommandName: "FetchCatalogFile",
    CatalogId: catalogId,
  };

  return await apiClient.postCommandAsync(command, file);
};
