import styled from '@emotion/styled';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import XButton, { XButtonStyle } from 'src/components/layout/XButton';
import XCheckbox from 'src/components/layout/XCheckbox';
import { XContainer } from 'src/components/layout/XContainer';
import XDatePicker from 'src/components/layout/XDatePicker';
import XFormButtons from 'src/components/layout/XFormButtons';
import { XRow } from 'src/components/layout/XRow';
import { CatalogValidationSummary } from 'src/models/Catalog';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { ApplicationState } from '../../../store';
import * as catalogActions from '../../../store/catalogs/actions';
import * as catalogSelects from '../../../store/catalogs/selectors';
import { CatalogFormData } from '../../../store/catalogs/types';

type StateProps = {
  data: CatalogFormData;
  validationSummary?: CatalogValidationSummary;
  account: AuthenticationAccount;
};

type DispatchProps = {
  storeInformation: (data: CatalogFormData) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

type State = {
  inputData: CatalogFormData;
};

class WizardResultPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      inputData: this.props.data
    };
  }

  public downloadValidationSummary() {
    const data =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(this.props.validationSummary, null, 2));
    const link = document.createElement('a');
    link.download = 'validatie-rapport.json';
    link.href = data;
    link.click();
  }

  public render() {
    return (
      <XContainer style={{ position: 'relative', padding: '15px 15px 0 15px' }}>
        <div style={{ padding: '40px 0 0 0', minHeight: '250px' }}>
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <CheckCircleIcon style={{ color: '0d8416', width: '100px', height: '100px' }} />
          </div>
          <div style={{ textAlign: 'center' }}>Catalogus toegevoegd!</div>
        </div>

        <XRow space={true} style={{ marginTop: '15px' }}>
          <Link to={this.props.account.isAdmin ? '/app/admin/catalogs/' : '/app/my/catalogs'}>
            <XButton colorscheme={XButtonStyle.Primary}>Sluiten</XButton>
          </Link>
        </XRow>
      </XContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    data: catalogSelects.newCatalogState(state.catalogs),
    validationSummary: state.catalogs.validation.validationSummary,
    account: state.authentication.account
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  storeInformation: (data: CatalogFormData) => dispatch(catalogActions.storeCatalogAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardResultPage);
