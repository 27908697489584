import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { XActionButton } from 'src/components/layout/XActionButton';
import {
  InterfaceType,
  InterfaceMethod,
  InterfaceAuthentication,
  CatalogModel,
  InterfaceSettingsKey
} from '../../../../models/Catalog';
import { saveCatalogInterface } from '../../../../store/catalogs/api';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import * as catalogActions from 'src/store/catalogs/actions';
import { ApiResult } from 'src/helpers/apiClient';
import { MessageType } from 'src/store/layout/types';
import { mapError } from 'src/helpers/errorMap';
import i18n from '../../../../i18n';

type OwnProps = {
  catalogId?: string;
};

type StateProps = {
  catalog?: CatalogModel;
};

type DispatchProps = {
  showNotification: (message: string, type?: MessageType) => void;
  getCatalogById: (id: string) => Promise<ApiResult>;
};

type AllProps = OwnProps & DispatchProps & StateProps;

const ManualInterfaceMethod: FC<AllProps> = props => {
  const history = useHistory();

  const catalogInterface = {
    method: InterfaceMethod.Manual,
    type: InterfaceType.CatalogSend,
    authentication: InterfaceAuthentication.None,
    id: props.catalog
      ? props.catalog.interfaces.length > 0
        ? props.catalog.interfaces[0].id
        : ''
      : '',
    settings: {
      [InterfaceSettingsKey.Url]: '',
      [InterfaceSettingsKey.Username]: '',
      [InterfaceSettingsKey.Password]: '',
      [InterfaceSettingsKey.PortalName]: '',
      [InterfaceSettingsKey.Token]: '',
      [InterfaceSettingsKey.AutoUpdate]: '',
      [InterfaceSettingsKey.UpdateTimeInterval]: '',
      [InterfaceSettingsKey.FilterType]: '',
      [InterfaceSettingsKey.UseMultipleFiles]: '',
      [InterfaceSettingsKey.Url2]: '',
      [InterfaceSettingsKey.Username2]: '',
      [InterfaceSettingsKey.Password2]: '',
      [InterfaceSettingsKey.Filter]: ''
    }
  };

  const saveInterface = async () => {
    const apiResult = await saveCatalogInterface(catalogInterface, props.catalogId);
    if (apiResult.IsSuccess) {
      history.push(`/app/my/catalogs/edit/${props.catalogId}`);
    } else {
      props.showNotification(
        i18n.t('notification.savingCatalogInterface') + mapError(apiResult.Errors),
        'error'
      );
    }
  };

  return (
    <div>
      <XSaveManualInterface>
        <XActionButton style={{ marginLeft: 'auto' }} onClick={saveInterface}>
          Save
        </XActionButton>
      </XSaveManualInterface>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    catalog: state.catalogs.data.detail
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type)),
  getCatalogById: (id: string) => dispatch(catalogActions.getCatalogThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualInterfaceMethod);

const XSaveManualInterface = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;
