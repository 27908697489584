import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

import { Catalog } from 'src/models/Catalog';
import { XImage, XImageContainer } from '../layout/XImage';

import { generateAvatar } from 'src/helpers/avatarGenerator';
import { formatDateToString, getImageUrl } from 'src/helpers/formatHelpers';
import Logo from '../../style/images/ltxLogos/ltxdefOpaque.svg';

type OwnProps = {
  catalog: Catalog;
};

type AllProps = OwnProps;

const XCatalogLarge: React.SFC<AllProps> = props => {
  return (
    <CatalogContent>
      <ImageContainer>
        <XImage
          src={getImageUrl(props.catalog.imageURL, props.catalog.organizationImageURL)}
          alt=""
          onError={(e: any) => {
            e.target.onError = null;
            e.target.src = generateAvatar(props.catalog.title, 145);
          }}
        />
      </ImageContainer>
      <CatalogText>
        <CatalogTitle>{props.catalog.title}</CatalogTitle>
        <CatalogLabel>{props.catalog.organizationName}</CatalogLabel>
        <CatalogLabel>{props.catalog.downloads} downloads</CatalogLabel>
        <CatalogLabel>{formatDateToString(props.catalog.lastCreationDate)}</CatalogLabel>
      </CatalogText>
    </CatalogContent>
  );
};

export default XCatalogLarge;

const ImageContainer = styled(XImageContainer)({
  boxShadow: '0px 3px 20px rgba(0,0,0,0.1)',
  transition: 'box-shadow ease-in-out .1s',
  ':hover': {
    boxShadow: '0px 5px 25px rgba(0,0,0,0.15)'
  }
});
const CatalogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  marginRight: '10px'
});

const CatalogText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
  padding: '3px',
  cursor: 'pointer'
});

const CatalogTitle = styled('label')({
  fontSize: '14px',
  fontFamily: 'Montserrat-Bold', // TODO: get font from theme
  color: 'black',
  cursor: 'pointer',
  width: '145px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const CatalogLabel = styled('label')({
  fontSize: '10px',
  color: 'black',
  paddingTop: '6px',
  textDecoration: 'none',
  cursor: 'pointer'
});
