import { BaseReducer } from 'src/helpers/baseReducer';
import { Action } from './actions';
import { ActionTypes, MessageState } from './types';

export const initialState: MessageState = {
  newMessages: [],
  messages: [],
  errors: undefined,
  loading: false,
  catalogMessages: [],
  orderMessages: []
};

export class MessageReducer extends BaseReducer<MessageState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: MessageState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_MESSAGES: {
        newState.messages = action.payload.messages || [];
        return newState;
      }
      case ActionTypes.GET_CATALOG_MESSAGES: {
        newState.catalogMessages = action.payload.catalogMessages || [];
        return newState;
      }
      case ActionTypes.GET_ORGANIZATION_CATALOG_MESSAGES: {
        newState.catalogMessages = action.payload.catalogMessages || [];
        return newState;
      }
      case ActionTypes.GET_SENT_ORDER_MESSAGES: {
        newState.orderMessages = action.payload.orderMessages || [];
        return newState;
      }
      case ActionTypes.GET_ORDER_MESSAGES: {
        newState.orderMessages = action.payload.orderMessages || [];
        return newState;
      }
      default:
        return undefined;
    }
  }
}
