import React from 'react';
import styled from '@emotion/styled';
import { XImage, XImageContainer } from '../layout/XImage';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { formatDateToString, getImageUrl } from 'src/helpers/formatHelpers';

type OwnProps = {
  stock: any;
};

type AllProps = OwnProps;

const XCatalogLarge: React.FC<AllProps> = props => {
  return (
    <StockContent>
      <ImageContainer>
        <XImage
          src={getImageUrl(props.stock.imageURL, props.stock.organizationImageURL)}
          alt=""
          onError={(e: any) => {
            e.target.onError = null;
            e.target.src = generateAvatar(props.stock.title, 145);
          }}
        />
      </ImageContainer>
      <StockText>
        <StockTitle>{props.stock.title}</StockTitle>
        <StockLabel>{props.stock.organizationName}</StockLabel>
        <StockLabel>{props.stock.downloads} downloads</StockLabel>
        <StockLabel>{formatDateToString(props.stock.creationDate)}</StockLabel>
      </StockText>
    </StockContent>
  );
};

export default XCatalogLarge;

const ImageContainer = styled(XImageContainer)({
  boxShadow: '0px 3px 20px rgba(0,0,0,0.1)',
  transition: 'box-shadow ease-in-out .1s',
  ':hover': {
    boxShadow: '0px 5px 25px rgba(0,0,0,0.15)'
  }
});
const StockContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  marginRight: '10px'
});

const StockText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
  padding: '3px'
  // cursor: 'pointer'
});

const StockTitle = styled('label')({
  fontSize: '14px',
  fontFamily: 'Montserrat-Bold', // TODO: get font from theme
  color: 'black',
  // cursor: 'pointer',
  width: '145px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const StockLabel = styled('label')({
  fontSize: '10px',
  color: 'black',
  paddingTop: '6px',
  textDecoration: 'none'
  // cursor: 'pointer'
});
