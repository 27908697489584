import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XPageRouter from 'src/components/shared/XPageRouter';
import { PageRoute } from 'src/models/Route';
import { CatalogsRoute, CatalogRoute } from 'src/pages/catalogs/CatalogsRouter';
import {
  ConnectionsRoute,
  BrowseOrganizationsRoute,
  BrowseOrganizationRoute
} from 'src/pages/connections/ConnectionsRouter';
import { StocksRoute, StockRoute } from 'src/pages/stock/StocksRouter';

type StateProps = {};

type DispatchProps = {};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

const Routes: PageRoute[] = [
  ConnectionsRoute,
  BrowseOrganizationsRoute,
  BrowseOrganizationRoute,
  CatalogsRoute,
  StocksRoute,
  StockRoute,
  CatalogRoute
];

class ExploreRouter extends React.Component<AllProps> {
  public render() {
    return <XPageRouter shouldRedirectToConnections={true} routes={Routes} {...this.props} />;
  }
}

const mapStateToProps = (): StateProps => {
  return {};
};

const mapDispatchToProps = (): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExploreRouter);
