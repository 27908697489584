export enum InterfaceAuthentication {
  None = 'None',
  UsernamePassword = 'UsernamePassword',
  BasicAuth = 'BasicAuth',
  JWTAuth = 'JWTAuth'
}

export enum InterfaceType {
  StockSend = 'StockSend'
}

export enum InterfaceMethod {
  Ftp = 'Ftp',
  Api = 'Api',
  VertilinQ = 'VertilinQ',
  ItsPerfect = 'ItsPerfect',
  DeEekhoorn = 'DeEekhoorn',
  LightAndLiving = 'LightAndLiving',
  DTP = 'DTP'
}

export enum InterfaceSettingsKey {
  Url = 'Url',
  Username = 'Username',
  Password = 'Password',
  PortalName = 'PortalName',
  Token = 'Token'
}

export interface Stock {
  id: string;
  currentVersionId?: string;
  organizationId: string;
  organizationName: string;
  organizationImageURL: string;
  title: string;
  description: string;
  imageURL: string;
  downloads: number;
  downloaded: boolean;
  lastCreationDate: Date;
  connectionDate: Date;
  publicationStatus: PublicationStatus;
  publicationDate: Date;
  validationFileUrl: string;
  mappingId: string;
  fileFormatId: string;
  fileFormatVersionId: string;
  creationDate: Date;
}

export interface StockModel {
  stock: Stock;
  downloads?: StockDownloads[];
  interfaces: StockInterface[];
}

export interface StockDownloads {
  organizationId: string;
  organizationName: string;
  downloads: number;
  lastDownloadDate?: Date;
}

export interface StockInterface {
  id: string;
  method: InterfaceMethod;
  type: InterfaceType;
  settings: StockInterfaceSetting;
  authentication: string;
  stockId?: string;
}

export interface StockInterfaceSetting {
  Url: string;
  Username: string;
  Password: string;
  Token: string;
  PortalName: string;
}

export interface StockFileViolation {
  type: string;
  level: string;
  objectId: string;
  message: string;
  lineNumber?: number;
}

export interface StockValidationSummary {
  violations: StockFileViolation[];
}

export enum PublicationStatus {
  Unpublished = 'Unpublished',
  Published = 'Published'
}

export enum CSVMappingTypes {
  IdentifierEanNumber = 'IdentifierEanNumber',
  Stock = 'Stock', 
  StockAvailabilityDate = 'StockAvailabilityDate' 
}

