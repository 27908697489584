import { Dispatch } from 'redux';
import { OrganizationGroup } from 'src/models/OrganizationGroup';
import { listOrganizationGroups } from './api';
import { ActionTypes } from './types';

type FetchStatus = 'request' | 'success' | 'failure';

export type GetOrganizationGroupsAction = {
  type: ActionTypes.GET_ORGANIZATION_GROUPS;
  status: FetchStatus;
  payload: { organizationGroups?: OrganizationGroup[] };
};

const getOrganizationGroupsAction = (
  status: FetchStatus,
  data?: OrganizationGroup[]
): GetOrganizationGroupsAction => ({
  type: ActionTypes.GET_ORGANIZATION_GROUPS,
  status,
  payload: {
    organizationGroups: data
  }
});

export const getOrganizationGroupsThunk = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getOrganizationGroupsAction('request'));
    const groups = await listOrganizationGroups();

    if (groups) {
      dispatch(getOrganizationGroupsAction('success', groups));
    } else {
      dispatch(getOrganizationGroupsAction('failure'));
    }
  };
};

export type Action = GetOrganizationGroupsAction;
