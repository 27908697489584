import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { OrganizationType } from 'src/models/Organization';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import CatalogPage from '../myltx/catalogs/CatalogPage';
import StockPage from '../myltx/stock/StockPage';
import CatalogsPageRetailer from '../myltx/catalogs/CatalogsPageRetailer';
import CatalogsPageSupplier from '../myltx/catalogs/CatalogsPageSupplier';
import StockPageRetailer from '../myltx/stock/StockPageRetailer';
import StockPageSupplier from '../myltx/stock/StockPageSupplier';
import ConnectionPage from '../myltx/connections/ConnectionPage';
import ConnectionsPage from '../myltx/connections/ConnectionsPage';
import ConnectRequestsPage from '../myltx/connections/ConnectRequestsPage';
import ShowCatalogPage from '../shared/ShowCatalogPage';
import ShowOrganizationPage from '../shared/ShowOrganizationPage';
import NotFoundCard from '../system/NotFoundCard';
import WizardRouter from './addCatalogWizard/WizardRouter';
import WizardValidationRouter from './addCatalogWizard/WizardValidationRouter';
import AddNewCatalogPage from './addNewCatalog/AddNewCatalogPage';
import EditInterfacePage from './addNewCatalog/EditInterfacePage';
import WizardConvertRouter from './convertCatalogWizard/WizardConvertRouter';
import SoftwareInterfacePage from './software/SoftwareInterfacePage';
import AddStockPage from './stock/addStock/AddStockPage';
import EditStockInterfacePage from './stock/addStock/EditStockInterfacePage';
import CatalogsTrafficPage from 'src/pages/myltx/catalogs/CatalogsTrafficPage';
import OrdersTrafficPage from 'src/pages/myltx/orders/OrdersTrafficPage';
import CatalogsSettingsPage from 'src/pages/myltx/catalogs/CatalogsSettingsPage';
import OrdersSettingsPage from 'src/pages/myltx/orders/OrdersSettingsPage';
import StockTrafficPage from 'src/pages/myltx/stock/StockTrafficPage';
import SentOrderPreviewPage from 'src/pages/myltx/orders/SentOrderPreviewPage';

type StateProps = {
  account: AuthenticationAccount;
};

type DispatchProps = {};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

class MyLtxRouter extends React.Component<AllProps> {
  public render() {
    const { match } = this.props;

    const catalogPage =
      this.props.account.organizationType === OrganizationType.Retailer
        ? CatalogsPageRetailer
        : CatalogsPageSupplier;

    const stockPage =
      this.props.account.organizationType === OrganizationType.Retailer
        ? StockPageRetailer
        : StockPageSupplier;

    return (
      <Switch location={this.props.location}>
        <Route
          path={`${match.path}`}
          exact={true}
          component={() => <Redirect to={`${match.path}/relationships`} />}
        />
        <Route
          path={`${match.path}/relationships/requests`}
          exact={true}
          component={() => <Redirect to={`${match.path}/relationships/requests/in`} />}
        />
        <Route
          path={`${match.path}/catalogs/traffic/`}
          exact={true}
          component={() => (
            <Redirect
              to={`${match.path}/catalogs/traffic/${
                this.props.account.organizationType === OrganizationType.Supplier ? 'out' : 'in'
              }`}
            />
          )}
        />
        <Route
          path={`${match.path}/orders/traffic/`}
          exact={true}
          component={() => (
            <Redirect
              to={`${match.path}/orders/traffic/${
                this.props.account.organizationType === OrganizationType.Retailer ? 'out' : 'in'
              }`}
            />
          )}
        />
        <Route
          path={`${match.path}/stock/traffic/`}
          exact={true}
          component={() => (
            <Redirect
              to={`${match.path}/stock/traffic/${
                this.props.account.organizationType === OrganizationType.Supplier ? 'out' : 'in'
              }`}
            />
          )}
        />
        <Route path={`${match.path}/stock`} exact={true} component={stockPage} />
        <Route path={`${match.path}/stocks/add-stock`} component={AddStockPage} />
        <Route
          path={`${match.path}/stocks/stock-interface/:id`}
          component={EditStockInterfacePage}
        />
        <Route path={`${match.path}/stocks/edit/:id`} exact={true} component={StockPage} />

        <Route path={`${match.path}/relationships`} component={ConnectionsPage} exact={true} />
        <Route path={`${match.path}/connections/:id`} component={ConnectionPage} exact={true} />
        <Route
          path={`${match.path}/connections/organization/:id`}
          component={ShowOrganizationPage}
          exact={true}
        />
        <Route
          path={`${match.path}/catalogs/traffic/:direction`}
          exact={true}
          component={CatalogsTrafficPage}
        />
        <Route
          path={`${match.path}/orders/traffic/:direction`}
          exact={true}
          component={OrdersTrafficPage}
        />
        <Route
          path={`${match.path}/stock/traffic/:direction`}
          exact={true}
          component={StockTrafficPage}
        />
        <Route
          path={`${match.path}/relationships/requests/:type`}
          exact={true}
          component={ConnectRequestsPage}
        />
        <Route path={`${match.path}/catalogs`} exact={true} component={catalogPage} />
        <Route path={`${match.path}/orders/settings`} exact={true} component={OrdersSettingsPage} />
        <Route path={`${match.path}/orders/sent-order/:id`} component={SentOrderPreviewPage} />
        <Route
          path={`${match.path}/orders/settings/interface/:interfaceId?`}
          component={SoftwareInterfacePage}
        />
        <Route
          path={`${match.path}/catalogs/settings`}
          exact={true}
          component={CatalogsSettingsPage}
        />
        <Route
          path={`${match.path}/catalogs/settings/interface/:interfaceId?`}
          component={SoftwareInterfacePage}
        />
        <Route path={`${match.path}/catalogs/new`} component={WizardRouter} />
        <Route path={`${match.path}/catalogs/new-empty`} component={AddNewCatalogPage} />
        <Route
          path={`${match.path}/catalogs/catalog-interface/:id`}
          component={EditInterfacePage}
        />
        <Route path={`${match.path}/catalogs/convert`} component={WizardConvertRouter} />
        <Route path={`${match.path}/catalogs/validate`} component={WizardValidationRouter} />
        <Route path={`${match.path}/catalogs/edit/:id`} exact={true} component={CatalogPage} />
        <Route path={`${match.path}/catalogs/:id`} exact={true} component={ShowCatalogPage} />
        <Route path={`${match.path}`} component={NotFoundCard} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyLtxRouter);
