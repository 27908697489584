import * as React from 'react';
import styled from '@emotion/styled';
import { FormControlLabel, Radio, RadioProps } from '@material-ui/core';

type OwnProps = {
  value?: string;
  label?: string;
  placeholder?: string;
};

type AllProps = OwnProps & RadioProps;

const XRadioButton: React.FC<AllProps> = props => {
  return (
    <div>
      <FormControlLabel
        control={<Radio color="secondary" {...props} />}
        label={props.label}
        labelPlacement="start"
        value={props.value}
      />
    </div>
  );
};

export default XRadioButton;
