import styled from '@emotion/styled';
import * as React from 'react';
import { connect } from 'react-redux';
import { XPaper } from 'src/components/layout/XPaper';
import { AuthenticationAccount } from 'src/store/authentication/types';
import * as OrganizationGroupActions from 'src/store/organizationgroups/actions';
import * as OrganizationActions from 'src/store/organizations/actions';

import { TextField } from '@material-ui/core';
import XNoContent from 'src/components/domain/XNoContent';
import XOrganizationRow from 'src/components/domain/XOrganizationRow';
import XPageContent from 'src/components/layout/XPageContent';
import XTable from 'src/components/layout/XTable';
import { Organization, OrganizationType } from 'src/models/Organization';
import { OrganizationGroup } from 'src/models/OrganizationGroup';
import { ApplicationState } from '../../../store';
import { getMyOrganizationThunk } from 'src/store/organizations/actions';
import { Link, RouteComponentProps } from 'react-router-dom';
import XPagination from 'src/components/layout/XPagination';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import XScrollView from 'src/components/layout/XScrollView';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { showConfirmation, showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { OrderMessage } from 'src/models/OrderMessage';
import { getOrderMessagesThunk } from 'src/store/data/actions';
import { MyOrganizationModel } from 'src/models/Organization';
import EmptyImage from '../../../style/images/empty-clipboard.svg';
import XSmallButton from 'src/components/layout/XSmallButton';
import { XA } from '../AccountPage';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import { XRowCell } from 'src/components/layout/XRow';
import XSearchBar from 'src/components/layout/XSearchBar';
import { XActionButton, XSecActionButton } from 'src/components/layout/XActionButton';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import { Skeleton } from '@material-ui/lab';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  account: AuthenticationAccount;
  connections: Organization[];
  organizationGroups: OrganizationGroup[];
  orderMessages: OrderMessage[];
  myOrganization?: MyOrganizationModel;
  loading: boolean;
};

type DispatchProps = {
  getConnections: () => any;
  getOrganizationGroups: () => any;
  deleteConnection: (organizationId: string) => Promise<ApiResult>;
  showConfirm: (title: string, message: string, onConfirm: () => void) => void;
  showNotification: (text: string, type?: MessageType) => void;
  getOrderMessages(): () => void;
  getMyOrganization: () => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{}>;

type State = {
  search: string;
  currentOffset: number;
};

class ConnectionsPage extends React.Component<AllProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      search: '',
      currentOffset: 0
    };
  }

  public componentDidMount() {
    this.props.getConnections();
    this.props.getOrderMessages();
    this.props.getMyOrganization();
  }

  public removeConnection(organization: Organization) {
    this.props.showConfirm(
      i18n.t('confirmation.disconnect'),
      i18n.t('confirmation.sureWantToEndConnection'),
      () => {
        this.props.deleteConnection(organization.id).then(result => {
          if (result.IsSuccess)
            this.props.showNotification(i18n.t('notification.connectionRemoved'));
          else
            this.props.showNotification(
              i18n.t('notification.errWhileRemovingConnection') + mapError(result.Errors),
              'error'
            );
        });
      }
    );
  }

  public render() {
    const pageSize = 20;
    const connections = this.props.connections.filter(
      r => r.name.toLowerCase().indexOf(this.state.search) !== -1
    );
    const paged = connections.slice(this.state.currentOffset, this.state.currentOffset + pageSize);
    const type = this.props.account.organizationType;
    const orgsName = type === OrganizationType.Retailer ? i18n.t('common.suppliers') : 'retailers';
    return (
      <ErrorBoundary>
        <XPageContent>
          <StyledPaper style={{ height: this.props.connections.length === 0 ? '400px' : '' }}>
            <Translation>
              {t => (
                <XPaperHeader
                  header={t('myArxis.myRelationships')}
                  count={!this.props.loading ? connections.length : 0}
                  subheader={
                    <span>
                      {t('organizations.belowAreAll') +
                        orgsName.toLowerCase() +
                        t('organizations.yourRel')}
                      <br />
                      <Link to="/app/explore/connections">
                        {t('connections.findRelationships')} {'>'}
                      </Link>
                    </span>
                  }
                />
              )}
            </Translation>
            {!!this.props.connections.length && (
              <XFilterBar>
                <XRowCell>
                  <Translation>
                    {t => (
                      <XSearchBar
                        placeholder={'Filter ' + t('myArxis.relationships')}
                        onChange={e =>
                          this.setState({ search: e.target.value.toLowerCase() })
                        }></XSearchBar>
                    )}
                  </Translation>
                </XRowCell>
              </XFilterBar>
            )}
            {connections.length === 0 && (
              <XNoContent image={EmptyImage}>
                {this.state.search.length > 0 ? (
                  <Translation>{t => <h3> {t('connections.noRelFound')} </h3>}</Translation>
                ) : (
                  <Translation>
                    {t => (
                      <div>
                        <div>
                          <h3 style={{ fontSize: '15px', color: '#222222' }}>
                            {t('connections.dontHaveRel')}
                          </h3>
                        </div>
                        <div style={{ textAlign: 'left', marginTop: '30px' }}>
                          <div style={{ marginTop: '20px', marginBottom: '5px' }}>
                            <b>Wat kan ik doen?</b>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                              <Link to="/app/explore/connections">
                                <XSmallButton
                                  text={
                                    this.props.account.organizationType ===
                                    OrganizationType.Retailer
                                      ? t('connections.connWithSupp')
                                      : t('connections.connWithRet')
                                  }
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Translation>
                )}
              </XNoContent>
            )}
            {connections.length !== 0 && (
              <XScrollView>
                <Translation>
                  {t => (
                    <XTable
                      headers={[
                        t('myArxis.Organization'),
                        t('address.place'),
                        t('tooltip.Since'),
                        'Live Connection?',
                        ''
                      ]}>
                      {this.props.loading ? (
                        Array.from({ length: 6 }).map((_, idx) => <Skeleton key={idx} />)
                      ) : (
                        <>
                          {paged.map(obj => (
                            <XOrganizationRow
                              key={obj.id}
                              link={
                                this.props.account.organizationType === OrganizationType.Retailer
                                  ? '/app/my/connections/organization/' + obj.id
                                  : '/app/my/connections/' + obj.id
                              }
                              organization={obj}
                              organizationGroups={this.props.organizationGroups}
                              onDelete={() => this.removeConnection(obj)}
                              showConnectionDate
                              showLiveConnection
                              liveConnection={
                                this.props.myOrganization &&
                                this.props.myOrganization.organizationConnections.find(org =>
                                      org.sourceOrganizationId === obj.id
                                    || org.targetOrganizationId === obj.id
                                )?.lastTrafficDate != null
                              }
                              showDelete
                            />
                          ))}
                        </>
                      )}
                    </XTable>
                  )}
                </Translation>
              </XScrollView>
            )}
            {connections.length > pageSize && (
              <XPagination
                itemCount={connections.length}
                itemsPerPage={pageSize}
                changePage={i => this.setState({ currentOffset: i })}
              />
            )}
          </StyledPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    connections: state.organizations.connections.sort((a, b) => a.name.localeCompare(b.name)),
    organizationGroups: state.organizationGroups.all,
    orderMessages: state.data.orderMessages,
    myOrganization: state.organizations.myOrganization,
    loading: state.organizations.loading
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getConnections: () => dispatch(OrganizationActions.getOrganizationConnectionsThunk()),
  getOrganizationGroups: () => dispatch(OrganizationGroupActions.getOrganizationGroupsThunk()),
  deleteConnection: (organizationId: string) =>
    dispatch(OrganizationActions.deleteConnectionThunk(organizationId)),
  showConfirm: (title: string, message: string, onConfirm: () => void) =>
    dispatch(showConfirmation(title, message, onConfirm)),
  showNotification: (text: string, type?: MessageType) => dispatch(showNotification(text, type)),
  getOrderMessages: () => dispatch(getOrderMessagesThunk()),
  getMyOrganization: () => dispatch(getMyOrganizationThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsPage);

const StyledPaper = styled(XPaper)`
  min-height: 300px;
  max-height: calc(100% - 40px);
  overflow-y: auto;
`;
