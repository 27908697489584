import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XPageContent from 'src/components/layout/XPageContent';
import {
  getInterfaceText,
  getInterfaceTypesOrder
} from 'src/constants/OrganizationInterface/OrganizationInterfaces';
import { ApiResult } from 'src/helpers/apiClient';
import {
  InterfaceType,
  MyOrganizationModel,
  OrderInterfaceSettingsModel,
  Organization,
  OrganizationInterface
} from 'src/models/Organization';
import { Software } from 'src/models/Software';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import {
  editOrganizationThunk,
  getMyOrganizationThunk,
  saveOrderInterfaceSettingsThunk,
  deleteOrderInterfaceThunk,
  getOrganizationIdentifiersThunk
} from 'src/store/organizations/actions';
import { sortSoftware } from 'src/store/software/actions';
import InterfaceCard from 'src/pages/myltx/software/InterfaceCard';
import { XPaper } from '../../../components/layout/XPaper';
import XPaperHeader from '../../../components/layout/XPaperHeader';
import AddCircleIcon from '@material-ui/icons/AddCircle';

type StateProps = {
  organization?: MyOrganizationModel;
  software: Software[];
};

type DispatchProps = {
  showNotification: (message: string, type: MessageType) => void;
  getOrganization: () => void;
  getOrganizationIdentifiers: () => void;
  saveOrderInterfaceSettings: (
    organizationId: string,
    data: OrderInterfaceSettingsModel
  ) => Promise<ApiResult>;
  editOrganization: (organization: Organization) => Promise<ApiResult>;
  deleteOrderInterface: (id: string) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{ id: string }>;

class OrdersSettingsPage extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.state = {};
  }

  public async onOrderInterfaceDelete(id: string) {
    await this.props.deleteOrderInterface(id);
    this.props.getOrganization();
  }

  public componentDidMount() {
    this.props.getOrganization();
    this.props.getOrganizationIdentifiers();
  }

  public getInterfaces(t: InterfaceType): OrganizationInterface[] | undefined {
    if (t === InterfaceType.OrderReceive) {
      const interfaces = this.props.organization?.interfaces.filter(x => x.type === t);
      if (interfaces?.length === 0)
        return undefined;

      return interfaces;
    }
    else {
      const interfaces = this.props.organization?.interfaces.filter(x => x.type === t).slice(0, 1);
      if (interfaces?.length === 0)
        return undefined;

        return interfaces;
    }
  }

  public render() {
    const interfaceType = getInterfaceTypesOrder(
      this.props.organization?.organization.organizationType
    )[0];
    const header = getInterfaceText(interfaceType);
    const interfaces = this.getInterfaces(interfaceType);
    const isOrderReceive = interfaceType === InterfaceType.OrderReceive ? true : false;

    const config = () => {
    this.props.history.push('/app/my/orders/settings/interface?type=' + interfaceType);
  }

    return (
      <XPageContent style={{ maxWidth: '880px' }}>
        <XPaper>
          <XPaperHeader
            header={header.title}
            subheader={header.description}
            search={false}
            icons={isOrderReceive === true && interfaces?.length != null ? [
              {
                icon: AddCircleIcon,
                toolTip: 'Add',
                name: 'add',
                action: () => config()
              }
            ] : []}
          />
          {interfaces ? interfaces?.map((i, idx) => {
            return (
              <InterfaceCard
                onDelete={() => this.onOrderInterfaceDelete(i.id)}
                deleteVisible={isOrderReceive}
                key={idx}
                title={''}
                subTitle={''}
                interface={i}
                type={interfaceType}
                editPath="/app/my/orders/settings/interface"
              />
            );
          }) : <InterfaceCard
                 onDelete={() => this.onOrderInterfaceDelete('')}
                 deleteVisible={isOrderReceive}
                 key={0}
                 title={''}
                 subTitle={''}
                 interface={undefined}
                 type={interfaceType}
              editPath="/app/my/orders/settings/interface"
               />
          }
          </XPaper>
        </XPageContent>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    organization: state.organizations.myOrganization,
    software: state.software.all.sort((a, b) => sortSoftware(a, b))
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type: MessageType) => {
    dispatch(showNotification(message, type));
  },
  getOrganization: () => dispatch(getMyOrganizationThunk()),
  getOrganizationIdentifiers: () => dispatch(getOrganizationIdentifiersThunk()),
  saveOrderInterfaceSettings: (id, data) => dispatch(saveOrderInterfaceSettingsThunk(id, data)),
  editOrganization: (organization: Organization) => dispatch(editOrganizationThunk(organization)),
  deleteOrderInterface: (id: string) => dispatch(deleteOrderInterfaceThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersSettingsPage);
