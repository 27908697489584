import { ApplicationState } from '..';
import { RequestStateModel } from './types';

export const catalogViewRequestBetweenOrganizations = (
  state: RequestStateModel,
  organizationId: string
) =>
  state.requests
    .filter(
      c => c.requestOrganization.id === organizationId || c.targetOrganization.id === organizationId
    )
    .sort(
      (a, b) =>
        new Date(b.connectRequest.requestDate).getTime() -
        new Date(a.connectRequest.requestDate).getTime()
    )
    .find(a => true);

export const activeRequests = (state: RequestStateModel) =>
  state.requests.filter(c => !c.connectRequest.approvedDate && !c.connectRequest.disapprovedDate);

export const inactiveRequests = (state: RequestStateModel) =>
  state.requests.filter(c => c.connectRequest.approvedDate || c.connectRequest.disapprovedDate);

export const activeIncommingRequest = (state: ApplicationState) =>
     state.requests.requests.filter(
    r =>
      r.requestOrganization.id !== state.authentication.account.organizationId &&
      !r.connectRequest.approvedDate &&
      !r.connectRequest.disapprovedDate
  ); 
