import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XPageRouter from 'src/components/shared/XPageRouter';
import { PageRoute } from 'src/models/Route';

import ShowOrganizationPage from '../shared/ShowOrganizationPage';
import OrganizationsPage from './OrganizationsPage';

export const ConnectionsRoute: PageRoute = {
  path: `/app/explore/connections`,
  exact: true,
  component: OrganizationsPage
};

export const BrowseOrganizationsRoute: PageRoute = {
  path: `/app/explore/connections/organizations/:type`,
  exact: true,
  component: OrganizationsPage
};

// organization
export const BrowseOrganizationRoute: PageRoute = {
  path: `/app/explore/connections/organization/:id`,
  exact: true,
  component: ShowOrganizationPage
};

export const Routes: PageRoute[] = [
  ConnectionsRoute,
  BrowseOrganizationsRoute,
  BrowseOrganizationRoute
];

type StateProps = {};

type DispatchProps = {};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

class StartRouter extends React.Component<AllProps> {
  public render() {
    return <XPageRouter routes={Routes} {...this.props} />;
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StartRouter);
