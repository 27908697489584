import React, { FC, useState } from 'react';
import { Mapping } from '../../store/catalogs/types';
import XMappingRow from './XMappingRow';
import styled from '@emotion/styled';
import { XContainer } from '../layout/XContainer';
import { FormControl, Select } from '@material-ui/core';

type OwnProps = {
  mappingData: Mapping;
  onChange: (e: any) => void;
  setDelimiter: (delimiter: string) => void;
  delimiter: string;
}; 

const XCSVMapping: FC<OwnProps> = React.memo(({ mappingData, onChange, setDelimiter, delimiter }) => {
  const onDelimiterChange = (e: any) => {
    setDelimiter(e.target.value);
  };

  return (
    <>
      <XContainer>
        <XSelectionRow>
          <XCell>
            {'Delimiter'}
          </XCell>
          <XCell></XCell>
          <FormControl variant="outlined">
            <XSelect native onChange={e => onDelimiterChange(e)} value={delimiter} >
              <option>
               ;
              </option>
              ))
                <option>
                ,
              </option>
            </XSelect>
          </FormControl>
        </XSelectionRow>
        {mappingData?.MappingItems.map(c => (
          <XMappingRow key={c.Column} c={c} onChange={onChange} />
        ))}
      </XContainer>
    </>
  );
});

const XSelect = styled(Select)`
  margin: 1.5rem 0.5rem 1rem 0;
  width: 14rem;
  height: 2rem;
`;

const XSelectionRow = styled.div`
  display: flex;
  align-items: center;
`;

const XCell = styled('div')({
  position: 'relative',
  display: 'inline-block',
  width: '14rem',
  ':hover span': {
    visibility: 'visible'
  }
});

export default XCSVMapping;
