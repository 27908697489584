import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import MyAccountPage from '../myltx/AccountPage';
import MyOrganizationPage from '../myltx/OrganizationPage';
import MySettingsPage from '../myltx/SettingsPage';
import NotFoundCard from '../system/NotFoundCard';
import SoftwarePage from './software/SoftwarePage';
import SubscriptionsPage from './SubscriptionsPage';
import SoftwareInterfacePage from './software/SoftwareInterfacePage';

type StateProps = {
  account: AuthenticationAccount;
};

type DispatchProps = {};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

class MyAccoutRouter extends React.Component<AllProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch location={this.props.location}>
        <Route
          path={`${match.path}`}
          exact={true}
          component={() => <Redirect to={`${match.path}/account`} />}
        />
        <Route path={`${match.path}/account`} exact={true} component={MyAccountPage} />
        <Route path={`${match.path}/organization`} exact={true} component={MyOrganizationPage} />
        <Route path={`${match.path}/settings`} exact={true} component={MySettingsPage} />
        <Route path={`${match.path}/subscriptions`} exact={true} component={SubscriptionsPage} />
        <Route path={`${match.path}/software`} component={SoftwarePage} />
        <Route path={`${match.path}`} component={NotFoundCard} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccoutRouter);
