import { RequestManageModel, RequestModel } from '../../models/Request';
export enum ActionTypes {
  SEND_REQUEST = '[requests] SEND_REQUEST',
  CANCEL_REQUEST = '[requests] CANCEL_REQUEST',
  GET_REQUESTS = '[requests] GET_REQUESTS',
  GET_MANAGE_REQUESTS = '[requests] GET_MANAGE_REQUESTS',
  UPDATE_REQUEST = '[requests] UPDATE_REQUEST'
}

export enum RequestState {
  NOT_REQUESTED,
  REQUEST_PENDING,
  APPROVED,
  DISAPPROVED
}

export interface RequestStateModel {
  loading: boolean;
  requests: RequestModel[];
  manageRequests: RequestManageModel[];
  errors?: string;
}
