import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import styled from '@emotion/styled';
import Cookies from 'js-cookie';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import XLTXInformation from 'src/components/domain/XLTXInformation';
import { XPaper } from 'src/components/layout/XPaper';
import { ApplicationState } from 'src/store';
import { logoutThunk } from 'src/store/authentication/actions';
import { AuthenticationState } from 'src/store/authentication/types';
import Logo from '../../style/images/logos/main.svg';
import { LeftSide, LeftSideInner, LTXLogo, RightSide } from './LoginPage.style';
import { Translation } from 'react-i18next';

type OwnProps = {};

type DispatchProps = {
  logout: () => Promise<void>;
};

type AllProps = OwnProps & DispatchProps & RouteComponentProps;

class LogoutPage extends React.Component<AllProps> {
  constructor(props: any) {
    super(props);
  }

  public componentDidMount() {
    this.props.logout();
    this.props.history.push('/login');
  }

  public render() {
    return (
      <LogoutLayout>
        <LeftSide>
          <LeftSideInner>
            <LogoutInner>
              <Translation>{t => <div> {t('login.loggedOutS')} </div>}</Translation>
            </LogoutInner>
          </LeftSideInner>
        </LeftSide>
        <RightSide>
          <XLTXInformation />
        </RightSide>

        <LTXLogo src={Logo} />
      </LogoutLayout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AuthenticationState, void, Action>
): DispatchProps => ({
  logout: () => dispatch(logoutThunk())
});
export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);

const LogoutLayout = styled.div`
  height: 100vh;
  overflow: hidden;
  background: rgb(45, 128, 194);
  display: flex;
`;

const LogoutInner = styled(XPaper)`
  padding: 30px;
  text-align: center;
  width: 500px;
  height: 120px;
  top: calc(50% - 60px);
  left: calc(50% - 250px);
  position: absolute;
`;
