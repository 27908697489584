import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import SubscriptionCard from './subscriptions/SubscriptionCard';
import XPageContent from '../../components/layout/XPageContent';
import { XPaper } from '../../components/layout/XPaper';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { AuthenticationAccount } from '../../store/authentication/types';
import i18n from 'src/i18n';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  account: AuthenticationAccount;
};

type OwnState = {
  organizationType: string;
};

type AllProps = StateProps & RouteComponentProps & OwnState;

const SubscriptionsPage: React.FC<AllProps> = props => {
  const [organizationType, setOrganizationType] = useState('');

  useEffect(() => {
    setOrganizationType(props.account.organizationType);
  }, []);

  const retailers = [
    i18n.t('pricing.receiveUnlimitedCatalogs'),
    i18n.t('pricing.unlimitedOrderHistory')
  ];
  const suppliers = [
    i18n.t('pricing.publishUnlimitedCatalogs'),
    i18n.t('pricing.unlimitedOrderHistory')
  ];

  const prices = {
    retailer: [
      {
        name: 'Free',
        price: 0,
        items: [...retailers, 'Max. 1 ' + i18n.t('common.suppliers')],
        limit: 1
      },
      {
        name: 'Premium I',
        price: 99,
        items: [...retailers, 'Max. 20 ' + i18n.t('common.suppliers')],
        limit: 20
      },
      {
        name: 'Premium II',
        price: 199,
        items: [...retailers, 'Max. 50 ' + i18n.t('common.suppliers')],
        limit: 50
      },
      {
        name: 'Premium III',
        price: 349,
        items: [...retailers, 'Max. 100 ' + i18n.t('common.suppliers')],
        limit: 100
      },
      {
        name: 'Unlimited',
        price: 999,
        items: [...retailers, 'Meer dan 100 ' + i18n.t('common.suppliers')],
        limit: 999999
      }
    ],
    supplier: [
      {
        name: 'Free',
        price: 0,
        items: [...suppliers, 'Max. 1 ' + i18n.t('common.retailers')],
        limit: 1
      },
      {
        name: 'Premium I',
        price: 199,
        items: [...suppliers, 'Max. 50 ' + i18n.t('common.retailers')],
        limit: 50
      },
      {
        name: 'Premium II',
        price: 299,
        items: [...suppliers, 'Max. 100 ' + i18n.t('common.retailers')],
        limit: 100
      },
      {
        name: 'Premium III',
        price: 499,
        items: [...suppliers, 'Max. 200 ' + i18n.t('common.retailers')],
        limit: 200
      },
      {
        name: 'Unlimited',
        price: 999,
        items: [...suppliers, 'Meer dan 200 ' + i18n.t('common.retailers')],
        limit: 999999
      }
    ]
  };

  return (
    <ErrorBoundary>
      <XPageContent>
        <XPaper style={{ position: 'relative' }}>
          <XPaperHeader header={i18n.t('myArxis.Subscription')} />
          <XCardsContainer>
            {organizationType &&
              prices[organizationType.toLocaleLowerCase()].map(p => (
                <SubscriptionCard key={p.name} p={p} organizationType={organizationType} />
              ))}
          </XCardsContainer>
          <div style={{ padding: '0 2rem' }}>
            <XSubProgressBar></XSubProgressBar>
          </div>
        </XPaper>
      </XPageContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account
  };
};

export default withRouter(connect(mapStateToProps, {})(SubscriptionsPage));

const XCardsContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
`;

const XSubProgressBar = styled.div`
  width: 100%;
  border-radius: 5px;
  height: 65px;
`;
