import { BaseReducer } from 'src/helpers/baseReducer';
import { EmptyOrganization, Organization } from 'src/models/Organization';
import { EmptyOrganizationModel, OrganizationModel } from '../../models/Organization';
import { Action } from './actions';
import { ActionTypes, OrganizationGroupsState } from './types';

export const initialState: OrganizationGroupsState = {
  all: [],
  errors: undefined,
  loading: false
};

export class OrganizationGroupReducer extends BaseReducer<OrganizationGroupsState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: OrganizationGroupsState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_ORGANIZATION_GROUPS: {
        newState.all = action.payload.organizationGroups || [];
        return newState;
      }
      default:
        return undefined;
    }
  }
}
