import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { ThemeProvider } from 'emotion-theming';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { theme } from './style/Style';

import { PublicPages } from './pages/Routes';
// Below is needed to get styled components (react emotion) working with material UI
// The order of stylesheet injection is important and we will/can manually do it here
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import { JssProvider } from 'react-jss';

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')!
});
// End
/* eslint-disable import/first */
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/nl';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './store/index';
import './i18n';

import AppLoadingPage from './pages/index/pages/AppLoadingPage';
const App = React.lazy(() => import('./App'));

moment.locale('nl');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="nl">
        <Router>
          <ThemeProvider theme={theme}>
            <StylesProvider jss={jss} generateClassName={generateClassName}>
              {window.location.href.match(/([/]app)/) ? null : (
                <div className="app">
                  <Switch>
                    {PublicPages.slice(0, 3).map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={route.layout}
                        />
                      );
                    })}
                  </Switch>
                </div>
              )}
              <React.Suspense fallback={<AppLoadingPage />}>
                {window.location.href.match(
                  /(login|register|[/]app|confirm|error|signup|resetPassword|passwordForgotten|logout)/
                ) && <App />}
              </React.Suspense>
            </StylesProvider>
          </ThemeProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
