import styled from '@emotion/styled';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { formatDateToString } from 'src/helpers/formatHelpers';
import { XRowCell } from '../layout/XRow';
import { TableRow } from '../layout/XTable';

type OwnProps = {
  stock: any;
  link?: string;
  showCreationDate?: boolean;
};

type AllProps = OwnProps;

const XStockRow: React.FC<AllProps> = props => {
  const content = (
    <TableRow>
      <TitleCell>{props.stock.title}</TitleCell>
      <TitleCell>{props.stock.description}</TitleCell>
      {props.showCreationDate ? (
        <XRowCell>{formatDateToString(props.stock.creationDate)}</XRowCell>
      ) : null}
    </TableRow>
  );

  return props.link ? <Link to={props.link ?? ''}>{content}</Link> : content;
};

export default XStockRow;

const TitleCell = styled(XRowCell)``;
