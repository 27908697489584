import styled from '@emotion/styled';
import React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { Catalog, PublicationStatus } from 'src/models/Catalog';
import { MyOrganizationModel, OrganizationModel } from 'src/models/Organization';
import { RequestModel } from 'src/models/Request';
import { CatalogRequestStatus } from 'src/store/requests/api';
import XButton from '../layout/XButton';
import { XContainer } from '../layout/XContainer';
import XFormButtons from '../layout/XFormButtons';
import XGrid from '../layout/XGrid';
import { XImageContainer } from '../layout/XImage';
import { XImage } from '../layout/XImage';
import { XPaperContainer, XPaperLabel, XPaperText } from '../layout/XPaper';
import { XRow, XRowCell } from '../layout/XRow';
import XSelectItem from '../layout/XSelectItem';
import XCatalog from './XCatalog';
import XOrganizationAddress from './XOrganizationAddress';

import EditIcon from '@material-ui/icons/Edit';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { getImageUrl } from 'src/helpers/formatHelpers';
import XPopup from '../layout/XPopup';
import XCatalogRow from './XCatalogRow';
import XOrganizationTitle from './XOrganizationTitle';
import XGoBack from '../shared/XGoBack';
import { EmailOutlined } from '@material-ui/icons';
import { XActionButton, XSecActionButton } from '../layout/XActionButton';
import { XActionStrip } from '../layout/XActionStrip';
import Skeleton from '@material-ui/lab/Skeleton';
import { Translation } from 'react-i18next';
import i18n from 'src/i18n';

type OwnProps = {
  loadingOrganization: boolean;
  loadingRequest: boolean;
  organization: OrganizationModel;
  myOrganization: MyOrganizationModel;
  catalogViewOffer?: RequestModel;
  history?: any;
  offerViewCatalogs: (catalogIds: string[]) => void;
  editViewCatalogRequest: (state: CatalogRequestStatus) => void;
  cancelRequest: () => void;
};

type AllProps = OwnProps;
type State = {
  showConnectPopup: boolean;
  availableCatalogs: string[];
};

class XRetailerDetails extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      showConnectPopup: false,
      availableCatalogs: []
    };
  }

  public toggleCatalog(catalog: Catalog, checked: boolean) {
    const current = this.state.availableCatalogs;
    if (checked) {
      current.push(catalog.id);
      this.setState({ availableCatalogs: current });
    } else this.setState({ availableCatalogs: current.splice(current.indexOf(catalog.id), 1) });
  }

  public getButtonState(props: OwnProps) {
    const isAdmin = props.myOrganization.organization.isAdmin;
    const connectRequest = props.catalogViewOffer?.connectRequest;
    const organizationConnection = props.myOrganization.organizationConnections.find(
      dc =>
        (dc.sourceOrganizationId === props.myOrganization.organization.id &&
          dc.targetOrganizationId === props.organization.organization.id) ||
        (dc.targetOrganizationId === props.myOrganization.organization.id &&
          dc.sourceOrganizationId === props.organization.organization.id)
    );

    if (isAdmin)
      return {
        show: false,
        showAvailableCatalogs: false
      };

    if (organizationConnection)
      return {
        show: false,
        showAvailableCatalogs: true
      }; 
    else if (!connectRequest)
      return {
        show: true,
        active: true,
        text: i18n.t('connections.createRelationship'),
        color: 'primary',
        showAvailableCatalogs: false,
        onClick: () => this.setState({ showConnectPopup: true })
      };
    else if (connectRequest.approvedDate) {
      if (organizationConnection)
        return {
          show: false,
          showAvailableCatalogs: true
        };
      else
        return {
          show: true,
          active: true,
          text: i18n.t('connections.createRelationship'),
          color: 'primary',
          onClick: () => this.setState({ showConnectPopup: true }),
          showAvailableCatalogs: false
        };
    } else if (connectRequest.disapprovedDate)
      return {
        show: true,
        active: false,
        text: i18n.t('connections.connRejected'),
        showAvailableCatalogs: false
      };
    else
      return {
        show: true,
        active: true,
        text: i18n.t('connections.cancelRequest'),
        color: '#ff5b4f',
        onClick: () => this.props.cancelRequest(),
        showAvailableCatalogs: false
      };
  }

  public render() {
    const otherRequested =
      this.props.catalogViewOffer?.connectRequest.requestOrganizationId ===
      this.props.organization.organization.id;
    const incommingRequest =
      otherRequested &&
      this.props.catalogViewOffer &&
      !this.props.catalogViewOffer.connectRequest.approvedDate &&
      !this.props.catalogViewOffer.connectRequest.disapprovedDate;
    const buttonState = this.getButtonState(this.props);

    return (
      <XContainer style={{ position: 'relative' }}>
        <XPopup
          title="Connect met retailer"
          buttonSaveText="Connect"
          buttonCancelText="Annuleren"
          onCancel={() => this.setState({ showConnectPopup: false })}
          onSave={() => {
            this.props.offerViewCatalogs(this.state.availableCatalogs);
            this.setState({ showConnectPopup: false });
          }}
          open={this.state.showConnectPopup}>
          <div style={{ marginBottom: '10px' }}>
            Selecteer catalogi die deze retailer meteen mag inzien na connectie
          </div>
          {this.props.myOrganization.catalogs
            .filter(catalog => catalog.publicationStatus == PublicationStatus.Published)
            .map(catalog => (
              <div key={catalog.id} style={{ padding: '4px 0' }}>
                <XSelectItem
                  value={this.state.availableCatalogs.indexOf(catalog.id) !== -1}
                  onToggle={e => this.toggleCatalog(catalog, e)}>
                  <XCatalogRow catalog={catalog} showDownloads={true} />
                </XSelectItem>
              </div>
            ))}
        </XPopup>
        <XRow style={{ paddingBottom: '15px' }}>
          <div style={{ paddingTop: '5px', flex: 1 }}>
            <XGoBack history={this.props.history} />
          </div>
          {this.props.loadingOrganization ? (
            <Skeleton width={120} />
          ) : (
            <>
              {!!this.props.organization.organization.email && (
                <XActionStrip>
                  {this.props.organization.organization.email.length > 0 && !incommingRequest && (
                    <div style={{ paddingRight: '10px' }}>
                      <XSecActionButton
                        href={'mailto:' + this.props.organization.organization.email}
                        target="_blank">
                        <EmailOutlined style={{ height: '19px', marginRight: '10px' }} />
                        <Translation>{t => t('connections.sendEmail')}</Translation>
                      </XSecActionButton>
                    </div>
                  )}
                </XActionStrip>
              )}
              {buttonState.show && !incommingRequest && (
                <div>
                  <XActionButton
                    className={
                      this.props.loadingRequest ? '' : buttonState.active ? 'disabled' : ''
                    }
                    onClick={() => {
                      if (buttonState.onClick) buttonState.onClick();
                    }}>
                    {buttonState.text} {this.props.loadingRequest ? '...' : ''}
                  </XActionButton>
                </div>
              )}{' '}
              {incommingRequest && (
                <XRow style={{ alignItems: 'center', width: 'unset' }}>
                  <div style={{ paddingRight: '10px' }}>
                    {this.props.organization.organization.name} heeft een connectie verzoek
                    verstuurd
                  </div>
                  <div style={{ paddingRight: '10px' }}>
                    <XSecActionButton
                      className={
                        this.props.loadingRequest ? '' : buttonState.active ? 'disabled' : ''
                      }
                      onClick={() => {
                        this.props.editViewCatalogRequest('disapprove');
                      }}>
                      Afwijzen{this.props.loadingRequest ? '...' : ''}
                    </XSecActionButton>
                  </div>
                  <XActionButton
                    className={
                      this.props.loadingRequest ? '' : buttonState.active ? 'disabled' : ''
                    }
                    onClick={() => {
                      this.props.editViewCatalogRequest('approve');
                    }}>
                    Accepteren{this.props.loadingRequest ? '...' : ''}
                  </XActionButton>
                </XRow>
              )}
            </>
          )}
        </XRow>

        <XRow>
          {this.props.loadingOrganization ? (
            <Skeleton variant="rect" width={100} height={100} />
          ) : (
            <XImageContainer
              style={{ minWidth: '100px', background: 'none' }}
              width={100}
              height={100}>
              <XImage
                src={getImageUrl(this.props.organization.organization.imageURL, undefined)}
                alt=""
                onError={(e: any) => {
                  e.target.onError = null;
                  e.target.src = generateAvatar(this.props.organization.organization.name, 100);
                }}
              />
            </XImageContainer>
          )}
          <div style={{ paddingLeft: '20px' }}>
            <TitleWrapper>
              {this.props.loadingOrganization ? (
                <Skeleton width={160} height={40} />
              ) : (
                <XOrganizationTitle
                  organization={this.props.organization.organization}
                  fontSize={'22px'}
                  bold={true}
                />
              )}
            </TitleWrapper>

            <XPaperText>
              {this.props.loadingOrganization ? (
                <Skeleton width={160} />
              ) : (
                this.props.organization.organization.description
              )}
            </XPaperText>

            <MediaQuery minWidth={800}>
              {this.props.loadingOrganization ? (
                <div style={{ marginLeft: '15px' }}>
                  <Skeleton width={160} />
                </div>
              ) : (
                <XOrganizationAddress organization={this.props.organization.organization} />
              )}
            </MediaQuery>
          </div>
          <div
            style={{
              marginLeft: '15px',
              paddingTop: '4px'
            }}>
            <TitleWrapper>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <div>
                  GLN:
                  <XPaperLabel>
                    {this.props.loadingOrganization ? (
                      <Skeleton width={160} />
                    ) : (
                      <>
                        {this.props.organization?.identifiers?.find(x => x.type === 'GLN')?.value}
                      </>
                    )}
                  </XPaperLabel>
                </div>
                <div>
                  Arxis ID:
                  <XPaperLabel>
                    {this.props.loadingOrganization ? (
                      <Skeleton width={160} />
                    ) : (
                      <>
                        {this.props.organization?.identifiers?.find(x => x.type === 'ArxisId')?.value}
                      </>
                    )}
                  </XPaperLabel>
                </div>
              </div>
            </TitleWrapper>
          </div>
        </XRow>

        <MediaQuery maxWidth={800}>
          {this.props.loadingOrganization ? (
            <Skeleton width={160} />
          ) : (
            <div style={{ marginLeft: '-15px', marginTop: '10px' }}>
              <XOrganizationAddress organization={this.props.organization.organization} />
            </div>
          )}
        </MediaQuery>

        {buttonState.showAvailableCatalogs ? (
          <StyledCatalogsDiv>
            <XPaperContainer style={{ position: 'relative' }}>
              <XContainer>
                <XPaperLabel>Beschikbare catalogi voor deze retailer</XPaperLabel>
              </XContainer>
              {!this.props.loadingOrganization && (
                <ActionButtons>
                  <div style={{ verticalAlign: 'middle' }}>
                    <Link to={'/app/my/connections/' + this.props.organization.organization.id}>
                      <EditIcon style={{ verticalAlign: 'middle' }} />
                    </Link>
                  </div>
                </ActionButtons>
              )}
            </XPaperContainer>
            <XGrid columns={3}>
              {this.props.loadingOrganization ? (
                <Skeleton variant="rect" width={75} height={75} />
              ) : (
                <>
                  {this.props.myOrganization?.catalogs?.length === 0 ? (
                    <NoItems>
                      U heeft nog geen catalogi om beschikbaar te stellen.{' '}
                      <Link to="/app/my/catalogs/new">publiceer nu een catalogus</Link>
                    </NoItems>
                  ) : (
                    undefined
                  )}
                  {this.props.myOrganization.catalogs
                    .filter(c =>
                      this.props.myOrganization.catalogConnections.find(
                        dc =>
                          dc.targetOrganizationId === this.props.organization.organization.id &&
                          dc.catalogId === c.id &&
                          dc.active
                      )
                    )
                    .slice(0, 12)
                    .map(obj => (
                      <XCatalog key={obj.id} catalog={obj} size="small" />
                    ))}{' '}
                </>
              )}
            </XGrid>
          </StyledCatalogsDiv>
        ) : (
          <div />
        )}
      </XContainer>
    );
  }
}

const StatsLineItem = styled(XRowCell)`
  line-height: 24px;
  text-align: right;
  max-width: 250px;
  & > button {
    max-width: 200px;
  }
`;

const TitleWrapper = styled.div`
  margin: 0 15px;
`;

const StyledCatalogsDiv = styled.div({
  marginTop: '10px'
});

const StyledChoiceTextDiv = styled.div({
  padding: '10px'
});

const StyledChoiceDiv = styled.div({
  display: 'flex',
  paddingLeft: '10px',
  paddingRight: '10px',
  fontSize: '13px'
});

const NoItems = styled.span`
  padding: 15px;
  & a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ActionButtons = styled.div`
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
  & svg {
    color: rgb(245, 0, 87);
  }
`;

export default XRetailerDetails;
