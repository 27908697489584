import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { MessageType } from 'src/store/layout/types';

type OwnProps = {
  type?: MessageType;
  message?: string;
  close: () => void;
};

type AlertProps = {
  type?: MessageType;
};

type AllProps = OwnProps;

type State = {};

class XToast extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Alert type={this.props.type}>
        {this.props.message}
        <IconButton
          style={{ marginLeft: '10px' }}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => this.props.close()}>
          <CloseIcon />
        </IconButton>
      </Alert>
    );
  }
}

export default XToast;

const Alert = styled('span')<AlertProps>`
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  white-space: pre;
  background-color: ${props =>
    props.type === 'error'
      ? '#f44336'
      : props.type === 'warning'
      ? '#ff9800'
      : props.type === 'success'
      ? '#4caf50'
      : '#3280c3'};
  color: #fff;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
`;
