import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

type OwnProps = {
  width?: number;
  height?: number;
  background?: string;
};

export const XImageContainer = styled.div<OwnProps>`
  border: 2px solid rgb(245, 245, 245);
  text-align: center;
  vertical-align: middle;
  position: relative;
  padding: 3px;
  height: ${props => (props.height ? props.height + 'px' : '145px')};
  width: ${props => (props.width ? props.width + 'px' : '145px')};
  background: ${props => (props.background ? props.background : 'rgb(245,245,245)')};
`;

export const XImageOverlay = styled('div')({
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  background: 'rgba(1,1,1,.4)',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  '& svg': {
    alignSelf: 'center',
    width: '32px',
    height: '32px',
    filter: 'drop-shadow( 1px 1px 2px rgba(0, 0, 0, .7))'
  }
});

export const XImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  width: 'auto',
  height: 'auto',
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  margin: 'auto'
});
