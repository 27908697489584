import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import XOrganizationRow from 'src/components/domain/XOrganizationRow';
import XOrganizationSelectPopup from 'src/components/domain/XOrganizationSelectPopup';
import XButton, { XButtonStyle } from 'src/components/layout/XButton';
import XCheckbox from 'src/components/layout/XCheckbox';
import { XContainer } from 'src/components/layout/XContainer';
import XDatePicker from 'src/components/layout/XDatePicker';
import XForm from 'src/components/layout/XForm';
import XFormButtons from 'src/components/layout/XFormButtons';
import XSelectItem from 'src/components/layout/XSelectItem';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { Organization, OrganizationType } from 'src/models/Organization';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../../store';
import * as catalogActions from '../../../store/catalogs/actions';
import * as catalogSelects from '../../../store/catalogs/selectors';
import { CatalogAccessibility, CatalogFormData } from '../../../store/catalogs/types';
import * as organizationActions from '../../../store/organizations/actions';
import { ResultRoute } from './WizardRouter';

type StateProps = {
  data: CatalogFormData;
  account: AuthenticationAccount;
  connections: Organization[];
};

type DispatchProps = {
  storeInformation: (data: CatalogFormData) => void;
  addCatalog: (data: CatalogFormData) => Promise<ApiResult>;
  getConnections: (id?: string) => void;
  showNotification: (text: string, type?: MessageType) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

type State = {
  inputData: CatalogFormData;
  showDateInput: boolean;
  availableAllConnections: boolean;
  showAddAvailablePopup: boolean;
  inputErrors: {
    startDate: boolean;
    endDate: boolean;
  };
  validForm: boolean;
  loading: boolean;
  publishResult?: boolean;
};

class WizardAccessPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      inputData: this.props.data,
      showAddAvailablePopup: false,
      availableAllConnections: false,
      loading: false,
      publishResult: undefined,
      showDateInput: false,
      inputErrors: {
        startDate: false,
        endDate: false
      },
      validForm: false
    };
  }

  public componentDidMount() {
    if (this.props.account.organizationType === OrganizationType.Retailer) {
      this.props.history.push('/app/start');
      return;
    }

    if (this.props.data.uploaded) {
      this.props.history.push('/app/my/catalogs/new');
      return;
    }

    if (this.props.account.isAdmin) this.props.getConnections(this.props.data.organizationId);
    else this.props.getConnections();
  }

  public previousPage = () => {
    this.props.history.goBack();
  };

  public handleDateFormChange = (e: any, target) => {
    const inputData = Object.assign({}, this.state.inputData);
    inputData[target] = e.toDate();

    this.setState({ inputData });
  };

  public handleFormChange = (key: string, value: any) => {
    const inputData = Object.assign({}, this.state.inputData);
    inputData[key] = value;

    this.setState({ inputData });
  };

  public storeForm = () => {
    const inputErrors = Object.assign({}, this.state.inputErrors);
    let valid = true;

    Object.keys(this.state.inputData).map(key => {
      if (key in this.state.inputErrors) {
        if (this.state.inputData[key] === '' || this.state.inputData[key] === 0) {
          inputErrors[key] = true;
          valid = false;
        } else {
          inputErrors[key] = false;
        }
      }
    });

    this.setState({ inputErrors });

    if (valid) {
      if (!this.state.showDateInput) {
        this.state.inputData.startDate = undefined;
        this.state.inputData.endDate = undefined;
      } else {
        if (!this.state.inputData.startDate) this.state.inputData.startDate = new Date();
        if (!this.state.inputData.endDate) this.state.inputData.endDate = new Date();
      }

      this.setState({ validForm: true, loading: true });
      this.props.addCatalog(this.state.inputData).then(result => {
        if (result.IsSuccess) this.props.history.push(ResultRoute.path);
        else {
          this.setState({ loading: false, publishResult: false });
          this.props.showNotification(
            'Er ging iets mis bij het publiceren van de catalogus: ' + mapError(result.Errors),
            'error'
          );
        }
      });
    }
  };

  public toggleRetailers(organizations: Organization[]) {
    this.handleFormChange('retailers', [...organizations.map(o => o.id)]);
    this.setState({ showAddAvailablePopup: false });
  }

  public render() {
    const datePickers = (
      <div>
        <XDatePicker
          label="start datum"
          value={this.state.inputData.startDate || new Date()}
          onChange={val => this.handleDateFormChange(val, 'startDate')}
        />

        <XDatePicker
          label="eind datum"
          value={this.state.inputData.endDate || new Date()}
          onChange={val => this.handleDateFormChange(val, 'endDate')}
        />
      </div>
    );

    return (
      <XContainer style={{ position: 'relative', padding: '15px 15px 0 15px' }}>
        <XCheckbox
          label="Beschikbaar binnen een bepaalde periode"
          onChange={e => this.setState({ showDateInput: e.target.checked })}
        />

        {this.state.showDateInput ? datePickers : ''}

        <div style={{ marginTop: '15px' }}>
          <XForm
            data={this.state.inputData}
            inputErrors={this.state.inputErrors}
            margin={false}
            fields={[
              {
                title: 'Beschikbaar',
                name: 'accessibility',
                required: true,
                type: 'select',
                options: [
                  {
                    name: 'Alleen voor mijn connecties',
                    value: CatalogAccessibility.AllConnections
                  },
                  {
                    name: 'Voor geselecteerde connecties',
                    value: CatalogAccessibility.SelectedConnections
                  }
                ]
              }
            ]}
            callback={(e, v) => this.handleFormChange(e, v)}
          />

          {this.state.inputData.accessibility === CatalogAccessibility.SelectedConnections && (
            <div style={{ position: 'relative', margin: '10px 0' }}>
              <XButton
                onClick={e => this.setState({ showAddAvailablePopup: true })}
                colorscheme={XButtonStyle.Secondary}>
                {this.state.inputData.retailers.length === 0
                  ? 'Selecteer retailers'
                  : this.state.inputData.retailers.length + ' geselecteerd'}
              </XButton>

              <XOrganizationSelectPopup
                title={'Catalogus beschikbaar voor..'}
                allowEmpty={true}
                onCancel={() => this.setState({ showAddAvailablePopup: false })}
                saveText="selecteren"
                open={this.state.showAddAvailablePopup}
                clearOnClose={false}
                organizations={this.props.connections}
                onSave={organizations => this.toggleRetailers(organizations)}
              />
            </div>
          )}
        </div>

        <XFormButtons
          onSave={this.storeForm}
          style={{ padding: '0' }}
          onCancel={this.previousPage}
          cancelText="Terug"
          loading={this.state.loading}
          saveText="Opslaan"
        />
      </XContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    data: catalogSelects.newCatalogState(state.catalogs),
    account: state.authentication.account,
    connections: state.organizations.connections
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  storeInformation: (data: CatalogFormData) => dispatch(catalogActions.storeCatalogAction(data)),
  getConnections: (id?: string) =>
    dispatch(organizationActions.getOrganizationConnectionsThunk(id)),
  addCatalog: (data: CatalogFormData) => dispatch(catalogActions.addCatalogThunk(data)),
  showNotification: (text: string, type?: MessageType) => dispatch(showNotification(text, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardAccessPage);

const RetailerName = styled.div`
  line-height: 28px;
`;

const RetailerSelection = styled.div`
  margin-top: 10px;
`;

const PublishResult = styled('div')`
  margin-top: 10px;
  padding: 10px;
  color: #9c1006;
  border: 1px solid #ed677a;
`;
