import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import XErrorContent from 'src/components/domain/XErrorContent';
import XRetailerDetails from 'src/components/domain/XRetailerDetails';
import XSupplierDetails from 'src/components/domain/XSupplierDetails';
import XButton from 'src/components/layout/XButton';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import XScrollView from 'src/components/layout/XScrollView';
import XGoBack from 'src/components/shared/XGoBack';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { MyOrganizationModel, OrganizationModel, OrganizationType } from 'src/models/Organization';
import { RequestModel } from 'src/models/Request';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { getMyOrganizationThunk, getOrganizationThunk } from 'src/store/organizations/actions';
import { createRequestThunk, updateRequestThunk } from 'src/store/requests/actions';
import * as requestActions from 'src/store/requests/actions';
import { CatalogRequestStatus } from 'src/store/requests/api';
import { catalogViewRequestBetweenOrganizations } from 'src/store/requests/selectors';
import i18n from 'src/i18n';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type OwnProps = {};

type StateProps = {
  organization?: OrganizationModel;
  myOrganization?: MyOrganizationModel;
  viewRequest?: RequestModel;
  myAccount: AuthenticationAccount;
  loadingOrganization: boolean;
};

type DispatchProps = {
  showNotification: (message: string, type: MessageType) => void;
  getRequests: () => void;
  getMyOrganization: () => void;
  organizationById: (id: string) => Promise<ApiResult>;
  createRequest: (targetOrganizationId: string, catalogIds?: string[]) => Promise<ApiResult>;
  cancelRequest: (id: string) => Promise<ApiResult>;
  editCatalogViewRequest: (
    catalogRequest: RequestModel,
    state: CatalogRequestStatus
  ) => Promise<ApiResult>;
};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps<{ id: string }>;

type State = {
  loadingCommand: boolean;
};

class ShowOrganizationPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      loadingCommand: false
    };
  }

  public componentDidMount() {
    this.props.organizationById(this.props.match.params.id).then(result => {
      if (!result.IsSuccess) {
        if (result.StatusCode === 401)
          // Not authorized for this organization
          this.props.history.push('/app/start');
      }
    });

    this.props.getMyOrganization();
    this.props.getRequests();
  }

  public requestCatalogs() {
    if (!this.props.organization) return;

    this.setState({ loadingCommand: true });
    this.props.createRequest(this.props.organization.organization.id).then(result => {
      this.setState({ loadingCommand: false });
      if (result.IsSuccess)
        this.props.showNotification(i18n.t('notification.connectionRequestSent'), 'success');
      else
        this.props.showNotification(
          i18n.t('notification.wentWrongSendingConnectionRequest') + mapError(result.Errors),
          'error'
        );
    });
  }

  public offerCatalogs(catalogIds: string[]) {
    if (!this.props.organization) return;

    this.setState({ loadingCommand: true });
    this.props.createRequest(this.props.organization.organization.id, catalogIds).then(result => {
      this.setState({ loadingCommand: false });
      if (result.IsSuccess)
        this.props.showNotification(i18n.t('notification.catalogsOffered'), 'success');
      else
        this.props.showNotification(
          i18n.t('notification.wentWrongWhenOfferingCatalog') + mapError(result.Errors),
          'error'
        );
    });
  }

  public editCatalogRequest(state: CatalogRequestStatus) {
    if (!this.props.viewRequest) return;

    this.setState({ loadingCommand: true });
    this.props.editCatalogViewRequest(this.props.viewRequest, state).then(result => {
      this.setState({ loadingCommand: false });
      if (result.IsSuccess) {
        this.props.showNotification(
          i18n.t('organizations.Application') +
            ' ' +
            (state === 'approve'
              ? i18n.t('organizations.approved')
              : i18n.t('organizations.turnedD')),
          'success'
        );
        this.props.getMyOrganization();
      } else
        this.props.showNotification(
          i18n.t('notification.wentWrongRespondingRequest') + mapError(result.Errors),
          'error'
        );
    });
  }

  public cancelRequest() {
    this.setState({ loadingCommand: true });
    this.props.cancelRequest(this.props.viewRequest?.connectRequest.id as string).then(result => {
      this.setState({ loadingCommand: false });
      if (result.IsSuccess)
        this.props.showNotification(i18n.t('notification.requestCanceled'), 'success');
      else
        this.props.showNotification(
          i18n.t('notification.errWhileCancelingRequest') + mapError(result.Errors),
          'error'
        );
    });
  }

  public render() {
    if (!this.props.organization || !this.props.myOrganization)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <XErrorContent />
          </XPaper>
        </XPageContent>
      );

    return (
      <ErrorBoundary>
        <XPageContent>
          <XPaper style={{ minHeight: '300px', padding: '15px' }}>
            <XScrollView>
              {this.props.organization.organization.organizationType ===
              OrganizationType.Supplier ? (
                <XSupplierDetails
                  organization={this.props.organization}
                  myOrganization={this.props.myOrganization}
                  catalogViewRequest={this.props.viewRequest}
                  requestViewCatalogs={() => this.requestCatalogs()}
                  cancelRequest={() => this.cancelRequest()}
                  editCatalogOffer={state => this.editCatalogRequest(state)}
                  loadingRequest={this.state.loadingCommand}
                  history={this.props.history}
                  loadingOrganization={this.props.loadingOrganization}
                />
              ) : (
                <XRetailerDetails
                  organization={this.props.organization}
                  myOrganization={this.props.myOrganization}
                  catalogViewOffer={this.props.viewRequest}
                  offerViewCatalogs={catalogIds => this.offerCatalogs(catalogIds)}
                  cancelRequest={() => this.cancelRequest()}
                  editViewCatalogRequest={state => this.editCatalogRequest(state)}
                  loadingRequest={this.state.loadingCommand}
                  history={this.props.history}
                  loadingOrganization={this.props.loadingOrganization}
                />
              )}
            </XScrollView>
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    myAccount: state.authentication.account,
    myOrganization: state.organizations.myOrganization,
    viewRequest: catalogViewRequestBetweenOrganizations(
      state.requests,
      state.organizations.detail?.organization.id ?? ''
    ),
    organization: state.organizations.detail,
    loadingOrganization: state.organizations.loading
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string) => {
    dispatch(showNotification(message));
  },
  getRequests: () => dispatch(requestActions.getRequestsThunk()),
  organizationById: (id: string) => dispatch(getOrganizationThunk(id)),
  getMyOrganization: () => dispatch(getMyOrganizationThunk()),
  createRequest: (targetOrganizationId: string, catalogIds?: string[]) =>
    dispatch(createRequestThunk(targetOrganizationId, catalogIds)),
  cancelRequest: (id: string) => dispatch(requestActions.cancelRequestThunk(id)),
  editCatalogViewRequest: (catalogRequest: RequestModel, state: CatalogRequestStatus) =>
    dispatch(updateRequestThunk(catalogRequest, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowOrganizationPage);
