import styled from '@emotion/styled';
import * as React from 'react';

type OwnProps = {
  count: number | string;
};

type AllProps = OwnProps;

const XCounter: React.SFC<AllProps> = props => {
  return <Counter>{props.count}</Counter>;
};

export default XCounter;

const Counter = styled('label')({
  // withTheme(
  background: '#dedede',
  color: '#353535',
  display: 'inline-block',
  borderRadius: '25px',
  fontSize: '12px',
  marginLeft: '4px',
  padding: '2px 8px',
  marginRight: 'auto',
  transform: 'translateY(-1px)'
});
// );
