import styled from '@emotion/styled';
import * as React from 'react';

type OwnProps = {
  onClick?: any;
  text?: string;
};

type AllProps = OwnProps;

const XSmallButton: React.SFC<AllProps> = props => {
  return (
    <div>
      <SmallButton onClick={props.onClick} {...props}>
        {props.text}
      </SmallButton>
    </div>
  );
};

export default XSmallButton;

const SmallButton = styled('button')`
  width: 100%;
  cursor: pointer;
  border: none;
  background: #3280c3;
  border-radius: 4px;
  padding: 2px 10px;
  font-weight: 600;
  color: #ffffff;
`;
