import React, { Component, ErrorInfo, ReactNode } from 'react';
import XNoContent from 'src/components/domain/XNoContent';
import ErrorImage from 'src/style/images/error-broken.svg';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import styled from '@emotion/styled';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <XPageContent>
          <StyledPaper>
            <XNoContent size={200} image={ErrorImage}>
              <div style={{ marginBottom: '15px' }}>
                Sorry.. there was an error! This page is broken!
              </div>
            </XNoContent>
          </StyledPaper>
        </XPageContent>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

const StyledPaper = styled(XPaper)`
  min-height: 300px;
  max-height: calc(100% - 40px);
  overflow-y: auto;
`;
