import * as React from 'react';
import { connect } from 'react-redux';
import { XPaper, XPaperTitle } from 'src/components/layout/XPaper';
import { XRowCell } from 'src/components/layout/XRow';
import * as accountActions from 'src/store/accounts/actions';
import { AccountSetting, AccountSettingForm } from 'src/store/authentication/types';

import styled from '@emotion/styled';
import XPageContent from 'src/components/layout/XPageContent';
import XScrollView from 'src/components/layout/XScrollView';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../store';
import AccountSettings from './account/AccountSettings';
import { XActionButton } from 'src/components/layout/XActionButton';
import { XTitle } from 'src/components/layout/XPaperHeader';
import { Translation } from 'react-i18next';
import i18n from '../../i18n';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  settings: AccountSetting[];
};
type State = {
  settingsData: AccountSettingForm[];
};

type DispatchProps = {
  showNotification: (message: string, type?: MessageType) => void;
  getSettings: () => Promise<ApiResult>;
  saveSettings: (data: AccountSettingForm[]) => Promise<ApiResult>;
};

type AllProps = StateProps & DispatchProps;

class MySettingsPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      settingsData: []
    };
  }

  public componentDidMount() {
    this.props.getSettings();
  }

  public handleSettingsChange = (data: AccountSettingForm[]) => {
    this.setState({ settingsData: data });
  };

  public saveSettings = () => {
    this.props.saveSettings(this.state.settingsData).then(result => {
      if (result.IsSuccess)
        this.props.showNotification(i18n.t('notification.settingsSaved'), 'success');
      else
        this.props.showNotification(
          i18n.t('notification.settingsSavingError') + mapError(result.Errors),
          'error'
        );
    });
  };

  public render() {
    return (
      <ErrorBoundary>
        <XPageContent style={{ maxWidth: '600px' }}>
          <XPaper>
            <StyledPaperTitle style={{ display: 'flex' }}>
              <XRowCell>
                <Translation>{t => <XTitle>{t('account.myPreferences')}</XTitle>}</Translation>
              </XRowCell>
              <Translation>
                {t => (
                  <XActionButton onClick={() => this.saveSettings()}>
                    {t('tooltip.Save')}
                  </XActionButton>
                )}
              </Translation>
            </StyledPaperTitle>
            <XScrollView>
              <AccountSettings
                settings={this.props.settings}
                onChange={data => this.handleSettingsChange(data)}
              />
            </XScrollView>
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    settings: state.account.settings
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type)),
  getSettings: () => dispatch(accountActions.GetSettingsThunk()),
  saveSettings: (data: AccountSettingForm[]) => dispatch(accountActions.SaveSettingsThunk(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MySettingsPage);

const StyledPaperTitle = styled(XPaperTitle)`
  margin-bottom: 20px;
  position: relative;
`;
