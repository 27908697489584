import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

import { XContainer } from './XContainer';

type OwnProps = { columns?: number; style?: any; width?: number };

type AllProps = OwnProps;

const XGrid: React.SFC<AllProps> = props => {
  return <Grid {...props}>{props.children}</Grid>;
};

export default XGrid;

const Grid = styled(XContainer)(
  {
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  },
  (props: OwnProps) => ({
    '> *': {
      width: props.columns ? ((100 / props.columns) as number) + '%' : '50%',
      minWidth: props.width ? props.width + 'px' : ''
    }
  })
);
