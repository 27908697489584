import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { XSecActionButton, XActionButton } from 'src/components/layout/XActionButton';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { XContainer } from 'src/components/layout/XContainer';
import XForm from 'src/components/layout/XForm';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper, XPaperTitle } from 'src/components/layout/XPaper';
import { XTitle } from 'src/components/layout/XPaperHeader';
import { XRow, XRowCell } from 'src/components/layout/XRow';
import { getEmailForm, getFtpForm, getSftpForm } from 'src/constants/OrganizationInterface/InterfaceSetting';
import {
  getInterfaceFormats,
  getInterfaceMethods,
  getInterfaceText
} from 'src/constants/OrganizationInterface/OrganizationInterfaces';
import { mapError } from 'src/helpers/errorMap';
import { InterfaceMethod, InterfaceType, OrganizationInterface } from 'src/models/Organization';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { validateFtpAccessThunk, validateSftpAccessThunk } from 'src/store/organizations/actions';
import { saveOrganizationInterface } from 'src/store/organizations/api';
import ErrorIcon from '@material-ui/icons/Warning';
import SuccessIcon from '@material-ui/icons/Check';
import { XPanel, XFailPanel, XSuccessPanel } from 'src/components/layout/XPanel';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type OwnProps = {};

type AllProps = StateProps &
  DispatchProps &
  OwnProps &
  RouteComponentProps<{ interfaceId: string }>;

type State = {
  interface?: OrganizationInterface;
  loading: boolean;
  generatedPassword: string;
  inputErrors: any;
  loadingFtpAccess: boolean;
  loadingSftpAccess: boolean;
  ftpTested: boolean;
  sftpTested: boolean;
  ftpSuccess: boolean;
  sftpSuccess: boolean;
};

function generatePassword() {
  var length = 10,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

class SoftwareInterfacePage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      inputErrors: [],
      generatedPassword: generatePassword(),
      loading: true,
      loadingFtpAccess: false,
      loadingSftpAccess: false,
      ftpTested: false,
      sftpTested: false,
      ftpSuccess: false,
      sftpSuccess: false
    };
  }

  async componentDidMount() {
    var interfaceId = this.props.match.params.interfaceId;
    if (interfaceId) {
      const result = this.props.organization?.interfaces.find(x => x.id === interfaceId);
      this.setState({ interface: result });
    } else {
      const queryParams = new URLSearchParams(this.props.location.search);
      const typeString = queryParams.get('type');
      if (typeString === null) return;

      const type = InterfaceType[typeString];
      let defaultMethod = InterfaceMethod.Manual;
      if (type === InterfaceType.OrderSend || type === InterfaceType.OrderReceive) {
        defaultMethod = InterfaceMethod.LtxFtp;
      }

      const newInterface = { id: '', type, method: defaultMethod, settings: {} };
      this.setState({ interface: newInterface });
    }

    this.setState({ loading: false });
  }

  setMethod = (method: InterfaceMethod) => {
    if (this.state.interface) this.setState({ interface: { ...this.state.interface, method } });
  };

  setFormat = (format: string) => {
    this.setSetting('FileFormat', format);
  };

  setSetting(key: string, value: string) {
    if (!this.state.interface) return;
    let settings = this.state.interface.settings;
    if (!settings) return;
    settings[key] = value;
    this.setState({
      interface: { ...this.state.interface, settings: settings }
    });
  }

  getLtxUserName = (): string => {
    let result =
      this.state.interface?.settings?.['LtxFtpUsername'] ??
      this.props.organization?.organization.email ??
      '';
    if (!result.length) result = this.props.account.email;
    return result;
  };

  getLtxPassword = (): string => {
    return this.state.interface?.settings?.['LtxFtpPassword'] ?? this.state.generatedPassword;
  };

  getLtxFtpAddress = (): string => {
    const arxisId = this.props.identifiers.filter(x => x.type === 'ArxisId')[0].value;
    return this.state.interface?.settings?.['LtxFtpAddress'] ?? 'ftp://ftp.arxis.io//'+ this.props.organization?.organization.organizationType +
     '/' + arxisId + '/Order/';
  };

  cancelEdit = () => {
    this.props.history.goBack();
  };

  addhttp = url => {
    if (url !== undefined && !/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = 'http://' + url;
    }
    return url;
  };

  public async validateMySftp() {
    if (this.state.interface === undefined) return;
    const orgInterface = this.state.interface;
    const url = this.addhttp(orgInterface.settings['SftpAddress']);
    this.setSetting('SftpAddress', url);
    if (orgInterface.settings['SftpAddress'])
      return await this.validateSftp({
        address: orgInterface.settings['SftpAddress'],
        username: orgInterface.settings['SftpUsername'],
        password: orgInterface.settings['SftpPassword']
      });
  }

  public async validateMyFtp() {
    if (this.state.interface === undefined) return;
    const orgInterface = this.state.interface;
    const url = this.addhttp(orgInterface.settings['FtpAddress']);
    this.setSetting('FtpAddress', url);
    if (orgInterface.settings['FtpAddress'])
      return await this.validateFtp({
        address: orgInterface.settings['FtpAddress'],
        username: orgInterface.settings['FtpUsername'],
        password: orgInterface.settings['FtpPassword']
      });
  }

  public async validateFtp(ftp: { address: string; username: string; password: string }) {
    if (!ftp.address || !ftp.username || !ftp.password) return;

    this.setState({ loadingFtpAccess: true, ftpTested: false });
    const result = await this.props.validateFtpAccess(ftp.address, ftp.username, ftp.password);
    this.setState({ loadingFtpAccess: false });

    this.setState({ ftpTested: true, ftpSuccess: result.IsSuccess });
    if (result.IsSuccess)
      this.props.showNotification(i18n.t('notification.FTPfolderExists'), 'success');
    else
      this.props.showNotification(
        i18n.t('notification.FTPfoldercouldNotBeReached') + mapError(result.Errors),
        'error'
      );
  }

  public async validateSftp(sftp: { address: string; username: string; password: string }) {
    if (!sftp.address || !sftp.username || !sftp.password) return;

    this.setState({ loadingSftpAccess: true, sftpTested: false });
    const result = await this.props.validateSftpAccess(sftp.address, sftp.username, sftp.password);
    this.setState({ loadingSftpAccess: false });

    this.setState({ sftpTested: true, sftpSuccess: result.IsSuccess });
    if (result.IsSuccess)
      this.props.showNotification(i18n.t('notification.SFTPfolderExists'), 'success');
    else
      this.props.showNotification(
        i18n.t('notification.SFTPfolderCouldNotBeReached') + mapError(result.Errors),
        'error'
      );
  }

  save = async () => {
    this.setSetting('LtxFtpUsername', this.getLtxUserName());
    this.setSetting('LtxFtpPassword', this.getLtxPassword());
    this.setSetting('LtxFtpAddress', this.getLtxFtpAddress());
    if (this.state.interface === undefined) return;
    const orgInterface = this.state.interface;
    console.log('Settings: ', orgInterface.settings);

    if (orgInterface.method === InterfaceMethod.Ftp) {
      await this.validateMyFtp();
      if (!this.state.ftpSuccess) {
        return;
      }
    }

    if (orgInterface.method === InterfaceMethod.Sftp) {
      await this.validateMySftp();
      if (!this.state.sftpSuccess) {
        return;
      }
    }

    this.setState({ loading: true });
    const result = await saveOrganizationInterface(orgInterface);
    this.setState({ loading: false });

    if (result.IsSuccess) {
      this.props.showNotification(
        i18n.t('notification.digiMessageFor') +
          this.text().title.toLowerCase() +
          i18n.t('notification.saved'),
        'success'
      );
      this.props.history.goBack();
    } else
      this.props.showNotification(
        i18n.t('notification.somethingWentWrongWhileSaving') + mapError(result.Errors),
        'error'
      );
  };

  validate = () => {};

  text = () => getInterfaceText(this.state.interface?.type);

  public render() {
    const methods = getInterfaceMethods(this.state.interface?.type);
    const formats = getInterfaceFormats(this.state.interface?.type);

    return (
      <ErrorBoundary>
        <XPageContent style={{ maxWidth: '600px' }}>
          <XPaper>
            <XPaperTitle style={{ display: 'flex' }}>
              <XRowCell>
                <XTitle>{this.text().title}</XTitle>
              </XRowCell>
              <Translation>
                {t => (
                  <XActionStrip>
                    <XSecActionButton onClick={() => this.cancelEdit()}>
                      {t('tooltip.Cancel')}
                    </XSecActionButton>
                    <XActionButton onClick={() => this.save()}>{t('tooltip.Save')}</XActionButton>
                  </XActionStrip>
                )}
              </Translation>
            </XPaperTitle>
            <XContainer>
              <Label>Via</Label>
              <XRow>
                {methods.map(method => (
                  <XRowCell style={{ padding: '5px' }}>
                    <Option
                      className={method.key === this.state.interface?.method ? 'selected' : ''}
                      onClick={() => this.setMethod(method.key)}>
                      {method.description}
                    </Option>
                  </XRowCell>
                ))}
              </XRow>

              <div style={{ marginTop: '25px' }}>
                {this.state.interface?.method === InterfaceMethod.LtxFtp && (
                  <Translation>
                    {t => (
                      <StatusCard>
                        {t('software.useFollowingInfoToEnter')}
                        <div>
                          <strong>{t('account.userName')}: </strong> {this.getLtxUserName()}
                        </div>
                        <div>
                          <strong>{t('account.password')}: </strong>
                          •••••••••
                        </div>
                      </StatusCard>
                    )}
                  </Translation>
                )}
                {this.state.interface?.method === InterfaceMethod.Sftp && (
                  <div>
                    <XForm
                      data={this.state.interface.settings}
                      inputErrors={this.state.inputErrors}
                      fields={getSftpForm()}
                      verticalAlign={true}
                      callback={(name, value) => this.setSetting(name, value)}
                    />
                    <div>
                      {this.state.loadingSftpAccess && (
                        <XPanel>
                          <div>
                            <CircularProgress
                              style={{
                                height: '22px',
                                width: '22px',
                                margin: '0 10px'
                              }}
                            />
                          </div>
                          <Translation>
                            {t => <div>{t('software.triesToConnToSFTP')}</div>}
                          </Translation>
                        </XPanel>
                      )}
                      {this.state.sftpTested && !this.state.sftpSuccess && (
                        <XFailPanel>
                          <div>
                            <ErrorIcon
                              style={{
                                height: '22px',
                                width: '22px',
                                margin: '0 10px'
                              }}
                            />
                          </div>
                          <Translation>
                            {t => <div> {t('software.cannotReachYourSFTP')} </div>}
                          </Translation>
                        </XFailPanel>
                      )}
                      {this.state.sftpTested && this.state.sftpSuccess && (
                        <XSuccessPanel>
                          <div>
                            <SuccessIcon
                              style={{
                                height: '22px',
                                width: '22px',
                                margin: '0 10px'
                              }}
                            />
                          </div>
                          <Translation>
                            {t => <div> {t('software.canReachYourSFTP')} </div>}
                          </Translation>
                        </XSuccessPanel>
                      )}
                    </div>
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                      <XSecActionButton onClick={() => this.validateMySftp()}>
                        Valideren
                      </XSecActionButton>
                    </div>
                  </div>
                )}
                {this.state.interface?.method === InterfaceMethod.Ftp && (
                  <div>
                    <XForm
                      data={this.state.interface.settings}
                      inputErrors={this.state.inputErrors}
                      fields={getFtpForm()}
                      verticalAlign={true}
                      callback={(name, value) => this.setSetting(name, value)}
                    />
                    <div>
                      {this.state.loadingFtpAccess && (
                        <XPanel>
                          <div>
                            <CircularProgress
                              style={{
                                height: '22px',
                                width: '22px',
                                margin: '0 10px'
                              }}
                            />
                          </div>
                          <Translation>
                            {t => <div>{t('software.triesToConnToFTP')}</div>}
                          </Translation>
                        </XPanel>
                      )}
                      {this.state.ftpTested && !this.state.ftpSuccess && (
                        <XFailPanel>
                          <div>
                            <ErrorIcon
                              style={{
                                height: '22px',
                                width: '22px',
                                margin: '0 10px'
                              }}
                            />
                          </div>
                          <Translation>
                            {t => <div> {t('software.cannotReachYourFTP')} </div>}
                          </Translation>
                        </XFailPanel>
                      )}
                      {this.state.ftpTested && this.state.ftpSuccess && (
                        <XSuccessPanel>
                          <div>
                            <SuccessIcon
                              style={{
                                height: '22px',
                                width: '22px',
                                margin: '0 10px'
                              }}
                            />
                          </div>
                          <Translation>
                            {t => <div> {t('software.canReachYourFTP')} </div>}
                          </Translation>
                        </XSuccessPanel>
                      )}
                    </div>
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                      <XSecActionButton onClick={() => this.validateMyFtp()}>
                        Valideren
                      </XSecActionButton>
                    </div>
                  </div>
                )}
                {this.state.interface?.method === InterfaceMethod.Email && (
                  <div>
                    <XForm
                      data={this.state.interface.settings}
                      inputErrors={this.state.inputErrors}
                      fields={getEmailForm()}
                      verticalAlign={true}
                      callback={(name, value) => this.setSetting(name, value)}
                    />
                  </div>
                )}
              </div>
              <Translation>{t => <Label>{t('software.Format')}</Label>}</Translation>
              <XRow>
                {formats.map(format => (
                  <XRowCell style={{ padding: '5px 5px 0px' }}>
                    <Option
                      className={
                        format === this.state.interface?.settings?.['FileFormat'] ? 'selected' : ''
                      }
                      onClick={() => this.setFormat(format)}>
                      {format}
                    </Option>
                  </XRowCell>
                ))}
              </XRow>
              {this.state.interface?.type == InterfaceType.OrderReceive && (
                <XRow>
                  {formats.map(format => (
                    <XRowCell style={{ padding: '0px 5px 0px' }}>
                      <SubOption>
                        {(format === 'LTX' || format === 'EANCOM') && '(zonder conversie)'}
                      </SubOption>
                    </XRowCell>
                  ))}
                </XRow>
              )}
            </XContainer>
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}
const Label = styled('div')`
  font-weight: bold;
  margin: 6px;
  font-size: 1.15em;
  margin-top: 12px;
`;

const Option = styled('div')`
  padding: 2px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  text-align: center;
  &.selected {
    font-weight: bold;
  }
`;

const SubOption = styled('div')`
  padding: 0px;
  text-align: center;
  font-size: 0.85em;
`;

const StatusCard = styled('div')`
  padding: 6px;
`;

type StateProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
  organization: state.organizations.myOrganization,
  account: state.authentication.account,
  identifiers: state.organizations.myIdentifiers
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: any) => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type)),
  validateFtpAccess: (url: string, user: string, pass: string) =>
    dispatch(validateFtpAccessThunk(url, user, pass)),
  validateSftpAccess: (url: string, user: string, pass: string) => dispatch(validateSftpAccessThunk(url, user, pass))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SoftwareInterfacePage));
