import styled from '@emotion/styled';
import React from 'react';
import { Organization } from 'src/models/Organization';
import { XContainer } from '../layout/XContainer';
import XField from '../layout/XField';
import XPagination from '../layout/XPagination';
import XPopup from '../layout/XPopup';
import { XRowCell } from '../layout/XRow';
import XSelectItem from '../layout/XSelectItem';
import XTable, { TableRow } from '../layout/XTable';
import XOrganizationRow from './XOrganizationRow';

type TableColumnDefinition = {
  name: string;
  width?: string;
  interpolation?: any;
};

type OwnProps = {
  data: any[];
  paged: boolean;
  pageSize: number;
  maxResults: number;
  columns: TableColumnDefinition[];
  title: string;
  open: boolean;
  onCancel: () => void;
};

type AllProps = OwnProps;
type State = {
  search: string;
  currentOffset: number;
};

class XTableViewerPopup extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      search: '',
      currentOffset: 0
    };
  }

  public cancel() {
    this.props.onCancel();
  }

  public render() {
    const rows = this.props.paged
      ? this.props.data.slice(
          this.state.currentOffset,
          this.state.currentOffset + this.props.pageSize
        )
      : this.props.data.slice(0, this.props.maxResults);

    const bottomContent = this.props.paged ? (
      <XPagination
        changePage={i => this.setState({ currentOffset: i })}
        itemsPerPage={this.props.pageSize}
        itemCount={this.props.data.length}
      />
    ) : (
      undefined
    );

    return (
      <XPopup
        title={this.props.title}
        open={this.props.open}
        onCancel={() => this.cancel()}
        cancelOnPagClick={true}
        bottomContent={this.props.data.length <= this.props.pageSize ? undefined : bottomContent}>
        {!this.props.paged && this.props.data.length > this.props.maxResults && (
          <div style={{ marginBottom: '5px', fontSize: '13px', color: '#ccc' }}>
            Eerste {this.props.maxResults} items:
          </div>
        )}
        <XTable style={{ padding: '0' }}>
          {rows.map((d, index) => (
            <TableRow key={index}>
              {this.props.columns.map((c, colIndex) => (
                <div
                  key={colIndex}
                  style={{ minWidth: c.width, maxWidth: c.width, flex: c.width ? '0' : '1' }}>
                  {c.interpolation ? c.interpolation(d[c.name]) : d[c.name]}
                </div>
              ))}
            </TableRow>
          ))}
        </XTable>
      </XPopup>
    );
  }
}

export default XTableViewerPopup;

const StyledField = styled.div`
  position: absolute;
  right: -10px;
  top: -56px;
  display: inline-block;
  vertical-align: top;
  width: 200px;
  z-index: 11;
  & input {
    padding: 3px 0 !important;
  }
`;
