import { apiClient, ApiResult } from 'src/helpers/apiClient';
import { AddStockFormData, EditStockFormData, Mapping } from './types';
import { StockInterface } from 'src/models/Stock';


export const getStock = async (id: string): Promise<ApiResult> => {
  const parameters = {
    Id: id
  };
  return await apiClient.postQueryAsync('GetStock', parameters);
};

export const listStocks = async (): Promise<ApiResult> => {
  return getStocks('ListStocks', {});
};

export const listSubscribedStocks = async (): Promise<ApiResult> => {
  return getStocks('ListSubscribedStocks', {});
};

const getStocks = async (queryName: string, params?: any): Promise<ApiResult> => {
  return await apiClient.postQueryAsync(queryName, params);
};

export const deleteStock = async (id: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'DeleteStock',
    Id: id
  };
  return await apiClient.postCommandAsync(command);
};

export async function addStock(param: AddStockFormData): Promise<ApiResult> {
  const command = {
    CommandName: 'AddStock',
    OrganizationId: param.organizationId,
    Title: param.title,
    Description: param.description,
    FileFormatId: param.fileFormatId,
    FileFormatVersionId: param.fileFormatVersionId,
    InterfaceType: param.interfaceType,
    InterfaceMethod: param.interfaceMethod,
    InterfaceAuthentication: param.interfaceAuthentication,
    Settings: param.settings
  };

  return await apiClient.postCommandAsync(command);
}

export const editStockAvailable = async (
  organizationIds: string[],
  stockId: string,
  available: boolean
): Promise<ApiResult> => {
  const command = {
    CommandName: 'EditStockAvailability',
    OrganizationIds: organizationIds,
    StockId: stockId,
    Available: available
  };
  return await apiClient.postCommandAsync(command);
};


export const saveStockInterface = async (
  stockInterface: StockInterface,
  stockId?: string | undefined
): Promise<ApiResult<StockInterface>> => {
  const command = {
    CommandName: 'SaveStockInterface',
    StockId: stockId,
    StockInterfaceId: stockInterface.id,
    InterfaceType: stockInterface.type,
    InterfaceMethod: stockInterface.method,
    InterfaceAuthentication: stockInterface.authentication,
    Settings: stockInterface.settings
  };

  return await apiClient.postCommandAsync(command);
};

export const editStock = async (id: string, formData: EditStockFormData): Promise<ApiResult> => {
  const command = {
    CommandName: 'EditStock',
    StockId: id,
    Title: formData.title,
    Description: formData.description
  };
  return await apiClient.postCommandAsync(command);
};

export const getFileFormats = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListFileFormats', {});
};

export const fileReaderCSV = async (file: File): Promise<ApiResult> => {
  const command = {
    CommandName: 'StockFileReaderCSV',
  };

  return await apiClient.postCommandAsync(command, [file]);
};

export const saveStockMapping = async (Mapping: Mapping, stockId: string, stockMappingId: string ): Promise<ApiResult> => {
  const command = {
    CommandName: 'SaveStockMapping',
    StockId: stockId,
    StockMappingId: stockMappingId,
    Mapping: {
      MappingItems: Mapping.mappingItems,
      Delimiter: Mapping.delimiter
    }
  };
  return await apiClient.postCommandAsync(command);
};

export const getStockMapping = async (id: string | undefined): Promise<ApiResult> => {
  const parameters = {
    MappingId: id
  };
  return await apiClient.postQueryAsync('GetStockMapping', parameters);
};
