import { BaseReducer } from 'src/helpers/baseReducer';
import {
  EmptyMyOrganizationModel,
  EmptyOrganization,
  InterfaceMethod,
  Organization,
  OrganizationType
} from 'src/models/Organization';
import { EmptyOrganizationModel, OrganizationModel } from '../../models/Organization';
import { Action } from './actions';
import { ActionTypes, OrganizationState } from './types';

export const emptyFormData = {
  // btwNumber: '',
  // iban: '',
  city: '',
  telephoneNumber: '',
  companyEmail: '',
  description: '',
  houseNumber: '',
  image: undefined,
  country: '',
  name: '',
  organizationType: OrganizationType.Retailer,
  postalCode: '',
  street: '',
  website: '',
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  maturityStatus: 100,
  passwordValid: '',
  acceptTermsAndConditions: false,
  language: '',
  numberOfLiveConnections: 0
};

export const initialState: OrganizationState = {
  myOrganization: EmptyMyOrganizationModel,
  detail: EmptyOrganizationModel,
  myIdentifiers: [],
  unconfiguredFtp: [],
  manageDetails: {
    identifiers: [],
    accounts: [],
    organization: EmptyOrganization,
    catalogs: [],
    stocks: [],
    connections: [],
    interfaces: [],
    settings: []
  },
  all: undefined,
  connections: [],
  connectionIdentifiers: [],
  errors: undefined,
  loading: false,
  formData: emptyFormData,
  accessToken: undefined
};

export class OrganizationReducer extends BaseReducer<OrganizationState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: OrganizationState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.ADD_ORGANIZATION: {
        newState.formData = emptyFormData;
        return newState;
      }
      case ActionTypes.STORE_FORM: {
        newState.formData = action.payload.data;
        return newState;
      }
      case ActionTypes.GET_MY_ORGANIZATION: {
        if (action.status === 'success' && action.payload.myOrganization) {
          action.payload.myOrganization.catalogs = action.payload.myOrganization.catalogs.sort(
            (a, b) => a.title.localeCompare(b.title)
          );
          newState.myOrganization = action.payload.myOrganization;
        } else newState.myOrganization = undefined;
        return newState;
      }
      case ActionTypes.GET_ORGANIZATION_IDENTIFIERS: {
        if (action.status === 'success' && action.payload.identifiers)
          newState.myIdentifiers = action.payload.identifiers;
        else newState.myIdentifiers = [];
        return newState;
      }
      case ActionTypes.GET_ORGANIZATION: {
        if (action.status === 'success' && action.payload.organization)
          newState.detail = action.payload.organization;
        else newState.detail = undefined;
        return newState;
      }
      case ActionTypes.GET_MANAGE_ORGANIZATION: {
        if (action.status === 'success' && action.payload.organization)
          newState.manageDetails = action.payload.organization;
        else newState.manageDetails = undefined;
        return newState;
      }
      case ActionTypes.GET_ORGANIZATIONS: {
        if (action.status === 'success' && action.payload.organizations)
          newState.all = action.payload.organizations;
        else newState.all = undefined;
        return newState;
      }
      case ActionTypes.GET_ORGANIZATION_CONNECTIONS: {
        if (action.status === 'success' && action.payload.organizations)
          newState.connections = action.payload.organizations;
        else newState.connections = [];
        return newState;
      }
      case ActionTypes.GET_UNCONFIGURED_FTP_ORGANIZATIONS: {
        if (action.status === 'success' && action.payload.organizations)
          newState.unconfiguredFtp = action.payload.organizations;
        else newState.unconfiguredFtp = [];
        return newState;
      }
      case ActionTypes.GET_CONNECTION_IDENTIFIERS: {
        if (action.status === 'success' && action.payload.connectionIdentifiers)
          newState.connectionIdentifiers = action.payload.connectionIdentifiers;
        else newState.connectionIdentifiers = [];
        return newState;
      }
      case ActionTypes.GET_ORGANIZATION_ACCESS_TOKEN: { 
        if(action.status === 'success' && action.payload.accessToken)
          newState.accessToken = action.payload.accessToken;
          else newState.accessToken = undefined;

        return newState;
      }
      case ActionTypes.DELETE_ORGANIZATION_ACCESS_TOKEN: { 
        if(action.status === 'success')
        newState.accessToken = undefined;

        return newState;
      }
      default:
        return undefined;
    }
  }
}
