import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  InterfaceSettingsKey,
  InterfaceMethod,
  InterfaceAuthentication,
  InterfaceType,
  CatalogModel,
  InterfaceUpdateInterval
} from '../../../../models/Catalog';
import { ApiResult } from 'src/helpers/apiClient';
import { MessageType } from 'src/store/layout/types';
import { mapError } from 'src/helpers/errorMap';
import { saveCatalogInterface } from '../../../../store/catalogs/api';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import * as catalogActions from 'src/store/catalogs/actions';
import { XActionButton } from 'src/components/layout/XActionButton';
import { Checkbox, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import styled from '@emotion/styled';
import i18n from '../../../../i18n';

type StateProps = {
  catalog?: CatalogModel;
};

type OwnProps = {
  catalogId?: string;
};

type DispatchProps = {
  showNotification: (message: string, type?: MessageType) => void;
  getCatalogById: (id: string) => Promise<ApiResult>;
};

type AllProps = OwnProps & DispatchProps & StateProps;

const VertilinQInterfaceMethod: FC<AllProps> = props => {
  const [url, setUrl] = useState('');
  const [token, setToken] = useState('');
  const [autoUpdate, setAutoUpdate] = useState('');
  const [updateTimeInterval, setUpdateTimeInterval] = useState('');

  const history = useHistory();

  const onChangeUpdateIntervalHandler = (e: any) => {
    setUpdateTimeInterval(e.target.value);
  };

  useEffect(() => {
    props.catalogId &&
      props.getCatalogById(props.catalogId).then(() => {
        if (!!props.catalog?.interfaces.length) {
          if (props.catalog?.interfaces[0].method === InterfaceMethod.VertilinQ) {
            setUrl(`${props.catalog?.interfaces[0].settings.Url}`);
            setToken(`${props.catalog?.interfaces[0].settings.Token}`);
            setAutoUpdate(`${props.catalog?.interfaces[0].settings.AutoUpdate}`);
            setUpdateTimeInterval(
              `${props.catalog?.interfaces[0].settings.UpdateTimeInterval || ''}`
            );
          }
        }
      });
  }, []);

  const catalogInterface = {
    method: InterfaceMethod.VertilinQ,
    type: InterfaceType.CatalogSend,
    authentication: InterfaceAuthentication.None,
    id: props.catalog
      ? props.catalog.interfaces.length > 0
        ? props.catalog.interfaces[0].id
        : ''
      : '',
    settings: {
      [InterfaceSettingsKey.Url]: url,
      [InterfaceSettingsKey.Username]: '',
      [InterfaceSettingsKey.Password]: '',
      [InterfaceSettingsKey.PortalName]: '',
      [InterfaceSettingsKey.Token]: token,
      [InterfaceSettingsKey.AutoUpdate]: autoUpdate,
      [InterfaceSettingsKey.UpdateTimeInterval]: updateTimeInterval,
      [InterfaceSettingsKey.FilterType]: '',
      [InterfaceSettingsKey.UseMultipleFiles]: '',
      [InterfaceSettingsKey.Url2]: '',
      [InterfaceSettingsKey.Username2]: '',
      [InterfaceSettingsKey.Password2]: '',
      [InterfaceSettingsKey.Filter]: ''
    }
  };

  const saveInterface = async () => {
    const apiResult = await saveCatalogInterface(catalogInterface, props.catalogId);
    if (apiResult.IsSuccess) {
      history.push(`/app/my/catalogs/edit/${props.catalogId}`);
    } else {
      props.showNotification(
        i18n.t('notification.savingCatalogInterface') + mapError(apiResult.Errors),
        'error'
      );
    }
  };

  const checkBoxHandler = (e: any) => {
    if (e.key === 'Enter') {
      if (autoUpdate === 'True')
        setAutoUpdate('False');
      else
        setAutoUpdate('True');
    } else return;
  };

  const handleOnChange = (e: any) => {
    if (e.target.checked === true)
      setAutoUpdate('True')
    else
      setAutoUpdate('False')
  }

  return (
    <div>
      <TextField
        value={url}
        onChange={e => setUrl(e.target.value)}
        label="Url"
        placeholder="(RPC endpoint)"
        fullWidth
      />
      <TextField value={token} onChange={e => setToken(e.target.value)} label="Token" fullWidth />
      {
        <XAuthInputs>
          <div>
            <Checkbox
              checked={autoUpdate === 'True'}
              onChange={e => handleOnChange(e)}
              onKeyPress={e => checkBoxHandler(e)}
            />
            <span>Catalog auto update</span>
          </div>
          <div style={{ marginTop: '1rem' }}>Select update interval:</div>
          <FormControl style={{ width: '100%', marginTop: '0.5rem' }}>
            <Select value={updateTimeInterval} onChange={onChangeUpdateIntervalHandler}>
              {Object.keys(InterfaceUpdateInterval).map(key => (
                <MenuItem key={key} value={InterfaceUpdateInterval[key]}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </XAuthInputs>
      }
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2rem' }}>
        <XActionButton onClick={saveInterface} style={{ marginLeft: 'auto' }}>
          Save
        </XActionButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    catalog: state.catalogs.data.detail
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type)),
  getCatalogById: (id: string) => dispatch(catalogActions.getCatalogThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(VertilinQInterfaceMethod);

const XInput = styled.div`
  margin-bottom: 1rem;
`;

const XAuthInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 50%;
`;
