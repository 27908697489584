import { IField } from 'src/components/layout/XForm';
import { InterfaceMethod, InterfaceType } from 'src/models/Organization';
import i18n from '../../i18n'


export function getFtpForm(): IField[] {
  return [
    { title: 'Ftp Url', name: 'FtpAddress', readonly: false, required: true, maxLength: 255 },
    { title: 'Ftp Gebruikersnaam', name: 'FtpUsername', fullWidth: false, required: true, maxLength: 255 },
    { title: 'Ftp Wachtwoord', name: 'FtpPassword', type: 'password', fullWidth: false, required: true, maxLength: 255 }
  ];
}

export function getSftpForm(): IField[] {
  return [
    { title: 'Sftp Url', name: 'SftpAddress', readonly: false, required: true, maxLength: 255 },
    { title: 'Sftp Gebruikersnaam', name: 'SftpUsername', fullWidth: false, required: true, maxLength: 255 },
    { title: 'Sftp Wachtwoord', name: 'SftpPassword', type: 'password', fullWidth: false, required: true, maxLength: 255 }
  ];
}

export function getEmailForm(): IField[] {
  return [
    { title: 'Emailadres', name: 'EmailAddress',  required: true, maxLength: 255 }
  ];
}

export const interfaceMethods = [
  { key: InterfaceMethod.LtxFtp, description: 'Arxis FTP' },
  { key: InterfaceMethod.Ftp, description: 'Jouw FTP' },
  { key: InterfaceMethod.Sftp, description: 'Jouw SFTP' },
  { key: InterfaceMethod.Email, description: 'E-mail' },
  { key: InterfaceMethod.Manual, description: i18n.t('tooltip.Manual')}
];

