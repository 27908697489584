import { BaseReducer } from 'src/helpers/baseReducer';
import { Action } from './actions';
import { ActionTypes, DataState } from './types';

export const initialState: DataState = {
  orderMessages: [],
  errors: undefined,
  loading: false
};

export class DataReducer extends BaseReducer<DataState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: DataState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_ORDERMESSAGES: {
        newState.orderMessages = action.payload.orderMessages || [];
        return newState;
      }
      default:
        return undefined;
    }
  }
}
