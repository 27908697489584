import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper, XPaperLabel, XPaperContainer } from 'src/components/layout/XPaper';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import { XContainer } from 'src/components/layout/XContainer';
import XTable, { TableRow } from 'src/components/layout/XTable';
import { XRowCell, XRow } from 'src/components/layout/XRow';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
import { XFieldDisplay, XKey, XValue } from 'src/pages/myltx/AccountPage';
import { Tooltip } from '@material-ui/core';
import { getOrderView } from 'src/store/data/api';

type OrderLine = {
  value: string;
  description: string;
  quantity: string;
  priceAmount: string;
  basePartPrice: string;
  partPrice: string;
  comment: string;
  reference: string;
};

const SentOrderPreviewPage: FC<RouteComponentProps<{ id: string }>> = props => {
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [recipient, setРecipient] = useState<string>('');
  const [sender, setSender] = useState<string>('');
  const [deliveryAddress, setDeliveryAddress] = useState<string>('');
  const [invoiceAddress, setInvoiceAddress] = useState<string>('');
  const [orderLines, setOrderLines] = useState<OrderLine[]>([]);

  useEffect(() => {
    var token = sessionStorage.getItem('auth_token');
    getOrderView(props.match.params.id, token!).then(res => {
      if (res.IsSuccess) {
        setOrderNumber(res.Data.orderNumber);
        setРecipient(res.Data.recipient);
        setSender(res.Data.sender);
        setDeliveryAddress(res.Data.deliveryAddress);
        setInvoiceAddress(res.Data.invoiceAddress);
        setOrderLines(res.Data.orderLines);
      }
    });
  }, []);

  return (
    <ErrorBoundary>
      <XPageContent>
        <XPaper>
          <XPaperHeader header="" history={props.history}></XPaperHeader>
          <XContainer>
            <XRow>
              <XRowCell>
                <XFieldDisplay style={{ marginRight: '30px' }}>
                  <XKey>Order number:</XKey>
                  <XValue>{orderNumber}</XValue>
                </XFieldDisplay>
                <XFieldDisplay style={{ marginRight: '30px' }}>
                  <XKey>Recipient:</XKey>
                  <XValue>{recipient}</XValue>
                </XFieldDisplay>
                <XFieldDisplay style={{ marginRight: '30px' }}>
                  <XKey>Sender:</XKey>
                  <XValue>{sender}</XValue>
                </XFieldDisplay>
              </XRowCell>

              <XRowCell>
                <XFieldDisplay>
                  <XKey>Delivery Address:</XKey>
                  <XValue>{deliveryAddress}</XValue>
                </XFieldDisplay>
                <XFieldDisplay>
                  <XKey>Invoice Address:</XKey>
                  <XValue>{invoiceAddress}</XValue>
                </XFieldDisplay>
                <XFieldDisplay>
                  <XKey>Order date:</XKey>
                  <XValue>{}</XValue>
                </XFieldDisplay>
              </XRowCell>
            </XRow>
          </XContainer>
          <XContainer>
            <XPaperContainer>
              <XContainer>
                <XPaperLabel>Orderlines:</XPaperLabel>
              </XContainer>
            </XPaperContainer>
          </XContainer>
          <XContainer>
            <XTable
              headers={[
                'Item Nr:',
                'EAN',
                'Description',
                'Quantity',
                'Price',
                'Comment',
                'Reference'
              ]}>
              {orderLines.map((line, idx) => (
                <TableRow key={idx}>
                  <StyledCell>{}</StyledCell>
                  <StyledCell>{}</StyledCell>
                  <StyledCell>{line.description}</StyledCell>
                  <StyledCell>{line.quantity} pieces</StyledCell>
                  <StyledCell>{line.basePartPrice}</StyledCell>
                  <StyledCell>
                    <Tooltip title="Tafel Verona, blad Halifax, HA84 eigen natuur, onderstel zwart">
                      <span>{line.comment}</span>
                    </Tooltip>
                  </StyledCell>
                  <StyledCell>{line.reference}</StyledCell>
                </TableRow>
              ))}
            </XTable>
          </XContainer>
        </XPaper>
      </XPageContent>
    </ErrorBoundary>
  );
};

export default SentOrderPreviewPage;

const StyledCell = styled(XRowCell)`
  padding-top: 5px;
  margin: 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
