import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import XBadge from './XBadge';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import i18n from 'src/i18n';
import SettingsIcon from 'src/style/images/icons/setting.svg';
import UserPlusIcon from 'src/style/images/icons/user-plus.svg';
import MessageIcon from 'src/style/images/icons/message.svg';
import ClipBoaardIcon from 'src/style/images/icons/clipboard-notes.svg';

export interface ILink {
  name: string;
  route: string;
  subRoutes?: ILink[];
  style?: any;
  badge?: number;
  defaultActive?: boolean;
  dividerTop?: boolean;
}

type OwnProps = {
  routes: ILink[];
  callback: any;
};

type AllProps = OwnProps & RouteComponentProps;

const XNavigation: React.FC<AllProps> = props => {
  const getIcon = (path: string, active: boolean) => {
    switch (path) {
      case i18n.t('myArxis.Overview'):
        return <Icon active={active} image={ClipBoaardIcon} />;
      case i18n.t('myArxis.Traffic'):
        return <Icon active={active} image={MessageIcon} />;
      case i18n.t('myArxis.Settings'):
        return <Icon active={active} image={SettingsIcon} />;
      case i18n.t('myArxis.Requests'):
        return <Icon active={active} image={UserPlusIcon} />;
    }
    return null;
  };

  let activeRoute: ILink | undefined;
  props.routes.forEach(route => {
    if (props.location.pathname.startsWith(route.route)) {
      activeRoute = route;
    }
    if (route.subRoutes) {
      route.subRoutes.forEach(subRoute => {
        if (props.location.pathname.startsWith(subRoute.route)) activeRoute = subRoute;
      });
    }
  });

  if (!activeRoute) activeRoute = props.routes.find(r => r.defaultActive);

  return (
    <NavigationContainer>
      <NavigationContent>
        {props.routes.map((route, index) => (
          <div key={route.name}>
            {route.dividerTop && <hr />}
            <NavigationBar
              to={route.route}
              style={route.style}
              activeRoute={route.name === activeRoute?.name}>
              {route.name}
              {route.badge !== undefined && route.badge > 0 && (
                <XBadge
                  color="#2d80c2"
                  place={{ top: 10, right: 90 }}
                  count={route.badge as number}></XBadge>
              )}
              {route.name === activeRoute?.name && <XSideArrow />}
            </NavigationBar>
            {route.subRoutes &&
              route.subRoutes.map((subRoute, subIndex) => (
                <NavigationSubBarContainer to={subRoute.route} key={subRoute.name}>
                  {getIcon(subRoute.name, subRoute.route === activeRoute?.route)}

                  <NavigationSubBar
                    to={subRoute.route}
                    style={subRoute.style}
                    activeRoute={subRoute.route === activeRoute?.route}>
                    {subRoute.name}
                    {subRoute.badge !== undefined && subRoute.badge > 0 && (
                      <XBadge
                        color="#2d80c2"
                        place={{ top: 0, right: -30 }}
                        count={subRoute.badge as number}></XBadge>
                    )}
                  </NavigationSubBar>
                  {subRoute.route === activeRoute?.route && <XSideArrow />}
                </NavigationSubBarContainer>
              ))}
          </div>
        ))}
      </NavigationContent>
    </NavigationContainer>
  );
};

export default withRouter(XNavigation);

const NavigationContainer = styled('div')({
  width: '100%',
  margin: 'auto',
  '& a': {
    textDecoration: 'none'
  }
});

const NavigationContent = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

type NavigationBarProps = {
  activeRoute?: boolean;
};

const NavigationSubBarContainer = styled(Link)({
  display: 'flex',
  alignContent: 'center',
  padding: '10px 50px',
  position: 'relative'
});

export const NavigationSubBar = withTheme(
  styled(Link, { shouldForwardProp: prop => prop !== 'activeRoute' })<NavigationBarProps>`
    display: block;
    text-align: left;
    font-weight: bold;
    font-size: 13px;
    margin-left: 4px;
    text-transform: none;
    border-radius: 4px;
    position: relative;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    color: ${props => (props.activeRoute ? 'rgb(45, 128, 194)' : '#737373')};
  `
);

export const NavigationBar = withTheme(
  styled(Link, { shouldForwardProp: prop => prop !== 'activeRoute' })<NavigationBarProps>`
    display: block;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    padding: 14px 35px;
    text-transform: none;
    border-radius: 4px;
    position: relative;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    color: ${props => (props.activeRoute ? 'rgb(45, 128, 194)' : '#737373')};
  `
);

const XSideArrow = styled(ArrowLeftIcon)({
  color: '#2d80c2',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  right: '-5px'
});

const Icon = styled('div')<any>({}, (props: any) => ({
  backgroundColor: props.active ? '#2d80c2' : '#737373',
  mask: 'url(' + props.image + ') no-repeat center',
  width: '15px',
  height: '15px',
  margin: 'auto 0'
}));
