import { BaseReducer } from 'src/helpers/baseReducer';
import { Action } from './actions';
import { ActionTypes, SoftwareState } from './types';

export const initialState: SoftwareState = {
  all: [],
  errors: undefined,
  loading: false
};

export class SoftwareReducer extends BaseReducer<SoftwareState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: SoftwareState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_SOFTWARE: {
        if (action.payload.software) newState.all = action.payload.software;
        return newState;
      }
      default:
        return newState;
    }
  }
}
