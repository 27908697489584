import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import styled from '@emotion/styled';
import XLTXInformation from 'src/components/domain/XLTXInformation';
import { XPaper } from 'src/components/layout/XPaper';
import { ApplicationState } from 'src/store';
import Logo from '../../style/images/logos/main.svg';
import { LeftSide, LeftSideInner, LTXLogo, RightSide } from '../login/LoginPage.style';

import SuccesImage from 'src/style/images/envelope.svg';
import { Translation } from 'react-i18next';

type OwnProps = {};

type State = {};

type DispatchProps = {};

type AllProps = OwnProps & DispatchProps & RouteComponentProps<{ emailCode: string }>;

class ConfirmationEmailSentPage extends React.Component<AllProps, State> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <LogoutLayout>
        <LeftSide>
          <LeftSideInner>
            <Translation>
              {t => (
                <LogoutInner>
                  <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                    <img src={SuccesImage} width="300px" />
                  </div>
                  {t('register.confirmationEmailSent')}
                </LogoutInner>
              )}
            </Translation>
          </LeftSideInner>
        </LeftSide>
        <RightSide>
          <XLTXInformation />
        </RightSide>

        <LTXLogo src={Logo} />
      </LogoutLayout>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (): DispatchProps => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationEmailSentPage);

const LogoutLayout = styled.div`
  height: 100vh;
  overflow: hidden;
  background: rgb(45, 128, 194);
  display: flex;
`;

const LogoutInner = styled(XPaper)`
  padding: 30px;
  text-align: center;
  width: 500px;
  min-height: 40px;
`;
