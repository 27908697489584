import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const AppLoadingPage: React.FC = () => {
  return (
    <XFallBackPage>
      <div className="logo">
        <img src={require('./images/svg/arxisdef.svg')} alt="Arxis" title="Arxis" />
      </div>
    </XFallBackPage>
  );
};

export default AppLoadingPage;

const pulse = keyframes`{
	0% {	
    transform: scale(1);}
	
	}
	
	50% {
		transform: scale(1.05);
	
	}
	
	100% {
		transform: scale(1);
	
	}
}
`;

const XFallBackPage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  background-color: #2d80c2;

  & .logo {
    width: 12rem;
    animation: ${pulse} 2s infinite;
    @media (min-width: 768) {
      width: 6rem;
    }
  }
`;
