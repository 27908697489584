import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Step, StepButton, StepLabel, Stepper } from '@material-ui/core';
import { XContainer } from 'src/components/layout/XContainer';
import { XPage } from 'src/components/layout/XPage';
import {
  XPaper,
  XPaperCenter,
  XPaperContainer,
  XPaperLabel,
  XPaperTitle,
  XPaperTitleLarge
} from 'src/components/layout/XPaper';
import XPageRouter from 'src/components/shared/XPageRouter';
import { PageRoute } from 'src/models/Route';
import { RegisterAccountFormData } from '../../store/accounts/types';
import RegisterAccountPage from './RegisterAccountPage';
import AdditionalInfoPage from './RegisterAdditionalInformationPage';
import RegisterOrganizationPage from './RegisterOrganizationPage';
import { Translation } from 'react-i18next';
import i18n from 'src/i18n';

const registerPath = '/register';

export const AccountRoute: PageRoute = {
  path: `${registerPath}`,
  exact: true,
  component: RegisterAccountPage,
  title: 'Account'
};

export const OrganizationRoute: PageRoute = {
  path: `${registerPath}/organization`,
  exact: true,
  component: RegisterOrganizationPage,
  title: i18n.t('myArxis.Organization')
};

export const AdditionalInfoRoute: PageRoute = {
  path: `${registerPath}/additional`,
  exact: true,
  component: AdditionalInfoPage,
  title: 'Extra'
};

const Routes: PageRoute[] = [AccountRoute, OrganizationRoute, AdditionalInfoRoute];

type State = {};
type StateProps = { data: RegisterAccountFormData };
type AllProps = RouteComponentProps & StateProps;

class RegisterRouter extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
  }

  public render() {
    let active = 0;
    for (let i = 0; i < Routes.length; i++) {
      if (this.props.location.pathname === Routes[i].path) {
        active = i;
      }
    }
    return (
      <XPage>
        <XPaperCenter>
          <XContainer style={{ overflowY: 'auto' }}>
            <Translation>{t => <XPaperTitle>{t('register.register')}</XPaperTitle>}</Translation>

            <XContainer style={{ padding: '30px 15px 15px 15px' }}>
              <XPaperContainer>
                <Stepper
                  activeStep={active}
                  style={{ backgroundColor: 'transparent' }}
                  alternativeLabel={true}>
                  {Routes.map((route, index) => {
                    let title: any = route.title;
                    if (index === active) {
                      title = <b>{route.title}</b>;
                    }
                    return (
                      <Step key={index}>
                        <StepButton
                          active={index === active}
                          completed={index < active}
                          onClick={() => {
                            this.props.history.push(route.path);
                          }}>
                          <StepLabel>{title}</StepLabel>
                        </StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
              </XPaperContainer>
            </XContainer>
            <XContainer>
              <CSSTransition key={this.props.location.key} classNames="slide" timeout={1000}>
                <XPageRouter routes={Routes} {...this.props} />
              </CSSTransition>
            </XContainer>
          </XContainer>
        </XPaperCenter>
      </XPage>
    );
  }
}

export default RegisterRouter;
