import { AnyAction, Dispatch } from 'redux';
import { commandAction, requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError, ApiResult } from 'src/helpers/apiClient';
import { EditOrganizationData } from 'src/models/domain/EditOrganization';
import { MyOrganizationModel, OrderInterfaceSettingsModel, Organization, ConnectionIdentifier, OrganizationAccessTokenModel, OrganizationIdentifier, OrganizationManageModel, OrganizationModel } from 'src/models/Organization';
import { OrganizationFormData, RegisterAccountFormData } from '../accounts/types';
import { addOrganization,
        addOrganizationConnection,
        addConnectionIdentifier,
        addOrganizationIdentifier,
        deleteConnection, 
        deleteOrganization, 
        deleteConnectionIdentifier, 
        deleteOrganizationIdentifier, 
        downloadCatalog, 
        editOrganization, 
        getManageOrganization, 
        getMyOrganization, 
        getOrganization, 
        getOrganizationAccessToken,
        getConnectionIdentifiers, 
        getOrganizationIdentifiers, 
        listOrganizationConnections, 
        listOrganizations, 
        listUnconfiguredFtpOrganizations, 
        saveOrderInterfaceSettings,
        validateFtpAccess,
        validateSftpAccess,
        deleteOrderInterface,
        deleteOrganizationAccessToken
} from './api';
import { ActionTypes } from './types';

type FetchStatus = 'request' | 'success' | 'failure';

export type GetOrganizationAction = {
  type: ActionTypes.GET_ORGANIZATION;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { organization?: OrganizationModel };
};

export type GetOrganizationAccessTokenAction = {
  type: ActionTypes.GET_ORGANIZATION_ACCESS_TOKEN;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {accessToken?: OrganizationAccessTokenModel};
};

export type DeleteOrganizationAccessTokenAction = { 
  type: ActionTypes.DELETE_ORGANIZATION_ACCESS_TOKEN;
  status: FetchStatus;
  errors?: ApiError[];
}

export type GetManageOrganizationAction = {
  type: ActionTypes.GET_MANAGE_ORGANIZATION;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { organization?: OrganizationManageModel };
};

export type GetMyOrganizationAction = {
  type: ActionTypes.GET_MY_ORGANIZATION;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { myOrganization?: MyOrganizationModel };
};

export type GetOrganizationIdentifiersAction = {
  type: ActionTypes.GET_ORGANIZATION_IDENTIFIERS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { identifiers?: OrganizationIdentifier[] };
};

export type GetOrganizationsAction = {
  type: ActionTypes.GET_ORGANIZATIONS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { organizations?: Organization[] };
};

export type GetOrganizationConnectionsAction = {
  type: ActionTypes.GET_ORGANIZATION_CONNECTIONS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { organizations?: Organization[] };
};

export type GetUnconfiguredFtpOrganizationsAction = {
  type: ActionTypes.GET_UNCONFIGURED_FTP_ORGANIZATIONS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { organizations?: Organization[] };
};

export type AddOrganizationAction = {
  type: ActionTypes.ADD_ORGANIZATION;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { formData?: RegisterAccountFormData };
};


export type SaveOrderInterfaceSettings = {
  type: ActionTypes.SAVE_ORDER_INTERFACE_SETTINGS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { data?: OrderInterfaceSettingsModel };
};

export type DeleteOrderInterface = {
  type: ActionTypes.DELETE_ORDER_INTERFACE;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { data?: boolean };
};

export type StoreFormAction = {
  type: ActionTypes.STORE_FORM;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { data: RegisterAccountFormData };
};

export type GetConnectionIdentifiersAction = {
  type: ActionTypes.GET_CONNECTION_IDENTIFIERS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { connectionIdentifiers?: ConnectionIdentifier[] };
};

const getUnconfiguredFtpOrganizationsAction = (
  status: FetchStatus,
  data?: Organization[],
  errors?: ApiError[]
): GetUnconfiguredFtpOrganizationsAction => ({
  type: ActionTypes.GET_UNCONFIGURED_FTP_ORGANIZATIONS,
  status,
  errors,
  payload: {
    organizations: data
  }
});

const getMyOrganizationAction = (
  status: FetchStatus,
  data?: MyOrganizationModel,
  errors?: ApiError[]
): GetMyOrganizationAction => ({
  type: ActionTypes.GET_MY_ORGANIZATION,
  status,
  errors,
  payload: {
    myOrganization: data
  }
});

const getOrganizationIdentifiersAction = (
  status: FetchStatus,
  identifiers?: OrganizationIdentifier[],
  errors?: ApiError[]
): GetOrganizationIdentifiersAction => ({
  type: ActionTypes.GET_ORGANIZATION_IDENTIFIERS,
  status,
  errors,
  payload: {
    identifiers
  }
});

const getOrganizationAction = (
  status: FetchStatus,
  data?: OrganizationModel,
  errors?: ApiError[]
): GetOrganizationAction => ({
  type: ActionTypes.GET_ORGANIZATION,
  status,
  errors,
  payload: {
    organization: data
  }
});

const getOrganizationAccessTokenAction = (
  status: FetchStatus,
  data?: OrganizationAccessTokenModel,
  errors?: ApiError[]
): GetOrganizationAccessTokenAction => ({
  type: ActionTypes.GET_ORGANIZATION_ACCESS_TOKEN,
  status,
  errors,
  payload: {accessToken: data}
});

const getManageOrganizationAction = (
  status: FetchStatus,
  data?: OrganizationManageModel,
  errors?: ApiError[]
): GetManageOrganizationAction => ({
  type: ActionTypes.GET_MANAGE_ORGANIZATION,
  status,
  errors,
  payload: {
    organization: data
  }
});

const getOrganizationsAction = (
  status: FetchStatus,
  data?: Organization[],
  errors?: ApiError[]
): GetOrganizationsAction => ({
  type: ActionTypes.GET_ORGANIZATIONS,
  status,
  errors,
  payload: {
    organizations: data
  }
});

const getOrganizationConnectionsAction = (
  status: FetchStatus,
  data?: Organization[],
  errors?: ApiError[]
): GetOrganizationConnectionsAction => ({
  type: ActionTypes.GET_ORGANIZATION_CONNECTIONS,
  status,
  errors,
  payload: {
    organizations: data
  }
});

const getConnectionIdentifiersAction = (
  status: FetchStatus,
  connectionIdentifiers?: ConnectionIdentifier[],
  errors?: ApiError[]
): GetConnectionIdentifiersAction => ({
  type: ActionTypes.GET_CONNECTION_IDENTIFIERS,
  status,
  errors,
  payload: {
    connectionIdentifiers
  }
});

export const addOrganizationAction = (
  status: FetchStatus,
  data?: RegisterAccountFormData,
  errors?: ApiError[]
): AddOrganizationAction => ({
  type: ActionTypes.ADD_ORGANIZATION,
  status,
  errors,
  payload: {
    formData: data
  }
});

export const storeFormAction = (data: RegisterAccountFormData) => ({
  type: ActionTypes.STORE_FORM,
  errors: [],
  status: 'success',
  payload: { data }
});

export const getMyOrganizationThunk = () => {
  return requestApiThunk(getMyOrganizationAction, getMyOrganization);
};

export const getOrganizationIdentifiersThunk = () => {
  return requestApiThunk(getOrganizationIdentifiersAction, getOrganizationIdentifiers);
};

export const getOrganizationThunk = (organizationId: string) => {
  return requestApiThunk(getOrganizationAction, () => getOrganization(organizationId));
};

export const getOrganizationAccessTokenThunk = (organizationId: string, generateNew: boolean) => { 
  return requestApiThunk(getOrganizationAccessTokenAction, () => getOrganizationAccessToken(organizationId, generateNew));
}

export const deleteOrganizationAccessTokenThunk = (organizationId: string) => { 
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.DELETE_ORGANIZATION_ACCESS_TOKEN, errors),
    () => deleteOrganizationAccessToken(organizationId)
  );
}

export const getManageOrganizationThunk = (organizationId: string) => {
  return requestApiThunk(getManageOrganizationAction, () => getManageOrganization(organizationId));
};

export const deleteOrganizationThunk = (id: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.DELETE_ORGANIZATION, errors),
    () => deleteOrganization(id)
  );
};

export const downloadCatalogThunk = (
  catalogId: string,
  catalogVersionId: string,
  fileFormat: string,
  fileFormatVersion: string
) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.CONSUME_DATA_CONNECTION, errors),
    () => downloadCatalog(catalogId, catalogVersionId, fileFormat, fileFormatVersion)
  );
};

export const getOrganizationsThunk = () => {
  return requestApiThunk(getOrganizationsAction, listOrganizations);
};

export const getOrganizationConnectionsThunk = (id?: string) => {
  return requestApiThunk(getOrganizationConnectionsAction, () => listOrganizationConnections(id));
};

export const getUnconfiguredFtpOrganizationsThunk = () => {
  return requestApiThunk(getUnconfiguredFtpOrganizationsAction, () => listUnconfiguredFtpOrganizations());
};

export const editOrganizationThunk = (organization: Organization) => {
  const editData: EditOrganizationData = {
    OrganizationId: organization.id,
    Name: organization.name,
    CompanyEmail: organization.email,
    Description: organization.description,
    Image: organization.image,
    Street: organization.street,
    HouseNumber: organization.houseNumber,
    PostalCode: organization.postalCode,
    City: organization.city,
    Country: organization.country,
    TelephoneNumber: organization.telephoneNumber,
    Website: organization.website,
    MaturityStatus: organization.maturityStatus,
    SoftwareId: organization.softwareId,
    subscriptionId: organization.subscriptionId,
    SupportsCatalogData: organization.supportsCatalogData,
    SupportsOrderData: organization.supportsOrderData,
    SupportsStockData: organization.supportsStockData,
    AccountStatus: organization.accountStatus
    // IBAN: organization.iban,
    // BtwNumber: organization.btwNumber
  };

  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.EDIT_ORGANIZATION, errors),
    () => editOrganization(editData)
  );
};

export const addOrganizationThunk = (formData: RegisterAccountFormData) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.ADD_ORGANIZATION, errors),
    () => addOrganization(formData)
  );
};

export const saveOrderInterfaceSettingsThunk = (organizationId: string, settings: OrderInterfaceSettingsModel) => {
  return requestApiThunk((status: FetchStatus, data?: any, errors?: ApiError[]) => commandAction(status, ActionTypes.SAVE_ORDER_INTERFACE_SETTINGS, errors), 
  () => saveOrderInterfaceSettings(organizationId, settings),
  getMyOrganizationThunk());
}

export const deleteOrderInterfaceThunk = (interfaceId: string) => {
  return requestApiThunk((status: FetchStatus, data?: any, errors?: ApiError[]) => commandAction(status, ActionTypes.DELETE_ORDER_INTERFACE, errors),
    () => deleteOrderInterface(interfaceId));
}

export const deleteConnectionThunk = (organizationId: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.DELETE_ORGANIZATION_CONNECTION, errors),
    () => deleteConnection(organizationId),
    getOrganizationConnectionsThunk()
  );
};

export const deleteOrganizationIdentifierThunk = (identifierId: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.DELETE_ORGANIZATION_IDENTIFIER, errors),
    () => deleteOrganizationIdentifier(identifierId),
    getOrganizationIdentifiersThunk()
  );
};

export const addOrganizationIdentifierThunk = (
  type: string,
  value: string,
  organizationId?: string
) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.ADD_ORGANIZATION_IDENTIFIER, errors),
    () => addOrganizationIdentifier(type, value, organizationId),
    getOrganizationIdentifiersThunk()
  );
};


export const addOrganizationConnectionThunk = (
  sourceOrganizationId: string,
  targetOrganizationId: string
) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.ADD_ORGANIZATION_IDENTIFIER, errors),
    () => addOrganizationConnection(sourceOrganizationId , targetOrganizationId)
  );
};


export const validateFtpAccessThunk = (
  url: string,
  user: string,
  pass: string
) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.VALIDATE_FTP_ACCESS, errors),
    () => validateFtpAccess(url, user, pass)
  );
};

export const validateSftpAccessThunk = (
  url: string,
  user: string,
  pass: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.VALIDATE_SFTP_ACCESS, errors),
    () => validateSftpAccess(url, user, pass)
  );
};

export const getConnectionIdentifiersThunk = (organizationId: string) => {
  return requestApiThunk(getConnectionIdentifiersAction, () => getConnectionIdentifiers(organizationId));
};

export const addConnectionIdentifierThunk = (
  organizationConnectionId: string,
  value: string,
  softwareId?: string
) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.ADD_CONNECTION_IDENTIFIER, errors),
    () => addConnectionIdentifier(organizationConnectionId, value, softwareId)
  );
};

export const deleteConnectionIdentifierThunk = (identifierId: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
    commandAction(status, ActionTypes.DELETE_CONNECTION_IDENTIFIER, errors),
    () => deleteConnectionIdentifier(identifierId)
  )
};

export type Action = GetMyOrganizationAction | GetOrganizationAction | GetManageOrganizationAction | GetUnconfiguredFtpOrganizationsAction |
  GetOrganizationsAction | GetOrganizationConnectionsAction | StoreFormAction | AddOrganizationAction | GetOrganizationIdentifiersAction |
  GetConnectionIdentifiersAction | DeleteOrderInterface | GetOrganizationAccessTokenAction | DeleteOrganizationAccessTokenAction;
