import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonGroup, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import styled from '@emotion/styled';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Translation } from 'react-i18next';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: grey[400]
    }
  }
});

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: '20px'
    }
  })
);

type OwnProps = { in: string; out: string };

type AllProps = OwnProps;

const XInboxOutboxButtons: FC<AllProps> = props => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div
      style={{
        height: '3rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <ThemeProvider theme={theme}>
        <ButtonGroup disableElevation>
          <XSwitchButton
            className={classes.root}
            variant="contained"
            color={history.location.pathname === props.in ? 'primary' : 'secondary'}
            onClick={() => history.push(props.in)}>
            <Translation>{t => t('traffic.Receive')}</Translation>
          </XSwitchButton>
          <XSwitchButton
            className={classes.root}
            variant="contained"
            color={history.location.pathname === props.out ? 'primary' : 'secondary'}
            onClick={() => history.push(props.out)}>
            <Translation>{t => t('traffic.Sent')}</Translation>
          </XSwitchButton>
        </ButtonGroup>
      </ThemeProvider>
    </div>
  );
};

export default XInboxOutboxButtons;

const XSwitchButton = styled(Button)`
  width: 180px;
`;
