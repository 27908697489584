import { ConnectionIdentifierKey, OrderInterfaceSettingsModel, OrganizationInterface } from 'src/models/Organization';
import { OrganizationFormData, RegisterAccountFormData } from '../accounts/types';
import { apiClient, ApiResult } from './../../helpers/apiClient';
import { EditOrganizationData } from './../../models/domain/EditOrganization';

export const getOrganization = async (organizationId: string): Promise<ApiResult> => {
  const parameters = {
    OrganizationId: organizationId
  };
  return await apiClient.postQueryAsync('GetOrganization', parameters);
};

export const getManageOrganization = async (organizationId: string): Promise<ApiResult> => {
  const parameters = {
    OrganizationId: organizationId
  };
  return await apiClient.postQueryAsync('GetManageOrganization', parameters);
};

export const getMyOrganization = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('GetMyOrganization', {});
};

export const getOrganizationAccessToken = async (organizationId: string, generateNew: boolean):Promise<ApiResult> => { 
  
  const parmeters = { 
    OrganizationId: organizationId,
    GenerateNew: generateNew
  };

  return await apiClient.postQueryAsync('GetOrganizationAccessToken', parmeters);
}

export const getOrganizationIdentifiers = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('GetOrganizationIdentifiers', {});
};

export const getOrganizationInterface = async (organizationInterfaceId: string): Promise<ApiResult<OrganizationInterface>> => {
  return await apiClient.postQueryAsync('GetOrganizationInterface', {});
};

export const listOrganizations = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListOrganizations', {});
};

export const listOrganizationConnections = async (id?: string): Promise<ApiResult> => {
  const parameters = {
    OrganizationId: id
  };
  return await apiClient.postQueryAsync('ListOrganizationConnections', parameters);
};

export const saveOrderInterfaceSettings = async (organizationId: string, data: OrderInterfaceSettingsModel): Promise<ApiResult> => {
  const command = {
    CommandName: "SaveOrderInterfaceSettings",
    OrganizationId: organizationId,
    Method: data.method,
    OrderEmailAddress: data.emailAddress,
    OrderFtpUsername: data.ftpUsername,
    OrderFtpPassword: data.ftpPassword,
  }
  return await apiClient.postCommandAsync(command);
};

export const deleteOrderInterface = async (interfaceId: string): Promise<ApiResult> => {
  const command = {
    CommandName: "DeleteOrderInterface",
    InterfaceId: interfaceId
  }
  return await apiClient.postCommandAsync(command);
};

export const deleteOrganizationAccessToken = async (organizationId: string): Promise<ApiResult> => {
  const command = {
    CommandName: "DeleteOrganizationAccessToken",
    OrganizationId: organizationId
  }
  return await apiClient.postCommandAsync(command);
}; 


export const saveOrganizationInterface = async (organizationInterface: OrganizationInterface, organizationId?: string | undefined): Promise<ApiResult<OrganizationInterface>> => {
  const command = {
    CommandName: "SaveOrganizationInterface",
    OrganizationInterfaceId: organizationInterface.id,
    InterfaceType: organizationInterface.type,
    InterfaceMethod: organizationInterface.method,
    Settings: organizationInterface.settings,
    OrganizationId: organizationId
  };

  return await apiClient.postCommandAsync(command);
};

export const addOrganization = async (data: RegisterAccountFormData): Promise<ApiResult> => {
  const command = {
    CommandName: 'CreateOrganization',
    Name: data.name,
    OrganizationType: data.organizationType,
    Description: data.description,
    CompanyEmail: data.companyEmail,
    Street: data.street,
    HouseNumber: data.houseNumber,
    PostalCode: data.postalCode,
    City: data.city,
    TelephoneNumber: data.telephoneNumber,
    Website: data.website,
    MaturityStatus: data.maturityStatus,
    // IBAN: data.iban,
    // BtwNumber: data.btwNumber,
    Country: data.country,

    email: data.email,
    password: data.password,
    firstName: data.firstName,
    lastName: data.lastName
  };

  return await apiClient.postCommandAsync(command, [data.image]);
};

export const editOrganization = async (data: EditOrganizationData): Promise<ApiResult> => {
  const command = {
    CommandName: 'EditOrganization',
    ...data
  };

  command.Image = undefined;
  return await apiClient.postCommandAsync(command, [data.Image]);
};

export const downloadCatalog = async (
  catalogId: string,
  catalogVersionId: string,
  fileFormat: string,
  fileFormatVersion: string
): Promise<ApiResult> => {
  const command = {
    CommandName: 'DownloadCatalog',
    CatalogId: catalogId,
    CatalogVersionId: catalogVersionId,
    FileFormatId: fileFormat,
    FileFormatVersionId: fileFormatVersion
  };

  return await apiClient.postCommandAsync(command);
};

export const deleteOrganization = async (id: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'DeleteOrganization',
    Id: id
  };

  return await apiClient.postCommandAsync(command);
};

export const deleteConnection = async (organizationId: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'DeleteConnection',
    OrganizationId: organizationId
  };

  return await apiClient.postCommandAsync(command);
};

export const deleteOrganizationIdentifier = async (identifierId: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'DeleteOrganizationIdentifier',
    IdentifierId: identifierId
  };

  return await apiClient.postCommandAsync(command);
};

export const addOrganizationIdentifier = async (
  type: string,
  value: string,
  organizationId?: string
): Promise<ApiResult> => {
  const command = {
    CommandName: 'AddOrganizationIdentifier',
    IdentifierType: type,
    Value: value,
    OrganizationId: organizationId
  };

  return await apiClient.postCommandAsync(command);
};

export const listUnconfiguredFtpOrganizations = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListOrganizationsUnconfiguredFtp');
};


export const addOrganizationConnection = async (
  sourceOrganizationId: string,
  targetOrganizationId: string
): Promise<ApiResult> => {
  const command = {
    CommandName: 'AddOrganizationConnection',
    SourceOrganizationId: sourceOrganizationId,
    TargetOrganizationId: targetOrganizationId
  };

  return await apiClient.postCommandAsync(command);
};


export const validateFtpAccess = async (
  url: string,
  user: string,
  pass: string
): Promise<ApiResult> => {
  const command = {
    CommandName: 'ValidateFtpAccess',
    Url: url,
    Username: user,
    Password: pass
  };

  return await apiClient.postCommandAsync(command);
};

export const validateSftpAccess = async (
  url: string,
  user: string,
  pass: string
): Promise<ApiResult> => {
  const command = {
    CommandName: 'ValidateSftpAccess',
    Url: url,
    Username: user,
    Password: pass
  };

  return await apiClient.postCommandAsync(command);
};

export const addConnectionIdentifier = async (
  organizationConnectionId: string,
  value: string,
  softwareId?: string
): Promise<ApiResult> => {
  const command = {
    CommandName: 'AddConnectionIdentifier',
    OrganizationConnectionId: organizationConnectionId,
    Value: value,
    SoftwareId: softwareId,
    Key: ConnectionIdentifierKey.SupplierAssignedNumber
  };

  return await apiClient.postCommandAsync(command);
};

export const deleteConnectionIdentifier = async (identifierId: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'DeleteConnectionIdentifier',
    Id: identifierId
  };

  return await apiClient.postCommandAsync(command);
};


export const getConnectionIdentifiers = async (organizationId?: string): Promise<ApiResult> => {
  const parameters = {
    OrganizationId: organizationId
  };
  
  return await apiClient.postQueryAsync('GetConnectionIdentifiers', parameters);
};
