import { OrganizationGroup } from 'src/models/OrganizationGroup';

export enum ActionTypes {
  GET_ORGANIZATION_GROUPS = '[organization group] GET_ORGANIZATION_GROUPS'
}

export interface OrganizationGroupsState {
  loading: boolean;
  errors?: string;
  all: OrganizationGroup[];
}
