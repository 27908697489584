import { OrderMessage } from 'src/models/OrderMessage';

export enum ActionTypes {
  GET_ORDERMESSAGES = '[data] GET_ORDERMESSAGES'
}

export interface DataState {
  loading: boolean;
  errors?: string;
  orderMessages: OrderMessage[];
}
