import styled from '@emotion/styled';
import { Select, Button } from '@material-ui/core';

const ActionButton = styled('a')(`
position: relative;
display: inline-flex;
align-items: center;
justify-content: center;
min-width: 3.6rem;
margin: 0;
padding: 4px 18px;
background: linear-gradient(180deg,#fff,#f9fafb);
border: .1rem solid var(--p-border,#c4cdd5);
box-shadow: 0 1px 0 0 rgba(22,29,37,.05);
border-radius: 3px;
color: #212b36;
text-align: center;
cursor: pointer;
line-height: 20px;
-webkit-user-select: none;
font-weight: 600!important;
user-select: none;
text-decoration: none;
transition-property: background,border,box-shadow;
transition-duration: var(--p-override-none,.2s);
transition-timing-function: var(--p-override-none,cubic-bezier(.64,0,.35,1));
-webkit-tap-highlight-color: transparent;
font-size: 13px;
height: 32px;
`);

export const XActionButton = styled(ActionButton)(`
position: relative;
background: rgb(231, 33, 123);
border-width: 0;
border-color: transparent;
box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.2) 0px -1px 0px 0px inset;
color: white!important;

&:hover {
  background: rgb(193, 31, 105);
  border-color: transparent;
}
`);

export const XSecActionButton = styled(ActionButton)(`
position: relative;
border-width: 1;
border-color: rgb(186, 191, 196);
color: rgb(32, 34, 35)!important;
`);

export const XActionSelect = styled(Select)(`
border-radius: 3px;
height: 32px!important;
border: .1rem solid rgb(186, 191, 196);
font-weight: 600!important;
color: #212b36!important;
width: inherit!important;

&::before, 
&::after  {
  display: none
}

& .MuiSelect-select.MuiSelect-select {
  padding: 4px 18px;
  padding-right: 40px;
}

& .MuiSelect-icon {
  right: 10px;
}
`);

export const XDisabledButton = styled(ActionButton)(`
background-color: lightgray;
pointer-events: none;
`);

export const XSubmitButton = styled(Button)(`
position: relative;
display: inline-flex;
align-items: center;
justify-content: center;
min-width: 3.6rem;
margin: 0;
padding: 4px 18px;
border: .1rem solid var(--p-border,#c4cdd5);
border-radius: 3px;
text-align: center;
cursor: pointer;
line-height: 20px;
-webkit-user-select: none;
font-weight: 600!important;
user-select: none;
text-decoration: none;
transition-property: background,border,box-shadow;
transition-duration: var(--p-override-none,.2s);
transition-timing-function: var(--p-override-none,cubic-bezier(.64,0,.35,1));
-webkit-tap-highlight-color: transparent;
font-size: 13px;
height: 32px;
background: rgb(231, 33, 123);
border-width: 0;
border-color: transparent;
box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.2) 0px -1px 0px 0px inset;
color: white!important;
text-transform: unset !important;
&:hover {
  background: rgb(193, 31, 105);
  border-color: transparent;
}
`);
