import { AnyAction, Dispatch } from 'redux';

import { commandAction, requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError, ApiResult } from 'src/helpers/apiClient';
import { FileFormat } from 'src/models/FileFormat';
import { Catalog, CatalogModel, CatalogValidationSummary } from '../../models/Catalog';
import { getMyOrganizationThunk } from '../organizations/actions';
import {
  convertCatalogFile,
  deleteCatalog,
  editCatalog,
  editCatalogAvailable,
  getCatalog,
  getFileFormats,
  listCatalogs,
  listSubscribedCatalogs,
  publishCatalog,
  validateCatalogFile,
  addCatalog,
  addCatalogHeader
} from './api';
import { ActionTypes, CatalogFormData, EditCatalogFormData, EmptyCatalogFormData, CatalogHeaderFormData } from './types';

export type FetchStatus = 'request' | 'success' | 'failure';
export type CatalogSort = 'all' | 'new' | 'special' | 'popular' | 'top';

// Action creator types
export type GetCatalogsAction = {
  type: ActionTypes.GET_CATALOGS;
  status: FetchStatus;
  sort: CatalogSort;
  errors?: ApiError[];
  payload: {
    catalogs?: Catalog[];
  };
};

export type GetSubscribedCatalogsAction = {
  type: ActionTypes.GET_SUBSCRIBED_CATALOGS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    catalogs?: Catalog[];
  };
};

export type ValidateCatalogFileAction = {
  type: ActionTypes.VALIDATE_CATALOG_FILE;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    validateResult?: CatalogValidationSummary;
  };
};

export type ConvertCatalogFileAction = {
  type: ActionTypes.CONVERT_CATALOG_FILE;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    convertResult: any;
  };
};

export type GetCatalogAction = {
  type: ActionTypes.GET_CATALOG;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    catalog?: CatalogModel;
  };
};

export type GetFileFormatsAction = {
  type: ActionTypes.GET_FILE_FORMATS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    fileFormats?: FileFormat[];
  };
};

export type GetCatalogsByOrganizationAction = {
  type: ActionTypes.GET_CATALOGS_ORGANIZATION;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { catalogs?: Catalog[] };
};

export type GetReceivingCatalogsByOrganizationAction = {
  type: ActionTypes.GET_RECEIVING_CATALOGS_ORGANIZATION;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { catalogs?: Catalog[] };
};

export type PublishCatalogAction = {
  type: ActionTypes.PUBLISH_CATALOG;
  errors?: ApiError[];
  status: FetchStatus;
};

export type StoreCatalog = {
  type: ActionTypes.STORE_CATALOG;
  status: FetchStatus;
  errors?: ApiError[];
  payload: { data: CatalogFormData };
};

export type AddCatalogAction = {
  type: ActionTypes.ADD_CATALOG;
  errors?: ApiError[];
  status: FetchStatus;
}

export type AddCatalogHeaderAction = {
  type: ActionTypes.ADD_CATALOG_HEADER;
  errors?: ApiError[];
  status: FetchStatus;
}
// Action creators
export const storeCatalogAction = (data: CatalogFormData): StoreCatalog => ({
  type: ActionTypes.STORE_CATALOG,
  errors: [],
  status: 'success',
  payload: { data }
});

export const getCatalogsAction = (
  status: FetchStatus,
  sort: CatalogSort,
  data?: Catalog[],
  errors?: ApiError[]
): GetCatalogsAction => ({
  type: ActionTypes.GET_CATALOGS,
  status,
  sort,
  errors,
  payload: {
    catalogs: data
  }
});

export const getSubscribedCatalogsAction = (
  status: FetchStatus,
  data?: Catalog[],
  errors?: ApiError[]
): GetSubscribedCatalogsAction => ({
  type: ActionTypes.GET_SUBSCRIBED_CATALOGS,
  status,
  errors,
  payload: {
    catalogs: data
  }
});

export const validateCatalogFileAction = (
  status: FetchStatus,
  data?: CatalogValidationSummary,
  errors?: ApiError[]
): ValidateCatalogFileAction => ({
  type: ActionTypes.VALIDATE_CATALOG_FILE,
  status,
  errors,
  payload: {
    validateResult: data
  }
});

export const convertCatalogFileAction = (
  status: FetchStatus,
  data?: any,
  errors?: ApiError[]
): ConvertCatalogFileAction => ({
  type: ActionTypes.CONVERT_CATALOG_FILE,
  status,
  errors,
  payload: {
    convertResult: data
  }
});

export const getCatalogAction = (
  status: FetchStatus,
  data?: CatalogModel,
  errors?: ApiError[]
): GetCatalogAction => ({
  type: ActionTypes.GET_CATALOG,
  status,
  errors,
  payload: {
    catalog: data
  }
});

export const getFileFormatsAction = (
  status: FetchStatus,
  data?: FileFormat[],
  errors?: ApiError[]
): GetFileFormatsAction => ({
  type: ActionTypes.GET_FILE_FORMATS,
  status,
  errors,
  payload: {
    fileFormats: data
  }
});

export const publishCatalogAction = (
  status: FetchStatus,
  errors?: ApiError[]
): PublishCatalogAction => ({
  type: ActionTypes.PUBLISH_CATALOG,
  errors,
  status
});

export const addCatalogAction = (
  status: FetchStatus,
  errors?: ApiError[]
): AddCatalogAction => ({
  type: ActionTypes.ADD_CATALOG,
  errors,
  status
});

export const addCatalogHeaderAction = (
  status: FetchStatus,
  errors?: ApiError[]
): AddCatalogHeaderAction => ({
  type: ActionTypes.ADD_CATALOG_HEADER,
  errors,
  status
});

export const getCatalogsByOrganizationAction = (
  status: FetchStatus,
  data?: Catalog[]
): GetCatalogsByOrganizationAction => ({
  type: ActionTypes.GET_CATALOGS_ORGANIZATION,
  status,
  payload: {
    catalogs: data
  }
});

// Action thunks
export const getCatalogsThunk = (sort: CatalogSort) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(getCatalogsAction('request', sort));

    const apiResult = await listCatalogs(sort);
    if (apiResult.IsSuccess) {
      dispatch(getCatalogsAction('success', sort, apiResult.Data));
    } else {
      dispatch(getCatalogsAction('failure', sort, undefined, apiResult.Errors));
    }
    return apiResult;
  };
};

export const getSubscribedCatalogsThunk = () => {
  return requestApiThunk(getSubscribedCatalogsAction, listSubscribedCatalogs);
};

export const editCatalogAvailableThunk = (
  organizationIds: string[],
  catalogId: string,
  available: boolean
) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.EDIT_CATALOG_AVAILABLE, errors),
    () => editCatalogAvailable(organizationIds, catalogId, available),
    getMyOrganizationThunk()
  );
};

export const editCatalogThunk = (id: string, formData: EditCatalogFormData) => {
  return async (dispatch: any) => {
    const catalogResult = await editCatalog(id, formData);
    if (catalogResult.IsSuccess) dispatch(getCatalogThunk(id));
    return catalogResult;
  };
};

export const validateCatalogFileThunk = (
  fileFormat: string,
  fileFormatVersion: string,
  file: File
) => {
  return requestApiThunk(validateCatalogFileAction, () =>
    validateCatalogFile(fileFormat, fileFormatVersion, file)
  );
};

export const convertCatalogFileThunk = (
  fromFileFormat: string,
  fromFileFormatVersion: string,
  toFileFormat: string,
  toFileFormatVersion: string,
  file: File
) => {
  return requestApiThunk(convertCatalogFileAction, () =>
    convertCatalogFile(
      fromFileFormat,
      fromFileFormatVersion,
      toFileFormat,
      toFileFormatVersion,
      file
    )
  );
};

export const getCatalogThunk = (id: string) => {
  return requestApiThunk(getCatalogAction, () => getCatalog(id));
};

export const deleteCatalogThunk = (id: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.DELETE_CATALOG, errors),
    () => deleteCatalog(id)
  );
};

export const getFileFormatsThunk = () => {
  return requestApiThunk(getFileFormatsAction, getFileFormats);
};

export const publishCatalogThunk = (catalogId: string) => {
  return async (dispatch: any) => {
    dispatch(publishCatalogAction('request'));

    const result = await publishCatalog(catalogId);
    if (result.IsSuccess) {
      dispatch(publishCatalogAction('success'));
      dispatch(getCatalogThunk(catalogId));
    } else {
      dispatch(publishCatalogAction('failure'));
    }
    return result;
  };
};

export const addCatalogThunk = (catalogFormData: CatalogFormData) => {
  return async (dispatch: Dispatch) => {
    dispatch(addCatalogAction('request'));
    const result = await addCatalog(catalogFormData);
    if (result.IsSuccess) {
      dispatch(addCatalogAction('success'));
      dispatch(storeCatalogAction(EmptyCatalogFormData));
    } else {
      dispatch(addCatalogAction('failure'));
    }

    return result;
  }
}

export const addCatalogHeaderThunk = (catalogHeaderFormData: CatalogHeaderFormData) => {
  return async (dispatch: Dispatch) => {
    dispatch(addCatalogHeaderAction('request'));
    const result = await addCatalogHeader(catalogHeaderFormData);
    if (result.IsSuccess) {
      dispatch(addCatalogHeaderAction('success'));
    } else {
      dispatch(addCatalogHeaderAction('failure'));
    }

    return result;
  }
}

// Export Action creator types
export type Action =
  | PublishCatalogAction
  | ValidateCatalogFileAction
  | ConvertCatalogFileAction
  | GetSubscribedCatalogsAction
  | GetCatalogsAction
  | GetCatalogAction
  | GetCatalogsByOrganizationAction
  | GetFileFormatsAction
  | StoreCatalog
  | AddCatalogAction
  | AddCatalogHeaderAction;
