import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import DownloadedIcon from '@material-ui/icons/CheckCircle';
import FlagIcon from '@material-ui/icons/Flag';
import * as React from 'react';
import { MaturityStatus } from 'src/constants/MaturityStatus';
import { Organization } from 'src/models/Organization';

type OwnProps = {
  organization: Organization;
  bold: boolean;
  fontSize?: string;
};

type AllProps = OwnProps;

const XOrganizationTitle: React.SFC<AllProps> = props => {
  return (
    <OrganizationTitle fontSize={props.fontSize} bold={props.bold}>
      <div style={{ display: 'inline-block', verticalAlign: 'top' }}>{props.organization.name}</div>
      <TitleIcons>{props.organization.connectStatus === 'Approved' && <span />}</TitleIcons>
    </OrganizationTitle>
  );
};

export default XOrganizationTitle;

const TitleIcons = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;

  svg {
    margin-right: 4px;
    font-size: 15px;
  }
`;

const Flag = styled(FlagIcon)<{ status: number }>`
  color: ${props => (props.status < 200 ? '#ff5b4f' : props.status < 300 ? '#ffc94d' : '#24bf3c')};
`;

const DownloadCheck = styled(DownloadedIcon)`
  color: #24bf3c;
`;

const OrganizationTitle = styled.div<{ bold: boolean; fontSize?: string }>`
  flex: 1;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  padding-top: 4px;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;
