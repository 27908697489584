import moment from 'moment';
import { FileFormat } from 'src/models/FileFormat';
import { ConnectStatus } from 'src/models/Organization';

export const formatDateToString = (
  date?: Date,
  includeTime?: boolean,
  utcToLocalTime?: boolean
) => {
  if (!date) return '-';

  let momentDate = moment(date);
  if (utcToLocalTime === true) momentDate = moment.utc(date).local();

  let format = 'DD MMM YYYY';
  if (includeTime) format += ' HH:mm:ss';

  return momentDate.format(format);
};

export const formatConnectionStatusToString = (connectionStatus?: ConnectStatus) => {
  return connectionStatus === ConnectStatus.Approved
    ? 'connected'
    : connectionStatus === ConnectStatus.Disapproved
    ? 'connectie afgewezen'
    : connectionStatus === ConnectStatus.Processing
    ? 'connectie in behandeling'
    : '';
};

export const getImageUrl = (primary: string, secondary?: string) => {
  if (isNullOrEmpty(primary) && isNullOrEmpty(secondary)) return '1'; // return random value to trigger error and use backup image

  return isNullOrEmpty(primary) ? secondary : primary;
};

const isNullOrEmpty = (url?: string) => {
  return !url || url.length === 0;
};

export const getFileFormatOptions = (
  fileFormats: FileFormat[],
  includeNotElectronicInterchangable: boolean
) => {
  const options: { name: string; value: any }[] = [];
  let formats = fileFormats;
  if (!includeNotElectronicInterchangable)
    formats = formats.filter(f => f.electronicInterchangeable);

  for (const format of formats) {
    for (const formatVersion of format.versions) {
      if (format.versions.length > 1) {
        options.push({ name: format.name +  '.' + formatVersion.name , value: format.id + '|' + formatVersion.id });
      } else {
        options.push({ name: format.name , value: format.id + '|' + formatVersion.id })
      }
    }

  }

  return options;
};
