import { BaseReducer } from 'src/helpers/baseReducer';
import { Action } from './actions';
import { ActionTypes, RequestStateModel } from './types';

export const initialState: RequestStateModel = {
  requests: [],
  manageRequests: [],
  errors: undefined,
  loading: false
};

export class RequestReducer extends BaseReducer<RequestStateModel, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: RequestStateModel = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_REQUESTS: {
        if (action.status === 'success' && action.payload.requests)
          newState.requests = action.payload.requests;
        else newState.requests = [];
        return newState;
      }
      case ActionTypes.GET_MANAGE_REQUESTS: {
        if (action.status === 'success' && action.payload.requests)
          newState.manageRequests = action.payload.requests;
        else newState.manageRequests = [];
        return newState;
      }
      default: {
        return undefined;
      }
    }
  }
}
