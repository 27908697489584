export abstract class BaseReducer<TState, TAction> {
  outstandingRequests = 0;
  actionTypes: string[] = [];
  initialState: TState;

  constructor(actionTypes: string[], initialState: TState) {
    this.actionTypes = actionTypes;
    this.initialState = initialState;
  }

  public handleState(state: any, action: any): TState {
    if (!state) {
      return this.initialState;
    }

    if (!this.actionTypes.includes(action.type)) {
      return state;
    }

    const newState = {
      ...state
    };

    let changedState = false;
    if (action.status === 'request') {
      if (!action.dontCountLoading) {
        this.outstandingRequests += 1;
        newState.loading = true;
        changedState = true;
      }
      return newState;
    } else {
      if (!action.dontCountLoading) {
        this.outstandingRequests -= 1;
        changedState = true;
        newState.loading = this.outstandingRequests > 0;
      }
    }
    const result = this.doHandle(newState, action);
    if (!result && !changedState) return state;
    return result ?? newState;
  }

  protected abstract doHandle(state: TState, action: TAction): TState | undefined;
}
