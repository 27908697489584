import styled from '@emotion/styled';
import { TextField, Tooltip, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { XContainer } from 'src/components/layout/XContainer';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper, XPaperText, XPaperTitle } from 'src/components/layout/XPaper';
import { XRow, XRowCell } from 'src/components/layout/XRow';
import XTable, { TableRow } from 'src/components/layout/XTable';
import { ApiResult } from 'src/helpers/apiClient';
import { StockModel } from 'src/models/Stock';
import { MyOrganizationModel, Organization, OrganizationType } from 'src/models/Organization';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import * as stockActions from 'src/store/stocks/actions';
import { showConfirmation, showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import * as organizationActions from 'src/store/organizations/actions';
import { EditStockFormData, Mapping } from 'src/store/stocks/types';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import XErrorContent from 'src/components/domain/XErrorContent';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import { mapError } from 'src/helpers/errorMap';
import { FileFormat } from 'src/models/FileFormat';
import { getFileFormatsThunk } from 'src/store/catalogs/actions';
import { Translation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import XOrganizationSelectPopup from 'src/components/domain/XOrganizationSelectPopup';
import XOrganizationRow from 'src/components/domain/XOrganizationRow';
import i18n from 'src/i18n';
import XNoContent from 'src/components/domain/XNoContent';
import XPagination from 'src/components/layout/XPagination';
import {XDragAndDropFileUpload} from 'src/components/layout/XDragAndDropFileUpload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { XActionButton, XDisabledButton } from 'src/components/layout/XActionButton';
import { fileReaderCSV, getStockMapping } from 'src/store/stocks/api';
import XStockCSVModel from 'src/components/domain/XStockCSVModel';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
import { XSecActionButton } from 'src/components/layout/XActionButton';

type StateProps = {
  stock?: StockModel;
  myOrganization?: MyOrganizationModel;
  connections: Organization[];
  myAccount: AuthenticationAccount;
  loadingStock: boolean;
  loadingOrganization: boolean;
  fileFormats: FileFormat[];
};

type DispatchProps = {
  showNotification: (message: string, type: MessageType) => void;
  getStockById: (id: string) => Promise<ApiResult>;
  getConnections: () => void;
  getMyOrganization: () => void;
  editStock: (id: string, formData: EditStockFormData) => Promise<ApiResult>;
  showConfirm: (title: string, message: string, onConfirm: () => void) => void;
  deleteStock: (id: string) => Promise<ApiResult>;
  editAvailableStock: (
    targetOrganizationIds: string[],
    stockId: string,
    available: boolean
  ) => Promise<ApiResult>;
  getFileFormats: () => Promise<void>;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps<{ id: string }>;

type State = {
  files: any;
  fetchLoading: boolean;
  editing: boolean;
  dateLimit: boolean;
  editModel: EditStockFormData;
  search: string;
  currentOffset: number;
  showAddAvailablePopup: boolean;
  loaded: boolean;
  editErrors: {
    title: boolean;
    startDate: boolean;
    endDate: boolean;
  };
  mapping: Mapping;
  openMappingModel: boolean;
};

class StockPage extends React.Component<AllProps, State> {
  editor: any;
  setEditorRef: (editor: any) => any;

  constructor(props: AllProps) {
    super(props);
    this.setEditorRef = editor => (this.editor = editor);

    this.state = {
      files: null,
      fetchLoading: false,
      editing: false,
      dateLimit: false,
      editModel: this.mapEditModel(),
      search: '',
      showAddAvailablePopup: false,
      currentOffset: 0,
      loaded: false,
      editErrors: {
        title: false,
        startDate: false,
        endDate: false
      },
      mapping: {
        delimiter: '',
        mappingItems: []
      },
      openMappingModel: false
    };
  }

  public mapEditModel() {
    if (!this.props.stock) return { title: '', description: '' };

    return {
      title: this.props.stock.stock.title,
      description: this.props.stock.stock.description
    };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.stock !== this.props.stock) this.setState({ editModel: this.mapEditModel() });
  }

  public componentDidMount() {
    if (this.props.myAccount.organizationType === OrganizationType.Retailer) {
      this.props.history.push('/app/start');
    }

    this.props.getStockById(this.props.match.params.id).then(apiResult => {
      if (!apiResult.IsSuccess) {
        if (apiResult.StatusCode === 401) this.props.history.push('/app/start');
      }
      this.setState({ loaded: true });
    });

    this.props.getConnections();
    this.props.getMyOrganization();
    this.props.getFileFormats();
  }

  public formChange(name, val) {
    const editModel = Object.assign({}, this.state.editModel);
    editModel[name] = val;

    this.setState({ editModel });
  }

  public async saveEdit() {
    if (!this.props.stock) return;

    const editErrors = Object.assign({}, this.state.editErrors);

    editErrors.title = this.state.editModel.title.length === 0;

    this.setState({ editErrors });

    let valid = true;
    Object.keys(editErrors).map(item => {
      if (editErrors[item] === true) {
        valid = false;
      }
    });

    const data = this.state.editModel;

    if (!valid) return;

    this.props.editStock(this.props.stock.stock.id, data).then(result => {
      console.log(result);
      if (result.IsSuccess) {
        this.props.showNotification('Stock adjusted', 'success');
        this.setState({ editing: false });
      } else this.props.showNotification('Error' + mapError(result.Errors), 'error');
    });
  }

  public deleteStock() {
    this.props.showConfirm('Delete stock', 'Are you sure you want to delete this stock?', () => {
      this.props.deleteStock(this.props.match.params.id).then(apiResult => {
        if (apiResult.IsSuccess) {
          this.props.showNotification('Stock deleted', 'success');
          this.props.history.push('/app/my/stock');
        } else
          this.props.showNotification('Stock Delete Error' + mapError(apiResult.Errors), 'error');
      });
    });
  }

  public cancelEdit() {
    this.setState({ editing: false });
  }

  private getFileFormat(fileFormat: string, fileFormatVersion: string) {
    const format = this.props.fileFormats.find(f => f.id === fileFormat);
    if (!format) return '';

    const version = format.versions.find(v => v.id === fileFormatVersion);
    return format.name + '.' + version?.name;
  }

  public toggleAvailability(organizations: Organization[], state: boolean) {
    if (!this.props.stock) return;

    this.setState({ showAddAvailablePopup: false });
    this.props.showNotification('Beschikbaarheid wordt aangepast...', 'warning');
    console.log(organizations);
    this.props
      .editAvailableStock(
        organizations.map(o => o.id),
        this.props.stock.stock.id,
        state
      )
      .then(result => {
        console.log(result);
        if (result.IsSuccess)
          this.props.showNotification(i18n.t('notification.availabilityAdjusted'), 'success');
        else this.props.showNotification('Error' + mapError(result.Errors), 'error');
      });
  }

  public handleFileChange = (files: any) => {
    this.setState({ files });
  };

  public fileReaderCSVHandler = async () => {
    this.setState({ fetchLoading: true });
    const apiResult = await fileReaderCSV(this.state.files[0]);
    if (apiResult.IsSuccess) {
      this.setState({ files: null });
      this.props.showNotification('File uploaded', 'success');
      this.setState({ mapping: apiResult.Data });
      this.setState({ openMappingModel: true });
    } else {
      this.props.showNotification('Error', 'error');
    }
    this.setState({ fetchLoading: false });
  };

  public getStockMappingById = async () => {
    const apiResult = await getStockMapping(this.props.stock?.stock.mappingId);
    if (apiResult.IsSuccess) {
      this.setState({ mapping: apiResult.Data });
      this.setState({ openMappingModel: true });
    }
  };

  public render() {
    const props = this.props;
    if (props.loadingStock || props.loadingOrganization || !this.state.loaded)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px', position: 'relative' }}>
            <Skeleton />
          </XPaper>
        </XPageContent>
      );
    else if (!props.stock || !props.myOrganization)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <XErrorContent />
          </XPaper>
        </XPageContent>
      );

    const makeAvailableOrganizations = props.connections.filter(
      c =>
        props.myOrganization?.stockConnections.find(
          dc => dc.targetOrganizationId === c.id && dc.stockId === this.props.stock?.stock.id
        )?.active !== true
    );

    let data: any = [];
    const connections = props.myOrganization.stockConnections.filter(
      dc => dc.stockId === this.props.stock?.stock.id && dc.active
    );

    console.log('dataConnections', connections);
    console.log('connections', props.connections);
    for (const dc of connections)
      data.push({
        dataConnection: dc,
        organization: props.connections.find(c => c.id === dc.targetOrganizationId)
      });

    data = data.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
    data = data.filter(
      d => d.organization.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    );
    const pageSize = 10;
    const paged = data.slice(this.state.currentOffset, this.state.currentOffset + pageSize);

    return (
      <ErrorBoundary>
        <XPageContent>
          <XPaper style={{ minHeight: '300px', position: 'relative' }}>
            <XPaperHeader
              header="Stock"
              customContent={
                <div>
                  <ActionButtons>
                    {this.state.editing ? (
                      <Translation>
                        {t => (
                          <div style={{ verticalAlign: 'middle' }}>
                            <Tooltip title={t('tooltip.Cancel') + ' '}>
                              <ClearIcon
                                onClick={() => this.cancelEdit()}
                                style={{ marginRight: '10px', verticalAlign: 'middle' }}
                              />
                            </Tooltip>
                            <Tooltip title={t('tooltip.Save') + ' '}>
                              <SaveIcon
                                onClick={async () => await this.saveEdit()}
                                style={{ verticalAlign: 'middle' }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </Translation>
                    ) : (
                      <Translation>
                        {t => (
                          <div style={{ verticalAlign: 'middle' }}>
                            {/* <Tooltip title={t('tooltip.explore') + ' '}>
                            <Link to={'/app/stock/' + this.props.match.params.id}>
                              <ViewIcon style={{ marginRight: '10px', verticalAlign: 'middle' }} />
                            </Link>
                          </Tooltip> */}
                            <Tooltip title="Adjust Stock">
                              <EditIcon
                                onClick={() => this.setState({ editing: true })}
                                style={{ marginRight: '10px', verticalAlign: 'middle' }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete Stock">
                              <DeleteIcon
                                onClick={() => this.deleteStock()}
                                style={{ verticalAlign: 'middle' }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </Translation>
                    )}
                  </ActionButtons>
                </div>
              }
            />
            <XContainer style={{ height: '100%', position: 'relative' }}>
              <XRow
                style={{
                  height: '100%'
                }}>
                <XRowCell>
                  <XRow>
                    <XRowCell>
                      <XContainer>
                        <XPaperTitle>
                          {this.state.editing ? (
                            <TextField
                              error={this.state.editErrors.title}
                              placeholder="Stock title"
                              name="title"
                              style={{ width: '100%' }}
                              value={this.state.editModel.title}
                              onChange={e => this.formChange(e.target.name, e.target.value)}
                            />
                          ) : (
                            props.stock.stock.title
                          )}
                        </XPaperTitle>
                        <XPaperText>
                          {this.state.editing ? (
                            <TextField
                              multiline={true}
                              rows={5}
                              name="description"
                              placeholder="Stock description"
                              style={{ width: '100%', height: '70px' }}
                              value={this.state.editModel.description}
                              onChange={e => this.formChange(e.target.name, e.target.value)}
                            />
                          ) : (
                            <span style={{ maxHeight: '92px', overflow: 'hidden' }}>
                              {props.stock.stock.description}
                            </span>
                          )}
                        </XPaperText>
                      </XContainer>
                    </XRowCell>
                    <XRowCell></XRowCell>
                    <XRowCell>
                      <XContainer>
                        <div style={{ fontWeight: 'bold' }}>Format:</div>
                        <div>
                          {this.getFileFormat(
                            this.props.stock?.stock.fileFormatId || '',
                            this.props.stock?.stock.fileFormatVersionId || ''
                          )}
                        </div>
                        {!!this.props.stock?.stock?.mappingId && (
                          <>
                            <div style={{ marginTop: '2rem', fontWeight: 'bold' }}>Mapping</div>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.getStockMappingById()}>
                              Edit stock mapping
                            </div>
                          </>
                        )}
                      </XContainer>
                    </XRowCell>
                  </XRow>
                </XRowCell>
              </XRow>
            </XContainer>
          </XPaper>

          {props.fileFormats.find(f => f.id === this.props.stock?.stock.fileFormatId)?.name ===
            'CSV' && (
            <XPaper style={{ marginTop: '15px' }}>
              <XPaperHeader header="Upload" />
              <XDragAndDropFileUpload handleFileChange={this.handleFileChange} />
              {this.state.files?.length !== 0 && this.state.files && (
                <div style={{ padding: '15px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                    <div style={{ marginRight: '10px' }}>{this.state.files?.map((files) => {return `${files.name} \n`})}</div>
                    <HighlightOffIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({ files: null })}
                    />
                  </div>
                  {!this.state.fetchLoading ? (
                    <XActionButton onClick={this.fileReaderCSVHandler}>Upload</XActionButton>
                  ) : (
                    <XDisabledButton>
                      Uploading..{' '}
                      <CircularProgress
                        style={{ height: '19px', width: '19px', marginLeft: '10px' }}
                      />
                    </XDisabledButton>
                  )}
                </div>
              )}
            </XPaper>
          )}

          <XPaper style={{ marginTop: '15px' }}>
            <Translation>
              {t => (
                <XOrganizationSelectPopup
                  title="Make stock available for: "
                  onCancel={() => this.setState({ showAddAvailablePopup: false })}
                  open={this.state.showAddAvailablePopup}
                  clearOnClose={true}
                  organizations={makeAvailableOrganizations}
                  onSave={organizations => this.toggleAvailability(organizations, true)}
                />
              )}
            </Translation>
            <div style={{ position: 'relative' }}>
              <Translation>
                {t => (
                  <XPaperHeader
                    header="Stock availabilty"
                    search={true}
                    onSearch={e => this.setState({ search: e.toLowerCase() })}
                    icons={[
                      {
                        icon: AddCircleIcon,
                        toolTip: t('tooltip.add'),
                        name: 'add',
                        action: () => this.setState({ showAddAvailablePopup: true })
                      }
                    ]}
                  />
                )}
              </Translation>
              {props.connections.length === 0 ? (
                <Translation>
                  {t => (
                    <XNoContent>
                      <div style={{ marginTop: '4px' }}>
                        <Link style={{ color: '#aaa' }} to="/app/connections">
                          Find Retailers
                        </Link>
                      </div>
                    </XNoContent>
                  )}
                </Translation>
              ) : (
                <div>
                  {paged.length === 0 ? (
                    <div style={{ padding: '15px' }}>
                      Deze stock is niet beschikbaar voor retailers
                    </div>
                  ) : (
                    <Translation>
                      {t => (
                        <XTable
                          headers={[
                            t('myArxis.Organization'),
                            t('address.place'),
                            'Downloads',
                            t('catalogs.lastDownloaded'),
                            ''
                          ]}>
                          {paged.map(obj => {
                            return (
                              <XOrganizationRow
                                key={obj.organization.id}
                                showDelete={true}
                                onDelete={() => this.toggleAvailability([obj.organization], false)}
                                dataConnection={obj.dataConnection}
                                link={'/app/my/connections/' + obj.organization.id}
                                organization={obj.organization}
                              />
                            );
                          })}
                        </XTable>
                      )}
                    </Translation>
                  )}
                  {data.length > pageSize && (
                    <XPagination
                      itemCount={data.length}
                      itemsPerPage={pageSize}
                      changePage={i => this.setState({ currentOffset: i })}
                    />
                  )}
                </div>
              )}
            </div>
          </XPaper>
          <Translation>
            {t => (
              <XPaper style={{ margin: '15px 0' }}>
                <XPaperHeader header={t('myArxis.Settings')} />
                <div style={{ padding: '0 15px' }}>
                  <h6>Beheer instellingen omtrent het aanbieden van deze voorraad</h6>
                  <XSecActionButton
                    onClick={() =>
                      props.history.push(`/app/my/stocks/stock-interface/${props.stock?.stock?.id}`)
                    }>
                    {t('myArxis.Settings')}
                  </XSecActionButton>
                </div>
              </XPaper>
            )}
          </Translation>
          {this.state.openMappingModel && (
            <XStockCSVModel
              stockId={this.props.stock?.stock?.id || ''}
              stockMappingId={this.props.stock?.stock?.mappingId || ''}
              mappingData={this.state.mapping}
              close={() => this.setState({ openMappingModel: false })}
            />
          )}
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    myAccount: state.authentication.account,
    connections: state.organizations.connections,
    myOrganization: state.organizations.myOrganization,
    stock: state.stocks.data.detail,
    loadingStock: state.stocks.loading,
    loadingOrganization: state.organizations.loading,
    fileFormats: state.catalogs.fileFormats
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type: MessageType) => {
    dispatch(showNotification(message, type));
  },
  getConnections: () => dispatch(organizationActions.getOrganizationConnectionsThunk()),
  getStockById: (id: string) => dispatch(stockActions.getStockThunk(id)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk()),
  editStock: (id: string, formData: EditStockFormData) =>
    dispatch(stockActions.editStockThunk(id, formData)),
  showConfirm: (title: string, message: string, onConfirm: () => void) =>
    dispatch(showConfirmation(title, message, onConfirm)),
  deleteStock: (id: string) => dispatch(stockActions.deleteStockThunk(id)),
  editAvailableStock: (targetOrganizationIds: string[], stockId: string, available: boolean) =>
    dispatch(stockActions.editStockAvailableThunk(targetOrganizationIds, stockId, available)),
  getFileFormats: () => dispatch(getFileFormatsThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(StockPage);

const ActionButtons = styled.div`
  cursor: pointer;

  & svg {
    color: rgb(245, 0, 87);
    font-weight: bold;
  }
`;
