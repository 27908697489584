import { apiClient, ApiResult } from 'src/helpers/apiClient';

export const listMessages = async (organizationId: string): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListOrganizationMessages', {
    OrganizationId: organizationId
  });
};

export const listCatalogMessages = async (catalogId: string): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListCatalogMessages', {
    catalogId: catalogId
  });
};

export const listOrganizationCatalogMessages = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListOrganizationCatalogMessages', {});
};

export const listSentOrderMessages = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListSentOrderMessages', {});
};

export const listOrderMessages = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListOrderMessages', {});
};

