import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as React from 'react';

export enum XButtonStyle {
  Primary,
  Secondary
}

type OwnProps = {
  onClick?: any;
  active?: boolean;
  colorscheme?: XButtonStyle;
  staticcolor?: string;
  textcolor?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  applyPadding?: boolean;
  loading?: boolean;
};

type AllProps = OwnProps;

const gradientSwitch = (style: XButtonStyle | undefined, props: any) => {
  switch (style) {
    case XButtonStyle.Primary:
      return (
        'linear-gradient(to right,' + props.theme.pStartColor + ',' + props.theme.pEndColor + ')'
      );
    case XButtonStyle.Secondary:
      return (
        'linear-gradient(to right,' + props.theme.sStartColor + ',' + props.theme.sEndColor + ')'
      );
    default:
      return '';
  }
};

const XButton: React.SFC<AllProps> = props => {
  return props.active === false ? (
    <NonActiveButton disabled={true}>
      {props.children}
      {props.loading === true ? (
        <CircularProgress style={{ height: '19px', width: '19px', marginLeft: '10px' }} />
      ) : (
        undefined
      )}
    </NonActiveButton>
  ) : (
    <ActiveButton onClick={props.onClick} {...props}>
      {props.children}
      {props.loading === true ? (
        <CircularProgress style={{ height: '19px', width: '19px', marginLeft: '10px' }} />
      ) : (
        undefined
      )}
    </ActiveButton>
  );
};

export default XButton;

const ActiveButton = styled(Button, { shouldForwardProp: prop => prop !== 'applyPadding' })<
  AllProps
>`  
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 11px;
    position: relative;
    transition: all 0.2s ease-in-out;
    padding: ${props =>
      props.applyPadding === false
        ? undefined
        : props.colorscheme !== undefined
        ? '13px 46px 13px 30px'
        : '13px 30px'};
    color: ${props => props.textcolor || '#fff'};
    width: ${props => props.width || ''};
    max-width: ${props => props.maxWidth || ''};
    box-shadow: ${props =>
      props.colorscheme === XButtonStyle.Primary
        ? '0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08)'
        : ''};
    background: ${props =>
      props.staticcolor ? props.staticcolor : gradientSwitch(props.colorscheme, props)};
    &::after ${props =>
      props.colorscheme !== undefined
        ? '{ content: "▶"; display: inline-block; position: absolute; opacity: 1; right: 22px; transition: all 0.2s ease-in-out }'
        : ''};
    &:hover::after {opacity: 0; right: 50px;};
    &:hover ${props =>
      props.colorscheme !== undefined ? '{padding: 13px 38px 13px 38px}' : undefined};
    `;

const NonActiveButton = styled(Button)(
  {
    backgroundColor: 'lightgrey',
    borderRadius: '0px',
    fontFamily: 'Montserrat-Bold',
    textTransform: 'uppercase',
    padding: '13px 30px',
    fontWeight: 'bold',
    letterSpacing: '2px',
    fontSize: '11px',
    position: 'relative',
    transition: 'all 0.2s ease-in-out'
  },
  (props: AllProps) => ({
    color: props.textcolor
  })
);
