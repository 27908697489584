import * as React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import XStockRow from 'src/components/domain/XStockRow';
import XNoContent from 'src/components/domain/XNoContent';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import XTable from 'src/components/layout/XTable';
import { Stock } from 'src/models/Stock';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { ApplicationState } from '../../../store';
import * as stockActions from '../../../store/stocks/actions';
import { getStocksThunk } from 'src/store/stocks/actions';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import XScrollView from 'src/components/layout/XScrollView';
import EmptyImage from '../../../style/images/empty-canvas.svg';
import { Link, RouteComponentProps } from 'react-router-dom';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import XSearchBar from 'src/components/layout/XSearchBar';
import { XSecActionButton } from 'src/components/layout/XActionButton';
import { XRowCell } from 'src/components/layout/XRow';
import { ViewType } from 'src/store/layout/types';
import { LinearProgress } from '@material-ui/core';
import { Translation } from 'react-i18next';
import XStock from 'src/components/domain/XStock';
import XGrid from 'src/components/layout/XGrid';
import GridIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import { XImage, XImageContainer } from 'src/components/layout/XImage';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { formatDateToString, getImageUrl } from 'src/helpers/formatHelpers';
import { changeView } from 'src/store/layout/actions';

type StateProps = {
  subscribedStocks: Stock[];
  account: AuthenticationAccount;
  stocks: Stock[];
  loading: boolean;
  view: ViewType;
};

type DispatchProps = {
  getSubscribedStocks: () => void;
  getStocks(): () => void;
  changeView(type: ViewType): () => void;
};

type State = {
  search: string;
  currentOffset: number;
  viewDownloaded: boolean;
  searchNewStocks: string;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{}>;

class StockPageRetailer extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      search: '',
      currentOffset: 0,
      viewDownloaded: false,
      searchNewStocks: ''
    };
  }

  public componentWillMount() {
    this.props.getSubscribedStocks();
    this.props.getStocks();
  }

  public switchView(view) {
    this.props.changeView(view);
  }

  public handleScrollBottom() {
    if (this.state.currentOffset >= this.props.stocks?.length) return;
    this.setState({ currentOffset: this.state.currentOffset + 30 });
  }

  public render() {
    if (this.props.loading)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <LinearProgress />
          </XPaper>
        </XPageContent>
      );

    const filtered = this.props.subscribedStocks.filter(
      c =>
        c.title.toLowerCase().indexOf(this.state.search) !== -1 ||
        c.organizationName.toLowerCase().indexOf(this.state.search) !== -1
    );

    const pageSize = 1000;
    const stocks = this.props.stocks.filter(
      c =>
        this.state.search.length === 0 ||
        c.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    );
    const paged = stocks.slice(0, this.state.currentOffset + pageSize);

    return (
      <XPageContent>
        <StyledPaper style={{ height: stocks?.length === 0 ? '300px' : '', marginBottom: '2rem' }}>
          <Translation>
            {t => (
              <XPaperHeader
                header={t('stock.Stock')}
                count={stocks?.length}
                subheader={t('stock.newAvailableStocks')}
              />
            )}
          </Translation>
          {this.props.stocks?.length !== 0 && (
            <XFilterBar style={{ paddingBottom: '15px' }}>
              <div style={{ flex: 1, paddingRight: '5px' }}>
                <Translation>
                  {t => (
                    <XSearchBar
                      placeholder={`Filter ${t('stock.stocks')}`}
                      onChange={e =>
                        this.setState({ searchNewStocks: e.target.value.toLowerCase() })
                      }></XSearchBar>
                  )}
                </Translation>
              </div>
              <div style={{ paddingRight: '5px', display: 'flex' }}></div>
              <Translation>
                {t => (
                  <XSecActionButton
                    onClick={() => this.switchView(this.props.view === 'list' ? 'grid' : 'list')}>
                    {this.props.view === 'list' ? (
                      <GridIcon style={{ marginRight: '5px' }} />
                    ) : (
                      <ListIcon style={{ marginRight: '5px' }} />
                    )}
                    {this.props.view === 'list' ? 'Grid' : t('organizations.List')}
                  </XSecActionButton>
                )}
              </Translation>
            </XFilterBar>
          )}

          {this.props.stocks?.length !== 0 && paged?.length === 0 && (
            <Translation>{t => <XNoContent message={t('stock.noStockFound')} />}</Translation>
          )}
          {!this.state.search.length && this.props.stocks?.length === 0 && (
            <Translation>
              {t => (
                <XNoContent
                  image={EmptyImage}
                  message={t('stock.dnthStockSubscriptions')}></XNoContent>
              )}
            </Translation>
          )}
          <XScrollView onScrollBottom={() => this.handleScrollBottom()}>
            {this.props.view === 'grid' ? (
              <XGrid
                columns={3}
                style={{
                  padding: '0'
                }}>
                {paged.map(dp => (
                  <XStock key={dp.id} stock={dp} size="small" />
                ))}
              </XGrid>
            ) : (
              <div style={{ padding: '15px' }}>
                {paged.map(c => (
                  <StockRow key={c.id}>
                    <StockImage>
                      <XImageContainer width={60} height={60}>
                        <XImage
                          src={getImageUrl(c.imageURL)}
                          alt=""
                          onError={(e: any) => {
                            e.target.onError = null;
                            e.target.src = generateAvatar(c.title, 60);
                          }}
                        />
                      </XImageContainer>
                    </StockImage>
                    <StockTitle>
                      {/* <Link to={'/app/stocks/' + c.id}>{c.title}</Link> */}
                      {c.title}
                    </StockTitle>
                    <StockCell>
                      {/* <Link to={'/app/connections/organization/' + c.organizationId}>
                        {c.organizationName}
                      </Link> */}
                      {c.organizationName}
                    </StockCell>
                    <StockCell>{c.downloads} downloads</StockCell>
                    <StockCell>{formatDateToString(c.creationDate)}</StockCell>
                  </StockRow>
                ))}
              </div>
            )}
          </XScrollView>
        </StyledPaper>
      </XPageContent>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    subscribedStocks: state.stocks.data.subscribed.sort(
      (a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    ),
    stocks: state.stocks.data.all || [],
    loading: state.stocks.loading,
    view: state.layout.viewCatalogs
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getSubscribedStocks: () => dispatch(stockActions.getSubscribedStocksThunk()),
  getStocks: () => dispatch(getStocksThunk()),
  changeView: (type: ViewType) => dispatch(changeView('catalogs', type))
});

export default connect(mapStateToProps, mapDispatchToProps)(StockPageRetailer);

const StyledPaper = styled(XPaper)`
  min-height: 300px;
`;

const StockRow = styled.div`
  display: flex;
  margin: 4px 0;
`;

const StockImage = styled.div`
  max-width: 75px;
  flex: 1;
`;

const StockCell = styled.div`
  flex: 1;
  font-size: 14px;
  padding-top: 4px;
`;

const StockTitle = styled(StockCell)`
  padding-left: 15px;
  font-weight: bold;
`;
