import styled from '@emotion/styled';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select
} from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { XContainer } from 'src/components/layout/XContainer';
import XFormButtons from 'src/components/layout/XFormButtons';
import { ApiError, ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { OrganizationType } from 'src/models/Organization';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { ApplicationState } from '../../../store';
import { ConversionResult, ConvertCatalogFormData } from '../../../store/catalogs/types';

import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import XTableViewerPopup from 'src/components/domain/XTableViewerPopup';
import { Translation } from 'react-i18next';
import i18n from 'src/i18n';

type StateProps = {
  account: AuthenticationAccount;
  result: {
    transformResult?: ConversionResult;
    error?: ApiError;
  };
};

type DispatchProps = {};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

type State = {
  showPopup: boolean;
};

class WizardConvertOutputPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      showPopup: false
    };
  }

  public componentDidMount() {
    if (this.props.account.organizationType === OrganizationType.Retailer) {
      this.props.history.push('/app/start');
      return;
    }

    console.log(this.props.result);
  }

  public previousPage = () => {
    this.props.history.goBack();
  };

  public next = () => {
    this.props.history.push('/app/my/catalogs/');
  };

  public downloadConversionSummary = () => {
    const data = Object.assign({}, this.props.result.transformResult);
    data.url = '';

    const dataLink =
      'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, null, 2));
    const link = document.createElement('a');
    link.download = 'validatie-rapport.json';
    link.href = dataLink;
    link.click();
  };

  public downloadFile = () => {
    const link = document.createElement('a');
    link.download = this.props.result.transformResult?.to + '.xml';
    link.href = this.props.result.transformResult?.url ?? '';
    console.log(link.download);
    link.click();
  };

  public render() {
    return (
      <XContainer style={{ position: 'relative', padding: '15px 15px 0 15px' }}>
        {this.props.result.transformResult && (
          <XTableViewerPopup
            paged={false}
            pageSize={0}
            maxResults={500}
            title={i18n.t('wizard.conversionReport')}
            columns={[
              { name: 'level', width: '100px' },
              {
                name: 'lineNumber',
                width: '100px',
                interpolation: str => (str === 0 ? '-' : '#' + str)
              },
              { name: 'message' }
            ]}
            open={this.state.showPopup}
            onCancel={() => this.setState({ showPopup: false })}
            data={this.props.result.transformResult.errors}
          />
        )}
        <div>
          {this.props.result.error ? (
            <div style={{ padding: '40px 0 0 0', minHeight: '250px' }}>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <HighlightOffIcon style={{ color: '9c1006', width: '100px', height: '100px' }} />
              </div>
              <div style={{ textAlign: 'center' }}>
                {this.props.result.error.errorCode === 'LTX4001'
                  ? i18n.t('wizard.conversionNotYetAvailableForThisFormat')
                  : mapError([this.props.result.error])}
              </div>
            </div>
          ) : this.props.result?.transformResult?.errors.length === 0 ? (
            <Translation>
              {t => (
                <div style={{ padding: '40px 0 0 0', minHeight: '250px' }}>
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <CheckCircleIcon style={{ color: '0d8416', width: '100px', height: '100px' }} />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    {t('wizard.conversionCompleteNoFindings')}
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '10px',
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}
                    onClick={() => this.downloadFile()}>
                    {t('wizard.downloadFile')}
                  </div>
                </div>
              )}
            </Translation>
          ) : (
            <Translation>
              {t => (
                <div style={{ padding: '40px 0 0 0', minHeight: '280px' }}>
                  <div style={{ textAlign: 'center' }}>
                    <HighlightOffIcon
                      style={{
                        color: '9c1006',
                        marginBottom: '10px',
                        width: '100px',
                        height: '100px'
                      }}
                    />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    {t('wizard.validationComplete')},{' '}
                    {this.props.result.transformResult?.errors.length} {t('wizard.findings')}
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      padding: '10px 0',
                      fontWeight: 'bold'
                    }}
                    onClick={() => this.setState({ showPopup: true })}>
                    {t('wizard.viewReport')}
                  </div>
                  <div
                    style={{ textAlign: 'center', cursor: 'pointer', fontWeight: 'bold' }}
                    onClick={() => this.downloadConversionSummary()}>
                    {t('wizard.downloadReport')}
                  </div>
                </div>
              )}
            </Translation>
          )}
          <Translation>
            {t => (
              <XFormButtons
                style={{ padding: '0' }}
                onCancel={this.previousPage}
                cancelText={t('tooltip.back')}
                onSave={this.next}
                saveText={t('tooltip.close')}
              />
            )}
          </Translation>
        </div>
      </XContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    result: state.catalogs.conversion
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardConvertOutputPage);

const ValidationSuccessResult = styled('div')`
  margin-top: 10px;
  padding: 10px;
  color: #0d8416;
  border: 1px solid #b7d167;
`;

const ValidationErrorResult = styled('div')`
  margin-top: 10px;
  padding: 10px;
  color: #9c1006;
  border: 1px solid #ed677a;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 15px;
  width: 100%;
`;
