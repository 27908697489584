import styled from '@emotion/styled';
import { LinearProgress, Select } from '@material-ui/core';
import * as React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { XContainer } from 'src/components/layout/XContainer';
import XFileSelectButton, { XButtonStyle } from 'src/components/layout/XFileSelectButton';
import XForm from 'src/components/layout/XForm';
import XFormButtons from 'src/components/layout/XFormButtons';
import { ApiResult } from 'src/helpers/apiClient';
import { getFileFormatOptions } from 'src/helpers/formatHelpers';
import { FileFormat } from 'src/models/FileFormat';
import { OrganizationType } from 'src/models/Organization';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../../store';
import * as catalogActions from '../../../store/catalogs/actions';
import { ConvertCatalogFormData } from '../../../store/catalogs/types';

type StateProps = {
  data: ConvertCatalogFormData;
  account: AuthenticationAccount;
  fileFormats: FileFormat[];
};

type DispatchProps = {
  convertCatalogFile: (
    fromFileFormat: string,
    fromFileFormatVersion: string,
    toFileFormat: string,
    toFileFormatVersion: string,
    file: File
  ) => Promise<ApiResult>;
  getFileFormats: () => void;
  showNotification: (text: string, type: MessageType) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

type State = {
  newData: ConvertCatalogFormData;
  converting: boolean;
  inputErrors: { fromFileFormatSelection: boolean; toFileFormatSelection: boolean };
  toFileFormatSelection: string | null;
  fromFileFormatSelection: string | null;
};

class WizardConvertInputPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      converting: false,
      toFileFormatSelection: props.data.toFileFormat
        ? props.data.toFileFormat + '|' + props.data.toFileFormatVersion
        : null,
      fromFileFormatSelection: props.data.fromFileFormat
        ? props.data.fromFileFormat + '|' + props.data.fromFileFormatVersion
        : null,
      inputErrors: { fromFileFormatSelection: false, toFileFormatSelection: false },
      newData: props.data
    };
  }

  public componentDidMount() {
    if (this.props.account.organizationType === OrganizationType.Retailer) {
      this.props.history.push('/app/start');
      return;
    }

    this.props.getFileFormats();
  }

  public handleFormFileSelectChange = (e: any) => {
    const newData = Object.assign({}, this.state.newData);
    if (e.target.files.length === 0) return;

    newData[e.target.name] = e.target.files[0];
    this.setState({ newData });
  };

  public handleFormChange(name: string, value: any) {
    const newData = Object.assign({}, this.state.newData);
    if (name === 'fromFileFormatSelection') {
      const split = value?.split('|') ?? [undefined, undefined];
      newData.fromFileFormat = split[0];
      newData.fromFileFormatVersion = split[1];
      this.setState({ fromFileFormatSelection: value });
    } else if (name === 'toFileFormatSelection') {
      const split = value?.split('|') ?? [undefined, undefined];
      newData.toFileFormat = split[0];
      newData.toFileFormatVersion = split[1];
      this.setState({ toFileFormatSelection: value });
    } else newData[name] = value;
    this.setState({ newData });
  }

  public previousPage = () => {
    this.props.history.goBack();
  };

  public next = () => {
    if (!this.state.newData.fromFileFormat || !this.state.newData.fromFileFormatVersion) {
      const inputErrors = Object.assign({}, this.state.inputErrors);
      inputErrors.fromFileFormatSelection = !this.state.fromFileFormatSelection;
      this.setState({ inputErrors });
      return;
    }

    if (!this.state.newData.toFileFormat || !this.state.newData.toFileFormatVersion) {
      const inputErrors = Object.assign({}, this.state.inputErrors);
      inputErrors.toFileFormatSelection = !this.state.toFileFormatSelection;
      this.setState({ inputErrors });
      return;
    }

    if (!this.state.newData.file?.size) return;

    this.setState({ converting: true });
    this.props
      .convertCatalogFile(
        this.state.newData.fromFileFormat,
        this.state.newData.fromFileFormatVersion,
        this.state.newData.toFileFormat,
        this.state.newData.toFileFormatVersion,
        this.state.newData.file
      )
      .then(() => {
        this.props.history.push('/app/my/catalogs/convert/result');
      });
  };

  public render() {
    return (
      <Translation>
        {t => (
          <XContainer style={{ position: 'relative', padding: '15px 15px 0 15px' }}>
            {!this.props.fileFormats ? (
              <LinearProgress />
            ) : (
              <div>
                <XForm
                  data={this.state}
                  inputErrors={this.state.inputErrors}
                  margin={false}
                  fields={[
                    {
                      title: t('wizard.inputFileFormat'),
                      name: 'fromFileFormatSelection',
                      required: true,
                      type: 'select',
                      options: getFileFormatOptions(this.props.fileFormats, false)
                        // We currently don't support conversion of csv files on the fly
                        .filter(i => i.name.toLowerCase().indexOf('csv') == -1)
                    },
                    {
                      title: t('wizard.outputFileFormat'),
                      name: 'toFileFormatSelection',
                      required: true,
                      type: 'select',
                      options: getFileFormatOptions(this.props.fileFormats, false)
                        // We currently don't support conversion of csv files on the fly
                        .filter(i => i.name.toLowerCase().indexOf('csv') == -1)
                        .filter(
                          i =>
                            i.value !==
                            this.state.newData.fromFileFormat +
                              '|' +
                              this.state.newData.fromFileFormatVersion
                        )
                    }
                  ]}
                  callback={(e, v) => this.handleFormChange(e, v)}
                />

                <div style={{ marginBottom: '15px' }}>
                  <XFileSelectButton
                    onSelect={this.handleFormFileSelectChange}
                    name="file"
                    active={
                      !this.state.converting &&
                      this.state.newData.toFileFormat?.length > 0 &&
                      this.state.newData.toFileFormatVersion?.length > 0 &&
                      this.state.newData.fromFileFormat?.length > 0 &&
                      this.state.newData.fromFileFormatVersion?.length > 0
                    }
                    colorscheme={XButtonStyle.Secondary}>
                    {t('tooltip.selectFile')}
                  </XFileSelectButton>
                </div>

                <XFormButtons
                  style={{ padding: '0' }}
                  onCancel={this.previousPage}
                  onSave={this.next}
                  loading={this.state.converting}
                  saveText={t('wizard.convert')}
                  enabledSave={!this.state.converting && this.state.newData.file?.size > 0}
                />
              </div>
            )}
          </XContainer>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    data: {
      file: null,
      fromFileFormat: '',
      fromFileFormatVersion: '',
      toFileFormat: '',
      toFileFormatVersion: ''
    },
    fileFormats: state.catalogs.fileFormats.sort((a, b) => a.name.localeCompare(b.name))
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getFileFormats: () => dispatch(catalogActions.getFileFormatsThunk()),
  convertCatalogFile: (
    fromFileFormat: string,
    fromFileFormatVersion: string,
    toFileFormat: string,
    toFileFormatVersion: string,
    file: File
  ) =>
    dispatch(
      catalogActions.convertCatalogFileThunk(
        fromFileFormat,
        fromFileFormatVersion,
        toFileFormat,
        toFileFormatVersion,
        file
      )
    ),
  showNotification: (text: string, type: MessageType) => dispatch(showNotification(text, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardConvertInputPage);
