import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { Divider } from '@material-ui/core';
import { XActionButton } from 'src/components/layout/XActionButton';

type OwnProps = {
  close: () => void;
};

const XSubscriptionUpgradeModal: React.FC<OwnProps> = ({ close }) => {
  return (
    <XSubUpgradeModal>
      <XSubUpgradeGrid>
        <h5>Upgrade</h5>
        <Divider />
        <h6>
          You need more connections? <br />
          Uprade your subsription from <strong>Free</strong> to
          <strong> Premium</strong>
        </h6>
        <Link to="/app/account/subscriptions">
          <XActionButton style={{ height: '3rem', fontSize: '1.1rem', width: '100%' }}>
            Subscriptions
          </XActionButton>
        </Link>
        <div onClick={close}>
          <CloseIcon />
        </div>
      </XSubUpgradeGrid>
    </XSubUpgradeModal>
  );
};

export default XSubscriptionUpgradeModal;

const XSubUpgradeModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999 !important;
`;

const XSubUpgradeGrid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  width: 28rem;
  background-color: white;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);

  & h5 {
    margin: 1rem 0;
    font-weight: bold;
  }

  & h6 {
    margin: 2rem 0;
    line-height: 2rem;
  }

  & div {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
`;
