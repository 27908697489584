import React from 'react';
import styled from '@emotion/styled';
import { XImage, XImageContainer, XImageOverlay } from '../layout/XImage';
import { Tooltip } from '@material-ui/core';
import DownloadedIcon from '@material-ui/icons/CheckCircle';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { formatDateToString, getImageUrl } from 'src/helpers/formatHelpers';
import { CloudDownload } from '@material-ui/icons';
import { Translation } from 'react-i18next';

type OwnProps = {
  stock: any;
};

type AllProps = OwnProps;

const XCatalogSmall: React.SFC<AllProps> = props => {
  return (
    <StockContent>
      <XImageContainer width={75} height={75}>
        <XImage
          src={getImageUrl(props.stock.imageURL, props.stock.organizationImageURL)}
          alt=""
          onError={(e: any) => {
            e.target.onError = null;
            e.target.src = generateAvatar(props.stock.title, 75);
          }}
        />
        {props.stock.downloaded && (
          // eslint-disable-next-line react/jsx-no-undef
          <Translation>
            {t => (
              <Tooltip title="You already downloaded this stock">
                <XImageOverlay>
                  <CloudDownload />
                </XImageOverlay>
              </Tooltip>
            )}
          </Translation>
        )}
      </XImageContainer>
      <StockText>
        <StockTitle>
          {props.stock.title}{' '}
          {props.stock.downloaded && (
            <Translation>
              {t => (
                <Tooltip title="You already downloaded this stock">
                  <DownloadCheck />
                </Tooltip>
              )}
            </Translation>
          )}
        </StockTitle>
        <StockLabel>{props.stock.organizationName}</StockLabel>
        <StockLabel>
          {formatDateToString(props.stock.creationDate)} • {props.stock.downloads} downloads
        </StockLabel>
      </StockText>
    </StockContent>
  );
};

export default XCatalogSmall;

const StockContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: 'auto'
});

export const StockTitle = styled('div')({
  fontSize: '14px',
  fontFamily: 'Montserrat-Bold' // TODO: get font from theme
});

export const StockLabel = styled('div')({
  fontSize: '10px'
  // cursor: 'pointer'
});

const StockText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '7px',
  marginLeft: '8px',
  color: 'black'
});

const DownloadCheck = styled(DownloadedIcon)`
  color: green;
  font-size: 15px;
  vertical-align: top;
  margin-top: 3px;
`;
