import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { InterfaceMethod } from 'src/models/Stock';
import Chip from '@material-ui/core/Chip';
import { TextField } from '@material-ui/core';
import i18n from 'src/i18n';

type OwnProps = {
  typeSelectHandler: (type: string, specType?: string) => void;
  setPassword: (password: string) => void;
  setUserName: (userName: string) => void;
  setPortalName: (portalName: string) => void;
  setToken: (token: string) => void;
  setUrl: (url: string) => void;
  token: string;
  password: string;
  userName: string;
  portalName: string;
  url: string;
};

type AllProps = OwnProps;

const SpecificInterfaceMethod: FC<AllProps> = props => {
  const [customType, setCustomType] = useState('');

  const specificMethods = ['DeEekhoorn', 'VertilinQ', 'ItsPerfect', 'LightAndLiving', 'DTP'];

  const customTypeHandler = (method: string) => {
    setCustomType(method);
    props.typeSelectHandler('Specific', method);
  };

  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <XSelectTypeContainer>
          {specificMethods.map(method => (
            <Chip
              style={{ flex: 1 }}
              key={method}
              label={method}
              clickable
              color={customType === method ? 'primary' : 'default'}
              onClick={() => customTypeHandler(method)}
            />
          ))}
        </XSelectTypeContainer>
      </div>
      {customType === InterfaceMethod.DeEekhoorn && (
        <div>
          <XAuthInputs>
            <TextField
              value={props.userName}
              onChange={e => props.setUserName(e.target.value)}
              label="User name"
            />
            <TextField
              value={props.password}
              onChange={e => props.setPassword(e.target.value)}
              label="Password"
              style={{ marginTop: '1rem' }}
              type='password'
            />
          </XAuthInputs>
        </div>
      )}
      {customType === InterfaceMethod.VertilinQ && (
        <XAuthInputs>
          <TextField
            value={props.url}
            onChange={e => props.setUrl(e.target.value)}
            label="Url"
            placeholder="(RPC endpoint)"
            fullWidth
          />
          <TextField
            value={props.token}
            onChange={e => props.setToken(e.target.value)}
            label="Token"
            fullWidth
          />
        </XAuthInputs>
      )}
      {customType === InterfaceMethod.ItsPerfect && (
        <XAuthInputs>
          <TextField
            value={props.url}
            onChange={e => props.setUrl(e.target.value)}
            label="Url"
            placeholder={`(${i18n.t('tooltip.companyname')}.itsperfect.it)`}
            fullWidth
          />
          <TextField
            value={props.token}
            onChange={e => props.setToken(e.target.value)}
            label="Token"
            fullWidth
          />
        </XAuthInputs>
      )}
      {customType === InterfaceMethod.LightAndLiving && (
        <XAuthInputs>
          <TextField
            value={props.portalName}
            onChange={e => props.setPortalName(e.target.value)}
            label="Portal Name"
            fullWidth
          />
          <TextField
            value={props.token}
            onChange={e => props.setToken(e.target.value)}
            label="Service Licencse"
            fullWidth
          />
          <TextField
            value={props.userName}
            onChange={e => props.setUserName(e.target.value)}
            label="Username"
            fullWidth
          />
          <TextField
            value={props.password}
            onChange={e => props.setPassword(e.target.value)}
            label="Password"
            type='password'
            fullWidth
          />
        </XAuthInputs>
      )}
       {customType === InterfaceMethod.DTP && (
        <XAuthInputs>
          <TextField
            value={props.url}
            onChange={e => props.setUrl(e.target.value)}
            label="FTP Url"
            fullWidth
          />
          <TextField
            value={props.userName}
            onChange={e => props.setUserName(e.target.value)}
            label="FTP Username"
            fullWidth
          />
          <TextField
            value={props.password}
            onChange={e => props.setPassword(e.target.value)}
            label="FTP Password"
            type='password'
            fullWidth
          />
        </XAuthInputs>
      )}
    </div>
  );
};

export default SpecificInterfaceMethod;

const XSelectTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & h6 {
    margin-bottom: 1rem;
  }
`;

const XAuthInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
`;
