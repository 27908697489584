import errorMappings from './../constants/ErrorMessages.NL.json'; // TODO dynamic load for other languages
import { ApiError } from './apiClient';

export const mapError = (error?: ApiError[]) => {
  if (!error) {
    // Call was unsuccessful but we didn't get an error?
    // Shouldn't happen.
    const defaultMapping = errorMappings.find(e => e.errorCode === 'default');
    return defaultMapping?.errorMessage;
  }

  const errorCode = error[0].errorCode;
  let errorMapping = errorMappings.find(e => e.errorCode === errorCode);
  if (!errorMapping) {
    errorMapping = errorMappings.find(e => e.errorCode === 'default');
  }

  return errorMapping?.errorMessage;
};
