import { API_URL_ACCOUNT, API_URL_AUTH, API_URL_AUTH_IMPERSONATED } from 'src/constants/ApiRoutes';
import { apiClient, ApiResult } from 'src/helpers/apiClient';
import { AuthenticationAccount } from './types';

export const authenticate = async (email: string, password: string): Promise<ApiResult> => {
  const data = {
    Email: email,
    Password: password
  };

  return await apiClient.postDataAsync(API_URL_AUTH, data);
};

export const authenticateImpersonated = async (token: string, impersonatedEmail: string): Promise<ApiResult> => {
  const data = {
    Token: token,
    ImpersonatedEmail: impersonatedEmail
  };

  return await apiClient.postDataAsync(API_URL_AUTH_IMPERSONATED, data);
};
