import styled from '@emotion/styled';

export const XActionStrip = styled('div')({
  display: 'flex',
  '& > *': {
    marginLeft: '5px!important'
  },
  '&.disabled': {
    opacity: '.5',
    '& *': {
      pointerEvents: 'none'
    }
  }
});
