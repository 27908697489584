import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import ArxisLogo from './images/svg/arxisdef.svg';
import LTXLogo from './images/svg/ltx-logo.svg';

function DomainRedirectPage() {
  useEffect(() => {
    let redirect = setTimeout(() => window.location.replace('https://arxis.io/'), 6000);

    return () => {
      clearTimeout(redirect);
    };
  }, []);

  return (
    <XRedirectPage>
      <XRedirectHeaderContainer>
        <h2>LTX is nu Arxis</h2>
        <h3>U wordt over enkele seconden doorgestuurd</h3>
      </XRedirectHeaderContainer>
      <XRedirectSection>
        <XRedirectLogo>
          <img src={LTXLogo} alt="LTX" title="LTX" />
        </XRedirectLogo>
        <XRedirectArrow>
          <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
            />
          </svg>
        </XRedirectArrow>
        <XRedirectLogo>
          <img src={ArxisLogo} alt="Arxis" title="Arxis" />
        </XRedirectLogo>
      </XRedirectSection>
    </XRedirectPage>
  );
}

export default DomainRedirectPage;

const pulse = keyframes`{
	0% {	
    transform: scale(1);}
	
	}
	
	50% {
		transform: scale(1.05);
	
	}
	
	100% {
		transform: scale(1);
	
	}
}
`;

const XRedirectPage = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(90deg, #371a90, #2f4cce);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    position: relative;
    justify-content: start;
  }
`;

const XRedirectHeaderContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
    & h2 {
      font-size: 2rem;
    }

    & h3 {
      font-size: 1rem;
      padding: 0 2rem;
    }
  }
`;

const XRedirectSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: fit-content;
    height: 70vh;
  }
`;

const XRedirectArrow = styled.div`
  animation: ${pulse} 2s infinite;

  & svg {
    height: 10em;
  }

  @media (max-width: 768px) {
    & svg {
      height: 4em;
      transform: rotate(90deg);
    }
  }
`;

const XRedirectLogo = styled.div`
  animation: ${pulse} 2s infinite;

  & img {
    height: 20em;
  }
  @media (max-width: 768px) {
    & img {
      height: 15em;
    }
  }
`;
