import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper, XPaperTitle } from 'src/components/layout/XPaper';
import { XTitle, XSubTitle } from 'src/components/layout/XPaperHeader';
import { XA } from '../myltx/AccountPage';

interface ErrorProps {
  title?: string;
  description?: string;
}

const NotFoundCard: React.SFC<ErrorProps & RouteComponentProps> = props => {
  return (
    <XPageContent style={{ maxWidth: '600px' }}>
      <XPaper>
        <XPaperTitle style={{ position: 'relative' }}>
          <XTitle>Deze pagina bestaat niet (meer).</XTitle>
          <XSubTitle>
            <XA onClick={() => props.history.goBack()}>Ga terug {'>'} </XA>
          </XSubTitle>
        </XPaperTitle>
      </XPaper>
    </XPageContent>
  );
};

export default NotFoundCard;
