import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

import { Catalog } from 'src/models/Catalog';
import { XImage, XImageContainer, XImageOverlay } from '../layout/XImage';

import { Tooltip } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/Check';
import DownloadedIcon from '@material-ui/icons/CheckCircle';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { formatDateToString, getImageUrl } from 'src/helpers/formatHelpers';
import Logo from '../../style/images/ltxLogos/ltxdefOpaque.svg';
import { CloudDownload } from '@material-ui/icons';
import { Translation } from 'react-i18next';

type OwnProps = {
  catalog: Catalog;
};

type AllProps = OwnProps;

const XCatalogSmall: React.SFC<AllProps> = props => {
  return (
    <CatalogContent>
      <XImageContainer width={75} height={75}>
        <XImage
          src={getImageUrl(props.catalog.imageURL, props.catalog.organizationImageURL)}
          alt=""
          onError={(e: any) => {
            e.target.onError = null;
            e.target.src = generateAvatar(props.catalog.title, 75);
          }}
        />
        {props.catalog.downloaded && (
          // eslint-disable-next-line react/jsx-no-undef
          <Translation>
            {t => (
              <Tooltip title={t('tooltip.prevDownloadedCatalog') + ' '}>
                <XImageOverlay>
                  <CloudDownload />
                </XImageOverlay>
              </Tooltip>
            )}
          </Translation>
        )}
      </XImageContainer>
      <CatalogText>
        <CatalogTitle>
          {props.catalog.title}{' '}
          {props.catalog.downloaded && (
            <Translation>
              {t => (
                <Tooltip title={t('tooltip.youDownloadedCatalog') + ' '}>
                  <DownloadCheck />
                </Tooltip>
              )}
            </Translation>
          )}
        </CatalogTitle>
        <CatalogLabel>{props.catalog.organizationName}</CatalogLabel>
        <CatalogLabel>
          {formatDateToString(props.catalog.lastCreationDate)} • {props.catalog.downloads} downloads
        </CatalogLabel>
      </CatalogText>
    </CatalogContent>
  );
};

export default XCatalogSmall;

const CatalogContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: 'auto'
});

export const CatalogTitle = styled('div')({
  fontSize: '14px',
  fontFamily: 'Montserrat-Bold' // TODO: get font from theme
});

export const CatalogLabel = styled('div')({
  fontSize: '10px',
  cursor: 'pointer'
});

const CatalogText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '7px',
  marginLeft: '8px',
  color: 'black'
});

const DownloadCheck = styled(DownloadedIcon)`
  color: green;
  font-size: 15px;
  vertical-align: top;
  margin-top: 3px;
`;
