import { css } from '@emotion/core';
import styled from '@emotion/styled';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import animateScrollTo from 'animated-scroll-to';
import * as React from 'react';
import { mediaQuery } from 'src/style/MediaQueries';

type OwnProps = {
  horizontal?: boolean;
  minwidth?: boolean;
  wrap?: boolean;
  style?: any;
};

type AllProps = OwnProps;

type State = {
  currentOffset: number;
  atEnd: boolean;
  contentFits: boolean;
};

class XList extends React.Component<AllProps, State> {
  private listview: React.RefObject<HTMLDivElement>;

  constructor(props: AllProps) {
    super(props);
    this.listview = React.createRef();
    this.state = {
      currentOffset: 0,
      atEnd: true,
      contentFits: true
    };
  }

  public componentDidMount = () => {
    this.updateFitState();
    window.addEventListener('resize', this.updateDimensions);
  };

  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  };

  public updateDimensions = () => {
    this.updateOffset();
  };

  public scroll = (direction: number) => {
    if (this.listview.current) {
      let pos = this.state.currentOffset;
      pos += (direction > 0 ? 1 : -1) * this.listview.current.clientWidth - 30;
      animateScrollTo(pos, {
        elementToScroll: this.listview.current,
        cancelOnUserAction: true
      }).then(this.updateOffset);
      this.setState({ currentOffset: pos });
    }
  };

  public updateOffset = () => {
    if (this.listview.current) {
      this.setState({ currentOffset: this.listview.current.scrollLeft }, this.updateFitState);
    }
  };

  public updateFitState = () => {
    if (this.listview.current) {
      this.setState({
        contentFits: this.listview.current.clientWidth > this.listview.current.scrollWidth,
        atEnd:
          this.state.currentOffset >
          this.listview.current.scrollWidth - this.listview.current.clientWidth - 10
      });
    }
  };

  public render() {
    const content = (
      <div>
        {' '}
        <List ref={this.listview} {...this.props}>
          {this.props.children}
        </List>
        {this.props.horizontal ? (
          <Paddles className="paddles">
            {this.state.currentOffset > 0 ? (
              <LeftPaddle onClick={() => this.scroll(-1)}>
                <ChevronLeftRoundedIcon css={PaddleIcon} style={{ verticalAlign: 'middle' }} />
              </LeftPaddle>
            ) : null}
            {!this.state.contentFits && !this.state.atEnd ? (
              <RightPaddle onClick={() => this.scroll(1)}>
                <ChevronRightRoundedIcon css={PaddleIcon} style={{ verticalAlign: 'middle' }} />
              </RightPaddle>
            ) : null}
          </Paddles>
        ) : null}
      </div>
    );
    if (this.props.horizontal) {
      return <Wrapper>{content}</Wrapper>;
    } else {
      return content;
    }
  }
}

export default XList;

const Wrapper = styled('div')({
  position: 'relative',
  ':hover .paddles': {
    opacity: 1
  }
});

const Paddles = styled('div')({
  opacity: 0,
  transition: 'opacity .2s ease-in-out'
});

const Paddle = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: 'calc(3em + 3%)',
  background: '#fff',
  cursor: 'pointer'
});

const PaddleIcon = css({
  left: 'calc(50% - .5em)',
  top: 'calc(50% - 60px)',
  position: 'absolute'
});

const LeftPaddle = styled(Paddle)({
  left: 0,
  background: 'linear-gradient(to right, rgba(255,255,255,1)  , rgba(255,255,255,0) )'
});

const RightPaddle = styled(Paddle)({
  right: 0,
  background: 'linear-gradient(to left, rgba(255,255,255,1)  , rgba(255,255,255,0))'
});

const List = styled('div')(
  {
    display: 'flex',
    width: '100%',
    WebkitOverflowScrolling: 'touch'
  },
  (props: AllProps) => ({
    flexDirection: props.horizontal ? 'row' : 'column',
    flexWrap: props.wrap ? 'wrap' : 'nowrap',
    overflowX: props.horizontal ? 'scroll' : 'hidden',
    overflowY: !props.horizontal ? 'scroll' : 'hidden',
    [mediaQuery[2]]: {
      overflowX: props.horizontal ? 'auto' : 'hidden',
      overflowY: !props.horizontal ? 'auto' : 'hidden'
    }
  })
);
