import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import XPageRouter from 'src/components/shared/XPageRouter';
import { PageRoute } from 'src/models/Route';
import ShowCatalogPage from '../shared/ShowCatalogPage';
import CatalogsPage from './CatalogsPage';

type StateProps = {};

type DispatchProps = {};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

export const CatalogsRoute: PageRoute = {
  path: `/app/explore/catalogs`,
  exact: true,
  component: CatalogsPage
};

// catalog
export const CatalogRoute: PageRoute = {
  path: `/app/explore/catalogs/:id`,
  exact: true,
  component: ShowCatalogPage
};

const Routes: PageRoute[] = [CatalogsRoute, CatalogRoute];

class BrowseRouter extends React.Component<AllProps> {
  public render() {
    return <XPageRouter routes={Routes} {...this.props} />;
  }
}

const mapStateToProps = (): StateProps => {
  return {};
};

const mapDispatchToProps = (): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BrowseRouter);
