import styled from '@emotion/styled';
import { LinearProgress, Select } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { XContainer } from 'src/components/layout/XContainer';
import XFileSelectButton, { XButtonStyle } from 'src/components/layout/XFileSelectButton';
import XForm from 'src/components/layout/XForm';
import XFormButtons from 'src/components/layout/XFormButtons';
import { ApiResult } from 'src/helpers/apiClient';
import { getFileFormatOptions } from 'src/helpers/formatHelpers';
import { FileFormat } from 'src/models/FileFormat';
import { OrganizationType } from 'src/models/Organization';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../../store';
import * as catalogActions from '../../../store/catalogs/actions';
import * as catalogSelects from '../../../store/catalogs/selectors';
import { CatalogFormData } from '../../../store/catalogs/types';
import { AccessRoute } from './WizardRouter';

type StateProps = {
  data: CatalogFormData;
  account: AuthenticationAccount;
  fileFormats: FileFormat[];
};

type DispatchProps = {
  storeInformation: (data: CatalogFormData) => void;
  validateCatalogFile: (
    fileFormat: string,
    fileFormatVersion: string,
    file: File
  ) => Promise<ApiResult>;
  getFileFormats: () => void;
  showNotification: (text: string, type: MessageType) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

type State = {
  newData: CatalogFormData;
  validating: boolean;
  inputErrors: { fileFormatSelection: boolean };
  fileFormatSelection: string | null;
  uploading: boolean;
};

class WizardFilePage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      validating: false,
      fileFormatSelection: props.data.fileFormat
        ? props.data.fileFormat + '|' + props.data.fileFormatVersion
        : null,
      inputErrors: { fileFormatSelection: false },
      newData: props.data,
      uploading: window.location.href.indexOf('/new/') !== -1
    };
  }

  public componentDidMount() {
    if (this.props.account.organizationType === OrganizationType.Retailer) {
      this.props.history.push('/app/start');
      return;
    }

    if (this.state.uploading && this.props.data.uploaded) {
      this.props.history.push('/app/my/catalogs/new');
      return;
    }

    this.props.getFileFormats();
  }

  public handleFormFileSelectChange = (e: any) => {
    const newData = Object.assign({}, this.state.newData);
    if (e.target.files.length === 0) return;

    newData[e.target.name] = e.target.files[0];
    this.setState({ newData });
  };

  public handleFormChange(name: string, value: any) {
    const newData = Object.assign({}, this.state.newData);
    if (name === 'fileFormatSelection') {
      const split = value?.split('|') ?? [undefined, undefined];
      newData.fileFormat = split[0];
      newData.fileFormatVersion = split[1];
      this.setState({ fileFormatSelection: value });
    } else newData[name] = value;
    this.setState({ newData });
  }

  public previousPage = () => {
    this.props.history.goBack();
  };

  public next = () => {
    if (!this.state.newData.fileFormat || !this.state.newData.fileFormatVersion) {
      const inputErrors = Object.assign({}, this.state.inputErrors);
      inputErrors.fileFormatSelection = !this.state.fileFormatSelection;
      this.setState({ inputErrors });
      return;
    }

    if (!this.state.newData.file?.size) return;

    this.props.storeInformation(this.state.newData);
    this.props.history.push(AccessRoute.path);
  };

  public render() {
    return (
      <XContainer style={{ position: 'relative', padding: '15px 15px 0 15px' }}>
        {!this.props.fileFormats ? (
          <LinearProgress />
        ) : (
          <div>
            <XForm
              data={this.state}
              inputErrors={this.state.inputErrors}
              margin={false}
              fields={[
                {
                  title: 'Bestands formaat',
                  name: 'fileFormatSelection',
                  required: true,
                  type: 'select',
                  options: getFileFormatOptions(this.props.fileFormats, this.state.uploading)
                }
              ]}
              callback={(e, v) => this.handleFormChange(e, v)}
            />

            <div style={{ marginBottom: '15px' }}>
              <XFileSelectButton
                onSelect={this.handleFormFileSelectChange}
                name="file"
                active={
                  !this.state.validating &&
                  this.state.newData.fileFormat?.length > 0 &&
                  this.state.newData.fileFormatVersion?.length > 0
                }
                colorscheme={XButtonStyle.Secondary}>
                Selecteer bestand
              </XFileSelectButton>
            </div>

            <XFormButtons
              style={{ padding: '0' }}
              onSave={this.next}
              onCancel={this.previousPage}
              cancelText="Terug"
              loading={this.state.validating}
              saveText="Volgende"
              enabledSave={!this.state.validating && this.state.newData.file?.size > 0}
            />
          </div>
        )}
      </XContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    data: catalogSelects.newCatalogState(state.catalogs),
    fileFormats: state.catalogs.fileFormats.sort((a, b) => a.name.localeCompare(b.name))
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getFileFormats: () => dispatch(catalogActions.getFileFormatsThunk()),
  storeInformation: (data: CatalogFormData) => dispatch(catalogActions.storeCatalogAction(data)),
  validateCatalogFile: (fileFormat: string, fileFormatVersion: string, file: File) =>
    dispatch(catalogActions.validateCatalogFileThunk(fileFormat, fileFormatVersion, file)),
  showNotification: (text: string, type: MessageType) => dispatch(showNotification(text, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardFilePage);
