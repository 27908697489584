import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';

import styled from '@emotion/styled';
import { LinearProgress } from '@material-ui/core';
import Cookies from 'js-cookie';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import XLTXInformation from 'src/components/domain/XLTXInformation';
import XField from 'src/components/layout/XField';
import XFormButtons from 'src/components/layout/XFormButtons';
import { XPaper } from 'src/components/layout/XPaper';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { ApplicationState } from 'src/store';
import { sendPasswordResetThunk } from 'src/store/accounts/actions';
import { AuthenticationState } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import Logo from '../../style/images/logos/main.svg';
import { LeftSide, LeftSideInner, LTXLogo, RightSide } from '../login/LoginPage.style';

type OwnProps = {};

type State = { email: string; sent: boolean; loading: boolean };

type DispatchProps = {
  sendPasswordReset: (email: string) => Promise<ApiResult>;
  showNotification: (text: string, type?: MessageType) => void;
};

type AllProps = OwnProps & DispatchProps & RouteComponentProps;

class PasswordForgottenPage extends React.Component<AllProps, State> {
  constructor(props: any) {
    super(props);
    this.state = { email: '', sent: false, loading: false };
  }

  public sent() {
    this.setState({ loading: true });
    this.props.sendPasswordReset(this.state.email).then(apiResult => {
      if (!apiResult.IsSuccess) {
        this.props.showNotification(
          'Er ging iets mis bij het versturen van de email: ' + mapError(apiResult.Errors),
          'error'
        );
        this.setState({ loading: false });
      } else {
        this.setState({ sent: true });
      }
    });
  }

  public handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.setState({ email: e.target.value });
      this.sent();
    }
  };

  public render() {
    return (
      <LogoutLayout>
        <LeftSide>
          <LeftSideInner>
            {!this.state.sent ? (
              <LogoutInner>
                <StyledDiv>
                  Wachtwoord vergeten? Vul hieronder je email adres in om je wachtwoord the
                  resetten.
                </StyledDiv>
                <XField
                  required={true}
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  onKeyPress={this.handleKeyPress}
                  placeholder="Email adres"
                />
                <XFormButtons
                  onCancel={e => this.props.history.goBack()}
                  onSave={e => this.sent()}
                  saveText="verstuur"
                  cancelText="terug"
                  enabled={!this.state.loading}
                  loading={this.state.loading}
                />
              </LogoutInner>
            ) : (
              <LogoutInner>
                Er is een bevestiging verzonden naar je email adres, check je inbox om je wachtwoord
                opnieuw in te stellen.
              </LogoutInner>
            )}
          </LeftSideInner>
        </LeftSide>
        <RightSide>
          <XLTXInformation />
        </RightSide>

        <LTXLogo src={Logo} />
      </LogoutLayout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (text: string, type?: MessageType) => dispatch(showNotification(text, type)),
  sendPasswordReset: (email: string) => dispatch(sendPasswordResetThunk(email))
});
export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgottenPage);

const LogoutLayout = styled.div`
  height: 100vh;
  overflow: hidden;
  background: rgb(45, 128, 194);
  display: flex;
`;

const LogoutInner = styled(XPaper)`
  padding: 30px;
  text-align: center;
  width: 500px;
  flex: 1;
`;

const StyledDiv = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;
