import styled from '@emotion/styled';
import { IconButton, Snackbar, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import BusinessIcon from '@material-ui/icons/AccountCircle';
import XFooter from 'src/components/layout/XFooter';
import { mediaQuery } from 'src/style/MediaQueries';
import HelpIcon from '@material-ui/icons/ContactSupportOutlined';
import { ApplicationState } from 'src/store';
import {
  hideConfirmation,
  hideNotification,
  showNotification,
  showSnackbar
} from 'src/store/layout/actions';
import { ConfirmationState, NotificationState, SnackbarState } from 'src/store/layout/types';

import XConfirmation from 'src/components/layout/XConfirmation';
import XToast from 'src/components/layout/XToast';
import { ApiResult } from 'src/helpers/apiClient';
import { getMyAccountThunk } from 'src/store/accounts/actions';
import { AccountModel } from 'src/store/accounts/types';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { PortalPages } from '../pages/Routes';
import { MyOrganizationModel, Organization } from 'src/models/Organization';
import { getMyOrganizationThunk } from 'src/store/organizations/actions';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import NotFoundPage from 'src/pages/system/NotFoundPage';
import NotFoundCard from 'src/pages/system/NotFoundCard';
import { Translation } from 'react-i18next';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Logo from 'src/style/images/logos/main.svg';

// Props
type State = {
  expanded: boolean;
  accountPopoverShow: boolean;
  anchorEl: HTMLElement | null;
  helpPopoverShow: boolean;
};

type StateProps = {
  snackbar: SnackbarState;
  notification: NotificationState;
  confirmation: ConfirmationState;
  account: AuthenticationAccount;
  myAccount?: AccountModel;
  myOrganization?: MyOrganizationModel;
};

type OwnProps = {
  collapsed: boolean;
};

type DispatchProps = {
  showSnackbar: (message: string) => void;
  showNotification: (message: string) => void;
  hideNotification: () => void;
  hideConfirmation: () => void;
  getMyAccount: () => Promise<ApiResult>;
  getMyOrganization: () => void;
};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps;

// Styles

const LayoutContainer = styled('div')({
  display: 'flex'
});

const MainContainer = styled('div')({
  backgroundImage: 'url(' + require('../style/images/background.jpg') + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'fixed',
  top: '80px',
  left: '0',
  right: '0',
  bottom: '0',
  [mediaQuery[0]]: {
    position: 'relative',
    height: '100vh',
    flex: '1',
    top: 'inherit'
  }
});

const Navbar = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  background: '#fff',
  width: '100vw',
  height: '80px',
  position: 'relative',
  overflow: 'hidden',
  [mediaQuery[0]]: {
    width: '110px',
    height: '100vh'
  }
});

const SubNavbar = styled('div')({
  height: '100vh',
  position: 'fixed',
  top: '80px',
  zIndex: 1,
  transform: 'translateX(-100%)',
  transition: '.2s ease-in-out all',
  [mediaQuery[0]]: {
    top: 'unset',
    transform: 'unset',
    position: 'relative'
  }
});
const NavbarHandle = styled('span')({
  display: 'block',
  position: 'absolute',
  padding: '15px',
  background: 'white',
  right: 0,
  transform: 'translateX(100%)',
  bottom: '20%',
  zIndex: 1,
  cursor: 'pointer',
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
  [mediaQuery[0]]: {
    display: 'none'
  }
});

const UserIcon = styled('div')({
  width: '32px',
  height: '32px',
  backgroundSize: 'cover',
  backgroundPosition: '50%',
  border: 'solid 2px white',
  borderRadius: '50%',
  cursor: 'pointer'
});

const OffIcon = styled(PowerSettingsNewIcon)<any>({}, (props: any) => ({
  color: props.impersonated ? '#E7217B' : '#737373',
  width: '30px',
  height: '30px',
  cursor: 'pointer'
}));

const HelperIcon = styled(HelpIcon)<any>({}, (props: any) => ({
  color: props.impersonated ? '#E7217B' : '#737373',
  width: '32px',
  height: '32px',
  cursor: 'pointer'
}));

// Layout, or Shell, or Skeleton for Main App (authenticated)
class PortalLayout extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      expanded: false,
      accountPopoverShow: false,
      anchorEl: null,
      helpPopoverShow: false
    };
  }
  public handleCloseNotification = (): void => {
    this.props.hideNotification();
  };

  public handleConfirm = () => {
    this.props.hideConfirmation();
    this.props.confirmation.onConfirm();
  };

  public componentDidMount() {
    if (!this.props.account.accountId) {
      this.props.history.replace('/login/' + encodeURIComponent(this.props.location.pathname));
      return;
    }

    this.props.getMyAccount();
    this.props.getMyOrganization();
  }

  public componentWillReceiveProps(props: AllProps) {
    if (!props.account.accountId) {
      this.props.history.replace('/login/' + encodeURIComponent(this.props.location.pathname));
    }
  }

  public handleAccountPopover(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      accountPopoverShow: !this.state.accountPopoverShow,
      anchorEl: e.currentTarget
    });
  }

  public handleAccountPopoverClose() {
    this.setState({
      accountPopoverShow: false
    });
  }

  public handleHelpPopover(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      helpPopoverShow: !this.state.helpPopoverShow,
      anchorEl: e.currentTarget
    });
  }

  public handleHelpPopoverClose() {
    this.setState({
      helpPopoverShow: false
    });
  }

  public render() {
    if (!this.props.account.accountId) {
      return null;
    }

    const portalPages = PortalPages.filter(
      p => p.shouldMapCallback === undefined || p.shouldMapCallback(this.props.account.isAdmin)
    );

    const impersonated = sessionStorage.getItem('impersonated') || false;

    let avatarImgUrl = this.props.myOrganization?.organization.imageURL;
    if (avatarImgUrl === undefined || avatarImgUrl === null)
      avatarImgUrl = generateAvatar(
        this.props.myOrganization?.organization.name || this.props.myAccount?.firstName || '',
        100
      );

    return (
      <LayoutContainer>
        <Navbar>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '100px' }}>
            <NavLink to="/app/start">
              <XLogo src={Logo} />
            </NavLink>
          </div>
          <div style={{ marginTop: '-2rem' }}>
            <Switch>
              {portalPages.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.navbar}
                  />
                );
              })}
            </Switch>
          </div>
          <div>
            <div style={{ display: 'flex', padding: '40px' }} onClick={this.handleHelpPopover.bind(this)}>
              <HelperIcon />
              <Popover
                open={this.state.helpPopoverShow}
                anchorEl={this.state.anchorEl}
                onClose={this.handleHelpPopoverClose.bind(this)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left'
                }}>
                <div style={{ display: 'flex', padding: '20px', minWidth: '300px' }}>
                  <Translation>
                    {t => (
                      <div style={{ display: 'flex', maxWidth: '400px' }}>{t('help.contact')}:&nbsp;</div>
                    )}
                  </Translation>
                </div>
              </Popover>
            </div>
            <XFooter>
              <FooterWrapper>
                <Tooltip
                  title={
                    this.props.myAccount?.firstName +
                    ' ' +
                    this.props.myAccount?.lastName +
                    ' | ' +
                    this.props.myAccount?.organizationName
                  }>
                  <div onClick={this.handleAccountPopover.bind(this)}>
                    <OffIcon impersonated={impersonated} />
                  </div>
                </Tooltip>
                <Popover
                  open={this.state.accountPopoverShow}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleAccountPopoverClose.bind(this)}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                  }}>
                  <div style={{ display: 'flex', padding: '20px', minWidth: '300px' }}>
                    <div style={{ padding: '10px' }}>
                      <UserIcon
                        style={{
                          backgroundImage: 'url(' + avatarImgUrl + ')'
                        }}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <b>
                        {this.props.myAccount?.firstName} {this.props.myAccount?.lastName}
                      </b>
                      <br />
                      {this.props.myAccount?.organizationName}
                    </div>
                    <Translation>
                      {t => (
                        <Tooltip title={t('tooltip.logOut') + ' '}>
                          <Link to="/logout">
                            <LogoutIcon />
                          </Link>
                        </Tooltip>
                      )}
                    </Translation>
                  </div>
                </Popover>
              </FooterWrapper>
            </XFooter>
          </div>
        </Navbar>
        {portalPages.length > 1 && (
          <SubNavbar style={{ transform: this.state.expanded ? 'none' : undefined }}>
            <div style={{ height: '100%', overflow: 'auto' }}>
              <Switch>
                {portalPages.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.sidebar}
                    />
                  );
                })}
                <Route component={NotFoundCard} />
              </Switch>
            </div>
            <NavbarHandle onClick={() => this.setState({ expanded: !this.state.expanded })}>
              {'||'}
            </NavbarHandle>
          </SubNavbar>
        )}
        <MainContainer>
          <Switch>
            {portalPages.map((route, index) => {
              return (
                <Route key={index} path={route.path} exact={route.exact} component={route.main} />
              );
            })}
            <Route component={NotFoundCard} />
          </Switch>
        </MainContainer>
        <XConfirmation
          title={this.props.confirmation.title}
          text={this.props.confirmation.message}
          open={this.props.confirmation.show}
          onConfirm={() => this.handleConfirm()}
          onCancel={() => this.props.hideConfirmation()}
        />
        <Snackbar // Notification / Toasts
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.props.notification.show}
          onClose={() => this.handleCloseNotification()}
          autoHideDuration={200000}>
          <XToast
            close={() => this.handleCloseNotification()}
            type={this.props.notification.type}
            message={this.props.notification.message}
          />
        </Snackbar>
        <Snackbar // Snackbar / ActionBar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.props.snackbar.show}
          autoHideDuration={6000}
          message={<span id="message-id">{this.props.snackbar.message}</span>}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit">
              <CloseIcon />
            </IconButton>
          ]}
        />
      </LayoutContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    snackbar: state.layout.snackbar,
    notification: state.layout.notification,
    confirmation: state.layout.confirmation,
    account: state.authentication.account,
    myAccount: state.account.my,
    myOrganization: state.organizations.myOrganization
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showSnackbar: (message: string) => {
    dispatch(showSnackbar(message));
  },
  showNotification: (message: string) => {
    dispatch(showNotification(message));
  },
  hideConfirmation: () => dispatch(hideConfirmation()),
  hideNotification: () => {
    dispatch(hideNotification());
  },
  getMyAccount: () => dispatch(getMyAccountThunk()),
  getMyOrganization: () => dispatch(getMyOrganizationThunk())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalLayout) as any);

const Connections = styled(BusinessIcon)({
  width: '35px',
  height: '35px',
  alignSelf: 'center'
});

const FooterWrapper = styled.div`
  display: flex;
  flex: 1 1 345px;
  align-items: space-between;
  justify-content: center;
`;

export const XLogo = styled('img')({
  left: '10px',
  right: '10px',
  top: 'calc(50% - 100px)',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxHeight: '62.5px',
  maxWidth: '62.5px',
  animationDuration: '.6s',
  marginBottom: '10px',
  marginTop: '20px',
  display: 'none',
  [mediaQuery[0]]: {
    display: 'inherit'
  },
  filter: `drop-shadow(4px 4px 8px #58585883)`
});

const NavLink = styled(Link)({
  flex: 1,
  textAlign: 'center',
  textTransform: 'none',
  textDecoration: 'none'
});
