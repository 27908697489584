import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import * as React from 'react';

type OwnProps = { placeholder?: string };

type AllProps = OwnProps & TextFieldProps;

const XField: React.SFC<AllProps> = props => {
  return <Field autoComplete={'new-password'} {...props} />;
};

const Field = styled(TextField)<any>({
  marginBottom: '15px',
  width: '100%'
});

export default XField;
