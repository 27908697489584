import { Message } from 'src/models/Message';
import { CatalogMessageModel } from 'src/models/Message'
import { OrderMessage } from 'src/models/OrderMessage';

export enum ActionTypes {
  GET_MESSAGES = '[messages] GET_MESSAGES',
  GET_NEW_MESSAGES = '[newMessages] GET_NEW_MESSAGES',
  SEND_MESSAGE = '[messages] SEND_MESSAGE',
  UPDATE_MESSAGE = '[messages] UPDATE_MESSAGE',
  GET_CATALOG_MESSAGES = '[messages] GET_CATALOG_MESSAGES',
  GET_ORGANIZATION_CATALOG_MESSAGES = '[messages] GET_ORGANIZATION_CATALOG_MESSAGES',
  GET_SENT_ORDER_MESSAGES = '[messages] GET_SENT_ORDER_MESSAGES',
  GET_ORDER_MESSAGES = '[messages] GET_ORDER_MESSAGES'
}

export interface MessageState {
  loading: boolean;
  readonly newMessages: Message[];
  messages: Message[];
  readonly errors?: string;
  catalogMessages?: CatalogMessageModel[];
  orderMessages?: OrderMessage[];
}

