import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const XSideBar = styled('div')({
  width: '245px',
  height: '100%',
  backgroundColor: 'rgb(245, 245, 245)',
  borderTop: '1px solid lightgrey',
  textAlign: 'center',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const XSideBarHeader = styled('header')({
  padding: '20px'
});

export const XSideBarText = styled('label')({
  fontSize: '12px'
});

export const XAdminSideBar = styled('div')({
  width: '280px',
  height: '100%',
  backgroundColor: 'rgb(245, 245, 245)',
  borderTop: '1px solid lightgrey',
  textAlign: 'center',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  paddingBottom: '80px',
  paddingTop: '60px'
});
