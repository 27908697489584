import { OrganizationType } from 'src/models/Organization';
import { AccountSetting } from '../authentication/types';

export enum ActionTypes {
  CREATE_ACCOUNT = '[account] CREATE_ACCOUNT',
  EDIT_ACCOUNT = '[account] EDIT_ACCOUNT',
  GET_ORGANIZATION = '[account] GET_ORGANIZATION',
  STORE_REGISTER_DATA = '[account] STORE_REGISTER_DATA',
  GET_SETTINGS = '[account] GET_SETTINGS',
  SAVE_SETTINGS = '[account] SAVE_SETTINGS',
  VERIFY_EMAIL_CODE = '[account] VERIFY_EMAIL_CODE',
  SEND_PASSWORD_RESET = '[account] SEND_PASSWORD_RESET',
  RESET_PASSWORD = '[account] RESET_PASSWORD',
  CHECK_EMAIL_AVAILABLE = '[account] CHECK_EMAIL_AVAILABLE',
  LIST_ACCOUNTS = '[account] LIST_ACCOUNTS',
  GET_ACCOUNT = '[account] GET_ACCOUNT',
  GET_MY_ACCOUNT = '[account] GET_MY_ACCOUNT',
  EDIT_MANAGE_ACCOUNT = '[account] EDIT_MANAGE_ACCOUNT',
  DELETE_ACCOUNT = '[account] DELETE_ACCOUNT',
  CREATE_ACCOUNT_FOR_ORGANIZATION = '[account] CREATE_ACCOUNT_FOR_ORGANIZATION'
}

export interface UserFormData {
  email: string;
  password: string;
  passwordValid: string;
  firstName: string;
  lastName: string;
  language: string;
}

export interface CreateAccountFormData extends UserFormData {
  organization: string;
}

export interface OrganizationFormData {
  name: string;
  description: string;
  companyEmail: string;
  image: any;
  organizationType: OrganizationType;
  maturityStatus: number;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  country: string;
  telephoneNumber: string;
  website: string;
  // iban: string;
  // btwNumber: string;
}

export interface RegisterAccountFormData extends UserFormData, OrganizationFormData {}

export interface AccountState {
  loading: boolean;
  errors?: string;
  accountFormData: RegisterAccountFormData;
  all: AccountListModel[];
  details?: AccountDetails;
  my?: AccountModel;
  settings: AccountSetting[];
}

export type AccountModel = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  active: boolean;
};

export type AccountListModel = {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
  organizationType: OrganizationType;
  registerDate: Date;
  lastLoginDate?: Date;
};

export type AccountDetails = {
  account: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    creationDate: Date;
    enabled: boolean;
    emailConfirmed: boolean;
    defaultOrganizationId: string;
  };
  activities: AccountActivity[];
};

type AccountActivity = {
  id: string;
  activityCode: number;
  timestamp: Date;
};

export type AccountEditModel = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  emailConfirmed: boolean;
};
