import { Snackbar } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import XToast from 'src/components/layout/XToast';
import { PublicPages } from 'src/pages/Routes';
import { ApplicationState } from 'src/store';
import { hideNotification, showNotification, showSnackbar } from 'src/store/layout/actions';
import { NotificationState, SnackbarState } from 'src/store/layout/types';

type StateProps = {
  snackbar: SnackbarState;
  notification: NotificationState;
  isAuthenticated: boolean;
};

type DispatchProps = {
  showSnackbar: (message: string) => void;
  showNotification: (message: string) => void;
  hideNotification: () => void;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps;

// Layout, or Shell, or Skeleton for login and register (unauthenticated)
class PublicLayout extends React.Component<AllProps> {
  public render() {
    return (
      <div>
        <Switch>
          {PublicPages.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}

          <Route render={() => <Redirect to="/404" />} />
        </Switch>
        <Snackbar // Notification / Toasts
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.props.notification.show}
          onClose={this.props.hideNotification}
          autoHideDuration={6000}>
          <XToast
            close={() => this.props.hideNotification()}
            type={this.props.notification.type}
            message={this.props.notification.message}
          />
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    snackbar: state.layout.snackbar,
    notification: state.layout.notification,
    isAuthenticated: state.authentication.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  showSnackbar: (message: string) => {
    dispatch(showSnackbar(message));
  },
  showNotification: (message: string) => {
    dispatch(showNotification(message));
  },
  hideNotification: () => {
    dispatch(hideNotification());
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicLayout) as any);
