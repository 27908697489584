import * as React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import XMessage from 'src/components/domain/XMessage';
import { XPaperCenter, XPaperContent, XPaperTitle } from 'src/components/layout/XPaper';
import { Message, MessageType } from 'src/models/Message';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { getMessagesThunk } from 'src/store/messages/actions';

import * as messageSelects from '../../store/messages/selectors';

// Props
type StateProps = {
  messages: Message[];
  account: AuthenticationAccount;
};

type DispatchProps = {
  getMessages: (id: string) => any;
};

type OwnProps = {
  title: string;
  type?: MessageType;
};

type AllProps = StateProps & DispatchProps & OwnProps;

// State
type State = {
  sort: number;
};

class ValidationPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      sort: 0
    };
  }

  public componentDidMount() {
    this.props.getMessages(this.props.account.organizationId);
  }

  public render() {
    return (
      <XPaperCenter style={{ minHeight: '700px' }} key="message">
        <XPaperTitle className="window-header">{this.props.title}</XPaperTitle>
        <Translation>
          {t => <XPaperContent>{t('validation.Validation')}</XPaperContent>}
        </Translation>
      </XPaperCenter>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    messages: messageSelects.getMessageState(state.messages)
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getMessages: (organizationId: string) => dispatch(getMessagesThunk(organizationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidationPage);
