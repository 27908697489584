import { createSelector } from 'reselect';
import { AccountState } from './types';

export const getState = (state: AccountState) => state;
export const getRegisterState = (state: AccountState) => state.accountFormData;

// export const getLoggedin = createSelector(getState, s => s.loggedin);
// export const getOrganization = createSelector(getState, s => s.organizations);

export const registerState = createSelector(getRegisterState, s => s);
