import { BaseReducer } from '../../helpers/baseReducer';
import { ActionTypes, SubscriptionState } from './types';
import { Action } from './actions';


export const initialState: SubscriptionState = {
  subscriptions: [],
  errors: undefined,
  loading: false,
};


export class SubscriptionReducer extends BaseReducer<SubscriptionState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: SubscriptionState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_SUBSCRIPTIONS: {
        if (action.status === 'success' && action.payload.subscriptions)
          newState.subscriptions = action.payload.subscriptions;
        else newState.subscriptions = [];
        return newState;
      }

      default:
        return undefined;
    }
  }
}
