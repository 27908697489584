import { OrganizationGroup } from 'src/models/OrganizationGroup';
import { apiClient, ApiResult } from '../../helpers/apiClient';

export const listOrganizationGroups = async (): Promise<OrganizationGroup[] | null> => {
  const result = await apiClient.postQueryAsync('ListOrganizationGroups', {});

  if (result.IsSuccess) {
    const groups = result.JsonResponse as OrganizationGroup[];
    return groups;
  } else {
    return null;
  }
};
