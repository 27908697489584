import * as React from 'react';

import styled from '@emotion/styled';
import { InformationContent, InformationPaper } from 'src/pages/login/LoginPage.style';
import { Translation } from 'react-i18next';

type OwnProps = {};

type AllProps = OwnProps;

const XLTXInformation: React.SFC<AllProps> = () => {
  return (
    <InformationPaper>
      <InformationContent>
        <Translation>
          {t => <header id="topHeader"> {t('header.arxisPlatform')} </header>}
        </Translation>

        <ContactInfo>
          <div>
            <ContactLink
              target="blank"
              href="http://maps.google.com/?q=Piet Heinkade 55,1019 GM Amsterdam, Nederland">
              Piet Heinkade 55
            </ContactLink>
          </div>
          <div>
            <ContactLink
              target="blank"
              href="http://maps.google.com/?q=Piet Heinkade 55,1019 GM Amsterdam, Nederland">
              1019 GM Amsterdam, Nederland
            </ContactLink>
          </div>
          <div>
            <ContactLink href="mailto:info@arxis.io">info@arxis.io</ContactLink>
          </div>
          <div>
            <ContactLink href="tel:+31 (0) 88 2400 110">+31 (0) 88 2400 110</ContactLink>
          </div>
        </ContactInfo>
      </InformationContent>
    </InformationPaper>
  );
};

export default XLTXInformation;

const ContactLink = styled.a`
  color: #ccc;
  text-decoration: none;

  &:hover {
    color: #ccc;
  }
`;

const ContactInfo = styled.div`
  color: #ccc;
  position: fixed;
  right: 50px;
  bottom: 50px;
  text-align: right;
`;
