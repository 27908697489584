import { push } from 'react-router-redux';

import { BaseReducer } from 'src/helpers/baseReducer';
import { OrganizationType } from 'src/models/Organization';
import { Action } from './actions';
import { AccountState, ActionTypes } from './types';

export const initialState: AccountState = {
  accountFormData: {
    email: '',
    password: '',
    passwordValid: '',
    firstName: '',
    lastName: '',
    name: '',
    companyEmail: '',
    description: '',
    image: undefined,
    maturityStatus: 100,
    organizationType: OrganizationType.Retailer,
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    country: '',
    telephoneNumber: '',
    website: '',
    language: '',
    // iban: '',
    // btwNumber: ''
  },
  all: [],
  my: undefined,
  settings: [],
  details: undefined,
  errors: undefined,
  loading: false
};

export class AccountReducer extends BaseReducer<AccountState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: AccountState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_SETTINGS: {
        if (action.status === 'success' && action.payload.settings)
          newState.settings = action.payload.settings;
        else newState.settings = [];
        return newState;
      }
      case ActionTypes.LIST_ACCOUNTS: {
        if (action.status === 'success' && action.payload.accounts)
          newState.all = action.payload.accounts;
        else newState.all = [];
        return newState;
      }
      case ActionTypes.GET_ACCOUNT: {
        if (action.status === 'success' && action.payload.account)
          newState.details = action.payload.account;
        else newState.details = undefined;
        return newState;
      }
      case ActionTypes.GET_MY_ACCOUNT: {
        if (action.status === 'success' && action.payload.account)
          newState.my = action.payload.account;
        else newState.my = undefined;
        return newState;
      }
      case ActionTypes.STORE_REGISTER_DATA: {
        newState.accountFormData = action.payload.data;
        return newState;
      }
      default:
        return undefined;
    }
  }
}
