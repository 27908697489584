import styled from '@emotion/styled';
import React, { Component, useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { useMediaQuery } from 'react-responsive';

const handleDragStart = e => e.preventDefault();
export default () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;

  let cards = 2;
  if (useMediaQuery({ minWidth: 767 })) cards = 4;
  if (useMediaQuery({ minWidth: 1267 })) cards = 6;

  return (
    <div style={{ padding: `0 ${chevronWidth}px`, maxWidth: '100%' }}>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={cards}
        gutter={20}
        rightChevron={<Bttn>{'>'}</Bttn>}
        leftChevron={<Bttn>{'<'}</Bttn>}
        outsideChevron
        chevronWidth={chevronWidth}>
        {users.map(u => (
          <div key={u.title}>
            <div style={{ height: '130px', width: '130px', margin: 'auto', position: 'relative' }}>
              <Img alt={u.title} title={u.title} src={u.img} />
            </div>
          </div>
        ))}
      </ItemsCarousel>
    </div>
  );
};

const Bttn = styled('span')({
  display: 'inline-block',
  padding: '7px 14px',
  borderRadius: '50%',
  boxShadow: '0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08)',
  fontSize: '20px'
});

const Img = styled('img')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '100%',
  maxWidth: '100%'
});

const users = [
  {
    title: 'DeEekhoorn',
    img: require('../pages/images/users/DeEekhoorn.jpg')
  },
  {
    title: 'Artex',
    img: require('../pages/images/users/Artex.png')
  },
  {
    title: 'Cotap',
    img: require('../pages/images/users/cotap.png')
  },
  {
    title: 'Van Dyck',
    img: require('../pages/images/users/VanDyck.png')
  },
  {
    title: 'Eijerkamp',
    img: require('../pages/images/users/logo-eijerkamp.jpg')
  },
  {
    title: 'Maxfurn',
    img: require('../pages/images/users/Maxfurn.png')
  },
  {
    title: 'Bopita',
    img: require('../pages/images/users/Bopita.png')
  },
  {
    title: 'Gero Wonen',
    img: require('../pages/images/users/Gero wonen.jpg')
  },
  {
    title: 'Livingfurn',
    img: require('../pages/images/users/Livingfurn.jpg')
  },
  {
    title: 'Feelings',
    img: require('../pages/images/users/Feelings.png')
  },
  {
    title: 'LS Bedding',
    img: require('../pages/images/users/LS bedding.png')
  },
  {
    title: 'Groter in Wonen',
    img: require('../pages/images/users/Groterinwonen.png')
  },
  {
    title: 'Hjort Knudsen',
    img: require('../pages/images/users/HjortKnudsen.jpg')
  },
  {
    title: 'LABEL51',
    img: require('../pages/images/users/LABEL51.png')
  },
  {
    title: 'TMC Wonen',
    img: require('../pages/images/users/TMCwonen.png')
  },
  {
    title: 'HK Living',
    img: require('../pages/images/users/HKLiving.png')
  },
  {
    title: 'Woonland',
    img: require('../pages/images/users/Woonland.png')
  },
  {
    title: 'Odrada',
    img: require('../pages/images/users/odrada.png')
  },
  {
    title: 'Presenttime',
    img: require('../pages/images/users/presenttime.png')
  },
  {
    title: 'Jansen Totaal Wonen',
    img: require('../pages/images/users/Jansentotaalwonen.png')
  },
  {
    title: 'Perfecta',
    img: require('../pages/images/users/perfecta-01.png')
  },
  {
    title: 'DTP Interiors',
    img: require('../pages/images/users/DTPInteriors.png')
  },
  {
    title: 'Lucide',
    img: require('../pages/images/users/Lucide.png')
  },
  {
    title: 'Veldeman',
    img: require('../pages/images/users/veldeman.png')
  },
  {
    title: 'Light - Living',
    img: require('../pages/images/users/Light-living.jpg')
  },
  {
    title: 'Verberckmoes',
    img: require('../pages/images/users/verberckmoes.png')
  },
  {
    title: 'Bodilson',
    img: require('../pages/images/users/Bodilson.png')
  },
  {
    title: 'Den Ouden Wonen',
    img: require('../pages/images/users/denouden.jpg')
  },
  {
    title: 'IMS Benelux',
    img: require('../pages/images/users/IMSBenelux.png')
  },
  {
    title: 'Its about RoMi',
    img: require('../pages/images/users/itsaboutromi.png')
  },
  {
    title: 'Kayori',
    img: require('../pages/images/users/Kayori.png')
  },
  {
    title: 'Bonsnijder',
    img: require('../pages/images/users/bronsnijder.jpg')
  },
  {
    title: 'Vinke Beilen',
    img: require('../pages/images/users/vinke_beilen.jpg')
  },
  {
    title: 'Van der Linde voor Wonen en Slapen',
    img: require('../pages/images/users/linde_wonen_slapen.jpg')
  },
  {
    title: 'De Korte Woonsfeer',
    img: require('../pages/images/users/korte_woonsfeer.jpg')
  },
  {
    title: 'Hasker Kroon',
    img: require('../pages/images/users/hasker_kroon.jpg')
  },
  {
    title: 'Jacbos thuis en interieur',
    img: require('../pages/images/users/jacobs_thuis_interieur.jpg')
  }
];

//export default LTXUsers;
