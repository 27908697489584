import React, { FC } from 'react';
import styled from '@emotion/styled';
import { MappingItem } from '../../store/stocks/types';
import { CSVMappingTypes } from '../../models/Stock';
import { FormControl, Select, Divider } from '@material-ui/core';

type OwnProps = {
  mappingData: MappingItem[];
  onChange: (e: any) => void;
};

const XStockCSVMapping: FC<OwnProps> = React.memo(({ mappingData, onChange }) => {
  return (
    <>
      {mappingData.map(c => (
        <div key={c.column}>
          <XSelectionRow>
            <XCell>
              {`${c.column}`.substr(0, 20)}
              {`${c.column}`.length > 20 && '...'}
              {`${c.column}`.length > 20 && <ToolTipText> {c.column}</ToolTipText>}
            </XCell>

            {!!c.sampleData.length ? (
              <XCell>
                {`${c.sampleData[0]}`.substr(0, 20)}
                {`${c.sampleData[0]}`.length > 20 && '...'}
                {`${c.sampleData[0]}`.length > 20 && <ToolTipText> {c.sampleData[0]}</ToolTipText>}
              </XCell>
            ) : (
              <XCell></XCell>
            )}

            <FormControl variant="outlined">
              <XSelect id={c.column} native onChange={onChange} defaultValue={c.mappingType}>
                <option></option>
                {Object.keys(CSVMappingTypes).map(t => (
                  <option key={t} value={t}>
                    {t}
                  </option>
                ))}
              </XSelect>
            </FormControl>
          </XSelectionRow>
          <Divider />
        </div>
      ))}
    </>
  );
});

export default XStockCSVMapping;

const XSelectionRow = styled.div`
  display: flex;
  align-items: center;
`;

const XSelect = styled(Select)`
  margin: 1.5rem 0.5rem 1rem 0;
  width: 14rem;
  height: 2rem;
`;

const ToolTipText = styled('span')({
  visibility: 'hidden',
  backgroundColor: '#333333',
  color: '#fff',
  textAlign: 'center',
  borderRadius: '6px',
  padding: '5px 0',
  position: 'absolute',
  zIndex: 1,
  bottom: '150%',
  left: '50%',
  marginLeft: '-60px',
  ':after': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: 'black transparent transparent transparent'
  }
});

const XCell = styled('div')({
  position: 'relative',
  display: 'inline-block',
  width: '14rem',
  ':hover span': {
    visibility: 'visible'
  }
});
