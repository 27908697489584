export enum ActionTypes {
  SAVE_ORDER = '[purchase-order] SAVE_ORDER',
  DELETE_ORDER = '[purchase-order] DELETE_ORDER',
  GET_NOT_SEND_ORDERS = '[purchase-order] GET_NOT_SEND_ORDERS',
  GET_LIST_OF_SENT_ORDERS = '[purchase-order] GET_LIST_OF_SENT_ORDERS',
  GET_CATALOG_VIEW = '[purchase-order] GET_CATALOG_VIEW',
  SEND_ORDER = '[purchase-order] SEND_ORDER'
}

export type Artical = {
  gtin: string;
  name: string;
  price: number;
  qty: number;
};

export type Order = {
  retailerOrganizationId: string;
  supplierOrganizationId: string;
  catalogId: string;
  lines: Artical[];
  deliveryDate: Date;
  creationDate: Date;
  id: string;
  reference: string;
  orderNumber: string;
  supplierName: string;
  isChecked: boolean;
};

export type OrderFormData = {
  targetOrganizationId: string;
  deliveryDate: Date;
  catalogId: string;
  orderLines:  Artical[];
  deliveryAddress: string;
  deliveryPostcode: string;
  deliveryPhone: string;
  deliveryEmail: string;
  reference: string;
  comment: string;
};

export interface OrdersState {
  loading: boolean;
  listNotSendOrders: Order[];
  listOfSentOrders: Order[];
  catalogView: any
  errors?: string;
}



