import styled from '@emotion/styled';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import XCatalogRow from 'src/components/domain/XCatalogRow';
import XNoContent from 'src/components/domain/XNoContent';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import XTable from 'src/components/layout/XTable';
import { OrganizationModel } from 'src/models/Organization';
import { RequestModel } from 'src/models/Request';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { updateRequestThunk } from 'src/store/requests/actions';
import { CatalogRequestStatus } from 'src/store/requests/api';
import { ApplicationState } from '../../../store';
import * as organizationActions from '../../../store/organizations/actions';

import { LinearProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import XPagination from 'src/components/layout/XPagination';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import XScrollView from 'src/components/layout/XScrollView';
import EmptyImage from '../../../style/images/empty-canvas.svg';
import XSmallButton from 'src/components/layout/XSmallButton';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { XActionButton, XSecActionButton } from 'src/components/layout/XActionButton';
import XSearchBar from 'src/components/layout/XSearchBar';
import XActionDropdown from 'src/components/layout/XActionDropdown';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import { XRowCell } from 'src/components/layout/XRow';
import { Translation } from 'react-i18next';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  myOrganization?: OrganizationModel;
  account: AuthenticationAccount;
  loading: boolean;
};

type DispatchProps = {
  showNotification: (message: string) => void;
  getMyOrganization: () => void;
  editRequest: (catalogRequest: RequestModel, status: CatalogRequestStatus) => void;
};

type State = {
  currentOffset: number;
  search: string;
  sort: string;
  asc: boolean;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{}>;

class CatalogsPageSupplier extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      currentOffset: 0,
      search: '',
      sort: 'lastCreationDate',
      asc: false
    };
  }

  public componentDidMount() {
    this.props.getMyOrganization();
  }

  private Sort(property: string) {
    console.log(property);
    if (property === 'Publicatiedatum') {
      if (this.state.sort === 'publicationDate') this.setState({ asc: !this.state.asc });
      this.setState({ sort: 'publicationDate' });
    } else if (property === 'Toegevoegd') {
      if (this.state.sort === 'lastCreationDate') this.setState({ asc: !this.state.asc });
      this.setState({ sort: 'lastCreationDate' });
    }
  }

  private sortDate(a, b) {
    let itemA = a;
    let itemB = b;
    if (this.state.asc) {
      itemA = b;
      itemB = a;
    }

    if (!itemA[this.state.sort] && !itemB[this.state.sort]) return 0;
    if (!itemA[this.state.sort]) return 1;
    if (!itemB[this.state.sort]) return -1;
    return new Date(itemB[this.state.sort]).getTime() - new Date(itemA[this.state.sort]).getTime();
  }

  public render() {
    if (this.props.loading || !this.props.myOrganization)
      return (
        <XPageContent>
          <XPaper
            style={{
              position: 'relative',
              minHeight: '300px'
            }}>
            <Translation>
              {t => <XPaperHeader header={t('myArxis.myCatalogs')} subheader={<Skeleton />} />}
            </Translation>
            <XFilterBar>
              <XRowCell>
                <Translation>
                  {t => (
                    <XSearchBar
                      placeholder={t('catalogs.filterCatalogs')}
                      onChange={e =>
                        this.setState({ search: e.target.value.toLowerCase() })
                      }></XSearchBar>
                  )}
                </Translation>
              </XRowCell>
            </XFilterBar>

            <XScrollView>
              <XTable
                headers={[
                  'Catalogus',
                  'Downloads',
                  'Toegevoegd',
                  'Verwerkingsstatus',
                  'Publicatiestatus',
                  'Publicatiedatum',
                  ''
                ]}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </XTable>
            </XScrollView>
          </XPaper>
        </XPageContent>
      );

    const pageSize = 50;
    const catalogs = this.props.myOrganization.catalogs.filter(
      c => c.title.toLowerCase().indexOf(this.state.search) !== -1
    );
    const sorted = catalogs.sort((a, b) => this.sortDate(a, b));
    const paged = sorted.slice(this.state.currentOffset, this.state.currentOffset + pageSize);
    return (
      <ErrorBoundary>
        <XPageContent>
          <XPaper
            style={{
              position: 'relative',
              minHeight: '300px',
              height: this.props.myOrganization.catalogs.length === 0 ? '300px' : ''
            }}>
            <Translation>
              {t => (
                <XPaperHeader
                  header={t('myArxis.myCatalogs')}
                  subheader={t('catalogs.catalogsYouPublished')}
                  count={catalogs.length}
                  customContent={
                    <XActionStrip>
                      <XActionButton
                        onClick={() => this.props.history.push('/app/my/catalogs/new-empty')}>
                        {t('catalogs.createCatalog')}
                      </XActionButton>
                      <XActionButton
                        onClick={() => this.props.history.push('/app/my/catalogs/new')}>
                        {t('catalogs.uploadCatalog')}
                      </XActionButton>
                    </XActionStrip>
                  }
                />
              )}
            </Translation>
            <XFilterBar>
              <XRowCell>
                <Translation>
                  {t => (
                    <XSearchBar
                      placeholder={t('catalogs.filterCatalogs')}
                      onChange={e =>
                        this.setState({ search: e.target.value.toLowerCase() })
                      }></XSearchBar>
                  )}
                </Translation>
              </XRowCell>
            </XFilterBar>
            {catalogs.length === 0 && (
              <Translation>
                {t => (
                  <XNoContent image={EmptyImage}>
                    {this.state.search.length > 0 ? (
                      <div>{t('catalogs.noCatalogsFound')}</div>
                    ) : (
                      <div>
                        <h3>{t('catalogs.notPublishedAnyCatalogs')}</h3>
                        <div style={{ marginTop: '4px' }}>
                          <Link style={{ color: '#aaa' }} to="/app/my/catalogs/new">
                            <XSmallButton text={t('catalogs.addCatalogNow')} />
                          </Link>
                        </div>
                      </div>
                    )}
                  </XNoContent>
                )}
              </Translation>
            )}

            <XScrollView>
              <XTable
                headers={[
                  'Catalogus',
                  'Downloads',
                  'Toegevoegd',
                  'Verwerkingsstatus',
                  'Publicatiestatus',
                  'Publicatiedatum',
                  ''
                ]}
                onSort={p => this.Sort(p)}>
                {paged.map(obj => (
                  <XCatalogRow
                    showDownloads={true}
                    key={obj.id}
                    link={'/app/my/catalogs/edit/' + obj.id}
                    showUploadDate={true}
                    catalog={obj}
                    showProcessingStatus={true}
                    showPublicationStatus={true}
                    showPublicationDate={true}
                    showProcessingIcon={true}
                  />
                ))}
              </XTable>
            </XScrollView>

            {catalogs.length > pageSize && (
              <XPagination
                itemCount={catalogs.length}
                itemsPerPage={pageSize}
                changePage={i => this.setState({ currentOffset: i })}
              />
            )}
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    myOrganization: state.organizations.myOrganization,
    loading: state.organizations.loading
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string) => {
    dispatch(showNotification(message));
  },
  editRequest: (catalogRequest: RequestModel, status: CatalogRequestStatus) =>
    dispatch(updateRequestThunk(catalogRequest, status)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogsPageSupplier);
