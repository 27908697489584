import styled from '@emotion/styled';
import React from 'react';
import { connect } from 'react-redux';
import XNoContent from 'src/components/domain/XNoContent';
import XOrganizationSmall from 'src/components/domain/XOrganizationSmall';
import XGrid from 'src/components/layout/XGrid';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import {
  Organization,
  OrganizationType,
  OrganizationReadinessStatus
} from 'src/models/Organization';
import { OrganizationReadinessStatusList } from 'src/constants/OrganizationReadinessStatus';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { getOrganizationsThunk } from 'src/store/organizations/actions';
import * as OrganizationActions from 'src/store/organizations/actions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import GridIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import CheckIcon from '@material-ui/icons/Check';
import ConnectionsIcon from '@material-ui/icons/Link';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import XOrganizationTitle from 'src/components/domain/XOrganizationTitle';
import { XImage, XImageContainer, XImageOverlay } from 'src/components/layout/XImage';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import XScrollView from 'src/components/layout/XScrollView';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { getImageUrl } from 'src/helpers/formatHelpers';
import { changeView } from 'src/store/layout/actions';
import { ViewType } from 'src/store/layout/types';
import EmptyImage from '../../style/images/empty.svg';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import XSearchBar from 'src/components/layout/XSearchBar';
import XSubscriptionUpgradeModal from 'src/components/layout/XSubscriptionUpgradeModal';
import { Translation } from 'react-i18next';
import i18n from '../../i18n';
import { Skeleton } from '@material-ui/lab';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import XButton, { XButtonStyle } from 'src/components/layout/XButton';

type OwnProps = {};

type StateProps = {
  account: AuthenticationAccount;
  organizations: Organization[];
  connections: Organization[];
  view: ViewType;
  loading: boolean;
};

type DispatchProps = {
  getOrganizations: () => void;
  getConnections: () => any;
  changeView(type: ViewType): () => void;
};

type State = {
  currentOffset: number;
  organizationType: OrganizationType;
  search: string;
  subUpgradeModal: boolean;
};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps<{ type?: string }>;

class OrganizationsPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      currentOffset: 30,
      search: '',
      organizationType:
        this.props.match.params.type === undefined
          ? this.props.account.organizationType === OrganizationType.Retailer
            ? OrganizationType.Supplier
            : OrganizationType.Retailer
          : this.props.match.params.type === 'suppliers'
            ? OrganizationType.Supplier
            : OrganizationType.Retailer,
      subUpgradeModal: false
    };
  }

  public componentDidMount() {
    this.props.getOrganizations();
    this.props.getConnections();
  }

  public handleScrollBottom() {
    if (this.state.currentOffset >= this.props.organizations.length) return;

    this.setState({ currentOffset: this.state.currentOffset + 30 });
  }

  public render() {
    const organizations = this.props.organizations
      .filter(
        c =>
          this.state.search.length === 0 ||
          c.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      )
      .filter(o => o.organizationType === this.state.organizationType)
      .filter(o => o.accountStatus !== OrganizationReadinessStatus.Hidden);

    const haveConnections = this.props.organizations.some(x => x.connectStatus === 'Approved');
    const scrollPaged = organizations.slice(0, this.state.currentOffset);

    const type = this.state.organizationType;
    const orgsName = type === OrganizationType.Supplier ? i18n.t('common.Suppliers') : 'Retailers';
    return (
      <ErrorBoundary>
        <XPageContent style={{ position: 'relative' }}>
          <StyledPaper
            style={{ height: !this.props.loading && organizations.length === 0 ? '300px' : '' }}>
            {this.props.connections.length === 0 && (
              <div style={{ padding: '10px' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: 'bold', color: '#222222' }}>
                  Welkom bij Arxis!
                </h1>
                <hr />
              </div>
            )}
            <Translation>
              {t => (
                <XPaperHeader
                  header={orgsName}
                  count={organizations.length}
                  subheader={
                    t('organizations.belowAreAll') +
                    orgsName.toLowerCase() +
                    t('organizations.findInteresting') +
                    orgsName.toLowerCase() +
                    t('organizations.andConnect')
                  }
                />
              )}
            </Translation>
            <XFilterBar style={{ paddingBottom: '15px' }}>
              <div style={{ flex: 1, paddingRight: '5px' }}>
                <XSearchBar
                  placeholder={'Filter ' + orgsName.toLowerCase()}
                  onChange={e =>
                    this.setState({ search: e.target.value.toLowerCase() })
                  }></XSearchBar>               
              </div>
            </XFilterBar>

            {!!this.state.search.length && scrollPaged.length === 0 && (
              <Translation>
                {t => <XNoContent message={t('organizations.noOrgFound')} />}
              </Translation>
            )}

            {!this.state.search.length && scrollPaged.length === 0 && !this.props.loading && (
              <Translation>
                {t => <XNoContent image={EmptyImage} message={t('organizations.noOrgAvailable')} />}
              </Translation>
            )}

            {this.props.loading &&
              Array.from({ length: 5 }).map((_, idx) => (
                <div key={idx} style={{ padding: '15px' }}>
                  <div
                    style={{
                      padding: '10px 15px',
                      borderRadius: '4px',
                      boxShadow:
                        '0 8px 15px 0 rgba(0, 0, 0, 0.09), 0 2px 8px 0 rgba(0, 0, 0, 0.08)',
                      display: 'flex'
                    }}>
                    <Skeleton variant="circle" width={55} height={55} />
                    <div style={{ marginLeft: '20px' }}>
                      <Skeleton variant="rect" width={250} height={20} />
                      <Skeleton width={250} />
                    </div>
                  </div>
                </div>
              ))}

            {!this.props.loading && (
              <XScrollView onScrollBottom={() => this.handleScrollBottom()}>
                {scrollPaged.map(organization => (
                  <XAccordion key={organization.id} style={{ padding: '10px 15px' }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">

                        <XOrgImage
                          src={getImageUrl(organization.imageURL, undefined)}
                          alt=""
                          onError={(e: any) => {
                            e.target.onError = null;
                            e.target.src = generateAvatar(organization.name, 60);
                          }}
                        />
                        <div style={{ marginLeft: '20px' }}>
                          <h6 style={{ fontWeight: 'bold', margin: 0 }}> {organization.name}</h6>
                          <div style={{ color: '#7b7b7b', minWidth: '250px' }}>
                            {organization.city} - {organization.country}
                          </div>
                        </div>
                        {this.state.organizationType === OrganizationType.Supplier ? (
                          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <div style={{
                              backgroundColor: OrganizationReadinessStatusList.find(c => c.value === organization.accountStatus)?.color,
                              minWidth: '115px',
                              height: '25px',
                              textAlign: 'center',
                              margin: '5px',
                              borderRadius: '10px',
                              color: '#fff',
                              fontWeight: 'bold',
                              fontSize: '12px',
                              alignSelf: 'center'
                            }}>
                              {OrganizationReadinessStatusList.find(c => c.value === organization.accountStatus)?.name}
                            </div>
                          </div>) : (<div></div>)}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Translation>
                          {t => (
                            <div style={{ marginLeft: '95px', width: '100%' }}>
                              {this.state.organizationType === OrganizationType.Supplier ? (
                                <div>
                                  <h6 style={{ color: '#7b7b7b' }}>
                                    {t('orgAcc.youCanDoBusinessWith')} {organization.name}{' '}
                                    {t('orgAcc.viaArxis')} {t('orgAcc.erpOrCashRegister')}
                                  </h6>

                                  <h6 style={{ color: '#7b7b7b' }}>
                                    {organization.name} {t('orgAcc.supportsThroughArxis')}
                                  </h6>
                                </div>
                              ) : (<div></div>)
                              }

                              <div>
                                {organization.supportsCatalogData ===
                                  OrganizationReadinessStatus.Ready || this.state.organizationType === OrganizationType.Retailer ? (
                                  <XBadgeSpan style={{ background: '#c6f6d5', color: '#22543d' }}>
                                    <CheckBoxOutlinedIcon
                                      style={{ marginRight: '4px' }}
                                      fontSize="small"
                                    />
                                    {t('common.catalog')}
                                  </XBadgeSpan>
                                ) : (
                                  <XBadgeSpan style={{ background: '#EDF2F7', color: '#7b7b7b' }}>
                                    <IndeterminateCheckBoxOutlinedIcon
                                      style={{ marginRight: '4px' }}
                                      fontSize="small"
                                    />
                                    {t('common.catalog')}
                                  </XBadgeSpan>
                                )}
                                {organization.supportsOrderData ===
                                  OrganizationReadinessStatus.Ready || this.state.organizationType === OrganizationType.Retailer ? (
                                  <XBadgeSpan style={{ background: '#c6f6d5', color: '#22543d' }}>
                                    <CheckBoxOutlinedIcon
                                      style={{ marginRight: '4px' }}
                                      fontSize="small"
                                    />
                                    Order
                                  </XBadgeSpan>
                                ) : (
                                  <XBadgeSpan style={{ background: '#EDF2F7', color: '#7b7b7b' }}>
                                    <IndeterminateCheckBoxOutlinedIcon
                                      style={{ marginRight: '4px' }}
                                      fontSize="small"
                                    />
                                    Order
                                  </XBadgeSpan>
                                )}
                                {organization.supportsStockData ===
                                  OrganizationReadinessStatus.Ready || this.state.organizationType === OrganizationType.Retailer ? (
                                  <XBadgeSpan style={{ background: '#c6f6d5', color: '#22543d' }}>
                                    <CheckBoxOutlinedIcon
                                      style={{ marginRight: '4px' }}
                                      fontSize="small"
                                    />
                                    Stock
                                  </XBadgeSpan>
                                ) : (
                                  <XBadgeSpan style={{ background: '#EDF2F7', color: '#7b7b7b' }}>
                                    <IndeterminateCheckBoxOutlinedIcon
                                      style={{ marginRight: '4px' }}
                                      fontSize="small"
                                    />
                                    Stock
                                  </XBadgeSpan>
                                )}
                              </div>

                              <div
                                style={{
                                  marginTop: '16px'
                                }}>
                                <XButton
                                  colorscheme={XButtonStyle.Primary}
                                  onClick={() =>
                                    this.props.history.push(
                                      `/app/explore/connections/organization/${organization.id}`
                                    )
                                  }>
                                  Connect
                                </XButton>
                              </div>
                            </div>
                          )}
                        </Translation>
                      </AccordionDetails>
                    </Accordion>
                  </XAccordion>
                ))}
              </XScrollView>
            )}
          </StyledPaper>
          {this.state.subUpgradeModal && (
            <XSubscriptionUpgradeModal close={() => this.setState({ subUpgradeModal: false })} />
          )}
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    organizations: (state.organizations.all || []).sort((a, b) => a.name.localeCompare(b.name)),
    loading: state.organizations.loading,
    connections: state.organizations.connections.sort((a, b) => a.name.localeCompare(b.name)),
    account: state.authentication.account,
    view: state.layout.viewOrganizations
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getConnections: () => dispatch(OrganizationActions.getOrganizationConnectionsThunk()),
  getOrganizations: () => dispatch(getOrganizationsThunk()),
  changeView: (type: ViewType) => dispatch(changeView('organizations', type))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsPage);

const StyledPaper = styled(XPaper)`
  min-height: 300px;
`;

export const XOrgImage = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  width: 'auto',
  height: 'auto',
  transition: 'all 0.25s ease'
});

const XAccordion = styled.div`
  .MuiPaper-elevation1 {
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.09), 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  }

  .MuiAccordionSummary-content {
    img {
      width: 55px;
      height: 55px;
      border-radius: 100%;
    }
  }

  .MuiAccordionSummary-content.Mui-expanded {
    img {
      width: 75px;
      height: 75px;
      border-radius: 0;
      border-right: none;
    }
  }

  .MuiAccordionDetails-root {
    padding: 16px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.09);
  }
`;

const XBadgeSpan = styled.span`
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  vertical-align: middle;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  border-radius: 0.125rem;
  font-weight: 700;
  margin-top: 0px;
  margin-inline: 0.5rem 0px;
  margin-bottom: 0.5rem;
`;
