import styled from '@emotion/styled';
import * as React from 'react';

import { Autocomplete } from '@material-ui/lab';
import XField from './XField';

export interface SelectOption {
  name: string;
  value: any;
}

export interface IField {
  name: string;
  title?: string;
  value?: any;
  type?: string;
  options?: SelectOption[];
  required?: boolean;
  readonly?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  maxLength?: number;
}

type OwnProps = {
  fields: IField[];
  margin?: boolean;
  data?: any;
  inputErrors?: any;
  validate?: boolean;
  verticalAlign?: boolean;
  submitHandler?: () => void;
  callback: (name: string, value: string) => any;
};

type AllProps = OwnProps;

type State = {
  formData: {};
  inField: boolean;
};

class XForm extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      formData: {},
      inField: false
    };
  }

  public componentWillReceiveProps(nextProps: any) {
    const obj = {};

    nextProps.fields.forEach((c: any) => {
      const name = nextProps.fields[nextProps.fields.indexOf(c).name];
      obj[name] = this.props.fields.indexOf(c) > -1;
    });

    this.setState(obj);
  }

  public handleFormChange = (name: string, value: any) => {
    const formData = Object.assign({}, this.state.formData);
    formData[name] = value;
    this.setState({ formData });
    this.props.callback(name, value);
  };

  public handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && this.props.submitHandler) {
      this.props.callback(e.target.name, e.target.value);
      this.props.submitHandler();
    }
  };

  public render() {
    return (
      <FormContainer>
        <FormContent>
          {this.props.fields.map(item => (
            <InputContainer
              margin={this.props.margin}
              key={item.name}
              style={{
                flex: item.fullWidth !== undefined && !item.fullWidth ? 'calc(50% - 15px)' : '100%',
                maxWidth: item.fullWidth !== undefined && !item.fullWidth ? 'calc(50% - 15px)' : ''
              }}>
              {item.type === 'select' ? (
                item.options == null ? (
                  <div />
                ) : (
                  <Autocomplete
                    key={item.name}
                    disabled={item.readonly === true}
                    options={item.options}
                    getOptionLabel={option => option.name ?? option}
                    getOptionSelected={(option, value) => {
                      if (!option || !value) return false;
                      return option.value === value.value;
                    }}
                    value={
                      !this.props.data[item.name]
                        ? null
                        : item.options?.find(o => {
                            if (!o) return false;
                            return o.value === this.props.data[item.name];
                          })
                    }
                    onChange={(e, v) => this.handleFormChange(item.name, v?.value)}
                    autoComplete={false}
                    autoHighlight={true}
                    autoSelect={true}
                    renderInput={params => {
                      // Override the browser autoComplete to stop it overlapping with the component
                      const inputProps = Object.assign({}, params.inputProps);
                      params.inputProps = {
                        ...inputProps,
                        autoComplete: 'new-password'
                      };
                      return (
                        <XField
                          label={item.title}
                          type={item.type}
                          required={item.required}
                          error={this.props.inputErrors && this.props.inputErrors[item.name]}
                          {...params}
                          name={item.name}
                        />
                      );
                    }}
                  />
                )
              ) : (
                <XField
                  error={this.props.inputErrors && this.props.inputErrors[item.name]}
                  key={item.name}
                  disabled={item.readonly === true}
                  name={item.name}
                  inputProps={{
                    maxLength: item.maxLength
                  }}
                  label={item.title}
                  multiline={item.multiline}
                  rows={item.multiline ? 5 : 1}
                  rowsMax={item.multiline ? 5 : 1}
                  required={item.required}
                  type={item.type}
                  onKeyPress={this.handleKeyPress}
                  onChange={e => this.handleFormChange(item.name, e.target.value)}
                  {...(this.props.data !== undefined ? { value: this.props.data[item.name] } : {})}
                />
              )}
            </InputContainer>
          ))}
        </FormContent>
      </FormContainer>
    );
  }
}

export default XForm;

const FormContainer = styled('div')({});
const FormContent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
});

const InputContainer = styled.div<{ margin?: boolean }>`
  height: auto
  display: flex;
  flex-direction: column;
  margin: ${props => (props.margin === undefined || props.margin === true ? '0 15px' : '0')};
`;
