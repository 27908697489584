import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'src/store';
import { getMyOrganizationThunk } from 'src/store/organizations/actions';
import { OrganizationModel } from 'src/models/Organization';
import styled from '@emotion/styled';
import { Translation } from 'react-i18next';

type OwnProps = {
  p: { name: string; price: string; items: []; limit: number };
  organizationType: string;
};

type StateProps = {
  myOrganization?: OrganizationModel;
};

type DispatchProps = {
  getMyOrganization: () => void;
};

type AllProps = StateProps & OwnProps & DispatchProps;

const SubscriptionCard: React.FC<AllProps> = ({
  p,
  organizationType,
  getMyOrganization,
  myOrganization
}) => {
  useEffect(() => {
    getMyOrganization();
  }, []);

  const range = (min: number, max: number) => {
    if (
      myOrganization &&
      myOrganization.organization.numberOfLiveConnections >= min &&
      myOrganization.organization.numberOfLiveConnections <= max &&
      p.limit === max
    ) {
      return (
        <Translation>
          {t => (
            <>
              <XCurrentSubHeader>{t('organizations.yourCurrentSubscription')}</XCurrentSubHeader>
              <XCurrentSubFooter>
                {t('organizations.youAreMakingUseOf')}{' '}
                {myOrganization.organization.numberOfLiveConnections}
                {t('organizations.liveConnectionsWith')}{' '}
                {organizationType === 'Supplier' ? t('common.retailers') : t('common.suppliers')}
              </XCurrentSubFooter>
            </>
          )}
        </Translation>
      );
    } else return;
  };

  return (
    <div
      className="card-premium"
      style={{ height: '24rem', position: 'relative', overflow: 'visible' }}>
      {organizationType === 'Supplier'
        ? range(0, 1) || range(2, 50) || range(51, 100) || range(101, 200) || range(201, 999999)
        : range(0, 1) || range(2, 20) || range(21, 50) || range(51, 100) || range(101, 999999)}
      <div className="name">{p.name}</div>
      {Number.isInteger(p.price) && (
        <div>
          <div className="price">€{p.price},-</div>
          <div className="mb-3">per month</div>
        </div>
      )}
      <hr style={{ marginTop: '20px', marginBottom: '10px', width: '80%' }} />
      <div className="text-left pl-4 pr-4">
        {p.items.map((i, idx) => (
          <div
            key={idx}
            className="note"
            style={{
              display: 'flex',
              alignItems: 'start',
              paddingTop: '8px',
              paddingBottom: '8px'
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <img
                src={require('../../../pages/index/pages/images/svg/check.svg')}
                style={{ marginRight: '10px', marginTop: '4px' }}
              />
            </div>
            <div
              style={{
                wordWrap: 'break-word',
                width: '75%'
              }}>
              {i}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    myOrganization: state.organizations.myOrganization
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getMyOrganization: () => dispatch(getMyOrganizationThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCard);

const XCurrentSubHeader = styled.div`
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  height: 40px;
  padding: 0 16px;
  min-width: 40px;
  min-height: auto;
  border-radius: 24px;
  background-color: #4caf4fe1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7.5px;
    border-width: 7.5px;
    border-style: solid;
    border-color: #4caf4fe1 transparent transparent transparent;
  }
`;

const XCurrentSubFooter = styled.div`
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 85%;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #606cfe, rgba(0, 0, 0, 0)) 1 100%;
  border-right: 0px;
  padding-left: 7px;
`;
