import styled from '@emotion/styled';
import { RadioGroup } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import XRadioButton from 'src/components/layout/XRadioButton';
import XSwitch from 'src/components/layout/XSwitch';
import { AccountSetting, AccountSettingForm } from 'src/store/authentication/types';
import { ApplicationState } from 'src/store';
import { changeLanguage } from 'src/store/language/actions';

type StateProps = {
  language: string;
};

type DispatchProps = {
  changeLanguage: (language: string) => void;
};

type OwnProps = {
  settings: AccountSetting[];
  onChange: (data: AccountSettingForm[]) => void;
};

type State = {
  changedSettings: AccountSettingForm[];
};

type AllProps = OwnProps & StateProps & DispatchProps;

class AccountSettings extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      changedSettings: this.props.settings.map(p => this.createForm(p.name, p.value))
    };
  }

  public componentDidUpdate(old: any) {
    let updateNeeded = false;
    for (const oldSetting of old.settings) {
      const existing = this.props.settings.find(c => c.name === oldSetting.name);
      if (existing === undefined) updateNeeded = true;
      else if (existing.value !== oldSetting.value) updateNeeded = true;
    }

    if (updateNeeded)
      this.setState({
        changedSettings: this.props.settings.map(p => this.createForm(p.name, p.value))
      });
  }

  public createForm(name: string, value: any): any {
    return { name, value };
  }

  public getValue(name: string): any {
    const changed = this.state.changedSettings.find(s => s.name === name);
    if (changed !== undefined) {
      return changed.value;
    }
    return this.props.settings.find(s => s.name === name)?.value;
  }

  public changeSetting(name: string, value: any) {
    const setting = this.state.changedSettings.find(s => s.name === name);
    if (setting !== undefined) {
      this.setState(
        {
          changedSettings: this.state.changedSettings.map(el =>
            el.name === name ? { ...setting, value: value + '' } : el
          )
        },
        () => this.props.onChange(this.state.changedSettings)
      );
      return;
    }

    const newSettings = this.state.changedSettings;
    newSettings.push({ name, value: value + '' });
    this.setState({ changedSettings: newSettings }, () =>
      this.props.onChange(this.state.changedSettings)
    );
  }

  public render() {
    const languageHanlder = (e: React.ChangeEvent<HTMLInputElement>): void => {
      this.changeSetting('Language', (e.target as HTMLInputElement).value);
      this.props.changeLanguage((e.target as HTMLInputElement).value);
    };

    return (
      <div>
        {this.state.changedSettings && (
          <Translation>
            {t => (
              <StyledSwitch
                label={t('account.rcMail')}
                checked={this.getValue('MailEnabled') === '1'}
                onChange={e => this.changeSetting('MailEnabled', e.target.checked ? '1' : '0')}
              />
            )}
          </Translation>
        )}
        <StyledLanguage>
          <Translation>{t => <h6>{t('account.sLanguage')}</h6>}</Translation>
          <RadioGroup row value={this.props.language} onChange={languageHanlder}>
            <XRadioButton value="nl" label="NL" />
            <XRadioButton value="en" label="EN" />
          </RadioGroup>
        </StyledLanguage>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    language: state.language.language
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  changeLanguage: (language: string) => dispatch(changeLanguage(language))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);

const StyledSwitch = styled(XSwitch)`
  margin: 0 10px;
`;

const StyledLanguage = styled.div`
  border-top: 1px solid grey;
  margin: 0 16px;
  & h6 {
    margin-top: 21px;
  }
`;
