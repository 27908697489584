import * as React from 'react';
import { connect } from 'react-redux';
import { XPaper, XPaperTitle } from 'src/components/layout/XPaper';
import { XTitle } from 'src/components/layout/XPaperHeader';
import * as accountActions from 'src/store/accounts/actions';
import { AuthenticationAccount } from 'src/store/authentication/types';

import styled from '@emotion/styled';
import SaveIcon from '@material-ui/icons/Save';
import XPageContent from 'src/components/layout/XPageContent';
import XScrollView from 'src/components/layout/XScrollView';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../store';
import AccountDetails from './account/AccountDetails';
import { Clear, Edit } from '@material-ui/icons';
import { AccountModel } from 'src/store/accounts/types';
import { Link } from 'react-router-dom';
import { XActionButton, XSecActionButton } from 'src/components/layout/XActionButton';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { XRowCell } from 'src/components/layout/XRow';
import { Translation } from 'react-i18next';
import i18n from 'src/i18n';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  authAccount: AuthenticationAccount;
  account?: AccountModel;
};
type State = {
  accountData: EditAccountForm;
  accountInputErrors: any;
  editing: boolean;
};

export type EditAccountForm = {
  id: string;
  email: string;
  password: string;
  passwordValid: string;
};

type DispatchProps = {
  showNotification: (message: string, type?: MessageType) => void;
  getMyAccount: () => void;
  editAccount: (account: EditAccountForm) => Promise<ApiResult>;
};

type AllProps = StateProps & DispatchProps;

class MyAccountPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      accountData: {
        id: this.props.authAccount.accountId,
        email: this.props.authAccount.email,
        password: '',
        passwordValid: ''
      },
      editing: false,
      accountInputErrors: {
        password: false,
        passwordValid: false
      }
    };
    this.props.getMyAccount();
  }

  public handleAccountChange = (name: string, value: string) => {
    const accountData = { ...this.state.accountData };
    accountData[name] = value;
    this.setState({ accountData });
  };

  public storeAccount = () => {
    const inputErrors = { ...this.state.accountInputErrors };
    let valid = true;

    if (
      this.state.accountData.password !== this.state.accountData.passwordValid ||
      this.state.accountData.password === ''
    ) {
      valid = false;
      inputErrors.password = true;
      inputErrors.passwordValid = true;
    }

    this.setState({ accountInputErrors: inputErrors });
    if (valid) {
      this.props.editAccount(this.state.accountData).then(result => {
        if (result.IsSuccess) {
          this.props.showNotification(i18n.t('notification.accountModified'), 'success');
          this.setState({ editing: false });
        } else
          this.props.showNotification(
            i18n.t('notification.errAdjustingAccount') + mapError(result.Errors),
            'error'
          );
      });
    }
  };

  public cancelEdit = () => {
    this.setState({ editing: false });
  };

  public render() {
    const props = this.props;
    return (
      <ErrorBoundary>
        <XPageContent style={{ maxWidth: '600px' }}>
          <XPaper>
            <XPaperTitle style={{ display: 'flex' }}>
              <XRowCell>
                <Translation>{t => <XTitle>{t('account.myAccount')}</XTitle>}</Translation>
              </XRowCell>
              {this.state.editing && (
                <Translation>
                  {t => (
                    <XActionStrip>
                      <XSecActionButton onClick={() => this.cancelEdit()}>
                        {t('tooltip.Cancel')}
                      </XSecActionButton>
                      <XActionButton onClick={() => this.storeAccount()}>
                        {t('tooltip.Save')}
                      </XActionButton>
                    </XActionStrip>
                  )}
                </Translation>
              )}
            </XPaperTitle>

            <XScrollView>
              {this.state.editing && (
                <div style={{ marginTop: '25px' }}>
                  <AccountDetails
                    registration={false}
                    inputErrors={this.state.accountInputErrors}
                    account={this.state.accountData}
                    callback={this.handleAccountChange}
                  />
                </div>
              )}
              {!this.state.editing && (
                <Translation>
                  {t => (
                    <div style={{ marginLeft: '15px', marginTop: '15px' }}>
                      <XFieldDisplay>
                        <XKey>{t('contact.name')}</XKey>
                        <XValue>
                          {props.account?.firstName} {props.account?.lastName}
                        </XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey>Email</XKey>
                        <XValue>{props.account?.email}</XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey> {t('myArxis.Organization')} </XKey>
                        <XValue>
                          {props.account?.organizationName}
                          <Link to={'/app/profile/organization'}>
                            <XA>{t('account.myOrganization')} &gt;</XA>
                          </Link>
                        </XValue>
                      </XFieldDisplay>
                      <XFieldDisplay>
                        <XKey>{t('account.password')}</XKey>
                        <XValue>
                          ••••••••••
                          <XA onClick={() => this.setState({ editing: true })}>
                            {t('account.changePass')} &gt;
                          </XA>
                        </XValue>
                      </XFieldDisplay>
                    </div>
                  )}
                </Translation>
              )}
            </XScrollView>
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    authAccount: state.authentication.account,
    account: state.account.my,
    organization: state.organizations.myOrganization,
    settings: state.account.settings
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type)),
  getMyAccount: () => dispatch(accountActions.getMyAccountThunk()),
  editAccount: (account: EditAccountForm) => dispatch(accountActions.EditAccountThunk(account))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);

export const XFieldDisplay = styled.div`
  padding: 10px;
  border-top: 1px solid #e4e4e4;
`;

export const XKey = styled.div`
  font-weight: bold;
  color: #7b7b7b;
`;
export const XA = styled.div`
  cursor: pointer;
  color: #e7247b;
  font-weight: 600;
  font-size: 12.5px;
`;

export const XValue = styled.div`
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
`;

const ActionButtons = styled.div`
  position: absolute;
  color: rgb(245, 0, 87);
  cursor: pointer;
  right: -15px;
  top: -15px;
`;
