import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import { ApplicationState } from 'src/store';
import { FileFormat } from 'src/models/FileFormat';
import { OrganizationModel } from 'src/models/Organization';
import * as stockActions from 'src/store/stocks/actions';
import * as organizationActions from 'src/store/organizations/actions';
import { AddStockFormData } from 'src/store/stocks/types';
import { InterfaceType } from 'src/models/Stock';
import { ApiResult } from 'src/helpers/apiClient';
import { XSecActionButton, XSubmitButton } from 'src/components/layout/XActionButton';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { mapError } from 'src/helpers/errorMap';
import i18n from 'src/i18n';
import { Translation } from 'react-i18next';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
import XFileFormatSelect from 'src/components/domain/XFileFormatSelect';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import { XPaper } from 'src/components/layout/XPaper';
import XPageContent from 'src/components/layout/XPageContent';
import StockInterface from './StockInterface';

type StateProps = {
  fileFormats: FileFormat[];
  myOrganization?: OrganizationModel;
};

type DispatchProps = {
  getFileFormats: () => void;
  getMyOrganization: () => void;
  addStock: (data: AddStockFormData) => Promise<ApiResult>;
  showNotification: (message: string, type?: MessageType) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

const AddStockPage: FC<AllProps> = props => {
  const [fileFormatId, setFileFormatId] = useState('');
  const [fileFormatVersionId, setFileFormatVersionId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [organizationId] = useState(`${props.myOrganization?.organization.id}`);
  const [interfaceType] = useState(InterfaceType.StockSend);
  const [interfaceMethod, setInterfaceMethod] = useState('');
  const [interfaceAuthentication, setInterfaceAuthentication] = useState('None');
  const [url, setUrl] = useState('');
  const [userName, setUserName] = useState('');
  const [portalName, setPortalName] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [autoUpdate, setAutoUpdate] = useState(false);
  const [updateTimeInterval, setUpdateTimeInterval] = useState('');
  const [uploadType, setUploadType] = useState('');

  const availableFormats = ['IDM', 'CSV', 'eCat', 'LTX', 'Artex'];

  const formats = props.fileFormats.filter(ar => availableFormats.find(rm => rm === ar.name));

  useEffect(() => {
    props.getFileFormats();
    props.getMyOrganization();
  }, []);

  useEffect(() => {
    props.fileFormats.map(
      f =>
        f.versions.length <= 1 &&
        f.versions.map(v => v.fileFormatId === fileFormatId && setFileFormatVersionId(v.id))
    );
  }, [fileFormatId]);

  const onChangeType = (event: any) => {
    setFileFormatId(event.target.value);
  };

  const onChangeVersion = (event: any) => {
    setFileFormatVersionId(event.target.value);
  };

  const typeSelectHandler = (type: string, specType?: string) => {
    if (type !== 'Specific') {
      setUploadType(type);
      setInterfaceMethod(type);
    } else {
      setUploadType('Specific');
      specType && setInterfaceMethod(specType);
    }
  };

  const addStock = async (e: any) => {
    e.preventDefault();
    const apiResult = await props.addStock({
      organizationId,
      title,
      description,
      fileFormatId,
      fileFormatVersionId,
      interfaceType,
      interfaceMethod,
      interfaceAuthentication,
      settings: {
        Url: url,
        Username: userName,
        Password: password,
        PortalName: portalName,
        Token: token,
        AutoUpdate: autoUpdate,
        UpdateTimeInterval: updateTimeInterval
      }
    });
    if (apiResult.IsSuccess) {
      props.history.push(`/app/my/stocks/edit/${apiResult.JsonResponse.entityId}`);
    } else {
      props.showNotification('Error' + mapError(apiResult.Errors), 'error');
    }
  };

  return (
    <ErrorBoundary>
      <XPageContent style={{ width: '44rem', height: 'fit-content' }}>
        <XPaper style={{ height: 'fit-content', maxHeight: 'fit-content' }}>
          <XPaperHeader header={i18n.t('stock.addStock')} />
          <form>
            <div>
              <XContent>
                <Translation>
                  {t => (
                    <XStockInputs>
                      <TextField
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        style={{ marginBottom: '1rem' }}
                        label={t('tooltip.Title')}></TextField>
                      <TextField
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        label={t('tooltip.Description')}
                        style={{ marginBottom: '1rem' }}
                        multiline
                        rows={1}
                      />
                    </XStockInputs>
                  )}
                </Translation>
              </XContent>
            </div>
            <XNewStock>
              <div style={{ display: 'flex' }}>
                <StockInterface
                  setInterfaceMethod={setInterfaceMethod}
                  setInterfaceAuthentication={setInterfaceAuthentication}
                  interfaceAuthentication={interfaceAuthentication}
                  typeSelectHandler={typeSelectHandler}
                  uploadType={uploadType}
                  url={url}
                  setUrl={setUrl}
                  password={password}
                  setPassword={setPassword}
                  userName={userName}
                  setUserName={setUserName}
                  portalName= {portalName}
                  setPortalName = {setPortalName}
                  token={token}
                  setToken={setToken}
                  autoUpdate={autoUpdate}
                  setAutoUpdate={setAutoUpdate}
                  updateTimeInterval={updateTimeInterval}
                  setUpdateTimeInterval={setUpdateTimeInterval}
                />
                <div style={{ minWidth: '15rem' }}>
                  <div style={{ padding: '0 2rem' }}>
                    <div>
                      {!!uploadType && uploadType !== 'Specific' && (
                        <XFileFormatSelect
                          fileFormatId={fileFormatId}
                          fileFormatVersionId={fileFormatVersionId}
                          onChangeVersion={onChangeVersion}
                          onChangeType={onChangeType}
                          formats={formats}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: '0 1rem 0 1rem', marginTop: '1rem' }}>
                <Translation>
                  {t => (
                    <XButtonsContainer>
                      <XSecActionButton onClick={() => props.history.goBack()}>
                        {t('tooltip.Cancel')}
                      </XSecActionButton>
                      <XSubmitButton type="submit" onClick={addStock}>
                        {t('stock.saveStock')}
                      </XSubmitButton>
                    </XButtonsContainer>
                  )}
                </Translation>
              </div>
            </XNewStock>
          </form>
        </XPaper>
      </XPageContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    fileFormats: state.stocks.fileFormats.sort((a, b) => a.name.localeCompare(b.name)),
    myOrganization: state.organizations.myOrganization
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getFileFormats: () => dispatch(stockActions.getFileFormatsThunk()),
  addStock: (data: AddStockFormData) => dispatch(stockActions.addStockThunk(data)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk()),
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStockPage);

const XNewStock = styled.div`
  height: fit-content;
  border-radius: 6px;
  background-color: white;
`;

const XContent = styled.div`
  padding: 0 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  & p {
    margin: 0.25rem 0;
  }
`;

const XStockInputs = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

const XButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;
