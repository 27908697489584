import React, { FC } from 'react';
import styled from '@emotion/styled';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Translation } from 'react-i18next';
import { FileFormat } from 'src/models/FileFormat';

type OwnProps = {
  onChangeType: (event: any) => void;
  onChangeVersion: (event: any) => void;
  fileFormatId: string;
  fileFormatVersionId: any;
  formats: FileFormat[];
};

type AllProps = OwnProps;

const XFileFormatSelect: FC<AllProps> = props => {
  return (
    <XFileFormatContainer>
      <Translation>
        {t => <p style={{ marginBottom: '7px' }}>{t('catalogs.selectFileFormat')}:</p>}
      </Translation>
      <FormControl style={{ width: '100%' }}>
        <Select value={props.fileFormatId} onChange={props.onChangeType}>
          {props.formats.map(fileFormat => (
            <MenuItem key={fileFormat.id} value={fileFormat.id}>
              {fileFormat.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {props.formats.map(
        f =>
          f.versions.length > 1 &&
          f.id === props.fileFormatId && (
            <XSelectFileFormatVersion key={f.id}>
              <p>Select format version:</p>
              <FormControl style={{ width: '100%' }}>
                <Select value={props.fileFormatVersionId} onChange={props.onChangeVersion}>
                  {props.formats.map(format =>
                    format.versions.map(
                      v =>
                        v.fileFormatId === props.fileFormatId && (
                          <MenuItem key={v.id} value={v.id}>
                            {v.name}
                          </MenuItem>
                        )
                    )
                  )}
                </Select>
              </FormControl>
            </XSelectFileFormatVersion>
          )
      )}
    </XFileFormatContainer>
  );
};

export default XFileFormatSelect;

const XFileFormatContainer = styled.div`
  margin-bottom: 2rem;

  & p {
    margin: 0;
  }
`;

const XSelectFileFormatVersion = styled.div`
  margin-top: 1rem;
`;
