import React, { FC, Suspense } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppLoadingPage from 'src/pages/index/pages/AppLoadingPage';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

const OrdersRouter = React.lazy(() => import('./OrdersRouter'));

type AllProps = RouteComponentProps;

const OrdersApp: FC<AllProps> = props => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<AppLoadingPage />}>
        <OrdersRouter {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default OrdersApp;
