import { ProcessingStatus, PublicationStatus } from 'src/models/Catalog';

export const getProcessingStatusText = (t: ProcessingStatus | undefined) : string => {
  switch (t) {
    case ProcessingStatus.Uploading:
      return "Aan het uploaden";
    case ProcessingStatus.Uploaded:
      return "In wachtrij";
    case ProcessingStatus.Validating: 
      return "3/4 Bezig met valideren";
    // case ProcessingStatus.Validated:
    // case ProcessingStatus.ValidatedWithWarnings:
    //   return "Gevalideerd";
    case ProcessingStatus.ValidationFailed:
      return "3/4 Validatie mislukt";
    case ProcessingStatus.WaitingForMapping:
      return "Voltooi bestands mapping"
    case ProcessingStatus.Converted:
      return "2/4 Converteren geslaagd"
    case ProcessingStatus.Converting:
      return "2/4 Bezig met converteren"
    case ProcessingStatus.ConvertingFailed:
      return "2/4 Converteren mislukt"
    case ProcessingStatus.Fetched: 
      return "1/4 Ophalen bestand geslaagd"
    case ProcessingStatus.Fetching:
      return "1/4 Ophalen nieuw bestand"
    case ProcessingStatus.FetchingFailed:
      return "1/4 Ophalen nieuw bestand mislukt"
    case ProcessingStatus.None:
      return "Wachten op verwerking"
    case ProcessingStatus.Promoted:
      return "4/4 Promoveren geslaagd"
    case ProcessingStatus.Promoting:
      return "4/4 Bezig met promoveren"
    case ProcessingStatus.PromotingFailed:
      return "4/4 Promoveren mislukt"
    case ProcessingStatus.UploadFailed:
      return "Upload failed"
    case ProcessingStatus.Uploading:
      return "Uploading"
    case ProcessingStatus.Validated: 
      return "3/4 Valideren geslaagd"
    case ProcessingStatus.ValidatedWithWarnings:
      return "3/4 Gevalideerd (waarschuwingen)"
    case ProcessingStatus.Validating:
      return "3/4 Bezig met valideren"
    case ProcessingStatus.ValidatingFailed:
    case ProcessingStatus.ValidationFailed:
      return "3/4 Valideren mislukt"
    case ProcessingStatus.MappingFailed:
      return "Bestandsmapping mislukt"
  }

  return "";
} 

export const getPublicationStatusText = (t: PublicationStatus | undefined) : string => {
  switch (t) {
    case PublicationStatus.Published:
      return "Gepubliceerd";
    case PublicationStatus.Unpublished:
      return "Niet gepubliceerd"
  }

  return "";
}
