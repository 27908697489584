import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import * as catalogActions from 'src/store/catalogs/actions';
import { ApiResult } from 'src/helpers/apiClient';
import { ApplicationState } from '../../../store';
import { XContainer } from '../../../components/layout/XContainer';
import { InterfaceMethod, CatalogModel } from 'src/models/Catalog';
import FTPInterfaceMethod from './uploadTypes/FTPInterfaceMethod';
import SFTPInterfaceMethod from './uploadTypes/SFTPInterfaceMethod';
import ManualInterfaceMethod from './uploadTypes/ManualInterfaceMethod';
import ApiInterfaceMethod from './uploadTypes/ApiInterfaceMethod';
import CustomInterfaceMethod from './uploadTypes/CustomInterfaceMethod';
import Chip from '@material-ui/core/Chip';

type StateProps = {
  catalog?: CatalogModel;
};

type DispatchProps = {
  getCatalogById: (id: string) => Promise<ApiResult>;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{ id?: string }>;

const EditInterfacePage: FC<AllProps> = props => {
  const [uploadType, setUploadType] = useState('');

  useEffect(() => {
    props.match.params.id &&
      props.getCatalogById(props.match.params.id).then(() => {
        if (!!props.catalog?.interfaces.length) {
          if (props.catalog?.interfaces[0].method === InterfaceMethod.DeEekhoorn) {
            setUploadType('Specific');
          } else if (props.catalog?.interfaces[0].method === InterfaceMethod.VertilinQ) {
            setUploadType('Specific');
          } else if (props.catalog?.interfaces[0].method === InterfaceMethod.ItsPerfect) {
            setUploadType('Specific');
          } else {
            setUploadType(`${props.catalog?.interfaces[0].method}`);
          }
        }
      });
  }, []);

  const onClickHandler = (type: string) => {
    setUploadType(type);
  };

  const catalogId = props.match.params.id;

  const selectUploadMethod = ['Sftp','Ftp', 'Manual', 'Api', 'Specific'];

  return (
    <XContainer
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <XCatalogInterface>
        <XContent>
          <h6>Hoe bied jij de catalogus aan op Arxis?</h6>
          <h6 style={{ margin: '1rem 0' }}>
            <strong>Via</strong>
          </h6>
          <XSelectTypeContainer>
            {selectUploadMethod.map(method => (
              <Chip
                style={{ flex: 1 }}
                key={method}
                label={method}
                clickable
                color={uploadType === method ? 'primary' : 'default'}
                onClick={() => onClickHandler(method)}
              />
            ))}
          </XSelectTypeContainer>
          <XTypeContainer>
            {uploadType === InterfaceMethod.Ftp && <FTPInterfaceMethod catalogId={catalogId} />}
            {uploadType === InterfaceMethod.Manual && (<ManualInterfaceMethod catalogId={catalogId} />)}
            {uploadType === InterfaceMethod.Sftp && <SFTPInterfaceMethod catalogId={catalogId}/>}
            {uploadType === InterfaceMethod.Api && <ApiInterfaceMethod catalogId={catalogId} />}
            {uploadType === 'Specific' && (
              <CustomInterfaceMethod
                catalogId={catalogId}
                method={
                  !!props.catalog?.interfaces.length ? props.catalog?.interfaces[0].method : ''
                }
              />
            )}
          </XTypeContainer>
        </XContent>
      </XCatalogInterface>
    </XContainer>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    catalog: state.catalogs.data.detail
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getCatalogById: (id: string) => dispatch(catalogActions.getCatalogThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInterfacePage);

const XCatalogInterface = styled.div`
  width: 32rem;
  height: 32rem;
`;

const XContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 6px;
`;

const XSelectTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & h6 {
    margin-bottom: 1rem;
  }
`;

const XSelectTypeBtn = styled.div`
  width: 7rem;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & h6 {
    margin: 0.5rem;
  }

  & .selected {
    font-weight: bold;
  }
`;

const XTypeContainer = styled.div`
  margin-top: 2rem;
`;
