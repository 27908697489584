import * as React from 'react';
import XPopup from './XPopup';
import { Translation } from 'react-i18next';

type OwnProps = {
  title: string;
  text: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

type AllProps = OwnProps;

const XConfirmation: React.FC<AllProps> = props => {
  return (
    <Translation>
      {t => (
        <XPopup
          title={props.title}
          buttonSaveText={t('confirmation.yes')}
          buttonCancelText={t('confirmation.no')}
          onSave={props.onConfirm}
          onCancel={props.onCancel}
          open={props.open}>
          {props.text}
        </XPopup>
      )}
    </Translation>
  );
};

export default XConfirmation;
