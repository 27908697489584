import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const XPage = styled('div')({
  backgroundImage: 'url(' + require('../../style/images/background.jpg') + ')',
  backgroundSize: 'cover',
  width: '100%',
  height: '100vh',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch'
});

export const XPageContainer = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex'
});

export const LTXLogo = styled('img')({
  position: 'fixed',
  right: '30px',
  bottom: '20px',
  maxWidth: '75px',
  maxHeight: '75px'
});
