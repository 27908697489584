let apiUrl = process.env.REACT_APP_API_URL;

if (process.env.NODE_ENV === 'development') {
  apiUrl = 'https://localhost:44336/';
}

export const API_URL = apiUrl;

export const API_URL_COMMAND = `${API_URL}command`;
export const API_URL_QUERY = `${API_URL}query`;
export const API_URL_LOGIN = `${API_URL}login`;

export const API_URL_AUTH = `${API_URL}authenticate`;
export const API_URL_AUTH_IMPERSONATED = `${API_URL}authenticateimpersonated`;
export const API_URL_ACCOUNT = `${API_URL}command/account`;

// https://localhost:44336/
