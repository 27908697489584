import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { PageRoute } from 'src/models/Route';

type OwnProps = {
  routes: PageRoute[];
  prependMatchPath?: boolean;
  shouldRedirectToConnections?: boolean;
};

type AllProps = OwnProps & RouteComponentProps;

class XPageRouter extends React.Component<AllProps, {}> {
  private static defaultProps: Partial<OwnProps> = {
    prependMatchPath: false
  };

  public render() {
    const { match } = this.props;
    const { routes } = this.props;
    const { prependMatchPath } = this.props;

    return (
      <Switch location={this.props.location}>
        {this.props.shouldRedirectToConnections && (
          <Route
            path={`${match.path}`}
            exact={true}
            component={() => <Redirect to={`${match.path}/connections`} />}
          />
        )}
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={prependMatchPath ? `${match.path}${route.path}` : route.path}
              exact={route.exact}
              component={route.component}
            />
          );
        })}
      </Switch>
    );
  }
}

export default XPageRouter;
