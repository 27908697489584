import { createSelector } from 'reselect';
import { ProcessingStatus, PublicationStatus } from 'src/models/Catalog';
import { ApplicationState } from '..';
import { CatalogState } from './types';

export const getCatalogs = (state: CatalogState) => state;
export const getNewCatalog = (state: CatalogState) => state.addCatalog;
export const catalogs = (state: CatalogState) => state.data;
export const getId = (state: CatalogState, id: string) => (state.data.all || []).filter(x => x.id === id);

export const getCatalogState = createSelector(getCatalogs, s => s.data);
export const newCatalogState = createSelector(getNewCatalog, s => s);
export const getCatalogById = createSelector(getId, s => s[0]);
export const getMyCatalogs = createSelector(getCatalogs, s => s.data.my);

export const getMyCatalogsRequiringAction = (state: ApplicationState) => 
  (state.organizations.myOrganization?.catalogs || []).filter(c => 
    c.processingStatus === ProcessingStatus.ValidationFailed ||
    ((c.processingStatus === ProcessingStatus.Validated || 
      c.processingStatus === ProcessingStatus.ValidatedWithWarnings) && 
      c.publicationStatus === PublicationStatus.Unpublished)
  )

