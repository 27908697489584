import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import XNavigation from 'src/components/layout/XNavigation';
import { XSideBar } from 'src/components/layout/XSideBar';
import { Catalog } from 'src/models/Catalog';
import { RequestModel } from 'src/models/Request';
import { ApplicationState } from 'src/store';
import { getMyCatalogsRequiringAction } from 'src/store/catalogs/selectors';
import { activeIncommingRequest } from 'src/store/requests/selectors';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { OrganizationType } from 'src/models/Organization';

type StateProps = {
  activeIncommingRequest: RequestModel[];
  catalogRequiringAction: Catalog[];
  account: AuthenticationAccount;
};

type State = {};

type DispatchProps = {};

type AllProps = StateProps & DispatchProps;

class MyAccountSideBar extends React.Component<AllProps, State> {
  public render() {
    return (
      <XSideBar>
        <Translation>
          {t => (
            <XNavigation
              routes={
                this.props.account.organizationType === OrganizationType.Retailer
                  ? [
                      { name: 'Account', route: '/app/profile/account' },
                      { name: t('myArxis.Organization'), route: '/app/profile/organization' },
                      { name: t('myArxis.Subscription'), route: '/app/profile/subscriptions' },
                      { name: 'Software', route: '/app/profile/software' },
                      {
                        name: t('myArxis.Preferences'),
                        route: '/app/profile/settings'
                      }
                    ]
                  : [
                      { name: 'Account', route: '/app/profile/account' },
                      { name: t('myArxis.Organization'), route: '/app/profile/organization' },
                      { name: t('myArxis.Subscription'), route: '/app/profile/subscriptions' },

                      {
                        name: t('myArxis.Preferences'),
                        route: '/app/profile/settings'
                      }
                    ]
              }
              callback={''}
            />
          )}
        </Translation>
      </XSideBar>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    activeIncommingRequest: activeIncommingRequest(state),
    catalogRequiringAction: getMyCatalogsRequiringAction(state),
    account: state.authentication.account
  };
};

const mapDispatchToProps = (): DispatchProps => ({
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSideBar);
