import { Software } from '../../models/Software';

export enum ActionTypes {
  GET_SOFTWARE = '[software] GET_SOFTWARE',
  POST_ORGANIZATION_SOFTWARE = '[software] POST_ORGANIZATION_SOFTWARE',
  GET_ORGANIZATION_SOFTWARE = '[software] GET_ORGANIZATION_SOFTWARE'
}

export interface SoftwareState {
  loading: boolean;
  all: Software[];
  readonly errors?: string;
}
