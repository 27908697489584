import React from 'react';
import styled from '@emotion/styled';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XNoContent from 'src/components/domain/XNoContent';
import XPageContent from 'src/components/layout/XPageContent';
import XPagination from 'src/components/layout/XPagination';
import { XPaper } from 'src/components/layout/XPaper';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import { XRowCell } from 'src/components/layout/XRow';
import XScrollView from 'src/components/layout/XScrollView';
import XTable, { TableRow } from 'src/components/layout/XTable';
import { formatDateToString } from 'src/helpers/formatHelpers';
import i18n from 'src/i18n';
import { OrderMessage } from 'src/models/OrderMessage';
import { OrganizationType } from 'src/models/Organization';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { getOrderMessagesThunk } from 'src/store/data/actions';
import EmptyImage from 'src/style/images/empty.svg';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  orderMessages: OrderMessage[];
  account: AuthenticationAccount;
};

type DispatchProps = {
  getOrderMessages(): () => void;
};

type OwnProps = {};

type State = {
  currentOffset: number;
};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps<{ direction?: string }>;

class StockTrafficPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      currentOffset: 0
    };
  }

  public componentDidMount() {
    this.props.getOrderMessages();
  }

  public render() {
    const pageSize = 20;
    const direction = this.props.match.params.direction;

    let title = i18n.t('traffic.rcvdStock');
    let subtitle = i18n.t('traffic.receivedMessages');
    let organizationTitle = i18n.t('traffic.Sender');
    let predicate = x => x.fromOrganizationId !== this.props.account.organizationId;

    if (direction === 'out') {
      title = i18n.t('traffic.sentStock');
      subtitle = i18n.t('traffic.allMessages');
      organizationTitle = i18n.t('traffic.Receiver');
      predicate = x => x.fromOrganizationId === this.props.account.organizationId;
    }

    const messages = this.props.orderMessages.filter(o => o.type === 'Stock');
    const orderMessages = messages.filter(predicate);

    const paged = orderMessages.slice(
      this.state.currentOffset,
      this.state.currentOffset + pageSize
    );

    if (this.props.account.organizationType === OrganizationType.Retailer) {
      if (direction === 'out') {
        return null;
      }
    }

    if (this.props.account.organizationType === OrganizationType.Supplier) {
      if (direction === 'in') {
        return null;
      }
    }

    return (
      <ErrorBoundary>
        <XPageContent>
          <StyledPaper
            style={{ margin: '15px 0', height: orderMessages.length === 0 ? '300px' : '' }}>
            <XPaperHeader
              header={title}
              count={orderMessages.length}
              subheader={subtitle}
              search={false}
            />

            {orderMessages.length === 0 ? (
              <XNoContent image={EmptyImage}>
                <Translation>{t => <h3>{t('traffic.noMessageTraffic')}</h3>}</Translation>
              </XNoContent>
            ) : (
              <XScrollView>
                <Translation>
                  {t => (
                    <XTable
                      headers={[
                        'Via',
                        organizationTitle,
                        'Type',
                        t('traffic.File'),

                        'Downloaded',
                        { title: '', width: 50 }
                      ]}>
                      {paged.map(dm => (
                        <TableRow key={dm.id}>
                          <StyledCell>Arxis</StyledCell>
                          <StyledCell>{dm.organizationName}</StyledCell>
                          <StyledCell>{dm.type}</StyledCell>
                          <SmallStyledCell>{dm.fileName}</SmallStyledCell>

                          <SmallStyledCell>
                            {formatDateToString(dm.creationDate, true, true)}
                          </SmallStyledCell>
                          <StyledCell style={{ textAlign: 'center', maxWidth: 50 }}>
                            <a target="_blank" download="true" href={dm.blobUri}>
                              <DownloadIcon />
                            </a>
                          </StyledCell>
                        </TableRow>
                      ))}
                    </XTable>
                  )}
                </Translation>
              </XScrollView>
            )}

            {orderMessages.length > pageSize && (
              <XPagination
                itemCount={orderMessages.length}
                itemsPerPage={pageSize}
                changePage={i => this.setState({ currentOffset: i })}
              />
            )}
          </StyledPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    orderMessages: state.data.orderMessages,
    account: state.authentication.account
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getOrderMessages: () => dispatch(getOrderMessagesThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(StockTrafficPage);

const StyledPaper = styled(XPaper)`
  min-height: 300px;
`;

const StyledCell = styled(XRowCell)`
  padding-top: 5px;
  margin: 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SmallStyledCell = styled(StyledCell)`
  font-size: 12px;
`;
