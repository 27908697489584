import styled from '@emotion/styled';
import * as React from 'react';
import XCheckbox from './XCheckbox';
import { XRowCell } from './XRow';

type OwnProps = {
  onToggle: (selected: boolean) => void;
  value?: boolean;
};

type State = {
  value: boolean;
};

class XSelectItem extends React.Component<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props);
    this.state = { value: this.props.value === undefined ? false : this.props.value === true };
  }

  public componentDidUpdate(prevProps: OwnProps) {
    if (prevProps.value !== this.props.value)
      this.setState({ value: this.props.value === undefined ? false : this.props.value === true });
  }

  public toggleInput() {
    const newValue = !this.state.value;
    this.setState({ value: newValue });
    this.props.onToggle(newValue);
  }

  public render() {
    return (
      <Row>
        <XRowCell
          style={{ maxWidth: '60px', display: 'flex', cursor: 'pointer' }}
          onClick={() => this.toggleInput()}>
          <div style={{ margin: 'auto' }}>
            <XCheckbox checked={this.state.value} />
          </div>
        </XRowCell>
        <XRowCell>{this.props.children}</XRowCell>
      </Row>
    );
  }
}

export default XSelectItem;

const Row = styled.div`
  display: flex;
`;
