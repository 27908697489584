import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link, RouteComponentProps } from 'react-router-dom';
import XCatalog from 'src/components/domain/XCatalog';
import XOrganizationDetail from 'src/components/domain/XOrganizationDetail';
import { XContainer } from 'src/components/layout/XContainer';
import XGrid from 'src/components/layout/XGrid';
import { XImage, XImageContainer } from 'src/components/layout/XImage';
import XPageContent from 'src/components/layout/XPageContent';
import {
  XPaper,
  XPaperContainer,
  XPaperLabel,
  XPaperText,
  XPaperTitle
} from 'src/components/layout/XPaper';
import { XRow, XRowCell } from 'src/components/layout/XRow';
import { ApiResult } from 'src/helpers/apiClient';
import { formatDateToString, getFileFormatOptions, getImageUrl } from 'src/helpers/formatHelpers';
import { CatalogModel, PublicationStatus } from 'src/models/Catalog';
import { MyOrganizationModel, OrganizationModel, OrganizationType } from 'src/models/Organization';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import * as catalogActions from 'src/store/catalogs/actions';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import * as organizationActions from 'src/store/organizations/actions';

import XErrorContent from 'src/components/domain/XErrorContent';
import XForm from 'src/components/layout/XForm';
import XPagination from 'src/components/layout/XPagination';
import XPopup from 'src/components/layout/XPopup';
import XScrollView from 'src/components/layout/XScrollView';
import XGoBack from 'src/components/shared/XGoBack';
import { delayExecution } from 'src/helpers/actionHelpers';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { mapError } from 'src/helpers/errorMap';
import { FileFormat } from 'src/models/FileFormat';
import { Software } from 'src/models/Software';
import { getSoftwareThunk, sortSoftware } from 'src/store/software/actions';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { XSecActionButton, XActionButton } from 'src/components/layout/XActionButton';
import { Skeleton } from '@material-ui/lab';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
const ShowPDF = React.lazy(() => import('../../components/shared/ShowPDF'));

type StateProps = {
  catalog?: CatalogModel;
  myOrganization?: MyOrganizationModel;
  myAccount: AuthenticationAccount;
  organizationModel?: OrganizationModel;
  loadingCatalog: boolean;
  loadingOrganization: boolean;
  fileFormats: FileFormat[];
  software: Software[];
};

type DispatchProps = {
  showNotification: (message: string, messageType?: MessageType) => void;
  getCatalogById: (id: string) => Promise<ApiResult>;
  getMyOrganization: () => void;
  getOrganization: (organizationId: string) => void;
  downloadCatalog: (
    catalogId: string,
    versionId: string,
    fileFormat: string,
    fileFormatVersion: string
  ) => Promise<ApiResult>;
  getFileFormats: () => Promise<ApiResult>;
  getSoftware: () => Promise<ApiResult>;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps<{ id: string }>;

type State = {
  showDownloadPopup: boolean;
  fileFormatData: {
    fileFormat: string | null;
    fileFormatVersion: string | null;
    fileFormatSelection: string | null;
    softwareSelection: string | null;
  };
  showOther: boolean;
  fileFormatSelectionError: boolean;
  processing: boolean;
  errorMessage: string;
  showPdf: boolean;
  pdfUrl: string;
  pdfPage: number;
  pdfTotalPages: number;
};

class ShowCatalogPage extends React.Component<AllProps, State> {
  setDocumentRef: (document: any) => any;
  document: any;

  public constructor(props: AllProps) {
    super(props);
    this.setDocumentRef = doc => (this.document = doc);

    this.state = {
      fileFormatData: {
        fileFormat: null,
        fileFormatVersion: null,
        fileFormatSelection: null,
        softwareSelection: null
      },
      showDownloadPopup: false,
      fileFormatSelectionError: false,
      processing: false,
      showOther: false,
      errorMessage: '',
      showPdf: false,
      pdfUrl: '',
      pdfPage: 1,
      pdfTotalPages: 1
    };
  }

  public componentDidUpdate(prev) {
    if (this.props.catalog?.catalog.id !== prev.catalog?.catalog.id) {
      this.setState({
        showDownloadPopup: false,
        fileFormatData: {
          fileFormat: null,
          fileFormatVersion: null,
          fileFormatSelection: null,
          softwareSelection: null
        },
        fileFormatSelectionError: false,
        processing: false,
        errorMessage: ''
      });
    }
    if (this.props.match.params.id !== prev.match.params.id) {
      this.props.getCatalogById(this.props.match.params.id).then(result => {
        if (!result.IsSuccess) {
          if (result.StatusCode === 401)
            // Not authorized for this catalog
            this.props.history.push('/app/start');
        } else {
          if (this.props.catalog)
            this.props.getOrganization(this.props.catalog.catalog.organizationId);
        }
      });

      this.props.getFileFormats();
      this.props.getSoftware();
      const softwareId = this.props.myOrganization?.organization.softwareId;
      if (softwareId !== null && softwareId !== '00000000-0000-0000-0000-000000000000')
        this.handleFormChange('softwareSelection', softwareId);
    }
  }

  public handleOnLoad(event, request: any, fileName: string) {
    var blobFile = request.response;
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blobFile);
    link.download = fileName;
    link.click();
  }

  public downloadFile(
    url,
    catalogName,
    organizationName,
    catalogId,
    version,
    fileFormat,
    extension
  ) {
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    var fileName =
      catalogName +
      '_v' +
      version +
      '_' +
      organizationName +
      '_' +
      catalogId +
      '-' +
      fileFormat +
      '.' +
      version +
      '.' +
      extension;
    request.onload = e => this.handleOnLoad(e, request, fileName);
    request.send();
  }

  public getFile(forPdf: boolean) {
    if (!this.props.catalog) {
      return;
    }

    const version = this.props.catalog.versions[0];
    const sourceFileFormat = this.props.fileFormats.find(f => f.id === version.fileFormat);
    if (!sourceFileFormat) return;

    let fileFormatId: string;
    let fileFormatVersionId: string;
    if (sourceFileFormat.electronicInterchangeable) {
      // File format/version should be selected if it is an interchangable source
      this.setState({ fileFormatSelectionError: !this.state.fileFormatData.fileFormatSelection });
      if (!this.state.fileFormatData.fileFormat || !this.state.fileFormatData.fileFormatVersion)
        return;

      fileFormatId = this.state.fileFormatData.fileFormat;
      fileFormatVersionId = this.state.fileFormatData.fileFormatVersion;
    } else {
      fileFormatId = version.fileFormat;
      fileFormatVersionId = version.fileFormatVersion;
    }

    this.setState({ processing: true });
    const startTime = new Date().getTime();
    this.props
      .downloadCatalog(
        this.props.catalog.catalog.id,
        this.props.catalog.versions[0].id,
        fileFormatId,
        fileFormatVersionId
      )
      .then(apiResult => {
        if (forPdf) {
          this.setState({ showPdf: true, pdfUrl: apiResult.Data, processing: false });
        } else {
          delayExecution(startTime, 500, () => {
            this.setState({
              processing: false,
              showDownloadPopup: !apiResult.IsSuccess,
              errorMessage: ''
            });

            if (apiResult.IsSuccess) {
              if (this.validateUrl(apiResult.Data)) {
                // Selected format available for download
                let fileFormat = this.props.fileFormats.find(x => x.id === fileFormatId);
                let fileFromatVersion =
                  fileFormat === undefined
                    ? '1'
                    : fileFormat.versions.find(x => x.id === fileFormatVersionId)?.name;
                this.downloadFile(
                  apiResult.Data,
                  this.props.catalog?.catalog.title,
                  this.props.catalog?.catalog.organizationName,
                  this.props.catalog?.catalog.id,
                  fileFromatVersion,
                  this.props.fileFormats.find(x => x.id === fileFormatId)?.name,
                  this.props.fileFormats.find(x => x.id === fileFormatId)?.defaultExtension
                );
              } else if (!apiResult.Data) {
                // Selected format unavailable for download. Added to queue of catalog conversions
                this.props.showNotification(
                  'De catalogus wordt klaargemaakt voor de gekozen software. \r\n' +
                    'Je zult per email (' +
                    this.props.myAccount.email +
                    ') op de hoogte gesteld worden van de voortgang',
                  'success'
                );
              }
            } else {
              if (apiResult.Errors && apiResult.Errors[0].errorCode === 'LTX4001')
                this.setState({
                  errorMessage:
                    'Deze catalogus is helaas (nog) niet beschikbaar in dit bestandsformaat'
                });
              else
                this.props.showNotification(
                  'Er ging iets mis bij het downloaden van de catalogus: ' +
                    mapError(apiResult.Errors),
                  'error'
                );
            }
          });
        }
      });
  }

  public componentDidMount() {
    this.props.getCatalogById(this.props.match.params.id).then(result => {
      if (!result.IsSuccess) {
        if (result.StatusCode === 401)
          // Not authorized for this catalog
          this.props.history.push('/app/start');
      } else {
        if (this.props.catalog)
          this.props.getOrganization(this.props.catalog.catalog.organizationId);
      }
    });

    this.props.getFileFormats();
    this.props.getSoftware();
    const softwareId = this.props.myOrganization?.organization.softwareId;
    if (softwareId !== null && softwareId !== '00000000-0000-0000-0000-000000000000')
      this.handleFormChange('softwareSelection', softwareId);
  }

  public handleFormChange(name: string, value: any) {
    const newData = Object.assign({}, this.state.fileFormatData);
    if (name === 'fileFormatSelection') {
      const split = value?.split('|') ?? [undefined, undefined];
      newData.fileFormat = split[0];
      newData.fileFormatVersion = split[1];
      newData.fileFormatSelection = value;
    } else if (name === 'softwareSelection') {
      console.log(name, value);
      if (value === 'Other') {
        this.setState({ showOther: true });
      } else {
        this.setState({ showOther: false });
        const software = this.props.software.find(s => s.id === value);
        if (!software) return;

        newData.fileFormat = software.fileFormat;
        newData.fileFormatVersion = software.fileFormatVersion;
      }
      newData.softwareSelection = value;
    } else newData[name] = value;
    this.setState({ fileFormatData: newData });
  }

  private getPDFWidth() {
    if (!this.document) return '';

    const width = parseInt(this.document.clientWidth, 10);
    return width - 20;
  }

  private validateUrl(value) {
    var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var regexp = new RegExp(expression);
    return regexp.test(value);
  }

  public render() {
    const props = this.props;

    const availableFormats = ['eCat', 'LTX', 'CSV', 'VME', 'eCatVeldeman', 'eCat.iOne'];
    const avaiableFileFormats = props.fileFormats.filter(file =>
      availableFormats.find(available => available === file.name)
    );

    if (!props.catalog || !props.myOrganization || !props.organizationModel)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <XErrorContent />
          </XPaper>
        </XPageContent>
      );

    const validCatalogs = props.organizationModel.catalogs
      .filter(c => c.id !== props.catalog?.catalog.id)
      .filter(c => c.publicationStatus == PublicationStatus.Published);

    let bottomContent;
    if (this.props.myAccount.organizationType !== OrganizationType.Supplier) {
      bottomContent = (
        <div>
          {validCatalogs.length ? (
            <div>
              <XPaperContainer>
                <XContainer>
                  <XPaperLabel>Meer van deze leverancier</XPaperLabel>
                </XContainer>
              </XPaperContainer>
              <XGrid columns={3}>
                {props.loadingCatalog || props.loadingOrganization ? (
                  <Skeleton variant="rect" width={190} height={100} />
                ) : (
                  <>
                    {' '}
                    {validCatalogs
                      .slice(0, 16)
                      .map(obj =>
                        obj.id !== props.catalog?.catalog.id ? (
                          <XCatalog
                            key={obj.id}
                            catalog={obj}
                            cRoute="/app/my/catalogs/"
                            size="small"
                          />
                        ) : null
                      )}{' '}
                  </>
                )}
              </XGrid>
            </div>
          ) : (
            undefined
          )}
        </div>
      );
    }

    const pdfFileFormat = this.props.fileFormats.find(f => f.name === 'PDF');

    const lastVersion = props.catalog.versions.find(
      version => version.id === props.catalog?.catalog.currentVersionId
    );

    if (!lastVersion) {
      return null;
    }

    const StatsLine = (
      <XRow style={{ padding: '15px' }}>
        <XRowCell style={{ paddingTop: '5px' }}>
          <XGoBack history={this.props.history} />
        </XRowCell>
        {props.loadingCatalog || props.loadingOrganization ? (
          <Skeleton width={100} />
        ) : (
          <XActionStrip>
            {lastVersion.fileFormat === pdfFileFormat?.id && (
              <XSecActionButton onClick={() => this.getFile(true)}>Bekijk</XSecActionButton>
            )}
            <XActionButton onClick={() => this.setState({ showDownloadPopup: true })}>
              Download
            </XActionButton>
          </XActionStrip>
        )}
      </XRow>
    );

    let fileFormatInputs;
    const sourceFileFormat = this.props.fileFormats.find(f => f.id === lastVersion.fileFormat);
    if (!sourceFileFormat || sourceFileFormat.electronicInterchangeable) {
      fileFormatInputs = [
        {
          name: 'softwareSelection',
          title: 'Welk software pakket heb je?',
          required: true,
          type: 'select',
          value: this.props.myOrganization?.organization.softwareId,
          options: [
            ...this.props.software.map(s => ({ name: s.name, value: s.id })),
            { name: 'Anders', value: 'Other' }
          ]
        }
      ];
      if (this.state.showOther) {
        const options = getFileFormatOptions(avaiableFileFormats, false);
        fileFormatInputs = [
          ...fileFormatInputs,
          {
            name: 'fileFormatSelection',
            title: 'Welk formaat?',
            required: true,
            type: 'select',
            options
          }
        ];
      }
    } else {
      if (!this.state.fileFormatData.fileFormat)
        this.setState({
          fileFormatData: {
            fileFormat: lastVersion.fileFormat,
            fileFormatVersion: lastVersion.fileFormatVersion,
            fileFormatSelection: lastVersion.fileFormat + '|' + lastVersion.fileFormatVersion,
            softwareSelection: null
          }
        });

      fileFormatInputs = [
        {
          name: 'fileFormatSelection',
          title: 'Bestands formaat',
          readonly: true,
          required: true,
          type: 'select',
          options: [
            {
              name: sourceFileFormat.name,
              value: lastVersion.fileFormat + '|' + lastVersion.fileFormatVersion
            }
          ]
        }
      ];
    }

    const bottomPager = (
      <XPagination
        itemCount={this.state.pdfTotalPages}
        itemsPerPage={1}
        changePage={n => this.setState({ pdfPage: n + 1 })}
      />
    );

    return (
      <ErrorBoundary>
        <XPageContent>
          <Suspense fallback={<div>Loading PDF...</div>}>
            {this.state.showPdf && (
              <ShowPDF
                open={this.state.showPdf}
                bottomPager={bottomPager}
                title={this.props.catalog?.catalog.title ?? ''}
                onCancel={() => this.setState({ showPdf: false })}
                ref={this.setDocumentRef}
                onLoadSuccess={pdf => this.setState({ pdfTotalPages: pdf.numPages })}
                file={this.state.pdfUrl}
                pageNumber={this.state.pdfPage}
                width={this.getPDFWidth()}
              />
            )}
          </Suspense>
          <XPopup
            title="Catalogus downloaden"
            cancelOnPagClick={true}
            open={this.state.showDownloadPopup}
            onCancel={() => this.setState({ showDownloadPopup: false })}
            onSave={() => this.getFile(false)}
            buttonSaveText="Download"
            buttonCancelText="Annuleren">
            <div style={{ minHeight: '130px' }}>
              {this.state.processing ? (
                <div style={{ textAlign: 'center' }}>
                  <div style={{ paddingTop: '20px', fontSize: '14px', marginBottom: '4px' }}>
                    Download verzoek aan het verwerken
                  </div>
                  <LinearProgress />
                </div>
              ) : (
                <div style={{ padding: '0 2px' }}>
                  <XForm
                    data={this.state.fileFormatData}
                    margin={false}
                    fields={fileFormatInputs}
                    inputErrors={{ fileFormatSelection: this.state.fileFormatSelectionError }}
                    callback={(name, value) => this.handleFormChange(name, value)}
                  />
                  {this.state.errorMessage && (
                    <div style={{ padding: '10px 0', color: '#F29223' }}>
                      {this.state.errorMessage}
                    </div>
                  )}
                </div>
              )}
            </div>
          </XPopup>

          <XPaper style={{ minHeight: '300px', padding: '15px' }}>
            <XScrollView>
              <div style={{ height: '100%', position: 'relative' }}>
                {StatsLine}
                <XRow
                  style={{
                    height: 'calc(100% - 80px)'
                  }}>
                  <XRowCell>
                    <XRow>
                      <XContainer>
                        {props.loadingCatalog || props.loadingOrganization ? (
                          <Skeleton variant="rect" width={145} height={145} />
                        ) : (
                          <XImageContainer width={145} height={145}>
                            <XImage
                              src={getImageUrl(
                                props.catalog?.catalog.imageURL,
                                props.catalog?.catalog.organizationImageURL
                              )}
                              alt=""
                              onError={(e: any) => {
                                e.target.onError = null;
                                e.target.src = generateAvatar(
                                  props.catalog?.catalog.title ?? '',
                                  145
                                );
                              }}
                            />
                          </XImageContainer>
                        )}
                      </XContainer>
                      <XRowCell>
                        <XContainer>
                          <div>
                            <XPaperTitle style={{ fontSize: '22px' }}>
                              {props.loadingCatalog || props.loadingOrganization ? (
                                <Skeleton />
                              ) : (
                                props.catalog?.catalog.title
                              )}
                            </XPaperTitle>
                            <PaperSubTitle>
                              {props.loadingCatalog || props.loadingOrganization ? (
                                <Skeleton />
                              ) : (
                                props.catalog?.catalog.downloads + ' downloads'
                              )}
                            </PaperSubTitle>
                            {lastVersion.endDate !== undefined && (
                              <PaperSubTitle>
                                {props.loadingCatalog || props.loadingOrganization ? (
                                  <Skeleton />
                                ) : (
                                  `beschikbaar tot ${formatDateToString(lastVersion.endDate)}`
                                )}
                              </PaperSubTitle>
                            )}
                            <XPaperText>
                              {props.loadingCatalog || props.loadingOrganization ? (
                                <Skeleton />
                              ) : (
                                props.catalog?.catalog.description
                              )}
                            </XPaperText>
                          </div>
                        </XContainer>
                      </XRowCell>
                    </XRow>

                    <MediaQuery maxWidth={1480}>
                      {props.loadingCatalog || props.loadingOrganization ? (
                        <Skeleton />
                      ) : (
                        <XRow>
                          <div
                            style={{
                              width: '100%'
                            }}>
                            <XOrganizationDetail
                              showCatalogs={false}
                              showCatalogsButton={false}
                              isAdmin={props.myAccount.isAdmin}
                              organizationModel={props.organizationModel}
                            />
                          </div>
                        </XRow>
                      )}
                    </MediaQuery>

                    {bottomContent}
                  </XRowCell>
                  <MediaQuery minWidth={1480}>
                    {props.loadingCatalog || props.loadingOrganization ? (
                      <Skeleton />
                    ) : (
                      <XRowCell
                        style={{
                          maxWidth: '350px',
                          borderLeftColor: 'rgb(245,245,245)',
                          borderLeftWidth: '4px',
                          borderLeftStyle: 'solid'
                        }}>
                        <XOrganizationDetail
                          showCatalogs={false}
                          isAdmin={props.myAccount.isAdmin}
                          showCatalogsButton={false}
                          organizationModel={props.organizationModel}
                        />
                      </XRowCell>
                    )}
                  </MediaQuery>
                </XRow>
              </div>
            </XScrollView>
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    myAccount: state.authentication.account,
    myOrganization: state.organizations.myOrganization,
    catalog: state.catalogs.data.detail,
    organizationModel: state.organizations.detail,
    loadingCatalog: state.catalogs.loading,
    loadingOrganization: state.organizations.loading,
    fileFormats: state.catalogs.fileFormats,
    software: state.software.all.sort((a, b) => sortSoftware(a, b))
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, messageType?: MessageType) => {
    dispatch(showNotification(message, messageType));
  },
  getCatalogById: (id: string) => dispatch(catalogActions.getCatalogThunk(id)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk()),
  getOrganization: (organizationId: string) =>
    dispatch(organizationActions.getOrganizationThunk(organizationId)),
  downloadCatalog: (
    catalogId: string,
    catalogVersionId: string,
    fileFormat: string,
    fileFormatVersion: string
  ) =>
    dispatch(
      organizationActions.downloadCatalogThunk(
        catalogId,
        catalogVersionId,
        fileFormat,
        fileFormatVersion
      )
    ),
  getFileFormats: () => dispatch(catalogActions.getFileFormatsThunk()),
  getSoftware: () => dispatch(getSoftwareThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowCatalogPage);

const StatsLineItem = styled(XRowCell)`
  line-height: 24px;

  & > button {
    max-width: 200px;
  }
`;

const PaperSubTitle = styled.div`
  font-size: 13px;
  margin: 2px 15px;
`;
