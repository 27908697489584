import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XPageContent from 'src/components/layout/XPageContent';
import {
  getInterfaceText,
  getInterfaceTypesCatalog
} from 'src/constants/OrganizationInterface/OrganizationInterfaces';
import { ApiResult } from 'src/helpers/apiClient';
import {
  InterfaceType,
  MyOrganizationModel,
  OrderInterfaceSettingsModel,
  Organization,
  OrganizationInterface
} from 'src/models/Organization';
import { Software } from 'src/models/Software';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import {
    deleteOrderInterfaceThunk,
  editOrganizationThunk,
  getMyOrganizationThunk,
  saveOrderInterfaceSettingsThunk
} from 'src/store/organizations/actions';
import { sortSoftware } from 'src/store/software/actions';
import InterfaceCard from 'src/pages/myltx/software/InterfaceCard';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  organization?: MyOrganizationModel;
  software: Software[];
};

type DispatchProps = {
  showNotification: (message: string, type: MessageType) => void;
  getOrganization: () => void;
  saveOrderInterfaceSettings: (
    organizationId: string,
    data: OrderInterfaceSettingsModel
  ) => Promise<ApiResult>;
  editOrganization: (organization: Organization) => Promise<ApiResult>;
  deleteOrderInterface: (id: string) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{ id: string }>;

class CatalogsSettingsPage extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.props.getOrganization();
  }

  public getInterface(t: InterfaceType): OrganizationInterface | undefined {
    return this.props.organization?.interfaces.find(x => x.type === t);
  }

  public render() {
    const interfaces = getInterfaceTypesCatalog(
      this.props.organization?.organization.organizationType
    );
    return (
      <ErrorBoundary>
        <XPageContent style={{ maxWidth: '880px' }}>
          {interfaces.map((i, idx) => {
            const text = getInterfaceText(i);

            return (
              <InterfaceCard
                onDelete={() => this.props.deleteOrderInterface(this.getInterface(i)?.id!)}
                deleteVisible={false}
                key={idx}
                title={text.title}
                subTitle={text.description}
                interface={this.getInterface(i)}
                type={i}
                editPath="/app/my/catalogs/settings/interface"
              />
            );
          })}
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    organization: state.organizations.myOrganization,
    software: state.software.all.sort((a, b) => sortSoftware(a, b))
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type: MessageType) => {
    dispatch(showNotification(message, type));
  },
  getOrganization: () => dispatch(getMyOrganizationThunk()),
  saveOrderInterfaceSettings: (id, data) => dispatch(saveOrderInterfaceSettingsThunk(id, data)),
  editOrganization: (organization: Organization) => dispatch(editOrganizationThunk(organization)),
  deleteOrderInterface: (id: string) => dispatch(deleteOrderInterfaceThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogsSettingsPage);
