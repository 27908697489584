import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

type MyProps = {
  style?: any;
};

const XPageContent: React.SFC<MyProps> = props => {
  return <StyledPageContent style={props.style}>{props.children}</StyledPageContent>;
};

const StyledPageContent = styled.div`
  margin: 40px auto;
  border-radius: 5px;
  max-width: 1200px;
  width: calc(100% - 80px);
`;

export default XPageContent;
