import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import XTableViewerPopup from 'src/components/domain/XTableViewerPopup';
import XButton from 'src/components/layout/XButton';
import { XContainer } from 'src/components/layout/XContainer';
import { XButtonStyle } from 'src/components/layout/XFileSelectButton';
import XFormButtons from 'src/components/layout/XFormButtons';
import XPagination from 'src/components/layout/XPagination';
import { XRowCell } from 'src/components/layout/XRow';
import XTable, { TableRow } from 'src/components/layout/XTable';
import { ApiError } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { CatalogValidationSummary } from 'src/models/Catalog';
import { OrganizationType } from 'src/models/Organization';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../../store';
import * as catalogActions from '../../../store/catalogs/actions';
import * as catalogSelects from '../../../store/catalogs/selectors';
import { CatalogFormData } from '../../../store/catalogs/types';
import { AccessRoute } from './WizardRouter';

type StateProps = {
  data: CatalogFormData;
  validationSummary?: CatalogValidationSummary;
  validationError?: ApiError;
  account: AuthenticationAccount;
};

type DispatchProps = {
  storeInformation: (data: CatalogFormData) => void;
  showNotification: (text: string, type: MessageType) => void;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps;

type State = {
  newData: CatalogFormData;
  violationsOffset: number;
  showPopup: boolean;
  uploading: boolean;
};

class WizardValidationPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      newData: props.data,
      violationsOffset: 0,
      showPopup: false,
      uploading: window.location.href.indexOf('/new/') !== -1
    };
  }

  public componentDidMount() {
    if (this.props.account.organizationType === OrganizationType.Retailer) {
      this.props.history.push('/app/start');
      return;
    }

    if (this.state.uploading && this.props.data.uploaded) {
      this.props.history.push('/app/my/catalogs/new');
      return;
    }

    if (!this.props.validationSummary && !this.props.validationError) {
      this.props.history.push('/app/my/catalogs/new/file');
    }
  }

  public previousPage = () => {
    this.props.history.goBack();
  };

  public next = () => {
    if (this.state.uploading) this.props.history.push(AccessRoute.path);
    else this.props.history.push('/app/my/catalogs');
  };

  public downloadValidationSummary() {
    const data =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(this.props.validationSummary, null, 2));
    const link = document.createElement('a');
    link.download = 'validatie-rapport.json';
    link.href = data;
    link.click();
  }

  public render() {
    return (
      <XContainer style={{ position: 'relative', padding: '0 15px' }}>
        {this.props.validationSummary && (
          <XTableViewerPopup
            paged={false}
            pageSize={0}
            maxResults={500}
            title="Validatie rapport"
            columns={[
              { name: 'level', width: '100px' },
              {
                name: 'lineNumber',
                width: '100px',
                interpolation: str => (str === 0 ? '-' : '#' + str)
              },
              { name: 'message' }
            ]}
            open={this.state.showPopup}
            onCancel={() => this.setState({ showPopup: false })}
            data={this.props.validationSummary.violations}
          />
        )}

        <div>
          {this.props.validationError ? (
            <div style={{ padding: '40px 0 0 0', minHeight: '250px' }}>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <HighlightOffIcon style={{ color: '9c1006', width: '100px', height: '100px' }} />
              </div>
              <div style={{ textAlign: 'center' }}>
                {this.props.validationError.errorCode === 'LTX4001'
                  ? 'Validatie is nog niet beschikbaar voor dit bestandsformaat'
                  : mapError([this.props.validationError])}
              </div>
            </div>
          ) : this.props.validationSummary?.violations.length === 0 ? (
            <div style={{ padding: '40px 0 0 0', minHeight: '250px' }}>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <CheckCircleIcon style={{ color: '0d8416', width: '100px', height: '100px' }} />
              </div>
              <div style={{ textAlign: 'center' }}>Validatie compleet, geen bevindingen</div>
            </div>
          ) : (
            <div style={{ padding: '40px 0 0 0', minHeight: '280px' }}>
              <div style={{ textAlign: 'center' }}>
                <HighlightOffIcon
                  style={{ color: '9c1006', marginBottom: '10px', width: '100px', height: '100px' }}
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                Validatie compleet, {this.props.validationSummary?.violations.length} bevindingen
              </div>
              <div
                style={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  padding: '10px 0',
                  fontWeight: 'bold'
                }}
                onClick={() => this.setState({ showPopup: true })}>
                Rapport inzien
              </div>
              <div
                style={{ textAlign: 'center', cursor: 'pointer', fontWeight: 'bold' }}
                onClick={() => this.downloadValidationSummary()}>
                Rapport downloaden
              </div>
            </div>
          )}

          <XFormButtons
            style={{ padding: '0' }}
            onSave={this.next}
            onCancel={this.previousPage}
            cancelText="Terug"
            saveText={this.state.uploading ? 'Volgende' : 'Sluiten'}
            enabledSave={
              this.state.uploading
                ? !this.props.validationError &&
                  this.props.validationSummary?.violations.find(v => v.level === 'Error') ===
                    undefined
                : true
            }
          />
        </div>
      </XContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    validationSummary: state.catalogs.validation.validationSummary,
    validationError: state.catalogs.validation.error,
    data: catalogSelects.newCatalogState(state.catalogs)
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  storeInformation: (data: CatalogFormData) => dispatch(catalogActions.storeCatalogAction(data)),
  showNotification: (text: string, type: MessageType) => dispatch(showNotification(text, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardValidationPage);
