import * as React from 'react';

interface ErrorProps {
  title?: string;
  description?: string;
}

const ErrorPage: React.SFC<ErrorProps> = props => {
  return (
    <React.Fragment>
      <h1>Error: {props.title}</h1>
      <h2>{props.description}</h2>
    </React.Fragment>
  );
};

export default ErrorPage;
