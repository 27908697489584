import React from 'react';
import { Translation } from 'react-i18next';
import { API_URL } from 'src/constants/ApiRoutes';
import { apiClient } from 'src/helpers/apiClient';
import './css/formsmain.css';
import i18n from 'i18next';

type State = {
  name: string;
  email: string;
  phone: string;
  message: string;
  loading: boolean;
  success: boolean;
  tried: boolean;
};

class ContactPage extends React.Component<{}, State> {
  componentDidMount() {
    document.title = 'Arxis | Contact';
  }

  constructor(props: any) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',
      loading: false,
      success: false,
      tried: false
    };

    this.submit = this.submit.bind(this);
  }

  public async submit(e) {
    e.preventDefault();
    this.setState({ loading: true, tried: true });
    if (this.state.loading || this.state.success) {
      return;
    }

    const required = ['name', 'email', 'message'];

    if (required.every(x => this.state[x].length > 0)) {
      const formData = {
        Name: this.state.name,
        Email: this.state.email,
        Phone: this.state.phone,
        Message: this.state.message
      };

      const response = await apiClient.postDataAsync(`${API_URL}contact`, formData);
      this.setState({ success: true });
    }
    this.setState({ loading: false });
  }

  public renderForm() {
    return (
      <Translation>
        {t => {
          if (this.state.success)
            return (
              <div id="gform_confirmation_wrapper_2" className="gform_confirmation_wrapper ">
                <div
                  id="gform_confirmation_message_2"
                  className="gform_confirmation_message_2 gform_confirmation_message">
                  {t('contact.success')}
                </div>
              </div>
            );
          else
            return (
              <form onSubmit={this.submit}>
                <div className="gform_body">
                  <ul
                    id="gform_fields_2"
                    className="gform_fields top_label form_sublabel_below description_below">
                    <li
                      id="field_2_1"
                      className="gfield gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
                      <label className="gfield_label" htmlFor="input_2_1">
                        {t('contact.name')}
                        <span className="gfield_required">*</span>
                      </label>
                      <div className="ginput_container ginput_container_text">
                        <input
                          name="input_1"
                          id="input_2_1"
                          type="text"
                          className="large"
                          tabIndex={1}
                          required={true}
                          aria-invalid="false"
                          value={this.state.name}
                          onChange={e => this.setState({ name: e.target.value })}
                        />
                      </div>
                    </li>
                    <li
                      id="field_2_3"
                      className="gfield gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
                      <label className="gfield_label" htmlFor="input_2_3">
                        {t('contact.email')}
                        <span className="gfield_required">*</span>
                      </label>
                      <div className="ginput_container ginput_container_email">
                        <input
                          name="input_3"
                          id="input_2_3"
                          type="email"
                          className="large"
                          tabIndex={2}
                          required={true}
                          aria-invalid="false"
                          value={this.state.email}
                          onChange={e => this.setState({ email: e.target.value })}
                        />
                      </div>
                    </li>
                    <li
                      id="field_2_5"
                      className="gfield field_sublabel_below field_description_below gfield_visibility_visible">
                      <label className="gfield_label" htmlFor="input_2_5">
                        {t('contact.phone')}
                      </label>
                      <div className="ginput_container ginput_container_text">
                        <input
                          name="input_5"
                          id="input_2_5"
                          type="text"
                          className="large"
                          tabIndex={3}
                          aria-invalid="false"
                          value={this.state.phone}
                          onChange={e => this.setState({ phone: e.target.value })}
                        />
                      </div>
                    </li>
                    <li
                      id="field_2_6"
                      className="gfield gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
                      <label className="gfield_label" htmlFor="input_2_6">
                        {t('contact.message')}
                        <span className="gfield_required">*</span>
                      </label>
                      <div className="ginput_container ginput_container_textarea">
                        <textarea
                          name="input_6"
                          id="input_2_6"
                          className="textarea medium"
                          tabIndex={4}
                          required={true}
                          aria-invalid="false"
                          rows={10}
                          cols={50}
                          defaultValue={''}
                          value={this.state.message}
                          onChange={e => this.setState({ message: e.target.value })}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="gform_footer top_label text-right">
                  <input
                    type="submit"
                    id="gform_submit_button_2"
                    className="gform_button button"
                    value={t('contact.submit') as string}
                    tabIndex={5}
                  />
                </div>
              </form>
            );
        }}
      </Translation>
    );
  }

  public render() {
    return (
      <Translation>
        {t => (
          <div className="wrapper-white fl-left default-background ">
            <div className="section container">
              <div className="row">
                <div className="col-sm-12" />
                <div data-link={true} className="cover-background col-lg-6">
                  <p />
                  <p>&nbsp;</p>
                  <h2 className="uppercase top-titel">{t('contact.contactUs')}</h2>
                  <p>&nbsp;</p>
                  <ul className="list-style-none contact-gegevens">
                    <li>
                      <img className="img" src={require('./images/location.svg')} width={30} />
                        <a href='http://maps.google.com/?q=Piet Heinkade 55,1019 GM Amsterdam, Nederland' target='blank'>
                          Piet Heinkade 55 , 1019 GM Amsterdam
                        </a>
                    </li>
                    <li>
                      <img className="img" src={require('./images/email.svg')} width={30} />
                      <a href="mailto:info@arxis.io">info@arxis.io</a>
                    </li>
                    <li>
                      <img className="img" src={require('./images/phone.svg')} width={30} />
                      <a href='tel:+31 (0) 88 2400 110'>+31 (0) 88 2400 110</a>
                    </li>
                  </ul>
                  <p />
                  <div className="center" />
                </div>
                <div
                  data-link={true}
                  style={{}}
                  className="  cover-background col-lg-6   price-card ">
                  <p />
                  <div
                    className="gf_browser_unknown gform_wrapper"
                    id="gform_wrapper_2"
                    style={{ opacity: this.state.loading ? 0.5 : 'unset' }}>
                    {this.renderForm()}
                  </div>
                  <p />
                  <div className="center" />
                </div>{' '}
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default ContactPage;
