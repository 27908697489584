import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as React from 'react';

export enum XButtonStyle {
  Primary,
  Secondary
}

type OwnProps = {
  onSelect?: any;
  name?: string;
  active?: boolean;
  colorscheme?: XButtonStyle;
  staticcolor?: string;
  textcolor?: string;
  width?: string;
  height?: string;
  padding?: boolean;
  loading?: boolean;
};

type AllProps = OwnProps;

const gradientSwitch = (style: XButtonStyle | undefined, props: any) => {
  switch (style) {
    case XButtonStyle.Primary:
      return (
        'linear-gradient(to right,' + props.theme.pStartColor + ',' + props.theme.pEndColor + ')'
      );
    case XButtonStyle.Secondary:
      return (
        'linear-gradient(to right,' + props.theme.sStartColor + ',' + props.theme.sEndColor + ')'
      );
    default:
      return '';
  }
};

const XFileSelectButton: React.SFC<AllProps> = props => {
  let hiddenFileSelectRef;
  const hiddenFileSelect = (
    <input
      ref={input => (hiddenFileSelectRef = input)}
      name={props.name}
      type="file"
      style={{ display: 'none' }}
      onChange={props.onSelect}
    />
  );

  return props.active === false ? (
    <NonActiveButton disabled={true}>
      {props.children}
      {props.loading === true ? (
        <CircularProgress style={{ height: '19px', width: '19px', marginLeft: '10px' }} />
      ) : (
        undefined
      )}
    </NonActiveButton>
  ) : (
    <div>
      <ActiveButton onClick={() => hiddenFileSelectRef.click()} {...props}>
        {props.children}
        {props.loading === true ? (
          <CircularProgress style={{ height: '19px', width: '19px', marginLeft: '10px' }} />
        ) : (
          undefined
        )}
      </ActiveButton>
      {hiddenFileSelect}
    </div>
  );
};

export default XFileSelectButton;

const ActiveButton = styled(Button)(
  {
    borderRadius: '0px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '2px',
    fontSize: '11px',
    position: 'relative',
    transition: 'all 0.2s ease-in-out'
  },
  (props: AllProps) => ({
    padding:
      props.padding === false
        ? undefined
        : props.colorscheme !== undefined
        ? '13px 46px 13px 30px'
        : '13px 30px',
    color: props.textcolor || '#fff',
    width: props.width || '',
    boxShadow:
      props.colorscheme === XButtonStyle.Primary
        ? '0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08)'
        : '',
    background: props.staticcolor ? props.staticcolor : gradientSwitch(props.colorscheme, props),
    '&::after':
      props.colorscheme !== undefined
        ? {
            content: '"▶"',
            position: 'absolute',
            opacity: 1,
            right: '22px',
            transition: 'all 0.2s ease-in-out'
          }
        : {},

    '&:hover::after': {
      opacity: 0,
      right: '50px'
    },
    '&:hover': {
      padding: props.colorscheme !== undefined ? '13px 38px 13px 38px' : undefined
    }
  })
);

const NonActiveButton = styled(Button)(
  {
    backgroundColor: 'lightgrey',
    borderRadius: '0px',
    textTransform: 'none',
    padding: '13px 46px 13px 30px',
    fontFamily: 'Montserrat-Bold'
  },
  (props: AllProps) => ({
    color: props.textcolor
  })
);
