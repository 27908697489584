import styled from '@emotion/styled';
import * as React from 'react';
import { theme } from 'src/style/Style';

type OwnProps = {
  count: number;
  color?: string;
  place?: {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
  };
};

type AllProps = OwnProps;

const XBadge: React.SFC<AllProps> = props => {
  return (
    <Badger style={props.place}>
      <BadgeNumber style={{ background: props.color }}>
        {props.count > 9 ? '9+' : props.count}
      </BadgeNumber>
    </Badger>
  );
};

export default XBadge;

const Badger = styled('div')({ zIndex: 10, position: 'absolute', top: 10, right: 10 });
const BadgeNumber = styled('label')({
  // withTheme(
  background: theme.pStartColor,
  borderRadius: '25px',
  fontSize: '12px',
  marginLeft: 'auto',
  padding: '0px 5px',
  minWidth: '19px',
  display: 'block',
  marginRight: 'auto',
  color: '#fff',
  textAlign: 'center'
});
// );
