import { css } from '@emotion/core';
import styled from '@emotion/styled';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import animateScrollTo from 'animated-scroll-to';
import * as React from 'react';

type OwnProps = {
  itemCount: number;
  itemsPerPage: number;
  changePage: (newStartIndex: number) => void;
};

type AllProps = OwnProps;

type State = {
  currentOffset: number;
};

class XPagination extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      currentOffset: 0
    };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.itemCount !== this.props.itemCount) {
      this.setState({ currentOffset: 0 });
      this.props.changePage(0);
    }
  }

  public canGoDown() {
    return this.state.currentOffset > 0;
  }

  public canGoUp() {
    return this.state.currentOffset + this.props.itemsPerPage < this.props.itemCount;
  }

  public changePageUp() {
    if (!this.canGoUp()) return;

    const newOffset = this.state.currentOffset + this.props.itemsPerPage;
    this.setState({ currentOffset: newOffset });
    this.props.changePage(newOffset);
  }

  public changePageDown() {
    if (!this.canGoDown()) return;

    const newOffset = this.state.currentOffset - this.props.itemsPerPage;
    this.setState({ currentOffset: newOffset });
    this.props.changePage(newOffset);
  }

  public render() {
    const numberOfPages = Math.ceil(this.props.itemCount / this.props.itemsPerPage);
    const currentPage = Math.round(this.state.currentOffset / this.props.itemsPerPage) + 1;
    const from = (currentPage - 1) * this.props.itemsPerPage + 1;
    const offset = this.props.itemCount % this.props.itemsPerPage;
    const to =
      currentPage * this.props.itemsPerPage -
      (currentPage === numberOfPages && offset !== 0 ? this.props.itemsPerPage - offset : 0);

    return (
      <PaginationOuter>
        <PaginationFromToItems>
          {from} - {to} of {this.props.itemCount}
        </PaginationFromToItems>
        <PaginationInner>
          <PaginationButton
            onClick={e => this.changePageDown()}
            className={`${!this.canGoDown() ? 'disabled' : ''}`}>
            &#60;
          </PaginationButton>
          <PaginationCurrent>
            {Math.round(this.state.currentOffset / this.props.itemsPerPage) + 1} /{' '}
            {Math.max(Math.ceil(this.props.itemCount / this.props.itemsPerPage), 1)}
          </PaginationCurrent>
          <PaginationButton
            onClick={e => this.changePageUp()}
            className={`${!this.canGoUp() ? 'disabled' : ''}`}>
            &#62;
          </PaginationButton>
        </PaginationInner>
      </PaginationOuter>
    );
  }
}

export default XPagination;

const PaginationOuter = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  position: relative;
`;

const PaginationInner = styled.div`
  display: flex;
  background: #f7f7f7;
`;

const PaginationButton = styled.div`
  width: 40px;
  padding: 10px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  color: white;
  flex: 1;
  cursor: pointer;
  background: #2e7fc1;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.09), 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  &.disabled {
    opacity: 0.6;
  }
`;

const PaginationCurrent = styled.div`
  flex: 1;
  text-align: center;
  line-height: 40px;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
`;

const PaginationFromToItems = styled.div`
  margin-left: 1rem;
  position: absolute;
  left: 0;
`;
