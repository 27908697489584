import styled from '@emotion/styled';
import * as React from 'react';
import { Translation } from 'react-i18next';

import ErrorImage from '../../style/images/error.svg';

type OwnProps = {
  message?: string;
  size?: number;
  image?: any;
};

type AllProps = OwnProps;

const XErrorContent: React.SFC<AllProps> = props => {
  return (
    <Error {...props}>
      <div>
        <img src={props.image ?? ErrorImage} />
        <Translation>
          {t => <div>{props.message ?? t('notification.errWhileLoading')}</div>}
        </Translation>
      </div>
    </Error>
  );
};

export default XErrorContent;

const Error = styled.div<OwnProps>`
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #aaa;
  & img {
    width: ${props => (props.size ? props.size + 'px' : '150px')};
    height: ${props => (props.size ? props.size + 'px' : '150px')};
  }
  & a {
    text-decoration: underline;
    cursor: pointer;
  }
`;
