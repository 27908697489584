import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XStockRow from 'src/components/domain/XStockRow';
import XNoContent from 'src/components/domain/XNoContent';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import XTable from 'src/components/layout/XTable';
import { OrganizationModel } from 'src/models/Organization';
import { RequestModel } from 'src/models/Request';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { updateRequestThunk } from 'src/store/requests/actions';
import { CatalogRequestStatus } from 'src/store/requests/api';
import { ApplicationState } from '../../../store';
import * as organizationActions from '../../../store/organizations/actions';
import XPagination from 'src/components/layout/XPagination';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import XScrollView from 'src/components/layout/XScrollView';
import EmptyImage from '../../../style/images/empty-canvas.svg';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { XActionButton } from 'src/components/layout/XActionButton';
import XSearchBar from 'src/components/layout/XSearchBar';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import { XRowCell } from 'src/components/layout/XRow';
import { Translation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  myOrganization?: OrganizationModel;
  account: AuthenticationAccount;
  loading: boolean;
};

type DispatchProps = {
  showNotification: (message: string) => void;
  getMyOrganization: () => void;
  editRequest: (catalogRequest: RequestModel, status: CatalogRequestStatus) => void;
};

type State = {
  currentOffset: number;
  search: string;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{}>;

class StockPageSupplier extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      currentOffset: 0,
      search: ''
    };
  }

  public componentDidMount() {
    this.props.getMyOrganization();
  }

  public render() {
    if (this.props.loading || !this.props.myOrganization)
      return (
        <XPageContent>
          <XPaper
            style={{
              position: 'relative',
              minHeight: '300px'
            }}>
            <Translation>
              {t => <XPaperHeader header={t('stock.Stock')} subheader={<Skeleton />} />}
            </Translation>
            <XFilterBar>
              <XRowCell>
                <Translation>
                  {t => (
                    <XSearchBar
                      placeholder="Filter stocks"
                      onChange={e =>
                        this.setState({ search: e.target.value.toLowerCase() })
                      }></XSearchBar>
                  )}
                </Translation>
              </XRowCell>
            </XFilterBar>

            <XScrollView>
              <XTable headers={['Stock', 'Description', 'Created']}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </XTable>
            </XScrollView>
          </XPaper>
        </XPageContent>
      );

    const pageSize = 20;
    const stocks = this.props.myOrganization.stocks.filter(
      c => c.title.toLowerCase().indexOf(this.state.search) !== -1
    );
    const paged = stocks.slice(this.state.currentOffset, this.state.currentOffset + pageSize);
    return (
      <ErrorBoundary>
        <XPageContent>
          <XPaper
            style={{
              position: 'relative',
              minHeight: '300px',
              height: stocks.length === 0 ? '400px' : ''
            }}>
            <Translation>
              {t => (
                <XPaperHeader
                  header={t('stock.Stock')}
                  subheader={t('stock.belowAreStocksYouHavePublished')}
                  count={stocks.length}
                  customContent={
                    <XActionStrip>
                      <XActionButton
                        onClick={() => this.props.history.push('/app/my/stocks/add-stock')}>
                        Add stock
                      </XActionButton>
                    </XActionStrip>
                  }
                />
              )}
            </Translation>
            <XFilterBar>
              <XRowCell>
                <Translation>
                  {t => (
                    <XSearchBar
                      placeholder="Filter stocks"
                      onChange={e =>
                        this.setState({ search: e.target.value.toLowerCase() })
                      }></XSearchBar>
                  )}
                </Translation>
              </XRowCell>
            </XFilterBar>
            {stocks.length === 0 ? (
              <Translation>
                {t => (
                  <XNoContent image={EmptyImage}>
                    {this.state.search.length > 0 ? (
                      <div>Stock not found</div>
                    ) : (
                      <div>
                        <h3>You have not published any stock yet</h3>
                      </div>
                    )}
                  </XNoContent>
                )}
              </Translation>
            ) : (
              <XScrollView>
                <XTable headers={['Stock', 'Description', 'Created']}>
                  {paged.map(obj => (
                    <XStockRow
                      key={obj.id}
                      link={'/app/my/stocks/edit/' + obj.id}
                      showCreationDate={true}
                      stock={obj}
                    />
                  ))}
                </XTable>
              </XScrollView>
            )}

            {stocks.length > pageSize && (
              <XPagination
                itemCount={stocks.length}
                itemsPerPage={pageSize}
                changePage={i => this.setState({ currentOffset: i })}
              />
            )}
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    myOrganization: state.organizations.myOrganization,
    loading: state.organizations.loading
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string) => {
    dispatch(showNotification(message));
  },
  editRequest: (catalogRequest: RequestModel, status: CatalogRequestStatus) =>
    dispatch(updateRequestThunk(catalogRequest, status)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(StockPageSupplier);
