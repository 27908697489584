import { OrganizationType } from "src/models/Organization";

export enum ActionTypes {
  LOGIN = '[auth] LOGIN',
  LOGOUT = '[auth] LOGOUT'
}

export type AuthenticationAccount = {
  accountId: string;
  email: string;
  organizationId: string;
  organizationType: OrganizationType;
  isAdmin: boolean;
  settings: AccountSetting[];
  token: string;
  organizationName: string;
  fullName: string
};

export type AccountSetting = {
  id: string;
  accountId: string;
  name: string;
  value: any;
  changed: boolean;
};

export type AccountSettingForm = {
  name: string;
  value: any;
};

export interface AuthenticationState {
  isAuthenticated: boolean;
  loading: boolean;
  authenticationError?: string;
  account: AuthenticationAccount;
}
