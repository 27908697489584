import { css } from '@emotion/core';
import styled from '@emotion/styled';

type OwnProps = {
  scale?: boolean;
  space?: boolean;
  vertical?: boolean;
};

type AllProps = OwnProps;

export const XRow = styled('div')<AllProps>`
  display: flex;
  width: ${props => (props.scale ? '200px' : '100%')};
  justify-content: ${props => (props.space ? 'space-between' : '')};
  flex-direction: ${props => (!props.vertical ? 'row' : 'column')};
`;

export const XRowCell = styled('div')({
  flex: '1'
});
