import { css } from '@emotion/core';
import styled from '@emotion/styled';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import animateScrollTo from 'animated-scroll-to';
import * as React from 'react';
import AvatarEditor from 'react-avatar-editor';

import Pica from 'pica/dist/pica';

import { Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { XImage } from './XImage';

import { generateAvatar } from 'src/helpers/avatarGenerator';
import Logo from '../../style/images/ltxLogos/ltxdefOpaque.svg';
import { Translation } from 'react-i18next';

type OwnProps = {
  image?: any;
  width?: number;
  height?: number;
  style?: any;
  editable?: boolean;
  onChange: (blob?: Blob) => void;
};

type AllProps = OwnProps;

type State = {
  width: number;
  height: number;
  scale: number;
  rotate: number;
  editing: boolean;
  editable: boolean;
  image: any;
};

class XImageEditor extends React.Component<AllProps, State> {
  setEditorRef: (editor: any) => any;
  setFileSelectRef: (fileSelect: any) => any;
  editor: any;
  fileSelect: any;

  constructor(props: AllProps) {
    super(props);

    this.setEditorRef = editor => (this.editor = editor);
    this.setFileSelectRef = fileSelect => (this.fileSelect = fileSelect);

    this.state = {
      ...this.fresh(),
      image: undefined
    };
  }

  public componentDidUpdate(prev) {
    if (this.props.image !== prev.image || this.props.editable !== prev.editable) {
      this.setState({
        ...this.fresh(),
        image: undefined
      });
    }
  }

  public fresh() {
    return {
      height: this.props.height ?? 150,
      width: this.props.width ?? 150,
      scale: 1.0,
      rotate: 0,
      editing: false,
      editable: this.props.editable ?? true
    };
  }

  public cancel() {
    this.setState({ ...this.fresh(), image: undefined });
  }

  public async save() {
    const pica = Pica();
    const img = this.editor.getImage();
    const offScreenCanvas = document.createElement('canvas');

    offScreenCanvas.width = 1500;
    offScreenCanvas.height = 1500;

    const promise = pica
      .resize(img, offScreenCanvas, {
        alpha: true
      })
      .then(result => pica.toBlob(result, 'image/png', 0.9))
      .then(blob => {
        this.props.onChange(blob ?? undefined);
        this.setState({ image: this.createDataURL(blob) });
      });

    await promise;
    this.setState({ ...this.fresh() });
  }

  public async getResult() {
    if (!this.state.editing) return undefined;

    const pica = Pica();
    const img = this.editor.getImage();
    const offScreenCanvas = document.createElement('canvas');

    offScreenCanvas.width = 1500;
    offScreenCanvas.height = 1500;

    const result = await pica.resize(img, offScreenCanvas, {
      alpha: true
    });
    const blob = await pica.toBlob(result, 'image/png', 0.9);
    this.setState({ ...this.fresh() });
    return blob;
  }

  public toEdit() {
    this.setState({ editing: true });

    if (!this.props.image) this.fileSelect.click();
  }

  public changeFile(e) {
    if (e.target.files.length === 0) {
      this.setState({ editing: false });
      return;
    }

    this.setState({ image: this.createDataURL(e.target.files[0]) });
    e.target.value = '';
  }

  public createDataURL(file) {
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(file);
  }

  public render() {
    const backupImage = generateAvatar('ltx', this.state.width, true, 3);

    let image = this.state.image ?? this.props.image;
    if (image instanceof Blob) image = this.createDataURL(image);

    return (
      <div style={{ ...this.props.style }}>
        <input
          type="file"
          ref={this.setFileSelectRef}
          style={{ display: 'none' }}
          onChange={e => this.changeFile(e)}
        />
        {this.state.editing && image ? (
          <ImageCropperWrapper>
            <AvatarEditor
              ref={this.setEditorRef}
              image={image}
              width={this.state.width - 50}
              height={this.state.height - 50}
              border={25}
              color={[0, 0, 0, 0.65]} // RGBA
              scale={this.state.scale}
              rotate={this.state.rotate}
              crossOrigin={'anonymous'}
            />
            <Translation>
              {t => (
                <ImageCropperWrapperControls style={{ width: this.state.width + 'px' }}>
                  <ImageControl>
                    <Tooltip title={t('tooltip.zoomOut') + ' '}>
                      <ZoomOutIcon
                        onClick={e => this.setState({ scale: this.state.scale * 0.9 })}
                      />
                    </Tooltip>
                    <Tooltip title="zoom in">
                      <ZoomInIcon onClick={e => this.setState({ scale: this.state.scale * 1.1 })} />
                    </Tooltip>
                  </ImageControl>
                  <ImageControl>
                    <Tooltip title={t('tooltip.turnLeft') + ' '}>
                      <RotateLeftIcon
                        onClick={e => this.setState({ rotate: this.state.rotate - 15 })}
                      />
                    </Tooltip>
                    <Tooltip title={t('tooltip.turnRight') + ' '}>
                      <RotateRightIcon
                        onClick={e => this.setState({ rotate: this.state.rotate + 15 })}
                      />
                    </Tooltip>
                  </ImageControl>
                  <ImageControl>
                    <Tooltip title={t('tooltip.selectFile') + ' '}>
                      <EditIcon onClick={e => this.fileSelect.click()} />
                    </Tooltip>
                  </ImageControl>
                  <CommitControl>
                    <Tooltip title={t('tooltip.cancel') + ' '}>
                      <CancelIcon style={{ color: 'red' }} onClick={e => this.cancel()} />
                    </Tooltip>
                    <Tooltip title={t('tooltip.toApply') + ' '}>
                      <CheckIcon
                        style={{ color: 'green' }}
                        onClick={async e => await this.save()}
                      />
                    </Tooltip>
                  </CommitControl>
                </ImageCropperWrapperControls>
              )}
            </Translation>
          </ImageCropperWrapper>
        ) : (
          <ImageWrapper
            style={{
              width: this.state.width + 'px',
              height: this.state.height + 'px',
              background: this.state.editable ? 'rgba(0, 0, 0, 0.1)' : ''
            }}>
            <XImage
              src={!image ? backupImage : image}
              onError={(e: any) => {
                e.target.onError = null;
                e.target.src = backupImage;
              }}
            />
            {this.state.editable && (
              <EditOverlay onClick={e => this.toEdit()}>
                <Translation>{t => <EditText>{t('tooltip.edit')}</EditText>}</Translation>
              </EditOverlay>
            )}
          </ImageWrapper>
        )}
      </div>
    );
  }
}

export default XImageEditor;

const ImageCropperWrapperControls = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImageCropperWrapper = styled.div``;

const ImageControl = styled.div`
  flex: 1;
  text-align: center;
  min-width: 72px;
  & > svg {
    margin: 4px;
    cursor: pointer;
  }
`;

const CommitControl = styled(ImageControl)``;

const EditOverlay = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  background: #000000;
  transition: opacity 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EditText = styled.div`
  flex: 1;
  text-align: center;
  color: #fff;
`;

const ImageWrapper = styled.div`
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
  }

  &:hover > div {
    opacity: 0.8;
  }
`;
