import { apiClient, ApiResult } from 'src/helpers/apiClient';

export const getOrderMessages = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListOrderMessages');
};

export const getOrderView = async (id: string, token: string): Promise<ApiResult> => {
  const params = {
    OrderId: id,
    Token: token
  }
  return await apiClient.postQueryAsync('GetOrderView', params);
};
