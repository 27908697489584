import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { InterfaceMethod } from 'src/models/Catalog';
import DeEekhoornApiInterfaceMethod from './DeEekhoornApiInterfaceMethod';
import ItsPerfectApiInterfaceMethod from './ItsPerfectApiInterfaceMethod';
import VertilinQInterfaceMethod from './VertilinQInterfaceMethod';
import IMSInterfaceMethod from './IMSInterfaceMethod';
import LightAndLivingInterfaceMethod from './LighAndLivingInterfaceMethod';
import Chip from '@material-ui/core/Chip';

type OwnProps = {
  catalogId?: string;
  method?: string;
};

type AllProps = OwnProps;

const CustomInterfaceMethod: FC<AllProps> = ({ catalogId, method }) => {
  const [customType, setCustomType] = useState('');

  useEffect(() => {
    method && setCustomType(method);
  }, []);

  const onClickHandler = (type: string) => {
    setCustomType(type);
  };

  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <XSelectTypeContainer>
          <Chip
            style={{ flex: 1 }}
            key={method}
            label="DeEekhoorn"
            clickable
            color={customType === 'DeEekhoorn' ? 'primary' : 'default'}
            onClick={() => onClickHandler('DeEekhoorn')}
          />
          <Chip
            style={{ flex: 1 }}
            key={method}
            label="VertilinQ"
            clickable
            color={customType === 'VertilinQ' ? 'primary' : 'default'}
            onClick={() => onClickHandler('VertilinQ')}
          />
          <Chip
            style={{ flex: 1 }}
            key={method}
            label="ItsPerfect"
            clickable
            color={customType === 'ItsPerfect' ? 'primary' : 'default'}
            onClick={() => onClickHandler('ItsPerfect')}
          />
          <Chip
            style={{ flex: 1 }}
            key={method}
            label="IMS"
            clickable
            color={customType === 'IMS' ? 'primary' : 'default'}
            onClick={() => onClickHandler('IMS')}
          />
          <Chip
            style={{ flex: 1 }}
            key={method}
            label="LightAndLiving"
            clickable
            color={customType === 'LightAndLiving' ? 'primary' : 'default'}
            onClick={() => onClickHandler('LightAndLiving')}
          />
        </XSelectTypeContainer>
      </div>
      {customType === InterfaceMethod.DeEekhoorn && (
        <DeEekhoornApiInterfaceMethod catalogId={catalogId} />
      )}
      {customType === InterfaceMethod.VertilinQ && (
        <VertilinQInterfaceMethod catalogId={catalogId} />
      )}
      {customType === InterfaceMethod.ItsPerfect && (
        <ItsPerfectApiInterfaceMethod catalogId={catalogId} />
      )}
      {customType === InterfaceMethod.IMS && (
        <IMSInterfaceMethod catalogId={catalogId} />
      )}
      {customType === InterfaceMethod.LightAndLiving && (
        <LightAndLivingInterfaceMethod catalogId={catalogId}/>
      )}
    </div>
  );
};

export default CustomInterfaceMethod;

const XSelectTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & h6 {
    margin-bottom: 1rem;
  }
`;

const XSelectTypeBtn = styled.div`
  width: 7rem;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & h6 {
    margin: 0.5rem;
  }

  & .selected {
    font-weight: bold;
  }
`;
