import styled from '@emotion/styled';
import { CompareArrowsOutlined } from '@material-ui/icons';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XFormButtons from 'src/components/layout/XFormButtons';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../store';
import * as accountActions from '../../store/accounts/actions';
import { RegisterAccountFormData } from '../../store/accounts/types';
import AccountDetails from '../myltx/account/AccountDetails';
import { OrganizationRoute } from './RegisterRouter';
import { RadioGroup } from '@material-ui/core';
import XRadioButton from '../../components/layout/XRadioButton';
import { Translation } from 'react-i18next';
import i18n from '../../i18n';

type StateProps = { data: RegisterAccountFormData };

type DispatchProps = {
  storeInformation: (data: RegisterAccountFormData) => void;
  getEmailAvailable: (email: string) => Promise<ApiResult>;
  showNotification: (message: string, type: MessageType) => void;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps;

type State = {
  formData: RegisterAccountFormData;
  waitingEmailCheck: boolean;
  inputErrors: {
    email: boolean;
    password: boolean;
    passwordValid: boolean;
  };
  validForm: boolean;
};

class RegisterAccountPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      formData: { ...props.data, language: i18n.language },
      waitingEmailCheck: false,
      inputErrors: {
        email: false,
        password: false,
        passwordValid: false
      },
      validForm: false
    };
  }

  public handleFormChange = (name: string, value: any) => {
    const formData = Object.assign({}, this.state.formData);
    formData[name] = value;
    this.setState({ formData });
  };

  public previousPage = () => {
    window.location.replace('https://arxis.io/');
  };

  public storeForm = () => {
    const inputErrors = Object.assign({}, this.state.inputErrors);
    let valid = true;

    Object.keys(this.state.formData).map(key => {
      if (key in this.state.inputErrors) {
        if (
          this.state.formData[key] === false ||
          this.state.formData[key] === '' ||
          this.state.formData[key] === 0
        ) {
          inputErrors[key] = true;
          valid = false;
        } else {
          inputErrors[key] = false;
        }
      }
    });
    if (this.state.formData.password !== this.state.formData.passwordValid) {
      inputErrors.passwordValid = true;
      valid = false;
    }

    this.setState({ inputErrors });

    this.props.storeInformation(this.state.formData);
    if (valid) {
      this.setState({ waitingEmailCheck: true });
      this.props.getEmailAvailable(this.state.formData.email).then(result => {
        if (result.IsSuccess) this.props.history.push(OrganizationRoute.path);
        else {
          if (result.Errors && result.Errors[0].errorCode === 'LTX3020') {
            const errors = Object.assign({}, this.state.inputErrors);
            errors.email = true;
            this.setState({ inputErrors: errors });

            this.props.showNotification(i18n.t('register.emailAlreadyInUse'), 'error');
          } else
            this.props.showNotification(
              i18n.t('notification.errCheckingEmail') + mapError(result.Errors),
              'error'
            );
        }

        this.setState({ waitingEmailCheck: false });
      });
    }
  };

  public render() {
    const languageHanlder = (e: React.ChangeEvent<HTMLInputElement>): void => {
      this.setState({
        formData: { ...this.state.formData, language: (e.target as HTMLInputElement).value }
      });
      i18n.changeLanguage((e.target as HTMLInputElement).value);
    };
    return (
      <div style={{ position: 'relative' }}>
        <AccountDetails
          registration={true}
          callback={this.handleFormChange}
          margin={false}
          data={this.state.formData}
          inputErrors={this.state.inputErrors}
          submitHandler={this.storeForm}
        />
        <div style={{ marginTop: '10px' }}>
          <Translation>{t => <h6>{t('account.sLanguage')}</h6>}</Translation>
          <RadioGroup row value={this.state.formData.language} onChange={languageHanlder}>
            <XRadioButton value="nl" label="NL" />
            <XRadioButton value="en" label="EN" />
          </RadioGroup>
        </div>
        <Translation>
          {t => (
            <XFormButtons
              style={{ padding: '15px 0 0 0' }}
              onCancel={this.previousPage}
              onSave={this.storeForm}
              saveText={t('tooltip.next')}
              enabled={!this.state.waitingEmailCheck}
              loading={this.state.waitingEmailCheck}
            />
          )}
        </Translation>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  data: state.account.accountFormData
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getEmailAvailable: (email: string) => dispatch(accountActions.checkEmailAvailableThunk(email)),
  showNotification: (message: string, type: MessageType) =>
    dispatch(showNotification(message, type)),
  storeInformation: (data: RegisterAccountFormData) => dispatch(accountActions.storeNewData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAccountPage);
