export enum ActionTypes {
  SHOW_DATAFORM = '[UI] SHOW_DATAFORM',
  SHOW_SNACKBAR = '[UI] SHOW_SNACKBAR',
  SHOW_NOTIFICATION = '[UI] SHOW_NOTIFICATION',
  SHOW_CONFIRMATION = '[UI] SHOW_CONFIRMATION',
  HIDE_NOTIFICATION = '[UI] HIDE_NOTIFICATION',
  HIDE_CONFIRMATION = '[UI] HIDE_CONFIRMATION',
  CHANGE_VIEW = '[UI] CHANGE_VIEW'
}

export type MessageType = 'info' | 'warning' | 'success' | 'error';
export type ViewType = 'grid' | 'list';

export interface NotificationState {
  show: boolean;
  message?: string;
  type?: MessageType;
}

export interface ConfirmationState {
  show: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
}

export interface SnackbarState extends NotificationState {
  actions?: 'undo';
}

export interface LayoutState {
  headerText: string;
  loader: {
    show: boolean;
  };
  snackbar: SnackbarState; // ActionBar
  notification: NotificationState; // Toast
  confirmation: ConfirmationState;
  viewCatalogs: ViewType;
  viewOrganizations: ViewType;

  prompt: {};
  // dataForm: { show: boolean };
}
