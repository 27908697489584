import { BaseReducer } from 'src/helpers/baseReducer';
import { OrganizationType } from 'src/models/Organization';
import { AuthenticationAction } from './actions';
import { ActionTypes, AuthenticationAccount, AuthenticationState } from './types';

export const initialState: AuthenticationState = {
  isAuthenticated: false,
  loading: false,
  authenticationError: undefined,
  account: {
    email: '',
    organizationType: OrganizationType.Retailer,
    isAdmin: false,
    accountId: '',
    settings: [],
    organizationId: '',
    token: '',
    fullName: '',
    organizationName: ''
  }
};

export class AuthenticationReducer extends BaseReducer<AuthenticationState, AuthenticationAction> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: AuthenticationState = initialState, action: AuthenticationAction) {
    switch (action.type) {
      case ActionTypes.LOGIN: {
        if (action.status === 'success') {
          newState.isAuthenticated = true;
          newState.authenticationError = undefined;
          newState.account = action.payload.data as AuthenticationAccount;
          return newState;
        }

        if (action.status === 'failure') {
          newState.isAuthenticated = false;
          newState.authenticationError =
            !action.errors || action.errors[0].errorCode !== 401
              ? 'Onverwachte fout bij het inloggen'
              : 'Email of wachtwoord onjuist';

          return newState;
        }
      }
      case ActionTypes.LOGOUT: {
        return initialState;
      }
      default:
        return undefined;
    }
  }
}
