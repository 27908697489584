import { AnyAction, Dispatch } from 'redux';
import { RequestManageModel, RequestModel } from '../../models/Request';

import { commandAction, requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError, ApiResult } from 'src/helpers/apiClient';
import {
  cancelRequest,
  CatalogRequestStatus,
  listManageRequests,
  listRequests,
  sendRequest,
  updateRequest
} from './api';
import { ActionTypes } from './types';

export type FetchStatus = 'request' | 'success' | 'failure';

// Action creator types
export type GetRequestsAction = {
  type: ActionTypes.GET_REQUESTS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    requests?: RequestModel[];
  };
};

export type GetManageRequestsAction = {
  type: ActionTypes.GET_MANAGE_REQUESTS;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    requests?: RequestManageModel[];
  };
};

export type UpdateCatalogRequestSuccessAction = {
  type: ActionTypes.UPDATE_REQUEST;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {};
};

// Action creators
export const getRequestsAction = (
  status: FetchStatus,
  data?: RequestModel[],
  errors?: ApiError[]
): GetRequestsAction => ({
  type: ActionTypes.GET_REQUESTS,
  status,
  errors,
  payload: {
    requests: data
  }
});

export const getManageRequestsAction = (
  status: FetchStatus,
  data?: RequestManageModel[],
  errors?: ApiError[]
): GetManageRequestsAction => ({
  type: ActionTypes.GET_MANAGE_REQUESTS,
  status,
  errors,
  payload: {
    requests: data
  }
});

// Action thunk
export const createRequestThunk = (targetOrganizationId: string, catalogIds?: string[]) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.SEND_REQUEST, errors),
    () => sendRequest(targetOrganizationId, catalogIds),
    getRequestsThunk()
  );
};

export const getRequestsThunk = () => {
  return requestApiThunk(getRequestsAction, listRequests);
};

export const getManageRequestsThunk = () => {
  return requestApiThunk(getManageRequestsAction, listManageRequests);
};

export const cancelRequestThunk = (id: string) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.CANCEL_REQUEST, errors),
    () => cancelRequest(id),
    getRequestsThunk()
  );
};

export const updateRequestThunk = (request: RequestModel, requestStatus: CatalogRequestStatus) => {
  return requestApiThunk(
    (status: FetchStatus, data?: any, errors?: ApiError[]) =>
      commandAction(status, ActionTypes.UPDATE_REQUEST, errors),
    () => updateRequest(request.connectRequest.id, requestStatus),
    getRequestsThunk()
  );
};

export type Action =
  | GetRequestsAction
  | UpdateCatalogRequestSuccessAction
  | GetManageRequestsAction;
