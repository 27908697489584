import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import * as stockActions from 'src/store/stocks/actions';
import { ApiResult } from 'src/helpers/apiClient';
import { ApplicationState } from 'src/store';
import { XContainer } from 'src/components/layout/XContainer';
import { InterfaceMethod, StockModel } from 'src/models/Stock';
import FTPInterfaceMethod from './uploadStockTypes/FTPInterfaceMethod';
import ApiInterfaceMethod from './uploadStockTypes/ApiInterfaceMethod';
import CustomInterfaceMethod from './uploadStockTypes/CustomInterfaceMethod';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
import Chip from '@material-ui/core/Chip';

type StateProps = {
  stock?: StockModel;
};

type DispatchProps = {
  getStockById: (id: string) => Promise<ApiResult>;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{ id?: string }>;

const EditStockInterfacePage: FC<AllProps> = props => {
  const [uploadType, setUploadType] = useState('');

  useEffect(() => {
    props.match.params.id &&
      props.getStockById(props.match.params.id).then(() => {
        if (!!props.stock?.interfaces.length) {
          if (props.stock?.interfaces[0].method === InterfaceMethod.DeEekhoorn) {
            setUploadType('Specific');
          } else if (props.stock?.interfaces[0].method === InterfaceMethod.VertilinQ) {
            setUploadType('Specific');
          } else if (props.stock?.interfaces[0].method === InterfaceMethod.ItsPerfect) {
            setUploadType('Specific');
          } else if (props.stock?.interfaces[0].method === InterfaceMethod.DTP) {
            setUploadType('Specific');
          } else {
            setUploadType(`${props.stock?.interfaces[0].method}`);
          }
        }
      });
  }, []);

  const onClickHandler = (type: string) => {
    setUploadType(type);
  };

  const stockId = props.match.params.id;

  const selectUploadMethod = ['Ftp', 'Api', 'Specific'];

  return (
    <ErrorBoundary>
      <XContainer
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <XStockInterface>
          <XContent>
            <h6>Hoe bied jij de voorraad aan op Arxis?</h6>
            <h6 style={{ margin: '1rem 0' }}>
              <strong>Via</strong>
            </h6>
            <XSelectTypeContainer>
              {selectUploadMethod.map(method => (
                <Chip
                  style={{ flex: 1 }}
                  key={method}
                  label={method}
                  clickable
                  color={uploadType === method ? 'primary' : 'default'}
                  onClick={() => onClickHandler(method)}
                />
              ))}
            </XSelectTypeContainer>
            <XTypeContainer>
              {uploadType === InterfaceMethod.Ftp && <FTPInterfaceMethod stockId={stockId} />}
              {uploadType === InterfaceMethod.Api && <ApiInterfaceMethod stockId={stockId} />}
              {uploadType === 'Specific' && (
                <CustomInterfaceMethod
                  stockId={stockId}
                  method={!!props.stock?.interfaces.length ? props.stock?.interfaces[0].method : ''}
                />
              )}
            </XTypeContainer>
          </XContent>
        </XStockInterface>
      </XContainer>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    stock: state.stocks.data.detail
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getStockById: (id: string) => dispatch(stockActions.getStockThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStockInterfacePage);

const XStockInterface = styled.div`
  width: 32rem;
  height: 32rem;
`;

const XContent = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 6px;
`;

const XSelectTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & h6 {
    margin-bottom: 1rem;
  }
`;

const XSelectTypeBtn = styled.div`
  width: 7rem;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & h6 {
    margin: 0.5rem;
  }

  & .selected {
    font-weight: bold;
  }
`;

const XTypeContainer = styled.div`
  margin-top: 2rem;
`;
