import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';

import styled from '@emotion/styled';
import { LinearProgress } from '@material-ui/core';
import Cookies from 'js-cookie';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import XLTXInformation from 'src/components/domain/XLTXInformation';
import XButton, { XButtonStyle } from 'src/components/layout/XButton';
import XField from 'src/components/layout/XField';
import XFormButtons from 'src/components/layout/XFormButtons';
import { XPaper } from 'src/components/layout/XPaper';
import { XRow, XRowCell } from 'src/components/layout/XRow';
import { ApiResult } from 'src/helpers/apiClient';
import { mapError } from 'src/helpers/errorMap';
import { ApplicationState } from 'src/store';
import { resetPasswordThunk } from 'src/store/accounts/actions';
import { AuthenticationState } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import Logo from '../../style/images/logos/main.svg';
import { LeftSide, LeftSideInner, LTXLogo, RightSide } from '../login/LoginPage.style';
import i18n from 'src/i18n';
import { Translation } from 'react-i18next';

type OwnProps = {};

type State = {
  password1: string;
  password2: string;
  error1: boolean;
  error2: boolean;
  reset: boolean;
  loading: boolean;
};

type DispatchProps = {
  resetPassword: (code: string, password: string) => Promise<ApiResult>;
  showNotification: (text: string, type?: MessageType) => void;
};

type AllProps = OwnProps & DispatchProps & RouteComponentProps<{ code: string }>;

class ResetPasswordPage extends React.Component<AllProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      error1: false,
      error2: false,
      reset: false,
      loading: false
    };
  }

  public changeForm1(val: string) {
    this.setState({ password1: val, error1: val.length < 3 });
  }
  public changeForm2(val: string) {
    this.setState({ password2: val, error2: val !== this.state.password1 });
  }

  public resetPassword() {
    if (this.state.error1 || this.state.error2 || this.state.password1.length === 0) return;

    const code = this.props.match.params.code;
    this.setState({ loading: true });
    this.props.resetPassword(code, this.state.password1).then(result => {
      if (result.IsSuccess) {
        this.setState({ reset: true });
      } else {
        this.props.showNotification(
          i18n.t('register.resetPassError') + mapError(result.Errors),
          'error'
        );
        this.setState({ loading: false });
      }
    });
  }

  public render() {
    return (
      <LogoutLayout>
        <LeftSide>
          <Translation>
            {t => (
              <LeftSideInner>
                {!this.state.reset ? (
                  <LogoutInner>
                    <StyledDiv>{t('register.enterNewPass')}</StyledDiv>
                    <XField
                      type="password"
                      required={true}
                      placeholder={t('register.newPass')}
                      error={this.state.error1}
                      onChange={e => this.changeForm1(e.target.value)}
                    />
                    <XField
                      type="password"
                      required={true}
                      placeholder={t('register.confirmNewPassword')}
                      error={this.state.error2}
                      onChange={e => this.changeForm2(e.target.value)}
                    />
                    <XRow>
                      <XRowCell />
                      <XRowCell>
                        <StyledButton
                          colorscheme={XButtonStyle.Primary}
                          onClick={e => this.resetPassword()}
                          active={!this.state.loading}
                          loading={this.state.loading}>
                          {t('tooltip.edit')}
                        </StyledButton>
                      </XRowCell>
                    </XRow>
                  </LogoutInner>
                ) : (
                  <LogoutInner>
                    <div>
                      {t('register.passwordHasBeenReset')}{' '}
                      <StyledLink to="/">{t('login.login')}</StyledLink>{' '}
                      {t('register.withYourNewPass')}
                    </div>
                  </LogoutInner>
                )}
              </LeftSideInner>
            )}
          </Translation>
        </LeftSide>
        <RightSide>
          <XLTXInformation />
        </RightSide>

        <LTXLogo src={Logo} />
      </LogoutLayout>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (text: string, type?: MessageType) => dispatch(showNotification(text, type)),
  resetPassword: (code: string, password: string) => dispatch(resetPasswordThunk(code, password))
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

const LogoutLayout = styled.div`
  height: 100vh;
  background: rgb(45, 128, 194);
  overflow: hidden;
  display: flex;
`;

const LogoutInner = styled(XPaper)`
  padding: 30px;
  text-align: center;
  width: 500px;
`;

const StyledDiv = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`;

const StyledButton = styled(XButton)`
  margin-top: 10px;
  width: 100%;
`;
