import { BaseReducer } from 'src/helpers/baseReducer';
import { Action } from './actions';
import { ActionTypes, StockState, EmptyStockFormData } from './types';

export const initialState: StockState = {
  data: {
    all: undefined,
    new: [],
    popular: [],
    special: [],
    top: [],
    my: [],
    subscribed: []
  },
  addStock: EmptyStockFormData,
  validation: {
    validationSummary: undefined,
    error: undefined
  },
  conversion: {
    transformResult: undefined,
    error: undefined
  },
  fileFormats: [],
  errors: undefined,
  loading: false
};

export class StockReducer extends BaseReducer<StockState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: StockState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_STOCK: {
        newState.data.detail = action.payload.stock;
        return newState;
      }
      case ActionTypes.GET_SUBSCRIBED_STOCKS: {
        if (action.status === 'success' && action.payload.stocks)
          newState.data.subscribed = action.payload.stocks;
        else newState.data.subscribed = [];
        return newState;
      }
      case ActionTypes.GET_STOCKS: {
        if (action.status === 'success' && action.payload.stocks)
          newState.data.all = action.payload.stocks;
        else newState.data.all = undefined;
        return newState;
      }
      case ActionTypes.ADD_STOCK: {
        newState.addStock.uploaded = true;
        return newState;
      }
      case ActionTypes.GET_FILE_FORMATS: {
        if (action.status === 'success' && action.payload.fileFormats)
          newState.fileFormats = action.payload.fileFormats;
        else newState.fileFormats = [];
        return newState;
      }
      default:
        return undefined;
    }
  }
}
