import styled from '@emotion/styled';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import * as React from 'react';

type OwnProps = {
  label?: string;
  name?: string;
  value: Date;
  onChange: any;
};

type AllProps = OwnProps & DatePickerProps;

const XDatePicker: React.SFC<any> = props => {
  return (
    <div>
      <StyledDatePicker {...props} onChange={props.onChange} />
    </div>
  );
};

export default XDatePicker;

const StyledDatePicker = styled(DatePicker)({
  marginBottom: '10px'
});
