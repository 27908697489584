import { Color } from '@material-ui/core';
import { ColorsObject } from 'redux-logger';

export const dataFormStyle = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.4)'
  },
  content: {
    width: '30%',
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center'
  }
};

export type XTheme = {
  pStartColor: string;
  pEndColor: string;
  sStartColor: string;
  sEndColor: string;
};

export const theme: XTheme = {
  pStartColor: '#E7217B',
  pEndColor: '#F29223',
  sStartColor: '#79CCFF',
  sEndColor: '#6170FF'
};
