import styled from '@emotion/styled';
import * as React from 'react';
import XField from 'src/components/layout/XField';
import XForm from 'src/components/layout/XForm';
import XImageEditor from 'src/components/layout/XImageEditor';
import { Countries } from 'src/constants/Countries';
import { Organization, OrganizationType } from 'src/models/Organization';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';

type OwnProps = {
  organization?: Organization;
  data?: any;
  inputErrors?: any;
  callback: (name: string, value: any) => any;
};

type AllProps = OwnProps;

class OrganizationDetails extends React.Component<AllProps> {
  public render() {
    const data = this.props.organization ? this.props.organization : this.props.data;
    if (!data) return <div />;

    const fieldsNextToImage = [
      { title: i18n.t('account.companyName'), name: 'name', required: true, maxLength: 255 },
      {
        title: i18n.t('address.street'),
        name: 'street',
        fullWidth: false,
        required: true,
        maxLength: 255
      },
      {
        title: i18n.t('address.houseNumber'),
        name: 'houseNumber',
        fullWidth: false,
        required: true,
        maxLength: 255
      },
      { title: 'Postcode', name: 'postalCode', fullWidth: false, required: true, maxLength: 255 },
      {
        title: i18n.t('address.place'),
        name: 'city',
        fullWidth: false,
        required: true,
        maxLength: 255
      },
      {
        title: i18n.t('address.country'),
        name: 'country',
        required: true,
        type: 'select',
        options: Countries
      }
    ];

    const fieldsRight = [
      {
        title: i18n.t('account.orgType'),
        name: 'organizationType',
        required: true,
        type: 'select',
        readonly: true,
        options: [
          { name: i18n.t('common.supplier'), value: OrganizationType.Supplier },
          { name: 'Retailer', value: OrganizationType.Retailer }
        ]
      },
      { title: i18n.t('contact.phone'), name: 'telephoneNumber', required: false, maxLength: 255 },
      { title: 'Email', name: 'email', required: false, maxLength: 255 },
      { title: 'Website', name: 'website', required: false, maxLength: 255 }
      // { title: 'IBAN-nummer', name: 'iban', required: false },
      // { title: 'Btw-nummer', name: 'btwNumber', required: false },
    ];

    return (
      <FormWrapper>
        <div style={{ flex: '1' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '1', maxWidth: '180px' }}>
              <XImageEditor
                image={data.image ? data.image : data?.imageURL}
                style={{ margin: '0 15px 15px 15px', minWidth: '150px', maxWidth: '150px' }}
                onChange={blob => {
                  this.props.callback('image', blob);
                }}
              />

              <div style={{ padding: '0 15px' }}>
                <Translation>
                  {t => (
                    <XField
                      title={t('account.desc')}
                      label={t('account.desc')}
                      multiline={true}
                      rows={3}
                      rowsMax={5}
                      inputProps={{ maxLength: '5000' }}
                      name="description"
                      value={data.description}
                      onChange={e => this.props.callback(e.target.name, e.target.value)}
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div style={{ flex: '1' }}>
              <XForm
                data={data}
                inputErrors={this.props.inputErrors}
                fields={fieldsNextToImage}
                callback={this.props.callback}
              />
            </div>
          </div>
        </div>
        <div style={{ flex: '1' }}>
          <XForm
            data={data}
            inputErrors={this.props.inputErrors}
            fields={fieldsRight}
            callback={this.props.callback}
          />
        </div>
      </FormWrapper>
    );
  }
}

export default OrganizationDetails;

const FormWrapper = styled.div`
  display: flex;
`;
