import styled from '@emotion/styled';
import * as React from 'react';

import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { Link } from 'react-router-dom';
import { Countries } from 'src/constants/Countries';
import { DataConnection, Organization } from 'src/models/Organization';
import { OrganizationGroup } from 'src/models/OrganizationGroup';
import { XRowCell } from '../layout/XRow';
import { TableRow } from '../layout/XTable';
import { formatDateToString } from 'src/helpers/formatHelpers';
import { Translation } from 'react-i18next';
import { CatalogMessageModel } from '../../models/Message';

type OwnProps = {
  catalogMessages?: CatalogMessageModel[];
  organization: Organization;
  dataConnection?: DataConnection;
  link?: string;
  showDelete?: boolean;
  showConnectionDate?: boolean;
  showLiveConnection?: boolean;
  liveConnection?: boolean;
  onDelete?: () => void;
  organizationGroups?: OrganizationGroup[];
  onGroupChanged?: (val: string) => void;
  showDownloads?: boolean;
};

type AllProps = OwnProps;

const XOrganizationRow: React.SFC<AllProps> = props => {
  const content = (
    <TableRow>
      <TitleCell>{props.organization.name}</TitleCell>
      <XRowCell>
        {props.organization.city},{' '}
        {Countries.find(c => c.value === props.organization.country)?.name}
      </XRowCell>
      {props.showConnectionDate && (
        <XRowCell>{formatDateToString(props.organization.connectionDate)}</XRowCell>
      )}
      {(props.showDownloads === true && (
        <Translation>
          {t => (
            <XRowCell>
              {props.catalogMessages === undefined ? '0' : props.catalogMessages?.find(x => x.toOrganizationId === props.organization.id)?.downloads}x {t('catalogs.downloaded')}
            </XRowCell>
          )}
        </Translation>
      ))}
      {props.catalogMessages !== undefined && (
        <XRowCell>
          {props.catalogMessages?.find(x => x.toOrganizationId === props.organization.id)?.lastDownloadedDate
            ? formatDateToString(props.catalogMessages?.find(x => x.toOrganizationId === props.organization.id)?.lastDownloadedDate)
            : ''}
        </XRowCell>
      )}
      {props.showLiveConnection && (
        <XRowCell>
          {props.liveConnection ? (
            <CheckCircleIcon style={{ color: '#4caf50' }} />
          ) : (
            <NotInterestedIcon style={{ color: '#f44336' }} />
          )}
        </XRowCell>
      )}

      {props.showDelete && (
        <XRowCell style={{ textAlign: 'right', color: '#ed677a' }}>
          <Translation>
            {t => (
              <Tooltip title={t('connections.deleteConnection') + ' '}>
                <DeleteIcon
                  onClick={e => {
                    if (props.onDelete) props.onDelete();
                    e.preventDefault();
                  }}
                />
              </Tooltip>
            )}
          </Translation>
        </XRowCell>
      )}
    </TableRow>
  );

  return props.link ? <Link to={props.link}>{content}</Link> : content;
};

export default XOrganizationRow;

const TitleCell = styled(XRowCell)``;
