import * as React from 'react';
import { Countries } from 'src/constants/Countries';
import { Organization } from 'src/models/Organization';
import { XPaperLabel, XPaperText } from '../layout/XPaper';

type OwnProps = {
  organization: Organization;
};

type AllProps = OwnProps;

const XOrganizationAddress: React.SFC<AllProps> = props => {
  return (
    <div>
      <XPaperText>
        {props.organization.street} {props.organization.houseNumber}
        <br />
        {props.organization.postalCode},{props.organization.city}
        <br />
        {Countries.find(c => c.value === props.organization.country)?.name}
      </XPaperText>
      <XPaperText>
        {props.organization.telephoneNumber}
        <br />
        {props.organization.website}
      </XPaperText>
    </div>
  );
};

export default XOrganizationAddress;
