import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Translation } from 'react-i18next';

export const XPaper = styled('div')({
  backgroundColor: '#fff',
  width: 'auto',
  height: 'auto',
  maxHeight: 'calc(100vh - 80px)',
  overflow: 'hidden',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 15px'
});

export const XPaperTitle = styled('header')({
  marginLeft: '15px',
  marginRight: '15px',
  fontWeight: 'bold',
  '& a': {
    fontWeight: 'normal'
  }
});

export const XPaperSubtitle = styled.div`
  margin: 10px 15px 0;
  color: #aaa;
  font-size: 14px;
`;

export const XPaperTitleLarge = styled(XPaperTitle)({
  fontSize: '21px'
});

export const XPaperContent = styled('div')({
  flex: 1,
  overflowY: 'auto'
});

export const XPaperText = styled('p')({
  marginLeft: '15px',
  marginRight: '15px',
  fontSize: '13px'
});

export const XPaperLabel = styled('label')({
  marginLeft: '15px',
  marginRight: '15px',
  marginBottom: 0,
  fontSize: '14px',
  fontWeight: 'bold'
});

export const XPaperContainer = styled('div')({
  borderTopLeftRadius: '6px',
  borderBottomLeftRadius: '6px',
  backgroundColor: '#f2f4f7'
});

export const XPaperCenter: React.SFC<{
  style?: any;
  width?: string;
  goBack?: () => any;
  goBackText?: string;
}> = props => {
  return (
    <PaperCenterWrapper>
      <PaperCenter style={{ width: props.width }}>
        {props.goBack !== undefined && (
          <GoBackButton onClick={props.goBack} className="goBack">
            <ArrowBack style={{ verticalAlign: 'middle' }} />
            <Translation>
              {t => <span style={{ verticalAlign: 'middle' }}>{t('tooltip.back')}</span>}
            </Translation>
          </GoBackButton>
        )}
        <XPaper style={props.style}>{props.children}</XPaper>
      </PaperCenter>
    </PaperCenterWrapper>
  );
};

const PaperCenter = styled('div')({
  width: '600px',
  margin: 'auto',
  padding: '20px'
});

const PaperCenterWrapper = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex'
});

const GoBackButton = styled('div')({
  color: 'white',
  paddingLeft: '15px',
  paddingBottom: '5px',
  cursor: 'pointer',
  opacity: 0.8
});
