import { AnyAction, Dispatch } from 'redux';

import { commandAction, requestApiThunk } from 'src/helpers/actionHelpers';
import { ApiError, ApiResult } from 'src/helpers/apiClient';
import { OrderMessage } from 'src/models/OrderMessage';
import { getOrderMessages } from './api';
import { ActionTypes } from './types';

export type FetchStatus = 'request' | 'success' | 'failure';

// Action creator types
export type GetOrderMessagesAction = {
  type: ActionTypes.GET_ORDERMESSAGES;
  status: FetchStatus;
  errors?: ApiError[];
  payload: {
    orderMessages?: OrderMessage[];
  };
};

export const getOrderMessagesAction = (
  status: FetchStatus,
  data?: OrderMessage[],
  errors?: ApiError[]
): GetOrderMessagesAction => ({
  type: ActionTypes.GET_ORDERMESSAGES,
  status,
  errors,
  payload: {
    orderMessages: data
  }
});

// Action thunks
export const getOrderMessagesThunk = () => {
  return requestApiThunk(getOrderMessagesAction, getOrderMessages);
};

// Export Action creator types
export type Action = GetOrderMessagesAction;
