import styled from '@emotion/styled';
import * as React from 'react';
import XList from './XList';
import { XRow, XRowCell } from './XRow';

type OwnProps = {
  headers?: (string | { title: string; width: number })[];
  onSort?: (property: string) => void;
  style?: any;
};

type AllProps = OwnProps;

type State = {};

class XTable extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {};
  }

  private Sort(header: string | { title: string }) {
    if (this.props.onSort) {
      if (typeof header === 'object') this.props.onSort(header.title);
      else this.props.onSort(header);
    }
  }

  public render() {
    return (
      <TableOuter style={{ ...this.props.style }}>
        {this.props.headers ? (
          <XRow>
            {this.props.headers.map(header => (
              <TableHeader
                key={typeof header === 'object' ? header.title : header}
                onClick={() => this.Sort(header)}
                style={{ maxWidth: typeof header === 'object' ? header.width + 'px' : undefined }}>
                {typeof header === 'object' ? header.title : header}
              </TableHeader>
            ))}
          </XRow>
        ) : (
          undefined
        )}
        {this.props.children}
      </TableOuter>
    );
  }
}

export default XTable;

const TableHeader = styled(XRowCell)`
  font-size: 12px;
  color: #888;
`;

const TableOuter = styled(XList)`
  padding: 0 15px;

  > * {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #eee;
    &:first-of-type {
      border: none;
    }
  }
`;

export const TableRow = styled(XRow)`
  font-size: 14px;
  padding: 4px 0;
`;
