import { ActionTypes } from './types'

export type ChangeLanguage = {
  type: ActionTypes.CHANGE_LANGUAGE;
  payload: string;
};

export const changeLanguage = (language: string,): ChangeLanguage => ({
  type: ActionTypes.CHANGE_LANGUAGE,
  payload: language
});
