import { ArrowBack } from '@material-ui/icons';
import * as H from 'history';
import React from 'react';

interface AllProps<Params extends { [K in keyof Params]?: string } = {}, S = H.LocationState> {
  history: H.History<S>;
}

class XGoBack extends React.Component<AllProps, {}> {
  public render() {
    return (
      <span
        style={{ fontSize: '24px', cursor: 'pointer' }}
        onClick={() => this.props.history.goBack()}
        className="goBack">
        <ArrowBack />
      </span>
    );
  }
}

export default XGoBack;
