import { BaseReducer } from 'src/helpers/baseReducer';
import { Action } from './actions';
import { ActionTypes, CatalogState, EmptyCatalogFormData } from './types';

export const initialState: CatalogState = {
  data: {
    all: undefined,
    new: [],
    popular: [],
    special: [],
    top: [],
    my: [],
    subscribed: []
  },
  addCatalog: EmptyCatalogFormData,
  validation: {
    validationSummary: undefined,
    error: undefined
  },
  conversion: {
    transformResult: undefined,
    error: undefined
  },
  fileFormats: [],
  errors: undefined,
  loading: false
};

export class CatalogReducer extends BaseReducer<CatalogState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: CatalogState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_CATALOG: {
        newState.data.detail = action.payload.catalog;
        return newState;
      }
      case ActionTypes.GET_SUBSCRIBED_CATALOGS: {
        if (action.status === 'success' && action.payload.catalogs)
          newState.data.subscribed = action.payload.catalogs;
        else newState.data.subscribed = [];
        return newState;
      }

      case ActionTypes.GET_CATALOGS: {
        if (action.status === 'success' && action.payload.catalogs)
          newState.data.all = action.payload.catalogs;
        else newState.data.all = undefined;
        return newState;
      }
      case ActionTypes.GET_CATALOGS_ORGANIZATION: {
        if (action.status === 'success' && action.payload.catalogs)
          newState.data.my = action.payload.catalogs;
        else newState.data.my = [];
        return newState;
      }
      case ActionTypes.CONVERT_CATALOG_FILE: {
        if (action.errors) {
          newState.conversion.transformResult = undefined;
          newState.conversion.error = action.errors[0];
        } else {
          newState.conversion.transformResult = action.payload.convertResult;
          newState.conversion.error = undefined;
        }
        return newState;
      }
      case ActionTypes.VALIDATE_CATALOG_FILE:
        console.log(newState);
        newState.validation = newState.validation || {};
        if (action.errors) {
          newState.validation.validationSummary = undefined;
          newState.validation.error = action.errors[0];
        } else {
          newState.validation.validationSummary = action.payload.validateResult;
          newState.validation.error = undefined;
        }
        return newState;
      case ActionTypes.PUBLISH_CATALOG: {
        newState.addCatalog.uploaded = true;
        return newState;
      }
      case ActionTypes.STORE_CATALOG: {
        newState.addCatalog = action.payload.data;
        return newState;
      }
      case ActionTypes.ADD_CATALOG: {
        newState.addCatalog.uploaded = true;
        return newState;
      }
      case ActionTypes.GET_FILE_FORMATS: {
        if (action.status === 'success' && action.payload.fileFormats)
          newState.fileFormats = action.payload.fileFormats;
        else newState.fileFormats = [];
        return newState;
      }
      default:
        return undefined;
    }
  }
}
