import styled from '@emotion/styled';
import * as React from 'react';
import { connect } from 'react-redux';
import XGridLayout from 'src/components/layout/XGridLayout';
import { XPaper, XPaperTitle } from 'src/components/layout/XPaper';
import { AuthenticationAccount } from 'src/store/authentication/types';

import { RequestModel } from 'src/models/Request';
import { getRequestsThunk, updateRequestThunk } from 'src/store/requests/actions';
import { ApplicationState } from '../../../store';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import XNoContent from 'src/components/domain/XNoContent';
import XRequestRow from 'src/components/domain/XRequestRow';
import XPageContent from 'src/components/layout/XPageContent';
import XTable from 'src/components/layout/XTable';
import { CatalogRequestStatus } from 'src/store/requests/api';
import { activeRequests, inactiveRequests } from 'src/store/requests/selectors';

import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import XPagination from 'src/components/layout/XPagination';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import { delayExecution } from 'src/helpers/actionHelpers';
import { ApiResult } from 'src/helpers/apiClient';
import EmptyImage from '../../../style/images/empty-clipboard.svg';
import { OrganizationType } from 'src/models/Organization';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import { XRowCell } from 'src/components/layout/XRow';
import XSearchBar from 'src/components/layout/XSearchBar';
import { XActionSelect } from 'src/components/layout/XActionButton';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import XInboxOutboxButtons from 'src/components/layout/XInboxOutboxButtons';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  allRequests: RequestModel[];
  account: AuthenticationAccount;
  requestsLoading: boolean;
};

type DispatchProps = {
  getRequests: () => void;
  editRequest: (request: RequestModel, state: CatalogRequestStatus) => Promise<ApiResult>;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{ type: string }>;

type State = {
  currentOffset: number;
  currentOffsetDenied: number;
  requestsType: string;
  loadingRequests: boolean;
  search: string;
  filter: string;
};

class ConnectRequestsPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      currentOffset: 0,
      search: '',
      currentOffsetDenied: 0,
      requestsType: props.match.params.type,
      loadingRequests: false,
      filter: 'all'
    };
  }

  public componentDidMount() {
    this.props.getRequests();
  }

  public componentDidUpdate(prevProps) {
    if (this.props.match.params.type !== prevProps.match.params.type) {
      this.setState({ requestsType: this.props.match.params.type });
    }
  }

  public editRequest(requestModel: RequestModel, status: CatalogRequestStatus) {
    this.setState({ loadingRequests: true });
    this.props.editRequest(requestModel, status).then(result => {
      this.setState({ loadingRequests: false });
    });
  }

  public filterRequests(request: RequestModel) {
    if (
      this.state.filter === 'processing' &&
      (request.connectRequest.approvedDate || request.connectRequest.disapprovedDate)
    )
      return false;
    if (this.state.filter === 'approved' && !request.connectRequest.approvedDate) return false;
    if (this.state.filter === 'disapproved' && !request.connectRequest.disapprovedDate)
      return false;

    if (this.state.requestsType === 'in') {
      return (
        request.requestOrganization.id !== this.props.account.organizationId &&
        request.requestOrganization.name.toLowerCase().indexOf(this.state.search) !== -1
      );
    } else {
      return (
        request.requestOrganization.id === this.props.account.organizationId &&
        request.targetOrganization.name.toLowerCase().indexOf(this.state.search) !== -1
      );
    }
  }

  public render() {
    const pageSize = 10;
    const allRequests = this.props.allRequests.filter(r => this.filterRequests(r));
    const pagedRequests = allRequests.slice(
      this.state.currentOffset,
      this.state.currentOffset + pageSize
    );

    const type = this.props.account.organizationType;
    const orgName = type === OrganizationType.Retailer ? i18n.t('common.supplier') : 'Retailer';
    return (
      <ErrorBoundary>
        <XPageContent>
          <XInboxOutboxButtons
            in="/app/my/relationships/requests/in"
            out="/app/my/relationships/requests/out"
          />
          <StyledPaper>
            <XPaperHeader
              header={
                (this.state.requestsType === 'in'
                  ? i18n.t('traffic.Receive')
                  : i18n.t('traffic.Sent')) + i18n.t('traffic._applications')
              }
              count={allRequests.length}
              subheader={
                i18n.t('traffic.belowAreAllRequestsYouHave') +
                (this.state.requestsType === 'in'
                  ? i18n.t('traffic.rcvdFrom')
                  : i18n.t('traffic.sentTo') + orgName.toLowerCase() + 's')
              }
            />

            {this.props.allRequests && (
              <XFilterBar>
                <XRowCell>
                  <Translation>
                    {t => (
                      <XSearchBar
                        placeholder={'Filter ' + t('traffic.requests')}
                        onChange={e =>
                          this.setState({ search: e.target.value.toLowerCase() })
                        }></XSearchBar>
                    )}
                  </Translation>
                </XRowCell>

                <SelectWrapper style={{ paddingRight: '5px' }}>
                  <Translation>
                    {t => (
                      <XActionSelect
                        value={this.state.filter}
                        onChange={e => this.setState({ filter: e.target.value as string })}>
                        <MenuItem value="all">{t('traffic.All')}</MenuItem>
                        <MenuItem value="processing">{t('traffic.Processing')}</MenuItem>
                        <MenuItem value="approved">{t('traffic.Accepted')}</MenuItem>
                        <MenuItem value="disapproved">{t('traffic.turnedDown')}</MenuItem>
                      </XActionSelect>
                    )}
                  </Translation>
                </SelectWrapper>
              </XFilterBar>
            )}

            {(this.state.filter !== 'all' || !!this.state.search.length) &&
              allRequests.length === 0 && (
                <XNoContent size={130} message={i18n.t('traffic.noRequestsFound')} />
              )}

            {!(this.state.filter !== 'all' || !!this.state.search.length) &&
              allRequests.length === 0 && (
                <XNoContent
                  image={EmptyImage}
                  size={130}
                  message={i18n.t('traffic.youDontHaveActiveRequests')}
                />
              )}

            {!!pagedRequests.length && (
              <Translation>
                {t => (
                  <XTable headers={[orgName, t('traffic.Sent'), 'Status']}>
                    <TransitionGroup>
                      {pagedRequests.map(obj => (
                        <CSSTransition
                          key={obj.connectRequest.id}
                          classNames="collapse"
                          timeout={500}
                          unmountOnExit={true}>
                          <XRequestRow
                            key={obj.connectRequest.id}
                            loadingRequest={
                              this.state.loadingRequests || this.props.requestsLoading
                            }
                            requestModel={obj}
                            ownOrganizationId={this.props.account.organizationId}
                            onApprove={() => this.editRequest(obj, 'approve')}
                            onDisapprove={() => this.editRequest(obj, 'disapprove')}
                          />
                        </CSSTransition>
                      ))}
                    </TransitionGroup>
                  </XTable>
                )}
              </Translation>
            )}
            {allRequests.length > pageSize && (
              <XPagination
                itemCount={allRequests.length}
                itemsPerPage={pageSize}
                changePage={i => this.setState({ currentOffset: i })}
              />
            )}
          </StyledPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    allRequests: state.requests.requests.sort(
      (a, b) =>
        new Date(b.connectRequest.requestDate).getTime() -
        new Date(a.connectRequest.requestDate).getTime()
    ),
    requestsLoading: state.requests.loading
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getRequests: () => dispatch(getRequestsThunk()),
  editRequest: (request: RequestModel, state: CatalogRequestStatus) =>
    dispatch(updateRequestThunk(request, state))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectRequestsPage);

const SelectWrapper = styled.div`
  margin-left: 10px;
  .MuiInput-root {
    height: 25px;
    font-size: 14px;
    width: 140px;
  }
`;

const StyledPaper = styled(XPaper)`
  min-height: 200px;
  margin-top: 15px;
  max-height: calc(50% - 10px);
  overflow-y: auto;
  transition: all 0.5s ease;
  &:first-of-type {
    margin-top: 0;
  }
`;
