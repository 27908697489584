import React from 'react';
import { NavLink } from 'react-router-dom';
import LTXUsers from '../components/LTXUsers';
import LTXfaq from '../components/LTXfaq';
import { Element } from 'react-scroll';
import Scroll from 'react-scroll';
import { Translation } from 'react-i18next';
import i18n from 'i18next';
const ScrollLink = Scroll.Link;

type State = {
  infoTab: string;
  priceTabe: string;
};

type Props = {
  openPopUp: () => void;
};

class HomePage extends React.Component<Props, State> {
  componentDidMount() {
    document.title = 'Arxis | Het platform voor de woonbranche';
  }

  constructor(props: any) {
    super(props);

    this.state = {
      infoTab: 'catalog',
      priceTabe: 'retailer'
    };
  }

  changeInfoTab(tab: string) {
    this.setState({ infoTab: tab });
  }
  public language = () => {
    return i18n.language;
  };

  public render() {
    return (
      <Translation>
        {t => {
          const retailers = [
            t('pricing.receiveUnlimitedCatalogs'),
            t('pricing.unlimitedOrderHistory')
          ];
          const suppliers = [
            t('pricing.publishUnlimitedCatalogs'),
            t('pricing.unlimitedOrderHistory')
          ];

          const prices = {
            retailer: [
              {
                name: 'Free',
                price: 0,
                items: [...retailers, 'Max. 1 ' + t('common.1supplier')]
              },
              {
                name: 'Premium I',
                price: 99,
                items: [...retailers, 'Max. 20 ' + t('common.suppliers')]
              },
              {
                name: 'Premium II',
                price: 199,
                items: [...retailers, 'Max. 50 ' + t('common.suppliers')]
              },
              {
                name: 'Premium III',
                price: 349,
                items: [...retailers, 'Max. 100 ' + t('common.suppliers')]
              },
              {
                name: 'Unlimited',
                price: t('pricing.priceCustom'),
                items: [...retailers, t('pricing.moreThan') + ' 100 ' + t('common.suppliers')]
              }
            ],
            supplier: [
              {
                name: 'Free',
                price: 0,
                items: [...suppliers, 'Max. 1 ' + t('common.1retailer')]
              },
              {
                name: 'Premium I',
                price: 199,
                items: [...suppliers, 'Max. 50 ' + t('common.retailers')]
              },
              {
                name: 'Premium II',
                price: 299,
                items: [...suppliers, 'Max. 100 ' + t('common.retailers')]
              },
              {
                name: 'Premium III',
                price: 499,
                items: [...suppliers, 'Max. 200 ' + t('common.retailers')]
              },
              {
                name: 'Unlimited',
                price: t('pricing.priceCustom'),
                items: [...suppliers, t('pricing.moreThan') + ' 100 ' + t('common.retailers')]
              }
            ]
          };
          return (
            <div id="home">
              <div className="height-vh default-background opening section">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6" style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ margin: '0 auto' }}>
                        <h1>
                          {t('header.headline1')}
                          <br />
                          <span>{t('header.headline2')}</span>
                        </h1>
                        <p />
                        <p>{t('header.text')}</p>
                        <p>&nbsp;</p>
                        <div className="row">
                          <div className="col-md-6 text-center">
                            <ScrollLink duration={500} smooth={true} to={'join'} title="Probeer nu">
                              <div className="button start">{t('common.tryNow')}</div>
                            </ScrollLink>
                            <span style={{ fontSize: '.9em' }}>{t('common.tryFree')}</span>
                            <div
                              style={{ marginTop: '12px' }}
                              className="button"
                              onClick={this.props.openPopUp}>
                              Whitepaper
                            </div>
                            <span style={{ fontSize: '.9em' }}>
                              {t('catalogs.downloadCatalogForMoreInfo')}
                            </span>
                            <a
                              href="https://arxis.pipedrive.com/scheduler/Oby5v7fY/arxis"
                              target="_blank">
                              <div style={{ marginTop: '12px' }} className="button demo">
                                {t('common.wantDemo')}
                              </div>
                            </a>
                            <span style={{ fontSize: '.9em' }}> {t('common.scheduleDemo')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 " style={{ textAlign: 'center' }}>
                      <img
                        style={{ width: '96%' }}
                        className="reveal reveal_visible d-none d-md-block "
                        src={require('./images/LTX-def.gif')}
                      />
                    </div>
                  </div>
                </div>
                <svg className="slanted" preserveAspectRatio="none" viewBox="0 0 1024 100">
                  <path fill="#ffffff" d="M0,100 1024,100 1024,50 716.8,95 0,0z" />
                </svg>{' '}
              </div>
              <div className=" bg-white fast section pt-5">
                <div className="container">
                  <Element id="features" name="features">
                    <div className="pl-5 pr-5 pt-5">
                      <div className="d-none d-md-block ">
                        <img
                          style={{ maxWidth: '100%' }}
                          className="reveal reveal_visible "
                          src={require('./images/svg/illustration/informatief_' +
                            this.language() +
                            '.svg')}
                        />
                      </div>
                      <div className="d-md-none">
                        <img
                          style={{ transform: 'translateX(10%)', maxWidth: '100%' }}
                          className="reveal reveal_visible "
                          src={require('./images/svg/illustration/informatief_verticaal_' +
                            this.language() +
                            '.svg')}
                        />
                      </div>
                    </div>
                    <div>
                      <Element id="pros" name="pros">
                        <div className="text-center pt-5">
                          <h2>{t('benefits.title')}</h2>
                        </div>
                      </Element>
                    </div>
                  </Element>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h5>
                        <strong>{t('common.retailer')}</strong>
                      </h5>
                      <p />
                      {['retailer1', 'retailer2', 'retailer3'].map(key => (
                        <div className="row card-item pt-3">
                          <div className="col-1" style={{ textAlign: 'right' }}>
                            <span>
                              <img src={require('./images/svg/add.svg')} width="24px" />
                            </span>
                          </div>
                          <div
                            className="col-11"
                            style={{ display: 'flex', justifyContent: 'center' }}>
                            <span>{t('benefits.' + key)}</span>
                          </div>
                        </div>
                      ))}
                      <p />
                    </div>
                    <div className="col-lg-6">
                      <h5>
                        <strong>{t('common.supplier')}</strong>
                      </h5>
                      <p />
                      {['supplier1', 'supplier2', 'supplier3'].map(key => (
                        <div className="row card-item pt-3">
                          <div className="col-1" style={{ textAlign: 'right' }}>
                            <span>
                              <img src={require('./images/svg/add.svg')} width="24px" />
                            </span>
                          </div>
                          <div
                            className="col-11"
                            style={{ display: 'flex', justifyContent: 'center' }}>
                            <span>{t('benefits.' + key)}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="default-background collab section">
                <svg className="slanted top" preserveAspectRatio="none" viewBox="0 0 1024 100">
                  <path fill="#ffffff" d="M1024,0 1024,100 0,0z" />
                </svg>
                <div className="container" style={{ paddingTop: '180px', paddingBottom: '100px' }}>
                  <div className="row">
                    <div className="col-md-6" style={{ textAlign: 'center' }}>
                      <img
                        className="reveal reveal_visible"
                        src={require('./images/Scherm-Imac.png')}
                      />
                    </div>
                    <div className="col-md-6" style={{ paddingBottom: '100px' }}>
                      <h2>{t('safety.title')}</h2>
                      <p />
                      {['controlConnections', 'protectedCatalogs', 'exclusiveCatalogs'].map(key => (
                        <div className="row card-item pt-4">
                          <div className="col-1" style={{ textAlign: 'right' }}>
                            <span>
                              <img src={require('./images/svg/verified.svg')} width="24px" />
                            </span>
                          </div>
                          <div
                            className="col-11"
                            style={{ display: 'flex', justifyContent: 'center' }}>
                            <span>{t('safety.' + key)}</span>
                          </div>
                        </div>
                      ))}
                      <p />
                    </div>
                  </div>
                </div>

                <svg className="slanted" preserveAspectRatio="none" viewBox="0 0 1024 100">
                  <path fill="#ffffff" d="M0,100 1024,100 1024,0 307.2,95 0,50z" />
                </svg>
              </div>
              <Element id="users" name="users">
                <div className=" bg-white supplier section">
                  <div className="container pt-5 text-center" style={{ paddingBottom: '200px' }}>
                    <LTXUsers />
                  </div>
                  <svg className="slanted" preserveAspectRatio="none" viewBox="0 0 1024 100">
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" style={{ stopColor: '#371a90', stopOpacity: 1 }} />
                      <stop offset="100%" style={{ stopColor: '#2f4cce', stopOpacity: 1 }} />
                    </linearGradient>
                    <path fill="url(#grad1)" d="M0,0 0,100 1024,100z" />
                  </svg>{' '}
                </div>
              </Element>
              <Element id="pricing" name="pricing">
                <div className="default-background prices section" id="prices">
                  <div className="container text-center" style={{ paddingTop: '20px' }}>
                    <h2>
                      <span>{t('pricing.headline')}</span>
                      {t('pricing.title')}
                    </h2>
                    <br />
                    <div className="text-tabs tabs">
                      <ul className="title">
                        <li
                          className={this.state.priceTabe === 'retailer' ? 'active' : ''}
                          data-tab="prices-retailer"
                          id="tab-retailer"
                          onClick={() => this.setState({ priceTabe: 'retailer' })}>
                          {t('common.retailer')}
                        </li>
                        &nbsp; / &nbsp;
                        <li
                          className={this.state.priceTabe === 'supplier' ? 'active' : ''}
                          data-tab="prices-supplier"
                          id="tab-supplier"
                          onClick={() => this.setState({ priceTabe: 'supplier' })}>
                          {t('common.supplier')}
                        </li>
                      </ul>
                      <div className="content">
                        <div className="card-container-premium active" id="price">
                          {prices[this.state.priceTabe].map(p => (
                            <div className="card-premium">
                              <div className="name">{p.name}</div>
                              {Number.isInteger(p.price) && (
                                <div>
                                  <div className="price">€{p.price},-</div>
                                  <div className="mb-3">{t('pricing.perMonth')}</div>
                                </div>
                              )}
                              {!Number.isInteger(p.price) && (
                                <div>
                                  <div className="price">{p.price}</div>
                                  <div className="mb-3">&nbsp;</div>
                                </div>
                              )}
                              <ScrollLink duration={500} to={'join'} smooth={true}>
                                <div
                                  style={{ minWidth: '160px', width: '160px' }}
                                  className="button start try">
                                  {t('common.tryNow')}
                                </div>
                              </ScrollLink>
                              <hr />
                              <div className="text-left pl-4 pr-4">
                                {p.items.map(i => (
                                  <div
                                    className="note"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      paddingTop: '8px',
                                      paddingBottom: '8px'
                                    }}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}>
                                      <img
                                        src={require('./images/svg/check.svg')}
                                        style={{ marginRight: '10px', marginTop: '4px' }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        wordWrap: 'break-word',
                                        width: '75%'
                                      }}>
                                      {i}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Element id="join" name="join">
                    <div
                      className="container join-steps"
                      style={{ paddingBottom: '120px', paddingTop: '150px' }}>
                      <div className="row ">
                        <div className="col-md-7">
                          <h2>
                            <span>{t('join.headline')}</span>
                            {t('join.forRetailer.title')}
                          </h2>
                          <p />
                          <div className="">
                            <div className="row card-item">
                              <div className="col-2" style={{ textAlign: 'right' }}>
                                <span>
                                  <img src={require('./images/svg/1_word lid.svg')} />
                                </span>
                              </div>
                              <div
                                className="col-10"
                                style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>&nbsp;&nbsp;1. &nbsp;&nbsp; {t('join.createAccount')}</span>
                              </div>
                            </div>
                            <hr />
                            <div className="row card-item">
                              <div className="col-2" style={{ textAlign: 'right' }}>
                                <span>
                                  <img src={require('./images/svg/2_maak connectie.svg')} />
                                </span>
                              </div>
                              <div
                                className="col-10"
                                style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>&nbsp;&nbsp;2. &nbsp;&nbsp; {t('join.connect')}</span>
                              </div>
                            </div>
                            <hr />
                            <div className="row card-item">
                              <div className="col-2" style={{ textAlign: 'right' }}>
                                <span>
                                  <img src={require('./images/svg/3_digitaal orderen.svg')} />
                                </span>
                              </div>
                              <div
                                className="col-10"
                                style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>
                                  &nbsp;&nbsp;3. &nbsp;&nbsp; {t('join.forRetailer.receive')}
                                </span>
                              </div>
                            </div>
                          </div>
                          <p />
                          <br />
                          <h2> {t('join.forSupplier.title')}</h2>
                          <p />
                          <div className="">
                            <div className="row card-item">
                              <div className="col-2" style={{ textAlign: 'right' }}>
                                <span>
                                  <img src={require('./images/svg/1_word lid.svg')} />
                                </span>
                              </div>
                              <div
                                className="col-10"
                                style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>&nbsp;&nbsp;1. &nbsp;&nbsp; {t('join.createAccount')}</span>
                              </div>
                            </div>
                            <hr />
                            <div className="row card-item">
                              <div className="col-2" style={{ textAlign: 'right' }}>
                                <span>
                                  <img src={require('./images/svg/2_maak connectie.svg')} />
                                </span>
                              </div>
                              <div
                                className="col-10"
                                style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>&nbsp;&nbsp;2. &nbsp;&nbsp; {t('join.connect')}</span>
                              </div>
                            </div>
                            <hr />
                            <div className="row card-item">
                              <div className="col-2" style={{ textAlign: 'right' }}>
                                <span>
                                  <img src={require('./images/svg/3_deel data.svg')} />
                                </span>
                              </div>
                              <div
                                className="col-10"
                                style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>
                                  &nbsp;&nbsp;3. &nbsp;&nbsp;{t('join.forSupplier.publish')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-5"
                          style={{ paddingTop: '150px', textAlign: 'center' }}>
                          <img
                            className="reveal reveal_visible"
                            src={require('./images/kosten-LTX.png')}
                          />

                          <div className="text-center" style={{ paddingTop: '60px' }}>
                            <NavLink
                              onClick={() =>
                                window.location.assign(`${window.location.href}register`)
                              }
                              to={'/register'}
                              title="Maak een account"
                              className="button start try">
                              {t('join.startNow')}
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Element>
                </div>
              </Element>
              {this.language() == 'nl' && (
                <div className=" bg-white supplier section">
                  <svg className="slanted top" preserveAspectRatio="none" viewBox="0 0 1024 40">
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" style={{ stopColor: '#371a90', stopOpacity: 1 }} />
                      <stop offset="100%" style={{ stopColor: '#2f4cce', stopOpacity: 1 }} />
                    </linearGradient>
                    <path fill="url(#grad1)" d="M1024,0 0,0 1024,40z" />
                  </svg>{' '}
                  <div
                    className="container pt-5 text-center"
                    style={{ paddingBottom: '120px' }}
                    id="faq">
                    <div className="text-center pt-3">
                      <h2>{t('faq.title')}</h2>
                      <div className="pt-3" style={{ textAlign: 'left' }}>
                        <LTXfaq />
                      </div>
                    </div>
                  </div>
                  <svg className="slanted" preserveAspectRatio="none" viewBox="0 0 1024 40">
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" style={{ stopColor: '#371a90', stopOpacity: 1 }} />
                      <stop offset="100%" style={{ stopColor: '#2f4cce', stopOpacity: 1 }} />
                    </linearGradient>
                    <path fill="#05083b" d="M0,0 0,40 1024,40z" />
                  </svg>{' '}
                </div>
              )}
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default HomePage;
