import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import XNoContent from 'src/components/domain/XNoContent';
import NotFoundImage from 'src/style/images/404.svg';
import XGoBack from 'src/components/shared/XGoBack';
interface ErrorProps {
  title?: string;
  description?: string;
}

const NotFoundPage: React.FC<ErrorProps> = props => {
  const history = useHistory();

  return (
    <XFourOFour>
      <XContainer>
        <XCard>
          <XNoContent size={300} image={NotFoundImage}>
            <h5>Oooooooooops page not found!</h5>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '2rem 0'
              }}>
              <h4
                style={{ cursor: 'pointer', marginRight: '1rem' }}
                onClick={() => history.goBack()}>
                Go back
              </h4>{' '}
              <XGoBack history={history} />
            </div>
          </XNoContent>
        </XCard>
      </XContainer>
    </XFourOFour>
  );
};

export default NotFoundPage;

const XFourOFour = styled.div`
  height: 100vw;
  width: 100vw;
`;

const XContainer = styled.div`
  padding-top: 6em;
  display: flex;
  justify-content: center;
`;

const XCard = styled.div`
  background: #fff;
  border-radius: 6px;
  height: fit-content;
  width: 50%;
`;
