import { BaseReducer } from 'src/helpers/baseReducer';
import { Action } from './actions';
import { ActionTypes, OrdersState } from './types';

export const initialState: OrdersState = {
  listNotSendOrders: [],
  listOfSentOrders: [],
  catalogView: [],
  errors: undefined,
  loading: false
};

export class PurchaseOrderReducer extends BaseReducer<OrdersState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: OrdersState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.GET_NOT_SEND_ORDERS: {
        newState.listNotSendOrders = action.payload.listNotSendOrders || [];
        return newState;
      }
      case ActionTypes.GET_LIST_OF_SENT_ORDERS: {
        newState.listOfSentOrders = action.payload.listOfSentOrders || [];
        return newState;
      }
      case ActionTypes.GET_CATALOG_VIEW: {
        newState.catalogView = action.payload.catalogView || [];
        return newState;
      }
      default:
        return undefined;
    }
  }
}
