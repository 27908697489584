import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { InterfaceMethod, InterfaceAuthentication } from 'src/models/Stock';
import { TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import SpecificInterfaceMethod from './uploadStockTypes/SpecificInterfaceMethod';

type OwnProps = {
  setInterfaceMethod: (interfaceMethod: string) => void;
  setInterfaceAuthentication: (interfaceAuthentication: string) => void;
  typeSelectHandler: (type: string, specType?: string) => void;
  setUrl: (url: string) => void;
  setPassword: (password: string) => void;
  setUserName: (userName: string) => void;
  setPortalName: (portalName: string) => void;
  setAutoUpdate: (autoUpdate: boolean) => void;
  setUpdateTimeInterval: (updateTimeInterval: string) => void;
  setToken: (token: string) => void;
  uploadType: string;
  url: string;
  password: string;
  userName: string;
  portalName: string;
  token: string;
  autoUpdate: boolean;
  updateTimeInterval: string;
  interfaceAuthentication: string;
};

const StockInterface: FC<OwnProps> = props => {
  const selectUploadMethod = ['Ftp', 'Api', 'Specific'];

  useEffect(() => {
    !props.autoUpdate && props.setUpdateTimeInterval('');
  }, [props.autoUpdate]);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <div>
        <XContent>
          <h6 style={{ marginBottom: '1rem' }}>Hoe bied jij de voorraad aan op Arxis?</h6>
          <XSelectTypeContainer>
            {selectUploadMethod.map(method => (
              <Chip
                style={{ flex: 1 }}
                key={method}
                label={method}
                clickable
                color={props.uploadType === method ? 'primary' : 'default'}
                onClick={() => props.typeSelectHandler(method)}
              />
            ))}
          </XSelectTypeContainer>

          <XTypeContainer>
            {props.uploadType === InterfaceMethod.Ftp && (
              <div>
                <XTextInput>
                  <TextField
                    value={props.url}
                    onChange={e => props.setUrl(e.target.value)}
                    label="FTP Url"
                    fullWidth
                  />
                </XTextInput>
                <XAuthInputs>
                  <XTextInput>
                    <TextField
                      value={props.userName}
                      onChange={e => props.setUserName(e.target.value)}
                      label="FTP Username"
                    />
                  </XTextInput>
                  <XTextInput>
                    <TextField
                      value={props.password}
                      onChange={e => props.setPassword(e.target.value)}
                      label="FTP Password"
                      type='password'
                    />
                  </XTextInput>
                </XAuthInputs>
              </div>
            )}
            {props.uploadType === InterfaceMethod.Api && (
              <div>
                <TextField
                  value={props.url}
                  onChange={e => props.setUrl(e.target.value)}
                  label="Full Url"
                  fullWidth
                />
                <XAuthInputs>
                  <span>Select type of authentication:</span>
                  <FormControl style={{ width: '50%', marginTop: '0.5rem' }}>
                    <Select
                      value={props.interfaceAuthentication}
                      onChange={(e: any) => props.setInterfaceAuthentication(e.target.value)}>
                      {Object.keys(InterfaceAuthentication).map(key => (
                        <MenuItem key={key} value={InterfaceAuthentication[key]}>
                          {InterfaceAuthentication[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </XAuthInputs>
                {props.interfaceAuthentication === InterfaceAuthentication.UsernamePassword && (
                  <XAuthInputs>
                    <TextField
                      value={props.userName}
                      onChange={e => props.setUserName(e.target.value)}
                      label="User name"
                    />
                    <TextField
                      value={props.password}
                      onChange={e => props.setPassword(e.target.value)}
                      label="Password"
                      style={{ marginTop: '1rem' }}
                      type='password'
                    />
                  </XAuthInputs>
                )}
                {props.interfaceAuthentication === InterfaceAuthentication.BasicAuth && (
                  <XAuthInputs>
                    <TextField
                      value={props.userName}
                      onChange={e => props.setUserName(e.target.value)}
                      label="User name"
                    />
                    <TextField
                      value={props.password}
                      onChange={e => props.setPassword(e.target.value)}
                      label="Password"
                      style={{ marginTop: '1rem' }}
                      type='password'
                    />
                  </XAuthInputs>
                )}
                {props.interfaceAuthentication === InterfaceAuthentication.JWTAuth && (
                  <TextField
                    value={props.token}
                    onChange={e => props.setToken(e.target.value)}
                    label="Token"
                    fullWidth
                    style={{ marginTop: '1rem' }}
                  />
                )}
              </div>
            )}
            {props.uploadType === 'Specific' && (
              <SpecificInterfaceMethod
                setPassword={props.setPassword}
                setUserName={props.setUserName}
                setPortalName={props.setPortalName}
                password={props.password}
                userName={props.userName}
                portalName={props.portalName}
                typeSelectHandler={props.typeSelectHandler}
                token={props.token}
                setToken={props.setToken}
                url={props.url}
                setUrl={props.setUrl}
              />
            )}
          </XTypeContainer>
        </XContent>
      </div>
    </div>
  );
};

export default StockInterface;

const XContent = styled.div`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 6px;
`;

const XSelectTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & h6 {
    margin-bottom: 1rem;
  }
`;

const XSelectTypeBtn = styled.div`
  width: 7rem;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & h6 {
    margin: 0.5rem;
  }

  & .selected {
    font-weight: bold;
  }
`;

const XTypeContainer = styled.div`
  margin-top: 2rem;
`;

const XTextInput = styled.div`
  margin-bottom: 1rem;
`;

const XAuthInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 75%;
`;
