import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Catalog } from 'src/models/Catalog';

import { XContainer } from '../layout/XContainer';
import XCatalogLarge from './XCatalogLarge';
import XCatalogSmall from './XCatalogSmall';

type OwnProps = {
  catalog: Catalog;
  size: 'small' | 'large';
  onClick?: (param: string) => void;
  cRoute?: string;
};

type AllProps = OwnProps & RouteComponentProps;

const XCatalog: React.FC<AllProps> = props => {
  const route = !!props.cRoute
    ? props.cRoute + props.catalog.id
    : '/app/explore/catalogs/' + props.catalog.id;
  return (
    <Link key={props.catalog.id} to={route}>
      <XContainer>
        {props.size === 'large' ? <XCatalogLarge {...props} /> : <XCatalogSmall {...props} />}
      </XContainer>
    </Link>
  );
};

export default withRouter(XCatalog);
