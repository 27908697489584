import React, { FC, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import XScrollView from '../layout/XScrollView';
import { XActionButton, XSecActionButton } from '../layout/XActionButton';
import { saveCatalogMapping } from '../../store/catalogs/api';
import { Mapping } from '../../store/catalogs/types';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { mapError } from 'src/helpers/errorMap';
import XCSVMapping from './XCSVMapping';
import i18n from 'src/i18n';

type OwnProps = {
  mappingData: Mapping;
  catalogId: string;
  catalogMappingId: string;
  catalogVersionId: string;
  close: () => void;
};

type DispatchProps = {
  showNotification: (message: string, type?: MessageType) => void;
};

type AllProps = OwnProps & DispatchProps;

const XCatalogCSVModel: FC<AllProps> = ({
  mappingData,
  catalogId,
  catalogMappingId,
  catalogVersionId,
  ...props
}) => {
  const [mappingItems, setMappingItems] = useState(Array());
  const [delimiter, setDelimiter] = useState(mappingData.Delimiter || ";");

  const history = useHistory();

  const onChange = useCallback(
    (e: any) => {
      e.persist();
      setMappingItems(prevState =>
        prevState.some(o => o.Column === e.target.id)
          ? prevState.map(o =>
            o.Column === e.target.id ? { ...o, MappingType: e.target.value } : o
          )
          : [...prevState, { Column: e.target.id, MappingType: e.target.value }]
      );
    },
    [setMappingItems]
  );

  const Mapping = {
    Delimiter: delimiter,
    MappingItems: mappingItems
  };

  const saveCSVMapping = async () => {
    const apiResult = await saveCatalogMapping(
      Mapping,
      catalogId,
      catalogMappingId,
      catalogVersionId
    );

    if (apiResult.IsSuccess) {
      props.close();
      props.showNotification('Mapping is succesfully saved', 'success');
      history.push(`/app/my/catalogs/edit/${catalogId}`);
    } else {
      props.showNotification(
        'Something whent wrong while saving mapping' + mapError(apiResult.Errors),
        'error'
      );
    }
  };

  return (
    <XCatalogModal>
      <XModalContainer>
        <XCatalogModalModel>
          <XModalHeader>
            <h5>Column Header</h5>
            <h5>Sample data</h5>
            <h5>Type</h5>
          </XModalHeader>
          <XScrollView>
            <XCSVMapping mappingData={mappingData} onChange={onChange} setDelimiter={setDelimiter} delimiter={delimiter} />
          </XScrollView>
          <XButtonsContainer>
            <XSecActionButton onClick={props.close}>{i18n.t('tooltip.Cancel')}</XSecActionButton>
            <XActionButton onClick={saveCSVMapping}>{i18n.t('tooltip.Save')}</XActionButton>
          </XButtonsContainer>
        </XCatalogModalModel>
      </XModalContainer>
    </XCatalogModal>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(XCatalogCSVModel);

const XCatalogModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999 !important;

  & .MuiOutlinedInput-input {
    padding: 7px 14px;
  }
`;

const XCatalogModalModel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 40rem;
  width: 50rem;
`;

const XModalContainer = styled.div`
  background-color: white;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
`;

const XModalHeader = styled.div`
  display: flex;

  & h5 {
    width: 14rem;
    margin: 0;
  }
`;

const XButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;
