import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import XPageRouter from 'src/components/shared/XPageRouter';
import { PageRoute } from 'src/models/Route';

import ValidationPage from './ValidationPage';

export const ValidationRoute: PageRoute = {
  path: `/validation`,
  exact: true,
  component: ValidationPage
};

const Routes: PageRoute[] = [ValidationRoute];

type State = {};
type AllProps = RouteComponentProps;

class ValidationRouter extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
  }

  public render() {
    return <XPageRouter routes={Routes} {...this.props} />;
  }
}

export default ValidationRouter;
