import { BaseReducer } from '../../helpers/baseReducer';
import { LanguageState, ActionTypes } from './types';
import { ChangeLanguage } from './actions';


export const initialState: LanguageState = {
  language: '',
};

export class LanguageReducer extends BaseReducer<LanguageState, ChangeLanguage> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(initialState: LanguageState, action: ChangeLanguage) {
    switch (action.type) {
      case ActionTypes.CHANGE_LANGUAGE: {
        initialState.language = action.payload;
        return initialState;
      }
      default:
        return undefined;
    }
  }
}
