import { ApiError } from 'src/helpers/apiClient';
import { StockValidationSummary, StockFileViolation, Stock, StockModel } from 'src/models/Stock';
import { FileFormat } from 'src/models/FileFormat';

export enum ActionTypes {
  ADD_STOCK = '[stock] ADD_STOCK',
  DELETE_STOCK = '[stock] DELETE_STOCK',
  GET_STOCK = '[stock] GET_STOCK',
  GET_FILE_FORMATS = '[stock] GET_FILE_FORMATS',
  GET_STOCKS = '[stock] GET_STOCKS',
  GET_SUBSCRIBED_STOCKS = '[stock] GET_SUBSCRIBED_STOCKS',
  GET_STOCK_ORGANIZATION = '[stock] GET_STOCK_ORGANIZATION',
  GET_RECEIVING_STOCK_ORGANIZATION = '[stock] GET_RECEIVING_STOCK_ORGANIZATION',
  EDIT_STOCK_AVAILABLE = '[stock] EDIT_STOCK_AVAILABLE'
}

export type AddStockFormData = {
  organizationId: string;
  title: string;
  description: string;
  fileFormatId: string;
  fileFormatVersionId: string;
  interfaceType: string;
  interfaceMethod: string;
  interfaceAuthentication: string;
  settings: object;
};

export enum StockAccessibility {
  SelectedConnections = 'SelectedConnections',
  AllConnections = 'AllConnections',
  Public = 'Public'
}

export type EditStockFormData = {
  title: string;
  description: string;
};

export type StockState = {
  loading: boolean;
  data: {
    all: Stock[] | undefined;
    new: Stock[];
    popular: Stock[];
    special: Stock[];
    top: Stock[];
    my: Stock[];
    subscribed: Stock[];
    detail?: StockModel;
  };
  addStock: StockFormData;
  validation: {
    validationSummary?: StockValidationSummary;
    error?: ApiError;
  };
  conversion: {
    transformResult?: any;
    error?: ApiError;
  };
  fileFormats: FileFormat[];
  errors?: string;
};

export type ConversionResult = {
  from: string;
  to: string;
  writeDuration: number;
  readDuraction: number;
  errors: StockFileViolation[];
  url: string;
};

export type StockFormData = {
  organizationId: string;
  title: string;
  description: string;
  image: any;
  versionName: string;
  startDate?: Date;
  endDate?: Date;
  fileFormat: string;
  fileFormatVersion: string;
  accessibility: StockAccessibility;
  retailers: string[];
  file: any;
  uploaded: boolean;
};

export const EmptyStockFormData = {
  title: '',
  description: '',
  image: undefined,
  organizationId: '',
  versionName: '',
  fileFormat: '',
  fileFormatVersion: '',
  accessibility: StockAccessibility.AllConnections,
  retailers: [],
  startDate: undefined,
  endDate: undefined,
  file: null,
  uploaded: false
};

export type MappingItem = {
  column: string;
  mappingType: string;
  sampleData: any;
}

export type Mapping = {
  mappingItems: MappingItem[];
  delimiter: string;
}

