import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { mediaQuery } from 'src/style/MediaQueries';

type OwnProps = {
  absolute?: boolean;
};

type AllProps = OwnProps;

const XFooter: React.SFC<AllProps> = props => {
  return (
    <FooterContainer absolute={props.absolute}>
      <FooterConent>{props.children}</FooterConent>
    </FooterContainer>
  );
};

export default XFooter;

const FooterContainer = styled('div')<any>({}, (props: any) => ({
  borderTop: '1px solid #C7C8C8',
  // background: 'rgb(245,245,245)',
  width: '100%',
  height: 'auto',
  position: props.absolute && 'absolute',
  bottom: '0',
  display: 'none',
  [mediaQuery[0]]: {
    display: 'inherit'
  }
}));

const FooterConent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '11px',
  color: '#525252',
  fontWeight: 'bold',
  margin: '15px 25px'
});

const FooterLabel = styled('label')({
  marginLeft: '5px'
});
