import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XSoftwareSelect from 'src/components/domain/XSoftwareSelect';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper, XPaperLabel } from 'src/components/layout/XPaper';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { XFieldDisplay, XKey, XValue } from '../AccountPage';
import styled from '@emotion/styled';
import TextField from '@material-ui/core/TextField';
import { XActionButton, XSecActionButton } from 'src/components/layout/XActionButton';
import { ApiResult } from 'src/helpers/apiClient';
import i18n from '../../../i18n';
import {
  InterfaceType,
  MyOrganizationModel,
  OrderInterfaceSettingsModel,
  Organization,
  OrganizationAccessTokenModel,
  OrganizationInterface,
  OrganizationType
} from 'src/models/Organization';
import { Software } from 'src/models/Software';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import {
  editOrganizationThunk,
  getMyOrganizationThunk,
  saveOrderInterfaceSettingsThunk,
  getOrganizationAccessTokenThunk,
  deleteOrganizationAccessTokenThunk
} from 'src/store/organizations/actions';
import { sortSoftware } from 'src/store/software/actions';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  organization?: MyOrganizationModel;
  software: Software[];
  accessToken?: OrganizationAccessTokenModel;
};

type DispatchProps = {
  showNotification: (message: string, type: MessageType) => void;
  getOrganization: () => void;
  getOrganizationAccessToken: (organizationId: string, generateNew: boolean) => Promise<ApiResult>;
  deleteOrganizationAccessToken: (organizationId: string) => void;
  saveOrderInterfaceSettings: (
    organizationId: string,
    data: OrderInterfaceSettingsModel
  ) => Promise<ApiResult>;
  editOrganization: (organization: Organization) => Promise<ApiResult>;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps<{ id: string }>;

type State = {};

class SoftwarePage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.props.getOrganization();
  }

  public async updateSoftwareId(softwareId: string) {
    const organization = this.props.organization?.organization;
    if (organization === undefined) return;
    organization.softwareId = softwareId;
    await this.props.editOrganization(organization);
    this.props.getOrganization();

    if(softwareId === "00000000-0000-0000-0000-000000000000")
    {
      this.getAccessToken(false);
    }
    else { 
      this.deleteAccessToken();
    }
  }

  public async copyAccessToken() {
      var token = this.props.accessToken?.accessToken; 
      navigator.clipboard.writeText(token!);

      this.props.showNotification(i18n.t('notification.copiedToClibboard'), 'success');
  }

  public async getAccessToken(generateNew) { 
    var organizationId = this.props.organization?.organization?.id;
    this.props.getOrganizationAccessToken(organizationId!, generateNew);

    if(generateNew){
      this.props.showNotification(i18n.t('notification.generatedNewToken'), 'success');
    }
  }

  public async deleteAccessToken() { 
    var organizationId = this.props.organization?.organization?.id;
    this.props.deleteOrganizationAccessToken(organizationId!);
  }

  public render() {
    return (
      <ErrorBoundary>
        <XPageContent style={{ maxWidth: '880px' }}>
          {this.props.organization?.organization.organizationType === OrganizationType.Supplier &&
            null}

          {this.props.organization?.organization.organizationType === OrganizationType.Retailer && (
            <div>
              <XPaper style={{ marginTop: '15px' }}>
                <Translation>
                  {t => (
                    <XPaperHeader
                      header={t('myArxis.mySoftware')}
                      subheader={t('myArxis.whichSoftwarePackageYouHave')}
                    />
                  )}
                </Translation>
                <XSoftwareSelect
                  softwareId={this.props.organization?.organization.softwareId}
                  onChange={softwareId => this.updateSoftwareId(softwareId)}
                />
              </XPaper>
             {this.props.organization?.organization.softwareId === '00000000-0000-0000-0000-000000000000' && (
              <div> 
                <XPaper style={{ marginTop: '15px' }}>
                <Translation>
                  {t => (
                    <XPaperHeader
                      header={t('organizations.accessToken')}
                      subheader={t('organizations.accessTokenDescription')}
                    />
                  )}
                </Translation>

               <Translation> 
               {t => (
                  <XFieldDisplay>
                        <XKey>Token</XKey>
                        <TextField value={this.props.accessToken?.accessToken} className="access-token" /> 
                        <Copy onClick={() => this.copyAccessToken()} /> 
                        <XButtonsContainer>
                          <XActionButton onClick={() => this.getAccessToken(true)}>
                          {t('organizations.generateNewAccessToken')}
                          </XActionButton>
                        </XButtonsContainer>
                 </XFieldDisplay>
                )}
                </Translation>
              </XPaper>
              </div>
             )}
            </div>
          )}
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    organization: state.organizations.myOrganization,
    accessToken: state.organizations.accessToken,
    software: state.software.all.sort((a, b) => sortSoftware(a, b))
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type: MessageType) => {
    dispatch(showNotification(message, type));
  },
  getOrganization: () => dispatch(getMyOrganizationThunk()),
  getOrganizationAccessToken: (id, generateNew) => dispatch(getOrganizationAccessTokenThunk(id, generateNew)),
  deleteOrganizationAccessToken: (id) => dispatch(deleteOrganizationAccessTokenThunk(id)),
  saveOrderInterfaceSettings: (id, data) => dispatch(saveOrderInterfaceSettingsThunk(id, data)),
  editOrganization: (organization: Organization) => dispatch(editOrganizationThunk(organization))
});

const XButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: right;
  margin-top: 1rem;
`;

const Copy = styled(FileCopyIcon)({
  width: '25px',
  height: '25px',
  alignSelf: 'center',
  color: '#E7217B',
  marginTop: '5px',
  marginLeft: '10px'
});

export default connect(mapStateToProps, mapDispatchToProps)(SoftwarePage);
