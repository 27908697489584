import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';

type OwnProps = {
  handleFileChange: (file: any) => void;
};

const getColor = props => {
  if (props.isDragAccept) {
    return '#4caf50';
  }
  if (props.isDragReject) {
    return '#f44336';
  }
  if (props.isDragActive) {
    return '#3280c3';
  }
  return '#eeeeee';
};

export const XDragAndDropFilesUpload: FC<OwnProps> = props => {
  const [files, setFiles] = useState(null);
  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
  }, []);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 3,
    onDrop
  });

  useEffect(() => {
    props.handleFileChange(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <div className="container">
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>You can add a new version by dragging your files(max 3) here</p>
      </Container>
    </div>
  );
};

export const XDragAndDropFileUpload: FC<OwnProps> = props => {
  const [files, setFile] = useState(null);
  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles);
  }, []);
  const {acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    onDrop
  });

  useEffect(() => {
    props.handleFileChange(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <div className="container">
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>You can add a new version by dragging a file here</p>
      </Container>
    </div>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  & p {
    cursor: pointer;
  }
`;
