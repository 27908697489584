export const Countries = [
  { name: 'Albanië', value: 'Albania' },
  { name: 'Andorra', value: 'Andorra' },
  { name: 'Armenië', value: 'Armenia' },
  { name: 'Azerbeidzjan', value: 'Azerbaijan' },
  { name: 'Belgie', value: 'Belgium' },
  { name: 'Bosnië en Herzegovina', value: 'Bosnia and Herzegovina' },
  { name: 'Bulgarije', value: 'Bulgaria' },
  { name: 'Cyprus', value: 'Cyprus' },
  { name: 'Denemarken', value: 'Denmark' },
  { name: 'Duitsland', value: 'Germany' },
  { name: 'Estland', value: 'Estonia' },
  { name: 'Finland', value: 'Findland' },
  { name: 'Frankrijk', value: 'France' },
  { name: 'Georgië', value: 'Georgia' },
  { name: 'Griekenland', value: 'Greece' },
  { name: 'Hongarije', value: 'Hungary' },
  { name: 'Ierland', value: 'Ireland' },
  { name: 'Ijsland', value: 'Iceland' },
  { name: 'Italië', value: 'Italy' },
  { name: 'Kroatië', value: 'Croatia' },
  { name: 'Letland', value: 'Latvia' },
  { name: 'Liechtenstein', value: 'Liechtenstein' },
  { name: 'Litouwen', value: 'Lithuania' },
  { name: 'Luxemburg', value: 'Luxembourg' },
  { name: 'Malta', value: 'Malta' },
  { name: 'Moldavië', value: 'Moldova' },
  { name: 'Monaco', value: 'Monaco' },
  { name: 'Montenegro', value: 'Montenegro' },
  { name: 'Nederland', value: 'Netherlands' },
  { name: 'Noord-Macedonië', value: 'North Macedonia' },
  { name: 'Noorwegen', value: 'Norway' },
  { name: 'Oostenrijk', value: 'Austria' },
  { name: 'Oekraïne', value: 'Ukraine' },
  { name: 'Polen', value: 'Poland' },
  { name: 'Portugal', value: 'Portugal' },
  { name: 'Roemenië', value: 'Romania' },
  { name: 'Rusland', value: 'Russia' },
  { name: 'San Marino', value: 'San Marino' },
  { name: 'Servië', value: 'Serbia' },
  { name: 'Slovenië', value: 'Slovenia' },
  { name: 'Slowakije', value: 'Slovakia' },
  { name: 'Spanje', value: 'Spain' },
  { name: 'Tsjechië', value: 'Czechia' },
  { name: 'Turkije', value: 'Turkey' },
  { name: 'Verenigd koninkrijk', value: 'United Kingdom' },
  { name: 'Wit-Rusland', value: 'Belarus' },
  { name: 'Zweden', value: 'Sweden' },
  { name: 'Zwitserland', value: 'Switzerland' }
];
