import styled from '@emotion/styled';

export const XPanel = styled('div')`
  padding: 6px;
  border-radius: 4px;
  background: #2d80c226;
  color: #2d80c2;
  display: flex;
  padding-top: 12px;
`;

export const XFailPanel = styled(XPanel)`
  background: #e91e6326;
  color: #e91e63;
`;

export const XSuccessPanel = styled(XPanel)`
  background: #4caf5026;
  color: #4caf50;
`;
