import { apiClient } from 'src/helpers/apiClient';
import { EditAccountData } from 'src/models/domain/EditAccount';

import { API_URL_ACCOUNT } from 'src/constants/ApiRoutes';
import { AccountSettingForm } from '../authentication/types';
import { ApiResult } from './../../helpers/apiClient';
import { AccountEditModel } from './types';

export async function saveAccount(command: {}) {
  return await apiClient.postCommandAsync(command);
}

export const createAccount = async (accountData: any, image: any): Promise<ApiResult> => {
  const command = {
    CommandName: 'CreateAccount',
    ...accountData
  };

  return await apiClient.postDataAsync(API_URL_ACCOUNT, command, [image]);
};

export const createAccountForOrganization = async (data: any): Promise<ApiResult> => {
  const command = {
    CommandName: 'CreateAccountForOrganization',
    ...data
  };

  return await apiClient.postCommandAsync(command);
};

export const editAccount = async (data: EditAccountData): Promise<ApiResult> => {
  const command = {
    CommandName: 'EditAccount',
    ...data
  };

  return await apiClient.postCommandAsync(command);
};

export const saveSettings = async (data: AccountSettingForm[]): Promise<ApiResult> => {
  const command = {
    CommandName: 'SaveSettings',
    Settings: data
  };

  return await apiClient.postCommandAsync(command);
};

export const getSettings = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('GetSettings', {});
};

export const checkEmailAvailable = async (email: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'CheckEmailAvailable',
    Email: email
  };

  return await apiClient.postDataAsync(API_URL_ACCOUNT, command);
};

export const verifyEmailCode = async (emailCode: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'VerifyEmailCode',
    Code: emailCode
  };
  return await apiClient.postDataAsync(API_URL_ACCOUNT, command);
};

export const sendPasswordReset = async (email: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'ForgottenPassword',
    Email: email
  };
  return await apiClient.postDataAsync(API_URL_ACCOUNT, command);
};

export const resetPassword = async (code: string, password: string): Promise<ApiResult> => {
  const command = {
    CommandName: 'ResetPassword',
    Code: code,
    Password: password
  };
  return await apiClient.postDataAsync(API_URL_ACCOUNT, command);
};

export const listAccounts = async (): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('ListAccounts', {});
};

export const getAccount = async (id: string): Promise<ApiResult> => {
  return await apiClient.postQueryAsync('GetAccount', { Id: id });
};

export const deleteAccount = async (id: string) => {
  const command = {
    CommandName: 'DeleteAccount',
    Id: id
  };
  return await apiClient.postCommandAsync(command);
};

export const editManageAccount = async (data: AccountEditModel) => {
  const command = {
    CommandName: 'EditManageAccount',
    Id: data.id,
    FirstName: data.firstName,
    LastName: data.lastName,
    Email: data.email,
    EmailConfirmed: data.emailConfirmed,
    Enabled: data.enabled
  };
  return await apiClient.postCommandAsync(command);
};

export const getMyAccount = async () => {
  return await apiClient.postQueryAsync('GetMyAccount', {});
};
