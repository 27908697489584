import * as React from 'react';

import Search from '@material-ui/icons/Search';
import styled from '@emotion/styled';

type OwnProps = {
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type AllProps = OwnProps;

const XSearchBar: React.SFC<AllProps> = props => {
  return (
    <Container>
      <IconWrapper>
        <Search />
      </IconWrapper>
      <Input placeholder={props.placeholder} onChange={props.onChange} />
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  border-style: solid;
  border-width: 1px;
  height: 34px;
`;

const IconWrapper = styled('div')`
  position: relative;
  width: 34px;
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Input = styled('input')`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
`;

export default XSearchBar;
