import React, { FC } from 'react';
import styled from '@emotion/styled';
import { MappingItem } from '../../store/catalogs/types';
import { FormControl, Select, Divider } from '@material-ui/core';
import { CSVMappingTypes } from '../../models/Catalog';

type OwnProps = {
  c: MappingItem;
  onChange: (e: any) => void;
};

const XMappingRow: FC<OwnProps> = React.memo(({ onChange, c }) => {
  return (
    <>
      <XSelectionRow>
        <XCell>
          {`${c.Column}`.substr(0, 20)}
          {`${c.Column}`.length > 20 && '...'}
          {`${c.Column}`.length > 20 && <ToolTipText> {c.Column}</ToolTipText>}
        </XCell>

        {c.SampleData ? (
          <XCell>
            {`${c.SampleData[0]}`.substr(0, 20)}
            {`${c.SampleData[0]}`.length > 20 && '...'}
            {`${c.SampleData[0]}`.length > 20 && <ToolTipText> {c.SampleData[0]}</ToolTipText>}
          </XCell>
        ) : (
          <XCell></XCell>
        )}

        <FormControl variant="outlined">
          <XSelect id={c.Column} native onChange={onChange} defaultValue={c.MappingType}>
            {Object.keys(CSVMappingTypes).map(t => (
              <option key={t} value={t}>
                {t}
              </option>
            ))}
          </XSelect>
        </FormControl>
      </XSelectionRow>
      <Divider />
    </>
  );
});

export default XMappingRow;

const XSelectionRow = styled.div`
  display: flex;
  align-items: center;
`;

const XSelect = styled(Select)`
  margin: 1.5rem 0.5rem 1rem 0;
  width: 14rem;
  height: 2rem;
`;

const ToolTipText = styled('span')({
  visibility: 'hidden',
  backgroundColor: '#333333',
  color: '#fff',
  textAlign: 'center',
  borderRadius: '6px',
  padding: '5px 0',
  position: 'absolute',
  zIndex: 1,
  bottom: '150%',
  left: '50%',
  marginLeft: '-60px',
  ':after': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: 'black transparent transparent transparent'
  }
});

const XCell = styled('div')({
  position: 'relative',
  display: 'inline-block',
  width: '14rem',
  ':hover span': {
    visibility: 'visible'
  }
});
