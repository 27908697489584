import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Action } from 'redux';

import styled from '@emotion/styled';
import { ThunkDispatch } from 'redux-thunk';
import XLTXInformation from 'src/components/domain/XLTXInformation';
import XButton, { XButtonStyle } from 'src/components/layout/XButton';
import { XContainer } from 'src/components/layout/XContainer';
import XForm from 'src/components/layout/XForm';
import { XPaperCenter, XPaperTitleLarge } from 'src/components/layout/XPaper';
import { PublicPages } from 'src/pages/Routes';
import { loginRequestThunk } from 'src/store/authentication/actions';
import { AuthenticationState } from 'src/store/authentication/types';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../store/index';
import Logo from '../../style/images/logos/main.svg';
import {
  FooterText,
  LeftSide,
  LoginButtonContainer,
  LoginContainer,
  LTXLogo,
  RightSide
} from './LoginPage.style';
import { Translation } from 'react-i18next';

type StateProps = {
  authentication: AuthenticationState;
  loading: boolean;
};

type DispatchProps = {
  loginAccount: (username: string, password: string) => void;
  showNotification: (text: string, type: MessageType) => void;
};

type OwnProps = {};

type AllProps = StateProps &
  DispatchProps &
  OwnProps &
  RouteComponentProps<{ redirectURL?: string }>;

type State = {
  showForm: boolean;
  formData: { email: string; password: string };
};

class LoginPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      showForm: true,
      formData: { email: '', password: '' }
    };

    if (window.location.href.indexOf('sessionExpired=1') !== -1)
      this.props.showNotification('Sessie verlopen, log opnieuw in om door te gaan', 'info');
  }

  public handleLoginClick = (): void => {
    const { email, password } = this.state.formData;
    this.props.loginAccount(email, password);
  };

  public handleRegisterClick = (): void => {
    this.props.history.push(PublicPages[3].path);
  };

  public handleFormChange = (name: string, value: string) => {
    const formData = Object.assign({}, this.state.formData);
    formData[name] = value;
    this.setState({ formData });
  };

  public render() {
    if (this.props.authentication.isAuthenticated) {
      if (this.props.match.params.redirectURL && this.props.match.params.redirectURL.length > 3)
        return <Redirect to={decodeURIComponent(this.props.match.params.redirectURL)} />;
      else
        return (
          <Redirect
            to={this.props.authentication.account.isAdmin ? '/app/admin/dashboard' : '/app/start'}
          />
        );
    }

    return (
      <LoginContainer>
        <LeftSide>
          <XPaperCenter
            goBack={() => {
              window.location.replace('https://arxis.io/');
            }}>
            <XContainer style={{ padding: '0' }}>
              <div style={{ padding: '10px 0' }}>
                <Translation>
                  {t => <XPaperTitleLarge> {t('login.login')} </XPaperTitleLarge>}
                </Translation>
                <form style={{ marginTop: '15px' }}>
                  <Translation>
                    {t => (
                      <XForm
                        fields={[
                          { title: t('contact.email'), name: 'email', fullWidth: true },
                          {
                            title: t('account.password'),
                            name: 'password',
                            type: 'password',
                            fullWidth: true
                          }
                        ]}
                        submitHandler={this.handleLoginClick}
                        verticalAlign={true}
                        callback={this.handleFormChange}
                      />
                    )}
                  </Translation>
                </form>
              </div>
              {this.props.authentication.authenticationError !== undefined && (
                <div style={{ color: '#F29223', padding: '0 15px' }}>
                  {this.props.authentication.authenticationError}
                </div>
              )}
              <XContainer
                style={{
                  margin: '-15px',
                  paddingTop: 0
                }}>
                <XContainer>
                  <FooterLine>
                    <Translation>
                      {t => (
                        <Link
                          to="/passwordForgotten"
                          style={{ color: '#8a8a8a', fontSize: '15px' }}>
                          {t('login.forgotPassword')}
                        </Link>
                      )}
                    </Translation>
                  </FooterLine>
                </XContainer>
              </XContainer>
              <LoginButtonContainer>
                <Link to="/register">
                  <Translation>
                    {t => <XButton textcolor="#666">{t('join.createAccount')}</XButton>}
                  </Translation>
                </Link>
                <Translation>
                  {t => (
                    <XButton
                      onClick={this.handleLoginClick}
                      colorscheme={XButtonStyle.Primary}
                      textcolor="#fff"
                      loading={this.props.loading}>
                      {t('login.LOGIN')}
                    </XButton>
                  )}
                </Translation>
              </LoginButtonContainer>
            </XContainer>
          </XPaperCenter>
        </LeftSide>
        <RightSide>
          <XLTXInformation />
        </RightSide>
        <LTXLogo src={Logo} />
        <MobileDevice>
          <div style={{ margin: 'auto' }}>
            <img width="50%" src={require('./access_denied.svg')} />
            <br />
            <br />
            <br />
            <h6>
              <Translation>
                {t => <strong> {t('login.notAvailableForMobile')} </strong>}
              </Translation>
            </h6>
            <br />
            <br />
          </div>
        </MobileDevice>
      </LoginContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    authentication: state.authentication,
    loading: state.authentication.loading
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AuthenticationState, void, Action>
): DispatchProps => ({
  loginAccount: (email: string, password: string) => dispatch(loginRequestThunk(email, password)),
  showNotification: (text: string, type: MessageType) => dispatch(showNotification(text, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

const FooterLine = styled.div`
  text-align: right;
  & a {
    color: #666;
  }
`;

const MobileDevice = styled.div`
  z-index: 5;
  text-align: center;
  background: white;
  position: fixed;
  top: 0;
  left: 0
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  @media (min-width: 718px) {
    display: none;
  }
`;
