import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';

import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { Catalog } from 'src/models/Catalog';
import { Organization, OrganizationModel, OrganizationType } from 'src/models/Organization';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { RequestState } from 'src/store/requests/types';
import XButton from '../layout/XButton';
import { XContainer } from '../layout/XContainer';
import XGrid from '../layout/XGrid';
import { XImage, XImageContainer } from '../layout/XImage';
import { XPaperContainer, XPaperLabel, XPaperText, XPaperTitle } from '../layout/XPaper';
import { XRow } from '../layout/XRow';
import XCatalog from './XCatalog';

import { generateAvatar } from 'src/helpers/avatarGenerator';
import { getImageUrl } from 'src/helpers/formatHelpers';
import Logo from '../../style/images/ltxLogos/ltxdefOpaque.svg';
import XOrganizationTitle from './XOrganizationTitle';

type OwnProps = {
  showCatalogs: boolean;
  showCatalogsButton: boolean;
  organizationModel: OrganizationModel;
  isAdmin: boolean;
  requestState?: RequestState;
  onRequestCatalogs?: (id: string) => void;
  onOfferCatalogs?: (id: string) => void;
};

type AllProps = OwnProps;

const XOrganizationDetail: React.SFC<AllProps> = props => {
  const organizationType = props.organizationModel.organization.organizationType;
  let requestButton = <div />;

  if (props.showCatalogsButton) {
    if (props.requestState === RequestState.NOT_REQUESTED) {
      requestButton = (
        <XButton
          staticcolor="#0b9d0b"
          textcolor="#fff"
          width="100%"
          onClick={() => {
            if (
              props.organizationModel.organization.organizationType === OrganizationType.Supplier
            ) {
              if (props.onRequestCatalogs) {
                props.onRequestCatalogs(props.organizationModel.organization.id);
              }
            } else {
              if (props.onOfferCatalogs) {
                props.onOfferCatalogs(props.organizationModel.organization.id);
              }
            }
          }}>
          CATALOGI {organizationType === OrganizationType.Supplier ? 'AANVRAGEN' : 'AANBIEDEN'}
        </XButton>
      );
    } else if (props.requestState === RequestState.REQUEST_PENDING) {
      requestButton = (
        <XButton active={false} staticcolor="#0b9d0b" textcolor="#fff" width="100%">
          CATALOGI {organizationType === OrganizationType.Supplier ? 'AANGEVRAAGD' : 'AANGEBODEN'}
        </XButton>
      );
    } else if (props.requestState === RequestState.DISAPPROVED) {
      requestButton = (
        <XButton active={false} staticcolor="#0b9d0b" textcolor="#fff" width="100%">
          CATALOGI {organizationType === OrganizationType.Supplier ? 'AANVRAAG' : 'AANBOD'}{' '}
          AFGEWEZEN
        </XButton>
      );
    } else if (props.requestState === RequestState.APPROVED) {
      requestButton = <div />; // Should we show anything here?
    }
  }

  return (
    <XContainer style={{ position: 'relative' }}>
      <XRow>
        <XImageContainer style={{ minWidth: '100px', background: 'none' }} width={100} height={100}>
          <XImage
            src={getImageUrl(props.organizationModel.organization.imageURL, undefined)}
            alt=""
            onError={(e: any) => {
              e.target.onError = null;
              e.target.src = generateAvatar(props.organizationModel.organization.name, 100);
            }}
          />
        </XImageContainer>
        <Link to={'/app/my/connections/organization/' + props.organizationModel.organization.id}>
          <TitleWrapper>
            <XOrganizationTitle
              organization={props.organizationModel.organization}
              fontSize={'16px'}
              bold={true}
            />
          </TitleWrapper>
          <EllipseText>{props.organizationModel.organization.description}</EllipseText>
        </Link>
      </XRow>

      <OrganizationButtonContainerSmall>{requestButton}</OrganizationButtonContainerSmall>
    </XContainer>
  );
};

const OrganizationButtonContainerSmall = styled('div')`
  position: absolute;
  top: 20px;
  right: 10px;
`;

const OrganizationButtonContainer = styled('div')({
  marginTop: '38px',
  marginBottom: '30px',
  maxWidth: '400px'
});

export default XOrganizationDetail;

const EllipseText = styled(XPaperText)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CatalogTitle = styled('label')({
  fontSize: '14px',
  fontFamily: 'Montserrat-Bold', // TODO: get font from theme
  paddingBottom: '4px'
});

const CatalogLabel = styled('label')({
  fontSize: '10px',
  cursor: 'pointer',
  marginTop: '3px'
});

const CatalogText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid #e0e0e0',
  paddingLeft: '7px',
  marginLeft: '8px',
  width: '100%',
  color: 'black'
});

const TitleWrapper = styled.div`
  margin: 0 15px;
`;
