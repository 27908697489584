import { createSelector } from 'reselect';
import { MessageState } from './types';

export const getMessages = (state: MessageState) => state;

export const getMessageState = createSelector(getMessages, s => s.messages);

export const getCatalogMessageState = createSelector(getMessages, s => s.catalogMessages);

export const getOrganizationCatalogMessageState = createSelector(getMessages, s => s.catalogMessages);

export const getOrderMessageState = createSelector(getMessages, s => s.orderMessages);
