const avatarApiUrl = 'https://eu.ui-avatars.com/api/?';
const colors = [
  'f0e9e9',
  'dbd1cc',
  'f2e7bd',
  'd2ebab',
  '94f7b9',
  '80cfcd',
  '9cc2ff',
  'dfc7ff',
  'ffd9fe',
  'ffd4df',
  'ffc9c9'
];

let cache: any[] = [];

const hashCode = (str: string) => {
  var hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash<<5)-hash)+char;
      hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export const generateAvatar = (
  name: string,
  size: number,
  rounded: boolean = false,
  length: number = 2
) => {
  let parameters = 'name=' + name + '&size=' + size + '&length=' + length;
  if (rounded !== undefined) parameters += '&rounded=' + rounded;

  const existing = cache.find(c => c[0] === parameters);
  if (existing) return existing[1];

  const color = colors[(Math.abs(hashCode(name))) % colors.length];
  const parametersWithBackground = parameters + '&background=' + color;
  const fullUrl = avatarApiUrl + parametersWithBackground;

  cache.push([parameters, fullUrl]);
  if (cache.length > 30) cache = cache.slice(cache.length - 30, cache.length);

  return fullUrl;
};
