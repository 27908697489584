import styled from '@emotion/styled';
import { SvgIconTypeMap, Tooltip } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import { Link } from 'react-router-dom';
import XField from './XField';
import XCounter from '../shared/XCounter';
import { XPaperTitle } from './XPaper';
import * as H from 'history';
import XGoBack from '../shared/XGoBack';
import { Translation } from 'react-i18next';

export interface HeaderIcon {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  name: string;
  toolTip: string;
  action?: () => void;
  link?: string;
  tooltip?: string;
  style?: React.CSSProperties;
  active?: boolean;
}

type OwnProps<Params extends { [K in keyof Params]?: string } = {}, S = H.LocationState> = {
  header: string;
  history?: H.History<S>;
  count?: string | number;
  subheader?: string | React.ReactNode;
  search?: boolean;
  icons?: HeaderIcon[];
  customContent?: any;
  customContentLeft?: any;
  onSearch?: (text: string) => void;
};

type AllProps = OwnProps;

class XPaperHeader extends React.Component<AllProps> {
  private getCustomContent() {
    const CustomContent = this.props.customContent;
    return CustomContent;
  }

  public render() {
    return (
      <XPaperTitle style={{ position: 'relative', flex: '0', marginBottom: '20px' }}>
        <Row>
          {this.props.history !== undefined && (
            <div style={{ marginTop: '-2px', paddingRight: '5px' }}>
              <XGoBack history={this.props.history} />
            </div>
          )}
          <div style={{ flex: 1 }}>
            <XTitle>
              {this.props.header}{' '}
              {this.props.count !== undefined && <XCounter count={this.props.count} />}
            </XTitle>
            <XSubTitle>{this.props.subheader}</XSubTitle>
          </div>
          <ContentRight>
            {this.props.customContentLeft}
            {this.props.search && (
              <StyledField>
                <Translation>
                  {t => (
                    <XField
                      placeholder={`${t('xPaperHeader.placeholder')}`}
                      name="search"
                      onChange={e => {
                        if (!this.props.onSearch) return;
                        this.props.onSearch(e.target.value);
                      }}
                    />
                  )}
                </Translation>
              </StyledField>
            )}
            <ActionButtons>
              {this.props.icons?.length
                ? this.props.icons?.map(icon => {
                    const IconComponent = icon.icon;
                    return (
                      <Tooltip title={icon.toolTip} key={icon.name}>
                        {icon.link ? (
                          <Link to={icon.link}>
                            <Icon style={icon.style} active={icon.active}>
                              <IconComponent />
                            </Icon>
                          </Link>
                        ) : (
                          <Icon
                            active={icon.active}
                            style={icon.style}
                            onClick={() => {
                              if (icon.action) icon.action();
                            }}>
                            <IconComponent />
                          </Icon>
                        )}
                      </Tooltip>
                    );
                  })
                : this.props.customContent && <div>{this.getCustomContent()}</div>}
            </ActionButtons>
          </ContentRight>
        </Row>
      </XPaperTitle>
    );
  }
}

export default XPaperHeader;

const Row = styled.div`
  display: flex;
`;

export const XTitle = styled.h6`
  font-weight: bold;
  color: black;
  margin-bottom: 0;
`;

export const XSubTitle = styled.div`
  font-weight: 600;
  color: #7b7b7b;
  white-space: pre;

  & a {
    color: rgb(231, 33, 123);
    font-weight: 600;
  }

  & a:hover {
    color: rgb(231, 33, 123);
    text-decoration: underline;
  }
`;

const ContentRight = styled.div``;

const StyledField = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 200px;
  margin-right: 15px;
  & input {
    padding: 3px 0 !important;
  }
`;

const ActionButtons = styled.div`
  display: inline-block;
  vertical-align: top;
`;

const Icon = styled.div<{ active?: boolean }>`
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  vertical-align: top;
  color: ${props => (props.active === false ? '#222' : 'rgb(245, 0, 87)')};

  &:last-of-type {
    margin-right: 0;
  }
`;
