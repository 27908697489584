import * as React from 'react';
import { connect } from 'react-redux';
import XNavigation from 'src/components/layout/XNavigation';
import { XSideBar } from 'src/components/layout/XSideBar';
import { Catalog } from 'src/models/Catalog';
import { RequestModel } from 'src/models/Request';
import { ApplicationState } from 'src/store';
import { getMyCatalogsRequiringAction } from 'src/store/catalogs/selectors';
import { activeIncommingRequest } from 'src/store/requests/selectors';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { Translation } from 'react-i18next';

type StateProps = {
  activeIncommingRequest: RequestModel[];
  catalogRequiringAction: Catalog[];
  account: AuthenticationAccount;
};

type State = {};

type DispatchProps = {};

type AllProps = StateProps & DispatchProps;

class MyLtxSideBar extends React.Component<AllProps, State> {
  public render() {
    return (
      <XSideBar>
        <Translation>
          {t => (
            <XNavigation
              routes={[
                {
                  name: t('myArxis.Relationships'),
                  route: '/app/my/relationships',
                  subRoutes: [
                    { name: t('myArxis.Overview'), route: '/app/my/relationships' },
                    {
                      name: t('myArxis.Requests'),
                      route: '/app/my/relationships/requests/',
                      badge: this.props.activeIncommingRequest.length
                    }
                  ]
                },
                {
                  name: t('common.Catalogs'),
                  route: '/app/my/catalogs',
                  subRoutes: [
                    { name: t('myArxis.Overview'), route: '/app/my/catalogs' },
                    {
                      name: t('myArxis.Traffic'),
                      route: '/app/my/catalogs/traffic/'
                    },
                    {
                      name: t('myArxis.Settings'),
                      route: '/app/my/catalogs/settings'
                    }
                  ]
                },
                {
                  name: 'Orders',
                  route: '/app/my/orders/traffic',
                  subRoutes: [
                    { name: t('myArxis.Traffic'), route: '/app/my/orders/traffic' },
                    {
                      name: t('myArxis.Settings'),
                      route: '/app/my/orders/settings'
                    }
                  ]
                },
                {
                  name: t('stock.Stock'),
                  route: '/app/my/stock',
                  subRoutes: [
                    { name: t('myArxis.Overview'), route: '/app/my/stock' },
                    {
                      name: t('myArxis.Traffic'),
                      route: '/app/my/stock/traffic/'
                    }
                  ]
                }
              ]}
              callback={''}
            />
          )}
        </Translation>
      </XSideBar>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    activeIncommingRequest: activeIncommingRequest(state),
    catalogRequiringAction: getMyCatalogsRequiringAction(state),
    account: state.authentication.account
  };
};

const mapDispatchToProps = (): DispatchProps => ({
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLtxSideBar);
