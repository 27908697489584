import React from 'react';
import { NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import './bootstrap.css';
import './index.css';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import DomainRedirectPage from './pages/DomainRedirectPage';
import Scroll from 'react-scroll';
import i18n from 'i18next';
import { initReactI18next, Translation } from 'react-i18next';
import { initLeadInfo } from './leadinfo';
import translationEN from './locales/en.json';
import translationNL from './locales/nl.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { apiClient } from '../../helpers/apiClient';
import { API_URL_ACCOUNT } from 'src/constants/ApiRoutes';
import CloseIcon from '@material-ui/icons/Close';

const ScrollLink = Scroll.Link;

type State = {
  nav: boolean;
  scrolled: boolean;
  whitePaperPopUp: boolean;
  email: string;
  valid: boolean;
};

class IndexPage extends React.Component<RouteComponentProps, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      nav: false,
      scrolled: false,
      whitePaperPopUp: false,
      email: '',
      valid: true
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    initLeadInfo();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  public handleScroll = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    this.setState({ scrolled: top > 100 });
  };

  public changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  public language = () => {
    return i18n.language;
  };

  openPopUp = () => {
    this.setState({ whitePaperPopUp: true });
  };

  closePopUp = () => {
    this.setState({ whitePaperPopUp: false });
  };

  sendEmailDowloadNotification = async () => {
    if (this.state.email.length > 0 && this.state.valid) {
      const command = {
        CommandName: 'SendWhitePaperDownloadedNotification',
        Email: this.state.email
      };
      return await apiClient.postDataAsync(API_URL_ACCOUNT, command);
    } else return;
  };

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleEmailChange(e) {
    const email = e.target.value;
    const emailValid = this.validateEmail(email);

    this.setState({
      email: e.target.value,
      valid: emailValid
    });
  }

  public render() {
    const scrollPages = [
      {
        title: 'benefits',
        to: 'features'
      },
      {
        title: 'references',
        to: 'users'
      },
      {
        title: 'pricing',
        to: 'pricing'
      }
    ];

    if (this.language() === 'nl')
      scrollPages.push({
        title: 'faq',
        to: 'faq'
      });
    return (
      <Translation>
        {t => (
          <div style={{ position: 'relative' }}>
            <nav
              className={
                'navbar navbar-expand-md navbar-inverse nav-down shrink default-background' +
                (this.state.scrolled ? '' : ' transparant')
              }
              role="navigation">
              <div className="container-fluid collapsed">
                {' '}
                {/* Brand and toggle get grouped for better mobile display */}
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => this.setState({ nav: !this.state.nav })}
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-controls="bs-example-navbar-collapse-1"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  {/*            <span*/}
                  {/*                    style="color: white" class="fa fa-bars"></span>*/}

                  <svg
                    width="24px"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="bars"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="svg-inline--fa fa-bars fa-w-14 fa-3x">
                    <path
                      fill="#ffffff"
                      d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
                    />
                  </svg>
                </button>
                <ScrollLink to="home" spy={true} smooth={true} duration={500}>
                  <NavLink to={'/'} title="Home" className="navbar-brand">
                    <img
                      width="100px"
                      style={{ position: 'absolute' }}
                      src={require('./pages/images/svg/arxisdef.svg')}
                      alt="Arxis"
                      title="Arxis"
                    />
                  </NavLink>
                </ScrollLink>
                <div className={'hidden-md-down'} style={{ width: '100%' }}>
                  <div id="bs-example-navbar-collapse" className="collapse navbar-collapse">
                    <ul id="menu-menu" className="nav navbar-nav">
                      <li
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-482"
                        className={
                          'menu-item last-menu-item menu-item-type-post_type menu-item-object-page nav-item' +
                          (this.props.location.pathname === '/' ? ' active current-menu-item' : '')
                        }>
                        <ScrollLink
                          to="home"
                          offset={-20}
                          spy={true}
                          smooth={true}
                          duration={500}
                          activeClass="some-active-class">
                          <NavLink to={'/'} title="Home" className="nav-link">
                            {' '}
                            Home
                          </NavLink>
                        </ScrollLink>
                      </li>
                      {this.props.location.pathname === '/' &&
                        scrollPages.map(x => (
                          <li
                            key={x.title}
                            itemType="https://www.schema.org/SiteNavigationElement"
                            id="menu-item-482"
                            className={
                              'menu-item last-menu-item menu-item-type-post_type menu-item-object-page nav-item'
                            }>
                            <ScrollLink
                              to={x.to}
                              offset={-100}
                              spy={true}
                              smooth={true}
                              duration={500}
                              className="nav-link"
                              activeClass="some-active-class">
                              {t('menu.' + x.title)}
                            </ScrollLink>
                          </li>
                        ))}

                      <li
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-630"
                        className={
                          'menu-item last-menu-item menu-item-type-post_type menu-item-object-page nav-item' +
                          (this.props.location.pathname === '/contact'
                            ? ' active current-menu-item'
                            : '')
                        }>
                        <NavLink to={'/contact'} title="Contact" className="nav-link">
                          Contact
                        </NavLink>
                      </li>

                      <li
                        className="menu-item"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          height: 'inherit'
                        }}
                        title={
                          this.language() === 'en'
                            ? 'Schakel over naar Nederlands'
                            : 'Switch to English'
                        }
                        onClick={() => this.changeLanguage(this.language() === 'en' ? 'nl' : 'en')}>
                        {this.language() === 'en'}
                        <span
                          className="lang-switch"
                          style={{
                            backgroundImage:
                              'url(' +
                              require('./locales/icons/' +
                                (this.language() === 'en' ? 'nl.svg' : 'en.svg')) +
                              ')'
                          }}
                        />
                      </li>
                    </ul>
                  </div>{' '}
                </div>
                <div
                  className="button-wrapper"
                  data-sr-id={6}
                  style={{ display: 'inline-flex', minWidth: '165px' }}>
                  <NavLink
                    onClick={() => window.location.assign(`${window.location.href}login`)}
                    to={'/login'}
                    title={t('menu.goToAppButton')}
                    className="button my-ltx d-none d-md-inline-block">
                    {t('menu.goToAppButton')}
                  </NavLink>
                </div>
              </div>
              <div className="hidden-md-up">
                <div
                  id="bs-example-navbar-collapse-1"
                  className={'navbar-collapse collapse' + (this.state.nav ? ' show' : '')}>
                  <ul id="menu-menu-1" className="nav navbar-nav">
                    <li
                      itemType="https://www.schema.org/SiteNavigationElement"
                      className={
                        'menu-item last-menu-item menu-item-type-post_type menu-item-object-page nav-item' +
                        (this.props.location.pathname === '/'
                          ? ' active current-menu-item current_page_item'
                          : '')
                      }>
                      <NavLink to={'/'} title="Home" className="nav-link">
                        Home
                      </NavLink>
                    </li>
                    {this.props.location.pathname === '/' &&
                      scrollPages.map(x => (
                        <li
                          key={x.title}
                          itemType="https://www.schema.org/SiteNavigationElement"
                          id="menu-item-482"
                          className={
                            'menu-item last-menu-item menu-item-type-post_type menu-item-object-page nav-item'
                          }>
                          <ScrollLink
                            to={x.to}
                            offset={-500}
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="nav-link"
                            onClick={() => this.setState({ nav: false })}
                            activeClass="some-active-class">
                            {t('menu.' + x.title)}
                          </ScrollLink>
                        </li>
                      ))}
                    <li
                      itemType="https://www.schema.org/SiteNavigationElement"
                      className={
                        'menu-item last-menu-item menu-item-type-post_type menu-item-object-page nav-item' +
                        (this.props.location.pathname === '/contact'
                          ? ' active current-menu-item current_page_item'
                          : '')
                      }>
                      <NavLink to={'/contact'} title="Contact" className="nav-link">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>{' '}
              </div>
            </nav>

            <Switch>
              <Route path={'/'} exact={true}>
                <HomePage openPopUp={this.openPopUp} />
              </Route>
              <Route path={'/contact'} exact={true} component={ContactPage} />
              <Route path={'/ltx'} exact={true} component={DomainRedirectPage} />
            </Switch>

            <div className="footer">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-lg-3 col-xs-12 center footer-logo">
                    <NavLink to={'/'} title="Start">
                      <img
                        width="70px"
                        src={require('./pages/images/svg/arxisdef.svg')}
                        alt="Arxis"
                        title="Arxis"
                      />
                    </NavLink>
                    <p />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-lg-1" />
                  <div className="col-xs-12 col-sm-12 col-lg-8">
                    <div className="footer-inner">
                      <h4>Contact</h4>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-4">
                          <div className="textwidget">
                            <p>
                              <strong>E-mail</strong>
                              <br />
                              <a href="mailto:info@arxis.io">info@arxis.io</a>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-lg-4">
                          <div className="textwidget">
                            <p>
                              <strong>Telefoon</strong>
                              <br />
                              <a href="tel:+31 (0) 88 2400 110">+31 (0) 88 2400 110</a>
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-lg-4">
                          <div className="textwidget">
                            <p>
                              <strong>{t('footer.address')}</strong>
                              <br />
                              <a href="http://maps.google.com/?q=1019 GM Amsterdam, Piet Heinkade 55">
                                Piet Heinkade 55
                                <br />
                                1019 GM Amsterdam
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-bar">
                <div className="container">
                  <div className="row">
                    <div className="textwidget">
                      <p
                        style={{
                          textAlign: 'center',
                          color: '#616bff',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}>
                        <img
                          alt="Arxis"
                          src={require('./pages/images/svg/arxis-text-white.svg')}
                          height="30px"
                        />{' '}
                        &nbsp; &nbsp; © {new Date().getFullYear()} | All rights reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.whitePaperPopUp && (
              <div className="whitepaper-popup-container">
                <div className="whitepaper-popup-content">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <h5>Whitepaper</h5>
                    <div style={{ cursor: 'pointer' }} onClick={this.closePopUp}>
                      <CloseIcon />
                    </div>
                  </div>
                  <div>
                    <span>{t('whitepaper.enterEmailToDownloadWhitepaper')}</span>
                  </div>
                  <div style={{ padding: '20px 0' }}>
                    <label style={{ display: 'flex' }} className="custom-field two">
                      <input
                        style={{ flex: 1 }}
                        type="email"
                        required
                        placeholder="&nbsp;"
                        value={this.state.email}
                        onChange={e => this.handleEmailChange(e)}
                      />
                      <span className="placeholder"> {t('contact.email')}</span>
                    </label>

                    {this.state.email.length > 0 && !this.state.valid && (
                      <span style={{ color: 'red' }}>Invalid e-mail address</span>
                    )}
                  </div>
                  {this.state.email.length > 0 && this.state.valid ? (
                    <a
                      href={
                        this.language() == 'nl'
                          ? 'https://arxis.io/whitepaper_Arxis_English.pdf'
                          : 'https://arxis.io/whitepaper_Arxis_NL.pdf'
                      }
                      target="_blank"
                      download>
                      <div
                        style={{ marginTop: '12px' }}
                        className={
                          this.state.email.length > 0 && this.state.valid
                            ? 'button'
                            : 'button disable'
                        }
                        onClick={this.sendEmailDowloadNotification}>
                        Download
                      </div>
                    </a>
                  ) : (
                    <div style={{ marginTop: '12px', cursor: 'auto' }} className={'button disable'}>
                      Download
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

export default IndexPage;
