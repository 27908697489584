import { BaseReducer } from 'src/helpers/baseReducer';
import { Action } from './actions';
import { ActionTypes, LayoutState } from './types';

export const initialState: LayoutState = {
  headerText: 'Hallo Arxis',
  loader: {
    show: false
  },
  snackbar: {
    show: false
  },
  notification: {
    show: false
  },
  confirmation: {
    message: '',
    onConfirm: () => {
      /* */
    },
    show: false,
    title: ''
  },
  viewCatalogs: 'grid',
  viewOrganizations: 'grid',
  prompt: {}
};

export class LayoutReducer extends BaseReducer<LayoutState, Action> {
  constructor() {
    super(Object.values(ActionTypes), initialState);
  }

  protected doHandle(newState: LayoutState = initialState, action: Action) {
    switch (action.type) {
      case ActionTypes.SHOW_SNACKBAR: {
        newState.snackbar = action.payload;
        return newState;
      }
      case ActionTypes.SHOW_NOTIFICATION: {
        newState.notification = action.payload;
        return newState;
      }
      case ActionTypes.HIDE_NOTIFICATION: {
        newState.notification = { show: false };
        return newState;
      }
      case ActionTypes.SHOW_CONFIRMATION: {
        newState.confirmation = action.payload;
        return newState;
      }
      case ActionTypes.HIDE_CONFIRMATION: {
        newState.confirmation = { ...newState.confirmation, show: false };
        return newState;
      }
      case ActionTypes.CHANGE_VIEW: {
        if (action.screen === 'catalogs') newState.viewCatalogs = action.payload;
        else newState.viewOrganizations = action.payload;
        return newState;
      }
      default:
        return undefined;
    }
  }
}
