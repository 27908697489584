import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { TextField, Checkbox, FormControl, Select, MenuItem } from '@material-ui/core';
import { XActionButton } from 'src/components/layout/XActionButton';
import {
  InterfaceType,
  InterfaceMethod,
  InterfaceSettingsKey,
  InterfaceAuthentication,
  CatalogModel,
  InterfaceUpdateInterval
} from '../../../../models/Catalog';
import { saveCatalogInterface } from '../../../../store/catalogs/api';
import { ApplicationState } from 'src/store';
import { showNotification } from 'src/store/layout/actions';
import * as catalogActions from 'src/store/catalogs/actions';
import { ApiResult } from 'src/helpers/apiClient';
import { MessageType } from 'src/store/layout/types';
import { mapError } from 'src/helpers/errorMap';
import i18n from '../../../../i18n';

type OwnProps = {
  catalogId?: string;
};

type StateProps = {
  catalog?: CatalogModel;
};

type DispatchProps = {
  showNotification: (message: string, type?: MessageType) => void;
  getCatalogById: (id: string) => Promise<ApiResult>;
};

type AllProps = OwnProps & DispatchProps & StateProps;

const FTPInterfaceMethod: FC<AllProps> = props => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [url, setUrl] = useState('');
  const [autoUpdate, setAutoUpdate] = useState('');
  const [updateTimeInterval, setUpdateTimeInterval] = useState('');
  const [userName2, setUserName2] = useState('');
  const [password2, setPassword2] = useState('');
  const [url2, setUrl2] = useState('');
  const [useMultipleFiles, setUseMultipleFiles] = useState('');
  const history = useHistory();

  useEffect(() => {
    props.catalogId &&
      props.getCatalogById(props.catalogId).then(() => {
        if (!!props.catalog?.interfaces.length) {
          if (props.catalog?.interfaces[0].method === InterfaceMethod.Ftp) {
            setUrl(`${props.catalog?.interfaces[0].settings.Url}`);
            setUserName(`${props.catalog?.interfaces[0].settings.Username}`);
            setPassword(`${props.catalog?.interfaces[0].settings.Password}`);
            setUrl2(`${props.catalog?.interfaces[0].settings.Url2}`);
            setUserName2(`${props.catalog?.interfaces[0].settings.Username2}`);
            setPassword2(`${props.catalog?.interfaces[0].settings.Password2}`);
            setAutoUpdate(`${props.catalog?.interfaces[0].settings.AutoUpdate}`);
            setUpdateTimeInterval(`${props.catalog?.interfaces[0].settings.UpdateTimeInterval}`);
            setUseMultipleFiles(`${props.catalog?.interfaces[0].settings.UseMultipleFiles}`);
          }
        }
      });
  }, []);

  const catalogInterface = {
    method: InterfaceMethod.Ftp,
    type: InterfaceType.CatalogSend,
    authentication: InterfaceAuthentication.None,
    id: props.catalog
      ? props.catalog.interfaces.length > 0
        ? props.catalog.interfaces[0].id
        : ''
      : '',
    settings: {
      [InterfaceSettingsKey.Url]: url,
      [InterfaceSettingsKey.Username]: userName,
      [InterfaceSettingsKey.Password]: password,
      [InterfaceSettingsKey.Token]: '',
      [InterfaceSettingsKey.AutoUpdate]: autoUpdate,
      [InterfaceSettingsKey.UpdateTimeInterval]: updateTimeInterval,
      [InterfaceSettingsKey.PortalName]: '',
      [InterfaceSettingsKey.FilterType]: '',
      [InterfaceSettingsKey.UseMultipleFiles]: useMultipleFiles,
      [InterfaceSettingsKey.Url2]: url2,
      [InterfaceSettingsKey.Username2]: userName2,
      [InterfaceSettingsKey.Password2]: password2,
      [InterfaceSettingsKey.Filter]: ''
    }
  };

  const saveInterface = async () => {
    const apiResult = await saveCatalogInterface(catalogInterface, props.catalogId);
    if (apiResult.IsSuccess) {
      history.push(`/app/my/catalogs/edit/${props.catalogId}`);
    } else {
      props.showNotification(
        i18n.t('notification.savingCatalogInterface') + mapError(apiResult.Errors),
        'error'
      );
    }
  };

  const onChangeUpdateIntervalHandler = (e: any) => {
    setUpdateTimeInterval(e.target.value);
  };

  const checkBoxHandler = (e: any) => {
    if (e.key === 'Enter') {
      if (autoUpdate === 'True')
        setAutoUpdate('False');
      else
        setAutoUpdate('True');
    } else return;
  };

  const handleUseMultipleFilesChange = (e: any) => {
    if (e.target.checked === true)
      setUseMultipleFiles('True')
    else
      setUseMultipleFiles('False')
  }

  const handleOnChange = (e: any) => {
    if (e.target.checked === true)
      setAutoUpdate('True')
    else
      setAutoUpdate('False')
  }

  return (
    <div>
      <XTextInput>
        <TextField value={url} onChange={e => setUrl(e.target.value)} label="FTP Url" fullWidth />
      </XTextInput>
      <div>
        <XTextInput>
          <TextField
            value={userName}
            onChange={e => setUserName(e.target.value)}
            label="FTP Username"
          />
        </XTextInput>
        <XTextInput>
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            label="FTP Password"
            type='password'
          />
        </XTextInput>
        <XTextInput>
          <Checkbox
            checked={useMultipleFiles === 'True'}
            onChange={e => handleUseMultipleFilesChange(e)}
            onKeyPress={e => checkBoxHandler(e)}
          />
          <span>Use multiple files as input</span>
        </XTextInput>
        {useMultipleFiles === 'True' && (
          <>
            <XTextInput>
              <TextField
                value={url2}
                onChange={e => setUrl2(e.target.value)}
                label="FTP2 Url"
                fullWidth
              />
            </XTextInput>
              <XTextInput>
                <TextField
                  value={userName2}
                  onChange={e => setUserName2(e.target.value)}
                  label="FTP2 Username"
                />
              </XTextInput>
              <XTextInput>
                <TextField
                  value={password2}
                  onChange={e => setPassword2(e.target.value)}
                  label="FTP2 Password"
                  type='password'
                />
              </XTextInput>
          </>
        )}
        <XTextInput>
          <Checkbox
            checked={autoUpdate === 'True'}
            onChange={e => handleOnChange(e)}
            onKeyPress={e => checkBoxHandler(e)}
          />
          <span>Catalog auto update</span>
        </XTextInput>
        <XTextInput>
          <div>Select update interval:</div>
          <FormControl style={{ width: '50%', marginTop: '0.5rem' }}>
            <Select value={updateTimeInterval} onChange={onChangeUpdateIntervalHandler}>
              {Object.keys(InterfaceUpdateInterval).map(key => (
                <MenuItem key={key} value={InterfaceUpdateInterval[key]}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </XTextInput>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2rem' }}>
        <XActionButton onClick={saveInterface} style={{ marginLeft: 'auto' }}>
          Save
        </XActionButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    catalog: state.catalogs.data.detail
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type?: MessageType) =>
    dispatch(showNotification(message, type)),
  getCatalogById: (id: string) => dispatch(catalogActions.getCatalogThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(FTPInterfaceMethod);

const XTextInput = styled.div`
  margin-bottom: 1rem;
`;
