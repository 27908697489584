export enum InterfaceAuthentication {
  None = "None",
  UsernamePassword = "UsernamePassword",
  BasicAuth = "BasicAuth",
  JWTAuth = "JWTAuth"
}

export enum InterfaceUpdateInterval {
  Day = "0 0 6 * * ?",
  Week = "0 0 6 ? * 1",
  Month = "0 0 6 1 * ?"
}

export enum InterfaceType {
  CatalogSend = "CatalogSend",
}

export enum InterfaceMethod {
  Ftp = "Ftp",
  Sftp = "Sftp",
  Manual = "Manual",
  Api = "Api",
  DeEekhoorn = "DeEekhoorn",
  ItsPerfect = 'ItsPerfect',
  VertilinQ = 'VertilinQ',
  ArxisApi = 'ArxisApi',
  IMS = 'IMS',
  LightAndLiving = 'LightAndLiving'
}

export enum InterfaceSettingsKey {
  Url = "Url",
  Username = "Username",
  Password = "Password",
  Url2 = "Url2",
  Username2 = "Username2",
  Password2 = "Password2",
  Token = "Token",
  PortalName = "PortalName",
  AutoUpdate = "AutoUpdate",
  UpdateTimeInterval = "UpdateTimeInterval",
  FileFormatId = "FileFormatId",
  FileFormatVersionId = "FileFormatVersionId",
  FilterType = "FilterType",
  UseMultipleFiles = "UseMultipleFiles",
  Filter = "Filter"
}

export interface Catalog {
  id: string;
  currentVersionId?: string;
  organizationId: string;
  organizationName: string;
  organizationImageURL: string;
  title: string;
  description: string;
  imageURL: string;
  downloads: number;
  downloaded: boolean;
  lastCreationDate: Date;
  connectionDate: Date;
  publicationStatus: PublicationStatus;
  publicationDate: Date;
  processingStatus: ProcessingStatus;
  validationFileUrl: string;
  mappingId: string;
  fileFormatId: string;
  fileFormatVersionId: string;
}

export interface CatalogVersion {
  id: string;
  version: string;
  startDate: Date;
  endDate: Date;
  fileFormat: string;
  fileFormatVersion: string;
  creationDate: Date;
  additionalSettings: string;
  publicationStatus: PublicationStatus;
  publicationDate: Date;
  processingStatus: ProcessingStatus;
  validationFileUrl: string;
  conversionFileUrl: string;
}

export interface CatalogModel {
  catalog: Catalog;
  versions: CatalogVersion[];
  downloads?: CatalogDownloads[];
  interfaces: CatalogInterface[];
}

export interface CatalogValidationSummary {
  violations: CatalogFileViolation[];
}

export interface CatalogFileViolation {
  type: string;
  level: string;
  objectId: string;
  message: string;
  lineNumber?: number;
}

export interface CatalogDownloads {
  organizationId: string;
  organizationName: string;
  downloads: number;
  lastDownloadDate?: Date;
}

export interface CatalogInterface {
  id: string,
  method: InterfaceMethod;
  type: InterfaceType;
  settings: CatalogInterfaceSetting;
  authentication: string;
  catalogId?: string;
}

export interface CatalogInterfaceSetting {
  Url: string;
  Username: string;
  Password: string;
  Url2: string;
  Username2: string;
  Password2: string;
  PortalName: string;
  UseMultipleFiles: string;
  Token: string;
  AutoUpdate: string;
  UpdateTimeInterval: string;
  FilterType: string;
  Filter: string;
}

export enum PublicationStatus {
  Unpublished = 'Unpublished',
  Published = 'Published'
}

export enum ProcessingStatus {
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  UploadFailed = 'UploadFailed',
  Validating = 'Validating',
  Validated = 'Validated',
  ValidatedWithWarnings = 'ValidatedWithWarnings',
  ValidationFailed = 'ValidationFailed',
  None = 'None',
  Fetching = 'Fetching',
  FetchingFailed = 'FetchingFailed',
  Fetched = 'Fetched',
  WaitingForMapping = 'WaitingForMapping',
  Converting = 'Converting',
  ConvertingFailed = 'ConvertingFailed',
  Converted = 'Converted',
  ConvertingSkipped = 'ConvertingSkipped',
  ValidatingFailed = 'ValidatingFailed',
  Promoting = 'Promoting',
  PromotingFailed = 'PromotingFailed',
  Promoted = 'Promoted',
  MappingFailed = 'MappingFailed',
  Sending = 'Sending',
  SendingFailed = 'SendingFailed',
  Sent = 'Sent',
  FileDetected = 'FileDetected',
  SavingOriginal = 'SavingOriginal',
  SavingOriginalFailed = 'SavingOriginalFailed',
  SavedOriginal = 'SavedOriginal',
  SavingConverted = 'SavingConverted',
  SavingConvertedFailed = 'SavingConvertedFailed',
  SavedConverted = 'SavedConverted',
  RequestDetected = 'RequestDetected'
}

export enum CSVMappingTypes {

  IdentifierEanNumber = 'IdentifierEanNumber',
  IdentifierGTIN = 'IdentifierGTIN',
  IdentifierSalesNumber = 'IdentifierSalesNumber',
  IdentifierOther = 'IdentifierOther',
  IdentifierFile2 = 'IdentifierFile2',
  IdentifierFile3 = 'IdentifierFile3',

  // Can be assigned to multiple columns in combination with LanguageIdentifier (e.g. 'NL', 'DE')
  NameNL = 'NameNL',
  NameEN = 'NameEN',
  NameDE = 'NameDE',
  NameFR = 'NameFR',
  NameOther = 'NameOther',

  AdditionalDescriptionTypeWEB = 'AdditionalDescriptionTypeWEB',

  ArticleGroup = 'ArticleGroup',
  ArticleSubGroup = 'ArticleSubGroup',
  ArticleSubSubGroup = 'ArticleSubSubGroup',

  Order = 'Order',

  // Prices
  SalesPrice = 'SalesPrice',
  RetailPrice = 'RetailPrice',

  // Promo prices
  PromoPrice = 'PromoPrice',
  PromoQuantity = 'PromoQuantity',
  PromoValidFrom = 'PromoValidFrom',
  PromoValidTo = 'PromoValidTo',

  // Vat
  VatId = 'VatId',
  Vat = 'Vat',

  // Supplier
  SupplierId = 'SupplierId',
  SupplierName = 'SupplierName',

  // Media
  MediaImage = 'MediaImage',
  MediaDocument = 'MediaDocument',
  MediaOther = 'MediaOther',

  // Properties
  ItemPropertyWidth = 'ItemPropertyWidth',
  ItemPropertyHeight = 'ItemPropertyHeight',
  ItemPropertyLength = 'ItemPropertyLength',
  ItemPropertyDepth = 'ItemPropertyDepth',
  ItemPropertySittingHeight = 'ItemPropertySittingHeight',
  ItemPropertySittingDepth = 'ItemPropertySittingDepth',
  ItemPropertyWeightPackaging = 'ItemPropertyWeightPackaging',
  ItemPropertyNumberOfPackages = 'ItemPropertyNumberOfPackages',
  ItemPropertyAssemblyTime = 'ItemPropertyAssemblyTime',
  ItemPropertyVolume = 'ItemPropertyVolume',
  ItemPropertyWeight = 'ItemPropertyWeight',
  ItemPropertyEcoMobilier = 'ItemPropertyEcoMobilier',
  ItemPropertyIntrastat = 'ItemPropertyIntrastat',
  ItemPropertyVivCode = 'ItemPropertyVivCode',
  ItemPropertyReference = 'ItemPropertyReference',
  ItemPropertyOriginCountry = 'ItemPropertyOriginCountry',

  // Attributes
  AttributeText = 'AttributeText',
  AttributeSelect = 'AttributeSelect',
  AttributeMultiSelect = 'AttributeMultiSelect',
  AttributeYesOrNoSelect = 'AttributeYesOrNoSelect',
  AttributeUnknown = 'AttributeUnknown',

  None = 'None'
}


