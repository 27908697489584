import { ApiError } from 'src/helpers/apiClient';
import {
  Catalog,
  CatalogFileViolation,
  CatalogModel,
  CatalogValidationSummary
} from 'src/models/Catalog';
import { FileFormat } from 'src/models/FileFormat';

export enum ActionTypes {
  GET_CATALOGS = '[catalogs] GET_CATALOGS',
  GET_SUBSCRIBED_CATALOGS = '[catalogs] GET_SUBSCRIBED_CATALOGS',
  GET_CATALOG = '[catalogs] GET_CATALOG',
  VALIDATE_CATALOG_FILE = '[catalogs] VALIDATE_CATALOG_FILE',
  CONVERT_CATALOG_FILE = '[catalogs] CONVERT_CATALOG_FILE',
  SAVE_CATALOG = '[catalogs] SAVE_CATALOG',
  PUBLISH_CATALOG = '[catalogs] PUBLISH_CATALOG',
  ADD_CATALOG = '[catalogs] ADD_CATALOG',
  ADD_CATALOG_HEADER = '[catalogs] ADD_CATALOG_HEADER',
  GET_CATALOGS_ORGANIZATION = '[catalogs] GET_CATALOGS_ORGANIZATION',
  GET_RECEIVING_CATALOGS_ORGANIZATION = '[catalogs] GET_RECEIVING_CATALOGS_ORGANIZATION',
  GET_FILE_FORMATS = '[catalogs] GET_FILE_FORMATS',
  STORE_CATALOG = '[catalogs] STORE_CATALOG',
  DELETE_CATALOG = '[catalogs] DELETE_CATALOG',
  EDIT_CATALOG_AVAILABLE = '[catalogs] EDIT_CATALOG_AVAILABLE'
}

export enum CatalogAccessibility {
  SelectedConnections = 'SelectedConnections',
  AllConnections = 'AllConnections',
  Public = 'Public'
}

export type EditCatalogFormData = {
  title: string;
  description: string;
  imageURL: string;
  image: any;
  version: string;
  startDate?: Date;
  endDate?: Date;
};

export type ConvertCatalogFormData = {
  fromFileFormat: string;
  fromFileFormatVersion: string;
  toFileFormat: string;
  toFileFormatVersion: string;
  file: any;
};

export type CatalogFormData = {
  organizationId: string;
  title: string;
  description: string;
  image: any;
  versionName: string;
  startDate?: Date;
  endDate?: Date;
  fileFormat: string;
  fileFormatVersion: string;
  accessibility: CatalogAccessibility;
  retailers: string[];
  file: any;
  uploaded: boolean;
};

export type MappingItem = {
  Column: string;
  MappingType: string;
  SampleData: any;
}

export type Mapping = {
  Delimiter: string;
  MappingItems: MappingItem[];
}

export type CatalogHeaderFormData = {
  organizationId: string;
  title: string;
  description: string;
  fileFormatId: string;
  fileFormatVersionId: string;
  interfaceType: string;
  interfaceMethod: string;
  interfaceAuthentication: string;
  settings: object;
};

export const EmptyCatalogFormData = {
  title: '',
  description: '',
  image: undefined,
  organizationId: '',
  versionName: '',
  fileFormat: '',
  fileFormatVersion: '',
  accessibility: CatalogAccessibility.AllConnections,
  retailers: [],
  startDate: undefined,
  endDate: undefined,
  file: null,
  uploaded: false
};

export type CatalogState = {
  loading: boolean;
  data: {
    all: Catalog[] | undefined;
    new: Catalog[];
    popular: Catalog[];
    special: Catalog[];
    top: Catalog[];
    my: Catalog[];
    subscribed: Catalog[];
    detail?: CatalogModel;
  };
  addCatalog: CatalogFormData;
  validation: {
    validationSummary?: CatalogValidationSummary;
    error?: ApiError;
  };
  conversion: {
    transformResult?: any;
    error?: ApiError;
  };
  fileFormats: FileFormat[];
  errors?: string;
};

export type ConversionResult = {
  from: string;
  to: string;
  writeDuration: number;
  readDuraction: number;
  errors: CatalogFileViolation[];
  url: string;
};

export type CommandResult = {
  data: any;
};
