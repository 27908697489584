import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import styled from '@emotion/styled';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { XButtonStyle } from 'src/components/layout/XButton';
import { XContainer } from 'src/components/layout/XContainer';
import XField from 'src/components/layout/XField';
import XFileSelectButton from 'src/components/layout/XFileSelectButton';
import XFormButtons from 'src/components/layout/XFormButtons';
import XImageEditor from 'src/components/layout/XImageEditor';
import { ApiResult } from 'src/helpers/apiClient';
import { Organization, OrganizationModel, OrganizationType } from 'src/models/Organization';
import { AuthenticationAccount } from 'src/store/authentication/types';
import * as catalogActions from 'src/store/catalogs/actions';
import * as organizationActions from 'src/store/organizations/actions';
import { ApplicationState } from '../../../store';
import * as catalogSelects from '../../../store/catalogs/selectors';
import { CatalogFormData, EmptyCatalogFormData } from '../../../store/catalogs/types';
import { FileRoute } from './WizardRouter';
import { Translation } from 'react-i18next';

type StateProps = {
  data: CatalogFormData;
  myOrganization?: OrganizationModel;
  targetOrganization?: OrganizationModel;
  account: AuthenticationAccount;
};

type DispatchProps = {
  storeInformation: (data: CatalogFormData) => void;
  getMyOrganization: () => void;
  getOrganization: (id: string) => Promise<ApiResult>;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{ organizationId?: string }>;

type State = {
  inputData: CatalogFormData;
  inputErrors: {
    title: boolean;
  };
  validForm: boolean;
};

class WizardBasicPage extends React.Component<AllProps, State> {
  addingAsAdmin = false;
  editor: any;
  setEditorRef: (editor: any) => any;

  constructor(props: AllProps) {
    super(props);
    this.setEditorRef = editor => (this.editor = editor);

    this.state = {
      inputData: this.props.data,
      inputErrors: {
        title: false
      },
      validForm: false
    };
  }

  public componentDidMount() {
    let orgId = '';
    if (this.props.account.isAdmin) {
      const organizationId = this.props.match.params.organizationId;
      if (!organizationId) return;

      this.addingAsAdmin = true;
      orgId = organizationId;
      this.props.getOrganization(organizationId);
    } else {
      if (this.props.account.organizationType === OrganizationType.Retailer) {
        this.props.history.push('/app/start');
        return;
      }

      orgId = this.props.account.organizationId;
      this.props.getMyOrganization();
    }

    let catalogData = this.state.inputData;
    if (this.props.data?.uploaded) catalogData = EmptyCatalogFormData;

    const inputData = Object.assign({}, catalogData);
    inputData.organizationId = orgId;
    this.setState({ inputData });
  }

  public previousPage = () => {
    this.props.history.goBack();
  };

  public handleFormChange = (e: any) => {
    const inputData = Object.assign({}, this.state.inputData);
    inputData[e.target.name] = e.target.value;

    this.setState({ inputData });
  };

  public handleFileChange = file => {
    const inputData = Object.assign({}, this.state.inputData);
    inputData.image = file;

    this.setState({ inputData });
  };

  public async storeForm() {
    const inputErrors = Object.assign({}, this.state.inputErrors);
    let valid = true;

    Object.keys(this.state.inputData).map(key => {
      if (key in this.state.inputErrors) {
        if (this.state.inputData[key] === '' || this.state.inputData[key] === 0) {
          inputErrors[key] = true;
          valid = false;
        } else {
          inputErrors[key] = false;
        }
      }
    });

    this.setState({ inputErrors });

    if (!valid) return;

    const data = this.state.inputData;
    const imgResult = await this.editor.getResult();
    if (imgResult) {
      data.image = imgResult;
      this.handleFileChange(imgResult);
    }

    this.setState({ validForm: true });
    this.props.storeInformation(this.state.inputData);
    this.props.history.push(FileRoute.path);
  }

  public render() {
    return (
      <XContainer style={{ position: 'relative', padding: '0' }}>
        {this.addingAsAdmin && (
          <FormControl style={{ width: '100%' }}>
            <Translation>
              {t => (
                <InputLabel htmlFor="organization-select">{t('myArxis.Organization')}</InputLabel>
              )}
            </Translation>
            <Select
              value={this.props.match.params.organizationId}
              onChange={this.handleFormChange}
              name="organizationId"
              readOnly={true}
              inputProps={{
                name: 'organizationId',
                id: 'organization-select'
              }}>
              <MenuItem
                key={this.props.match.params.organizationId}
                value={this.props.match.params.organizationId}>
                {this.props.targetOrganization?.organization.name}
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <XField
          label="Catalogus titel"
          name="title"
          required={true}
          inputProps={{ maxLength: 255 }}
          value={this.state.inputData.title}
          onChange={this.handleFormChange}
          error={this.state.inputErrors.title}
        />
        {/* <XField
          label="Versie titel"
          name="versionName"
          inputProps={{maxLength: 255}}
          value={this.state.inputData.versionName}
          onChange={this.handleFormChange}
        /> */}
        <XField
          label="Omschrijving"
          name="description"
          multiline={true}
          inputProps={{ maxLength: 255 }}
          value={this.state.inputData.description}
          rows={5}
          rowsMax={5}
          onChange={this.handleFormChange}
        />
        <LogoLabel>Catalogus afbeelding</LogoLabel>
        <XImageEditor
          ref={this.setEditorRef}
          image={this.state.inputData.image}
          width={200}
          height={200}
          style={{ margin: '15px 0' }}
          onChange={blob => {
            this.handleFileChange(blob);
          }}
        />

        <XFormButtons
          style={{ padding: '0' }}
          onSave={async () => await this.storeForm()}
          onCancel={this.previousPage}
          saveText="Volgende"
        />
      </XContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    data: catalogSelects.newCatalogState(state.catalogs),
    myOrganization: state.organizations.myOrganization,
    targetOrganization: state.organizations.detail,
    account: state.authentication.account
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  storeInformation: (data: CatalogFormData) => dispatch(catalogActions.storeCatalogAction(data)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk()),
  getOrganization: (id: string) => dispatch(organizationActions.getOrganizationThunk(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardBasicPage);

const LogoLabel = styled.div`
  color: #aaa;
`;
