import styled from '@emotion/styled';
import { TextField, Tooltip, Chip, Avatar, CircularProgress } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import XOrganizationRow from 'src/components/domain/XOrganizationRow';
import XCheckbox from 'src/components/layout/XCheckbox';
import { XContainer } from 'src/components/layout/XContainer';
import XDatePicker from 'src/components/layout/XDatePicker';
import XPageContent from 'src/components/layout/XPageContent';
import {XDragAndDropFileUpload, XDragAndDropFilesUpload} from 'src/components/layout/XDragAndDropFileUpload';
import { XPaper, XPaperLabel, XPaperText, XPaperTitle } from 'src/components/layout/XPaper';
import { XRow, XRowCell } from 'src/components/layout/XRow';
import XTable, { TableRow } from 'src/components/layout/XTable';
import { ApiResult } from 'src/helpers/apiClient';
import { formatDateToString } from 'src/helpers/formatHelpers';
import {
  CatalogModel,
  ProcessingStatus,
  PublicationStatus,
  InterfaceMethod
} from 'src/models/Catalog';
import {
  InterfaceType,
  MyOrganizationModel,
  Organization,
  OrganizationInterface,
  OrganizationType
} from 'src/models/Organization';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import * as catalogActions from 'src/store/catalogs/actions';
import { EditCatalogFormData, Mapping } from 'src/store/catalogs/types';
import { fetchCatalogFile } from 'src/store/catalogs/api';
import { showConfirmation, showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import * as organizationActions from 'src/store/organizations/actions';
import * as messageAction from 'src/store/messages/actions';

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CancelIcon from '@material-ui/icons/Cancel';
import DownloadedIcon from '@material-ui/icons/CheckCircle';
import AccessIcon from '@material-ui/icons/AccessTime';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ViewIcon from '@material-ui/icons/Visibility';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PublishIcon from '@material-ui/icons/Publish';
import XErrorContent from 'src/components/domain/XErrorContent';
import XNoContent from 'src/components/domain/XNoContent';
import XOrganizationSelectPopup from 'src/components/domain/XOrganizationSelectPopup';
import XImageEditor from 'src/components/layout/XImageEditor';
import XPagination from 'src/components/layout/XPagination';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import { mapError } from 'src/helpers/errorMap';
import {
  XActionButton,
  XSecActionButton,
  XDisabledButton
} from 'src/components/layout/XActionButton';
import { XActionStrip } from 'src/components/layout/XActionStrip';
import { getProcessingStatusText, getPublicationStatusText } from 'src/constants/Catalog';
import XField from 'src/components/layout/XField';
import { FileFormat } from 'src/models/FileFormat';
import { getFileFormatsThunk } from 'src/store/catalogs/actions';
import { getCatalogMapping } from 'src/store/catalogs/api';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import { getInterfaceTypes } from 'src/constants/OrganizationInterface/OrganizationInterfaces';
import { Skeleton } from '@material-ui/lab';
import XCatalogCSVModel from '../../../components/domain/XCatalogCSVModel';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';
import { CatalogMessageModel } from '../../../models/Message';
import { string } from 'prop-types';

type StateProps = {
  catalogMessages?: CatalogMessageModel[];
  catalog?: CatalogModel;
  myOrganization?: MyOrganizationModel;
  connections: Organization[];
  myAccount: AuthenticationAccount;
  loadingCatalog: boolean;
  loadingOrganization: boolean;
  fileFormats: FileFormat[];
};

type DispatchProps = {
  getCatalogMessages: (catalogId: string) => void;
  showNotification: (message: string, type: MessageType) => void;
  getCatalogById: (id: string) => Promise<ApiResult>;
  getConnections: () => void;
  getMyOrganization: () => void;
  editCatalog: (id: string, formData: EditCatalogFormData) => Promise<ApiResult>;
  editAvailableCatalog: (
    targetOrganizationIds: string[],
    catalogId: string,
    available: boolean
  ) => Promise<ApiResult>;
  showConfirm: (title: string, message: string, onConfirm: () => void) => void;
  deleteCatalog: (id: string) => Promise<ApiResult>;
  publishCatalog: (id: string) => Promise<ApiResult>;
  getFileFormats: () => Promise<void>;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps<{ id: string }>;

type State = {
  mapping: Mapping;
  catalogVersionId: string;
  openMappingModel: boolean;
  files: any;
  fetchLoading: boolean;
  editing: boolean;
  dateLimit: boolean;
  editModel: EditCatalogFormData;
  search: string;
  currentOffset: number;
  showAddAvailablePopup: boolean;
  loaded: boolean;
  editErrors: {
    title: boolean;
    startDate: boolean;
    endDate: boolean;
  };
};

class CatalogPage extends React.Component<AllProps, State> {
  editor: any;
  setEditorRef: (editor: any) => any;

  constructor(props: AllProps) {
    super(props);
    this.setEditorRef = editor => (this.editor = editor);

    this.state = {
      mapping: {
        Delimiter: '',
        MappingItems: []
      },
      catalogVersionId: '',
      openMappingModel: false,
      files: null,
      fetchLoading: false,
      editing: false,
      dateLimit:
        this.props.catalog?.versions[0]?.startDate !== undefined ||
        this.props.catalog?.versions[0]?.endDate !== undefined,
      editModel: this.mapEditModel(),
      search: '',
      showAddAvailablePopup: false,
      currentOffset: 0,
      loaded: false,
      editErrors: {
        title: false,
        startDate: false,
        endDate: false
      }
    };
  }

  public mapEditModel() {
    if (!this.props.catalog)
      return { title: '', description: '', imageURL: '', image: undefined, version: '' };

    return {
      title: this.props.catalog.catalog.title,
      description: this.props.catalog.catalog.description,
      imageURL: this.props.catalog.catalog.imageURL,
      image: undefined,
      version: this.props.catalog.versions[0]?.version,
      startDate:
        this.props.catalog.versions[0]?.endDate === undefined
          ? undefined
          : new Date(this.props.catalog.versions[0]?.startDate),
      endDate:
        this.props.catalog.versions[0]?.endDate === undefined
          ? undefined
          : new Date(this.props.catalog.versions[0]?.endDate)
    };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.catalog !== this.props.catalog) this.setState({ editModel: this.mapEditModel() });
  }

  public componentDidMount() {
    if (this.props.myAccount.organizationType === OrganizationType.Retailer) {
      this.props.history.push('/app/start');
    }

    this.props.getCatalogById(this.props.match.params.id).then(apiResult => {
      if (!apiResult.IsSuccess) {
        if (apiResult.StatusCode === 401)
          // Not authorized for this catalog
          this.props.history.push('/app/start');
      }
      this.setState({ loaded: true });
    });

    this.props.getConnections();
    this.props.getMyOrganization();
    this.props.getFileFormats();
    this.props.getCatalogMessages(this.props.match.params.id);
  }

  public formChange(name, val) {
    const editModel = Object.assign({}, this.state.editModel);
    editModel[name] = val;

    this.setState({ editModel });
  }

  public toggleAvailability(organizations: Organization[], state: boolean) {
    if (!this.props.catalog) return;

    this.setState({ showAddAvailablePopup: false });
    this.props.showNotification('Beschikbaarheid wordt aangepast...', 'warning');
    this.props
      .editAvailableCatalog(
        organizations.map(o => o.id),
        this.props.catalog.catalog.id,
        state
      )
      .then(result => {
        if (result.IsSuccess)
          this.props.showNotification(i18n.t('notification.availabilityAdjusted'), 'success');
        else
          this.props.showNotification(
            i18n.t('notification.catalogDeleteError') + mapError(result.Errors),
            'error'
          );
      });
  }

  public toggleDateLimit(value) {
    const editModel = Object.assign({}, this.state.editModel);
    if (!value) {
      editModel.startDate = undefined;
      editModel.endDate = undefined;
    } else {
      editModel.startDate = this.props.catalog?.versions[0]?.startDate || new Date();
      editModel.endDate = this.props.catalog?.versions[0]?.endDate || new Date();
    }

    this.setState({ dateLimit: value, editModel });
  }

  public async saveEdit() {
    if (!this.props.catalog) return;

    const editErrors = Object.assign({}, this.state.editErrors);

    editErrors.title = this.state.editModel.title.length === 0;
    editErrors.endDate =
      this.state.dateLimit &&
      (this.state.editModel.startDate ?? new Date()) > (this.state.editModel.endDate ?? new Date());

    this.setState({ editErrors });

    let valid = true;
    Object.keys(editErrors).map(item => {
      if (editErrors[item] === true) {
        valid = false;
      }
    });

    const data = this.state.editModel;
    const imgResult = await this.editor.getResult();
    if (imgResult) {
      data.image = imgResult;
      this.formChange('image', imgResult);
    }

    if (!valid) return;

    this.props.editCatalog(this.props.catalog.catalog.id, data).then(result => {
      if (result.IsSuccess) {
        this.props.showNotification(i18n.t('notification.catalogAdjusted'), 'success');
        this.setState({ editing: false });
      } else
        this.props.showNotification(
          i18n.t('notification.catalogAdjustingError') + mapError(result.Errors),
          'error'
        );
    });
  }

  public publishCatalog() {
    this.props.showConfirm(
      'Catalogus publiceren',
      'Weet je zeker dat je deze catalogus wilt publiceren? Alle connecties zullen per mail op de hoogte worden gesteld van deze publicatie.',
      () => {
        this.props.publishCatalog(this.props.match.params.id).then(apiResult => {
          if (apiResult.IsSuccess) {
            this.props.showNotification('Catalogus gepubliceerd!', 'success');
            this.props.history.push(this.props.location);
          } else
            this.props.showNotification(
              'Er ging iets mis tijdens het publiceren van de catalogus: ' +
                mapError(apiResult.Errors),
              'error'
            );
        });
      }
    );
  }

  public deleteCatalog() {
    this.props.showConfirm(
      i18n.t('confirmation.catalogDelete'),
      i18n.t('confirmation.catalogDeleteDiscoverDesc'),
      () => {
        this.props.deleteCatalog(this.props.match.params.id).then(apiResult => {
          if (apiResult.IsSuccess) {
            this.props.showNotification(i18n.t('notification.catalogDeleted'), 'success');
            this.props.history.push('/app/my/catalogs');
          } else
            this.props.showNotification(
              i18n.t('notification.catalogDeleteError') + mapError(apiResult.Errors),
              'error'
            );
        });
      }
    );
  }

  public cancelEdit() {
    this.formChange('image', undefined);
    this.setState({ editing: false });
  }

  public downloadValidationFile() {
    const link = document.createElement('a');
    link.href = this.props.catalog?.catalog.validationFileUrl ?? '';
    link.click();
  }

  public downloadValidation(validationFileUrl: string) {
    if (validationFileUrl && validationFileUrl?.trim() != '') {
      const link = document.createElement('a');
      link.href = validationFileUrl;
      link.click();
    }
  }

  public downloadConversionFile(conversionFileUrl: string) {
    if (conversionFileUrl && conversionFileUrl?.trim() != '') {
      const link = document.createElement('a');
      link.href = conversionFileUrl;
      link.click();
    }
  }

  private getFileFormat(fileFormat: string, fileFormatVersion: string) {
    const format = this.props.fileFormats.find(f => f.id === fileFormat);
    if (!format) return '';

    const version = format.versions.find(v => v.id === fileFormatVersion);
    return format.name + '.' + version?.name;
  }

  public getInterface(t: InterfaceType): OrganizationInterface | undefined {
    return this.props.myOrganization?.interfaces.find(x => x.type === t);
  }

  public handleFileChange = (files: any) => {
    this.setState({ files });
  };

  public fetchNewCatalogVersion = async () => {
    this.setState({ fetchLoading: true });
    console.log("fetch catalog");
    console.log(this.state.files);
    const apiResult = await fetchCatalogFile(this.props.catalog?.catalog.id, this.state.files);
    if (apiResult.IsSuccess) {
      this.setState({ files: null });
      this.props.showNotification('Catalog uploaded', 'success');
      this.props.getCatalogById(this.props.match.params.id);
    } else
      this.props.showNotification(
        'Something went wrong while uploading the catalog: ' + mapError(apiResult.Errors),
        'error'
      );
    this.setState({ fetchLoading: false });
  };

  public getCatalogMappingById = async (versionId: string) => {
    const apiResult = await getCatalogMapping(this.props.catalog?.catalog.mappingId);
    if (apiResult.IsSuccess) {
      this.setState({ mapping: JSON.parse(apiResult.Data.mapping) });
      this.setState({ catalogVersionId: versionId });
      this.setState({ openMappingModel: true });
    }
  };

  public render() {
    const props = this.props;
    if (props.loadingCatalog || props.loadingOrganization || !this.state.loaded)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px', position: 'relative' }}>
            <XContainer style={{ height: '100%', position: 'relative' }}>
              <XRow
                style={{
                  height: '100%'
                }}>
                <XRowCell>
                  <XRow>
                    <XContainer>
                      <Skeleton variant="rect" width={150} height={150} />
                    </XContainer>
                    <XRowCell>
                      <XContainer>
                        <XPaperTitle>
                          <Skeleton width={200} />
                        </XPaperTitle>
                        <XPaperTitle>
                          <Skeleton width={200} />
                        </XPaperTitle>
                      </XContainer>
                    </XRowCell>
                    <XRowCell>
                      <XContainer>
                        <XPaperTitle>
                          <Skeleton width={150} />
                        </XPaperTitle>
                        <XPaperTitle>
                          <Skeleton width={150} />
                        </XPaperTitle>
                      </XContainer>
                    </XRowCell>
                    <XRowCell>
                      <XContainer>
                        <XPaperTitle>
                          <Skeleton width={150} />
                        </XPaperTitle>
                        <XPaperTitle>
                          <Skeleton width={150} />
                        </XPaperTitle>
                      </XContainer>
                    </XRowCell>
                  </XRow>
                </XRowCell>
              </XRow>
            </XContainer>
          </XPaper>
        </XPageContent>
      );
    else if (!props.catalog || !props.myOrganization)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <XErrorContent />
          </XPaper>
        </XPageContent>
      );

    const latestVersion = props.catalog.versions?.sort(v => new Date(v.creationDate).getTime())[
      props.catalog.versions.length - 1
    ];
    const makeAvailableOrganizations = props.connections.filter(
      c =>
        props.myOrganization?.catalogConnections.find(
          dc => dc.targetOrganizationId === c.id && dc.catalogId === this.props.catalog?.catalog.id
        )?.active !== true
    );

    let data: any = [];
    const connections = props.myOrganization.catalogConnections.filter(
      dc => dc.catalogId === this.props.catalog?.catalog.id && dc.active
    );
    console.log('dataConnections', connections);
    console.log('connections', props.connections);

    for (const dc of connections)
      data.push({
        dataConnection: dc,
        organization: props.connections.find(c => c.id === dc.targetOrganizationId)
      });
    
    data = data.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
    data = data.filter(
      d => d.organization.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    );
    const pageSize = 10;
    const paged = data.slice(this.state.currentOffset, this.state.currentOffset + pageSize);

    // Show publish button if catalog is currently unpublished but validated
    const publicationStatus = this.props.catalog?.catalog.publicationStatus;
    const processingStatus = this.props.catalog?.catalog.processingStatus;

    const catalogIsPublished = publicationStatus === PublicationStatus.Published;
    const catalogIsValid =
      processingStatus === ProcessingStatus.ValidatedWithWarnings ||
      processingStatus === ProcessingStatus.Validated;

    const showPublishButton = !catalogIsPublished && catalogIsValid;

    // Show download validation summary button if url is not empty
    const showDownloadValidationFileButton =
      this.props.catalog?.catalog.validationFileUrl &&
      this.props.catalog?.catalog.validationFileUrl?.trim() != '';

    const interfaces = getInterfaceTypes(this.props.myOrganization?.organization.organizationType);
    console.log(`Interfaces: ${interfaces}`);

    const fileUploadMethod = props.catalog?.interfaces.filter(
      i => i.catalogId === props.catalog?.catalog.id
    )[0];

    const catalogVersions = this.props.catalog?.versions.sort(
      (a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    ).slice(0, 2);

    return (
      <ErrorBoundary>
        <XPageContent>
          <XPaper style={{ minHeight: '300px', position: 'relative' }}>
            <XPaperHeader
              header="Catalog"
              customContent={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {!catalogIsPublished && (
                    <div style={{ marginRight: '2rem' }}>
                      <XActionButton onClick={() => this.publishCatalog()}>Publiceer</XActionButton>
                    </div>
                  )}
                  <ActionButtons>
                    {this.state.editing ? (
                      <Translation>
                        {t => (
                          <div style={{ verticalAlign: 'middle' }}>
                            <Tooltip title={t('tooltip.Cancel') + ' '}>
                              <ClearIcon
                                onClick={() => this.cancelEdit()}
                                style={{ marginRight: '10px', verticalAlign: 'middle' }}
                              />
                            </Tooltip>
                            <Tooltip title={t('tooltip.Save') + ' '}>
                              <SaveIcon
                                onClick={async () => await this.saveEdit()}
                                style={{ verticalAlign: 'middle' }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </Translation>
                    ) : (
                      <Translation>
                        {t => (
                          <div style={{ verticalAlign: 'middle' }}>
                            <Tooltip title={t('tooltip.viewInStore') + ' '}>
                              <Link to={'/app/my/catalogs/' + this.props.match.params.id}>
                                <ViewIcon
                                  style={{ marginRight: '10px', verticalAlign: 'middle' }}
                                />
                              </Link>
                            </Tooltip>
                            <Tooltip title={t('tooltip.adjustCatalog') + ' '}>
                              <EditIcon
                                onClick={() => this.setState({ editing: true })}
                                style={{ marginRight: '10px', verticalAlign: 'middle' }}
                              />
                            </Tooltip>
                            <Tooltip title={t('tooltip.deleteCatalog') + ' '}>
                              <DeleteIcon
                                onClick={() => this.deleteCatalog()}
                                style={{ verticalAlign: 'middle' }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </Translation>
                    )}
                  </ActionButtons>
                </div>
              }
            />
            <XContainer style={{ height: '100%', position: 'relative' }}>
              <XRow
                style={{
                  height: '100%'
                }}>
                <XRowCell>
                  <XRow>
                    <XContainer>
                      <XImageEditor
                        editable={this.state.editing}
                        image={
                          this.state.editModel?.image
                            ? this.state.editModel.image
                            : this.state.editModel?.imageURL
                        }
                        style={{ minWidth: '150px', maxWidth: '150px' }}
                        ref={this.setEditorRef}
                        onChange={blob => {
                          this.formChange('image', blob);
                        }}
                      />
                    </XContainer>
                    <XRowCell>
                      <XContainer>
                        <XPaperTitle>
                          {this.state.editing ? (
                            <Translation>
                              {t => (
                                <TextField
                                  error={this.state.editErrors.title}
                                  placeholder={t('catalogs.catalogTitle')}
                                  name="title"
                                  style={{ width: '100%' }}
                                  value={this.state.editModel.title}
                                  onChange={e => this.formChange(e.target.name, e.target.value)}
                                />
                              )}
                            </Translation>
                          ) : (
                            props.catalog.catalog.title
                          )}
                        </XPaperTitle>
                        <XPaperText>
                          {this.state.editing ? (
                            <Translation>
                              {t => (
                                <TextField
                                  multiline={true}
                                  rows={5}
                                  name="description"
                                  placeholder={t('catalogs.catalogDescription')}
                                  style={{ width: '100%', height: '70px' }}
                                  value={this.state.editModel.description}
                                  onChange={e => this.formChange(e.target.name, e.target.value)}
                                />
                              )}
                            </Translation>
                          ) : (
                            <div style={{ maxHeight: '92px', overflow: 'hidden' }}>
                              {props.catalog.catalog.description}
                            </div>
                          )}
                        </XPaperText>
                      </XContainer>
                    </XRowCell>
                    <XRowCell>
                      <XContainer>
                        <XPaperLabel>Publicatiestatus:</XPaperLabel>
                        <XPaperText>
                          {getPublicationStatusText(props.catalog.catalog.publicationStatus)}
                        </XPaperText>
                        {catalogIsPublished && (
                          <>
                            <XPaperLabel>Publicatie datum:</XPaperLabel>
                            <XPaperText>
                              {props.catalog.catalog.publicationDate === undefined
                                ? '-'
                                : formatDateToString(props.catalog.catalog.publicationDate)}
                            </XPaperText>{' '}
                          </>
                        )}
                      </XContainer>
                    </XRowCell>
                    <XRowCell>
                      <XContainer>
                        {/* <VersionRow>
                      {this.state.editing 
                          ? <TextField error={this.state.editErrors.version} placeholder="Versie titel" name="version" style={{width: "100%"}} value={this.state.editModel.version} onChange={(e) => this.formChange(e.target.name, e.target.value)} /> 
                          : latestVersion.version}
                    </VersionRow> */}
                        {this.state.editing ? (
                          <div>
                            <Translation>
                              {t => (
                                <XCheckbox
                                  label={t('catalogs.availableInPeriod')}
                                  checked={this.state.dateLimit}
                                  onChange={e => this.toggleDateLimit(e.target.checked)}
                                />
                              )}
                            </Translation>
                            {this.state.dateLimit && (
                              <span>
                                van
                                <XDatePicker
                                  error={this.state.editErrors.startDate}
                                  name="startDate"
                                  value={this.state.editModel.startDate || new Date()}
                                  format="d MMM yyyy"
                                  onChange={e => this.formChange('startDate', e.toDate())}
                                />
                                tot
                                <XDatePicker
                                  error={this.state.editErrors.endDate}
                                  name="endDate"
                                  format="d MMM yyyy"
                                  value={this.state.editModel.endDate || new Date()}
                                  onChange={e => this.formChange('endDate', e.toDate())}
                                />
                              </span>
                            )}
                          </div>
                        ) : (
                          <div>
                            {(latestVersion?.startDate !== undefined ||
                              latestVersion?.endDate !== undefined) && (
                              <VersionRow>
                                <AccessIcon style={{ color: '#969696', verticalAlign: 'middle' }} />
                                <XPaperLabel>
                                  van {formatDateToString(latestVersion?.startDate)} tot{' '}
                                  {formatDateToString(latestVersion?.endDate)}
                                </XPaperLabel>
                              </VersionRow>
                            )}
                            {latestVersion?.endDate !== undefined &&
                              new Date(latestVersion?.endDate).getTime() < new Date().getTime() && (
                                <WarningVersionRow>
                                  Let op; deze catalogi is niet meer beschikbaar
                                </WarningVersionRow>
                              )}
                          </div>
                        )}
                        <VersionRow>
                          <GetAppIcon style={{ color: '#969696', verticalAlign: 'middle' }} />
                          <XPaperLabel>{props.catalog?.catalog.downloads} downloads</XPaperLabel>
                        </VersionRow>
                        <div style={{ marginTop: '2rem', fontWeight: 'bold' }}>Format:</div>
                        <div>
                          {this.getFileFormat(
                            this.props.catalog?.catalog.fileFormatId || '',
                            this.props.catalog?.catalog.fileFormatVersionId || ''
                          )}
                        </div>
                        {this.props.catalog?.catalog?.mappingId &&
                          !!this.props.catalog?.interfaces.length &&
                          !!this.props.catalog?.catalog.currentVersionId &&
                          this.props.fileFormats.find(
                            f => f.id === this.props.catalog?.catalog.fileFormatId
                          )?.name === 'CSV' && (
                            <>
                              <div style={{ marginTop: '2rem', fontWeight: 'bold' }}>Mapping</div>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.getCatalogMappingById('')}>
                                Edit catalog mapping
                              </div>
                            </>
                          )}
                      </XContainer>
                    </XRowCell>
                  </XRow>
                </XRowCell>
              </XRow>
            </XContainer>
          </XPaper>
          <XPaper style={{ marginTop: '15px' }}>
            <XPaperHeader
              header="Versies"
              customContent={
                fileUploadMethod?.method !== InterfaceMethod.Manual &&
                (!this.state.fetchLoading ? (
                  <XActionButton onClick={this.fetchNewCatalogVersion}>
                    Load new version
                  </XActionButton>
                ) : (
                  <XDisabledButton>
                    Loading..{' '}
                    <CircularProgress
                      style={{ height: '19px', width: '19px', marginLeft: '10px' }}
                    />
                  </XDisabledButton>
                ))
              }
            />
            {/* Make editing of this version friendly */}
            <XTable headers={['Versie', 'Aanmaakdatum', 'Verwerkingsstatus', 'Instellingen']}>
              {!!catalogVersions?.length &&
                catalogVersions.map(v => (
                  <StyledTableRow key={v.id}>
                    <XRowCell>{v.version}</XRowCell>
                    <XRowCell>{formatDateToString(v.creationDate)}</XRowCell>
                    <XRowCell>
                      {v.processingStatus === ProcessingStatus.WaitingForMapping && (
                        <Chip
                          label={getProcessingStatusText(v.processingStatus)}
                          clickable
                          color="primary"
                          onClick={() => this.getCatalogMappingById(v.id)}
                        />
                      )}
                      {[
                        ProcessingStatus.Fetching,
                        ProcessingStatus.Converting,
                        ProcessingStatus.Validating,
                        ProcessingStatus.Promoting
                      ].includes(v.processingStatus) && (
                        <Chip
                          color="primary"
                          avatar={
                            <Avatar>
                              <XPulseDots>
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                              </XPulseDots>
                            </Avatar>
                          }
                          label={getProcessingStatusText(v.processingStatus)}
                        />
                      )}
                      {[
                        ProcessingStatus.Fetched,
                        ProcessingStatus.Converted,
                        ProcessingStatus.Validated,
                        ProcessingStatus.Promoted
                      ].includes(v.processingStatus) && (
                        <XSuccessChip>
                          <Chip
                            avatar={
                              <Avatar>
                                <CheckIcon />
                              </Avatar>
                            }
                            label={getProcessingStatusText(v.processingStatus)}
                          />
                        </XSuccessChip>
                      )}
                      {v.processingStatus === ProcessingStatus.ValidatedWithWarnings && (
                        <XSuccessChip>
                          <Chip
                            avatar={
                              <Avatar>
                                <ErrorOutlineIcon />
                              </Avatar>
                            }
                            clickable
                            label={getProcessingStatusText(v.processingStatus)}
                            color="secondary"
                            onClick={() => this.downloadValidation(v.validationFileUrl)}
                          />
                        </XSuccessChip>
                      )}
                      {[ProcessingStatus.FetchingFailed, ProcessingStatus.PromotingFailed].includes(
                        v.processingStatus
                      ) && (
                        <Chip
                          avatar={
                            <Avatar>
                              <ErrorOutlineIcon />
                            </Avatar>
                          }
                          label={getProcessingStatusText(v.processingStatus)}
                          color="secondary"
                        />
                      )}
                      {v.processingStatus === ProcessingStatus.ConvertingFailed && (
                        <Chip
                          avatar={
                            <Avatar>
                              <ErrorOutlineIcon />
                            </Avatar>
                          }
                          clickable
                          label={getProcessingStatusText(v.processingStatus)}
                          color="secondary"
                          onClick={() => this.downloadConversionFile(v.conversionFileUrl)}
                        />
                      )}
                      {v.processingStatus === ProcessingStatus.ValidatingFailed && (
                        <Chip
                          avatar={
                            <Avatar>
                              <ErrorOutlineIcon />
                            </Avatar>
                          }
                          clickable
                          label={getProcessingStatusText(v.processingStatus)}
                          color="secondary"
                          onClick={() => this.downloadValidation(v.validationFileUrl)}
                        />
                      )}
                      {v.processingStatus === ProcessingStatus.MappingFailed && (
                        <Chip
                          avatar={
                            <Avatar>
                              <ErrorOutlineIcon />
                            </Avatar>
                          }
                          label={getProcessingStatusText(v.processingStatus)}
                          color="secondary"
                        />
                      )}
                      {v.processingStatus === ProcessingStatus.None && (
                        <Chip
                          avatar={
                            <Avatar>
                              <XPulseDots>
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                              </XPulseDots>
                            </Avatar>
                          }
                          label={getProcessingStatusText(v.processingStatus)}
                        />
                      )}
                    </XRowCell>
                    <XRowCell></XRowCell>
                  </StyledTableRow>
                ))}
            </XTable>

            {props.catalog?.interfaces[0]?.method === 'Manual' &&
              this.props.fileFormats.find(f => f.id === this.props.catalog?.catalog.fileFormatId)?.name === 'CSV' &&(
              <div style={{ marginTop: '25px' }}>
                <XDragAndDropFilesUpload handleFileChange={this.handleFileChange} />
              </div>
            )}

            {props.catalog?.interfaces[0]?.method === 'Manual' &&
              this.props.fileFormats.find(f => f.id === this.props.catalog?.catalog.fileFormatId)?.name !== 'CSV' &&(
              <div style={{ marginTop: '25px' }}>
                <XDragAndDropFileUpload handleFileChange={this.handleFileChange} />
              </div>
            )}

            {this.state.files?.length !== 0 && this.state.files && (
              <div style={{ padding: '15px' }}>
                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                  <div style={{ marginRight: '10px' }}>{this.state.files?.map((files) => {return `${files.name} \n`})}</div>
                  <HighlightOffIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.setState({ files: null })}
                  />
                </div>
                {!this.state.fetchLoading ? (
                  <XActionButton onClick={this.fetchNewCatalogVersion}>Upload</XActionButton>
                ) : (
                  <XDisabledButton>
                    Uploading..{' '}
                    <CircularProgress
                      style={{ height: '19px', width: '19px', marginLeft: '10px' }}
                    />
                  </XDisabledButton>
                )}
              </div>
            )}
          </XPaper>

          <XPaper style={{ margin: '15px 0' }}>
            <XPaperHeader header="Instellingen" />
            <div style={{ padding: '0 15px' }}>
              <h6>Beheer instellingen omtrent het aanbieden van deze catalogs</h6>
              <XSecActionButton
                onClick={() =>
                  props.history.push(
                    `/app/my/catalogs/catalog-interface/${props.catalog?.catalog.id}`
                  )
                }>
                Instellingen
              </XSecActionButton>
            </div>
          </XPaper>

          <XPaper style={{ marginTop: '15px' }}>
            <Translation>
              {t => (
                <XOrganizationSelectPopup
                  title={t('catalogs.makeCatalogAvailableFor')}
                  onCancel={() => this.setState({ showAddAvailablePopup: false })}
                  open={this.state.showAddAvailablePopup}
                  clearOnClose={true}
                  organizations={makeAvailableOrganizations}
                  onSave={organizations => this.toggleAvailability(organizations, true)}
                />
              )}
            </Translation>
            <div style={{ position: 'relative' }}>
              <Translation>
                {t => (
                  <XPaperHeader
                    header={t('catalogs.availabilty')}
                    search={true}
                    onSearch={e => this.setState({ search: e.toLowerCase() })}
                    icons={[
                      {
                        icon: AddCircleIcon,
                        toolTip: t('tooltip.add'),
                        name: 'add',
                        action: () => this.setState({ showAddAvailablePopup: true })
                      }
                    ]}
                  />
                )}
              </Translation>
              {props.connections.length === 0 ? (
                <Translation>
                  {t => (
                    <XNoContent>
                      <div style={{ marginTop: '15px' }}>
                        {t('catalogs.youDontHaveAnyRetailers')}
                      </div>
                      <div style={{ marginTop: '4px' }}>
                        <Link style={{ color: '#aaa' }} to="/app/explore/connections">
                          {t('catalogs.findInterestingRetailers')}
                        </Link>
                      </div>
                    </XNoContent>
                  )}
                </Translation>
              ) : (
                <div>
                  {paged.length === 0 ? (
                    <div style={{ padding: '15px' }}>
                      Deze catalog is niet beschikbaar voor retailers
                    </div>
                  ) : (
                    <Translation>
                      {t => (
                        <XTable
                          headers={[
                            t('myArxis.Organization'),
                            t('address.place'),
                            'Downloads',
                            t('catalogs.lastDownloaded'),
                            ''
                          ]}>
                          {paged.map(obj => {
                            return (
                              <XOrganizationRow
                                key={obj.organization.id}
                                showDelete={true}
                                showDownloads={true}
                                onDelete={() => this.toggleAvailability([obj.organization], false)}
                                dataConnection={obj.dataConnection}
                                catalogMessages={this.props.catalogMessages}
                                link={'/app/my/connections/' + obj.organization.id}
                                organization={obj.organization}
                              />
                            );
                          })}
                        </XTable>
                      )}
                    </Translation>
                  )}
                  {data.length > pageSize && (
                    <XPagination
                      itemCount={data.length}
                      itemsPerPage={pageSize}
                      changePage={i => this.setState({ currentOffset: i })}
                    />
                  )}
                </div>
              )}
            </div>
          </XPaper>

          {this.state.openMappingModel && (
            <XCatalogCSVModel
              catalogId={this.props.catalog?.catalog?.id || ''}
              catalogMappingId={this.props.catalog?.catalog?.mappingId || ''}
              catalogVersionId={this.state.catalogVersionId}
              mappingData={this.state.mapping}
              close={() => this.setState({ openMappingModel: false })}
            />
          )}
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    catalogMessages: state.messages.catalogMessages,
    myAccount: state.authentication.account,
    connections: state.organizations.connections,
    myOrganization: state.organizations.myOrganization,
    catalog: state.catalogs.data.detail,
    loadingCatalog: state.catalogs.loading,
    loadingOrganization: state.organizations.loading,
    fileFormats: state.catalogs.fileFormats
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type: MessageType) => {
    dispatch(showNotification(message, type));
  },
  getCatalogMessages: (catalogId: string) => dispatch(messageAction.getCatalogMessagesThunk(catalogId)),
  getConnections: () => dispatch(organizationActions.getOrganizationConnectionsThunk()),
  getCatalogById: (id: string) => dispatch(catalogActions.getCatalogThunk(id)),
  getMyOrganization: () => dispatch(organizationActions.getMyOrganizationThunk()),
  editCatalog: (id: string, formData: EditCatalogFormData) =>
    dispatch(catalogActions.editCatalogThunk(id, formData)),
  showConfirm: (title: string, message: string, onConfirm: () => void) =>
    dispatch(showConfirmation(title, message, onConfirm)),
  deleteCatalog: (id: string) => dispatch(catalogActions.deleteCatalogThunk(id)),
  editAvailableCatalog: (targetOrganizationIds: string[], catalogId: string, available: boolean) =>
    dispatch(catalogActions.editCatalogAvailableThunk(targetOrganizationIds, catalogId, available)),
  publishCatalog: (id: string) => dispatch(catalogActions.publishCatalogThunk(id)),
  getFileFormats: () => dispatch(getFileFormatsThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogPage);

const VersionRow = styled.div`
  padding: 4px 0;
  font-size: 14px;
`;

const WarningVersionRow = styled(VersionRow)`
  font-size: 12px;
  color: #ff9800;
`;

const ActionButtons = styled.div`
  cursor: pointer;

  & svg {
    color: rgb(245, 0, 87);
    font-weight: bold;
  }
`;

const StyledTable = styled.div`
  flex: 1;
`;

const StyledTableRow = styled(TableRow)`
  line-height: 28px;
`;

const XPulseDots = styled.div`
  margin-bottom: 2px;

  & div {
    width: 5px;
    height: 5px;
    background-color: white;

    border-radius: 2.5px;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  & .bounce1 {
    animation-delay: -0.32s;
  }

  & .bounce2 {
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const XSuccessChip = styled.div`
  .MuiChip-root {
    color: rgb(76, 175, 80, 0.87) !important;
    background-color: #4caf50 !important;
  }

  .MuiChip-root .MuiChip-avatar {
    color: white !important;
    background-color: #46a149 !important;
  }

  .MuiChip-label {
    color: white !important;
  }
`;
