import * as React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import XCatalogRow from 'src/components/domain/XCatalogRow';
import XNoContent from 'src/components/domain/XNoContent';
import XPageContent from 'src/components/layout/XPageContent';
import { XPaper } from 'src/components/layout/XPaper';
import XTable from 'src/components/layout/XTable';
import { Catalog, PublicationStatus } from 'src/models/Catalog';
import { AuthenticationAccount } from 'src/store/authentication/types';
import { ApplicationState } from '../../../store';
import * as catalogActions from '../../../store/catalogs/actions';
import { CatalogSort, getCatalogsThunk } from 'src/store/catalogs/actions';
import XPaperHeader from 'src/components/layout/XPaperHeader';
import XScrollView from 'src/components/layout/XScrollView';
import EmptyImage from '../../../style/images/empty-canvas.svg';
import { Link, RouteComponentProps } from 'react-router-dom';
import { XFilterBar } from 'src/components/layout/XFilterBar';
import XSearchBar from 'src/components/layout/XSearchBar';
import { XSecActionButton } from 'src/components/layout/XActionButton';
import { XRowCell } from 'src/components/layout/XRow';
import { ViewType } from 'src/store/layout/types';
import { LinearProgress } from '@material-ui/core';
import { Translation } from 'react-i18next';
import XCatalog from 'src/components/domain/XCatalog';
import XGrid from 'src/components/layout/XGrid';
import GridIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import { XImage, XImageContainer } from 'src/components/layout/XImage';
import { generateAvatar } from 'src/helpers/avatarGenerator';
import { formatDateToString, getImageUrl } from 'src/helpers/formatHelpers';
import { changeView } from 'src/store/layout/actions';
import ErrorBoundary from 'src/components/shared/ErrorBoundary';

type StateProps = {
  subscribedCatalogs: Catalog[];
  account: AuthenticationAccount;
  catalogs: Catalog[];
  loading: boolean;
  view: ViewType;
};

type DispatchProps = {
  getSubscribedCatalogs: () => void;
  getCatalogs(sort: CatalogSort): () => void;
  changeView(type: ViewType): () => void;
};

type State = {
  search: string;
  currentOffset: number;
  viewDownloaded: boolean;
  searchNewCatalogs: string;
};

type AllProps = StateProps & DispatchProps & RouteComponentProps<{}>;

class CatalogsPageRetailer extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      search: '',
      currentOffset: 0,
      viewDownloaded: false,
      searchNewCatalogs: ''
    };
  }

  public componentWillMount() {
    this.props.getSubscribedCatalogs();
    this.props.getCatalogs('all');
  }

  public switchView(view) {
    this.props.changeView(view);
  }

  public handleScrollBottom() {
    if (this.state.currentOffset >= this.props.catalogs.length) return;
    this.setState({ currentOffset: this.state.currentOffset + 30 });
  }

  public render() {
    if (this.props.loading)
      return (
        <XPageContent>
          <XPaper style={{ minHeight: '300px' }}>
            <LinearProgress />
          </XPaper>
        </XPageContent>
      );

    const filtered = this.props.subscribedCatalogs.filter(
      c =>
        c.title.toLowerCase().indexOf(this.state.search) !== -1 ||
        c.organizationName.toLowerCase().indexOf(this.state.search) !== -1
    );

    const pageSize = 1000;
    const catalogs = this.props.catalogs
      .filter(
        c =>
          this.state.searchNewCatalogs.length === 0 ||
          c.title.toLowerCase().indexOf(this.state.searchNewCatalogs.toLowerCase()) !== -1
      )
      .filter(c => this.state.viewDownloaded || !c.downloaded)
      // Only show the actual published catalogs of suppliers
      .filter(c => c.publicationStatus == PublicationStatus.Published);
    const paged = catalogs.slice(0, this.state.currentOffset + pageSize);

    return (
      <ErrorBoundary>
        <XPageContent>
          <StyledPaper
            style={{ height: catalogs.length === 0 ? '300px' : '', marginBottom: '2rem' }}>
            <Translation>
              {t => (
                <XPaperHeader
                  header={t('myArxis.myCatalogs')}
                  count={catalogs.length}
                  subheader={t('catalogs.newAvailableCatalogs')}
                />
              )}
            </Translation>
            {this.props.catalogs.length !== 0 && (
              <XFilterBar style={{ paddingBottom: '15px' }}>
                <div style={{ flex: 1, paddingRight: '5px' }}>
                  <Translation>
                    {t => (
                      <XSearchBar
                        placeholder={`Filter ${t('common.catalogs')}`}
                        onChange={e =>
                          this.setState({ searchNewCatalogs: e.target.value.toLowerCase() })
                        }></XSearchBar>
                    )}
                  </Translation>
                </div>
                <div style={{ paddingRight: '5px', display: 'flex' }}></div>
                <Translation>
                  {t => (
                    <XSecActionButton
                      onClick={() => this.switchView(this.props.view === 'list' ? 'grid' : 'list')}>
                      {this.props.view === 'list' ? (
                        <GridIcon style={{ marginRight: '5px' }} />
                      ) : (
                        <ListIcon style={{ marginRight: '5px' }} />
                      )}
                      {this.props.view === 'list' ? 'Grid' : t('organizations.List')}
                    </XSecActionButton>
                  )}
                </Translation>
              </XFilterBar>
            )}

            {this.props.catalogs.length !== 0 && paged.length === 0 && (
              <Translation>
                {t => <XNoContent message={t('catalogs.noCatalogsFound')} />}
              </Translation>
            )}
            {!this.state.search.length && this.props.catalogs.length === 0 && (
              <Translation>
                {t => (
                  <XNoContent
                    image={EmptyImage}
                    message={t('catalogs.dnthSubscriptions')}></XNoContent>
                )}
              </Translation>
            )}
            <XScrollView onScrollBottom={() => this.handleScrollBottom()}>
              {this.props.view === 'grid' ? (
                <XGrid
                  columns={3}
                  style={{
                    padding: '0'
                  }}>
                  {paged.map(dp => (
                    <XCatalog key={dp.id} catalog={dp} size="small" />
                  ))}
                </XGrid>
              ) : (
                <div style={{ padding: '15px' }}>
                  {paged.map(c => (
                    <CatalogRow key={c.id}>
                      <CatalogImage>
                        <XImageContainer width={60} height={60}>
                          <XImage
                            src={getImageUrl(c.imageURL, c.organizationImageURL)}
                            alt=""
                            onError={(e: any) => {
                              e.target.onError = null;
                              e.target.src = generateAvatar(c.title, 60);
                            }}
                          />
                        </XImageContainer>
                      </CatalogImage>
                      <CatalogTitle>
                        <Link to={'/app/explore/catalogs/' + c.id}>{c.title}</Link>
                      </CatalogTitle>
                      <CatalogCell>
                        <Link to={'/app/connections/organization/' + c.organizationId}>
                          {c.organizationName}
                        </Link>
                      </CatalogCell>
                      <CatalogCell>{c.downloads} downloads</CatalogCell>
                      <CatalogCell>{formatDateToString(c.lastCreationDate)}</CatalogCell>
                    </CatalogRow>
                  ))}
                </div>
              )}
            </XScrollView>
          </StyledPaper>
          <XPaper
            style={{
              minHeight: '500px',
              height: this.props.subscribedCatalogs.length === 0 ? '400px' : ''
            }}>
            <Translation>
              {t => (
                <XPaperHeader
                  header={t('catalogs.alreadyDownloadedCatalogs')}
                  count={filtered.length}
                />
              )}
            </Translation>
            {this.props.subscribedCatalogs && (
              <>
                <XFilterBar>
                  <XRowCell>
                    <Translation>
                      {t => (
                        <XSearchBar
                          placeholder={`Filter ${t('common.catalogs')}`}
                          onChange={e =>
                            this.setState({ search: e.target.value.toLowerCase() })
                          }></XSearchBar>
                      )}
                    </Translation>
                  </XRowCell>
                </XFilterBar>
                <XScrollView>
                  <Translation>
                    {t => (
                      <XTable
                        headers={[
                          t('common.Catalogs'),
                          t('common.supplier'),
                          t('catalogs.Published')
                        ]}>
                        {filtered.map(obj => (
                          <XCatalogRow
                            key={obj.id}
                            link={'/app/my/catalogs/' + obj.id}
                            catalog={obj}
                            showPublicationDate
                          />
                        ))}
                      </XTable>
                    )}
                  </Translation>
                </XScrollView>
              </>
            )}

            {filtered.length === 0 && (
              <XNoContent image={EmptyImage}>
                {this.state.search.length === 0 && (
                  <Translation>
                    {t => (
                      <div>
                        <h3>{t('catalogs.dnthSubscriptions')}</h3>
                        <div style={{ textAlign: 'left', marginTop: '30px' }}></div>
                      </div>
                    )}
                  </Translation>
                )}
                {this.state.search.length !== 0 && (
                  <div>
                    <Translation>{t => <h3> {t('catalogs.noCatalogsSub')} </h3>}</Translation>
                  </div>
                )}
              </XNoContent>
            )}
          </XPaper>
        </XPageContent>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account,
    subscribedCatalogs: state.catalogs.data.subscribed.sort((a, b) =>
      a.organizationName.localeCompare(b.organizationName)
    ),
    catalogs: (state.catalogs.data.all || []),
    loading: state.catalogs.data.all === undefined,
    view: state.layout.viewCatalogs
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getSubscribedCatalogs: () => dispatch(catalogActions.getSubscribedCatalogsThunk()),
  getCatalogs: (sort: CatalogSort) => dispatch(getCatalogsThunk(sort)),
  changeView: (type: ViewType) => dispatch(changeView('catalogs', type))
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogsPageRetailer);

const StyledPaper = styled(XPaper)`
  min-height: 300px;
`;

const CatalogRow = styled.div`
  display: flex;
  margin: 4px 0;
`;

const CatalogImage = styled.div`
  max-width: 75px;
  flex: 1;
`;

const CatalogCell = styled.div`
  flex: 1;
  font-size: 14px;
  padding-top: 4px;
`;

const CatalogTitle = styled(CatalogCell)`
  padding-left: 15px;
  font-weight: bold;
`;
