import {
  MyOrganizationModel,
  Organization,
  ConnectionIdentifier,
  OrganizationIdentifier,
  OrganizationManageModel,
  OrganizationModel,
  OrganizationAccessTokenModel
} from '../../models/Organization';
import { OrganizationFormData, RegisterAccountFormData } from '../accounts/types';

export enum ActionTypes {
  GET_ORGANIZATION = '[organization] GET_ORGANIZATION',
  GET_MANAGE_ORGANIZATION = '[organization] GET_MANAGE_ORGANIZATION',
  GET_MY_ORGANIZATION = '[organization] GET_MY_ORGANIZATION',
  GET_ORGANIZATION_IDENTIFIERS = '[organization] GET_ORGANIZATION_IDENTIFIERS',
  GET_ORGANIZATIONS = '[organization] GET_ORGANIZATIONS',
  GET_ORGANIZATION_CONNECTIONS = '[organization] GET_ORGANIZATION_CONNECTIONS',
  GET_CONNECTION_IDENTIFIERS = '[organization] GET_CONNECTION_IDENTIFIERS',
  CONSUME_DATA_CONNECTION = '[organization] CONSUME_DATA_CONNECTION',
  EDIT_ORGANIZATION = '[organization] EDIT_ORGANIZATION',
  STORE_FORM = '[organization] STORE_FORM',
  ADD_ORGANIZATION = '[organization] ADD_ORGANIZATION',
  ADD_ORGANIZATION_IDENTIFIER = '[organization] ADD_ORGANIZATION_IDENTIFIER',
  ADD_CONNECTION_IDENTIFIER = '[organization] ADD_CONNECTION_IDENTIFIER',
  DELETE_ORGANIZATION = '[organization] DELETE_ORGANIZATION',
  DELETE_ORGANIZATION_CONNECTION = '[organization] DELETE_ORGANIZATION_CONNECTION',
  DELETE_ORGANIZATION_IDENTIFIER = '[organization] DELETE_ORGANIZATION_IDENTIFIER',
  DELETE_CONNECTION_IDENTIFIER = '[organization] DELETE_CONNECTION_IDENTIFIER',
  SAVE_ORDER_INTERFACE_SETTINGS = '[organization] SAVE_ORDER_INTERFACE_SETTINGS',
  SAVE_ORDER_FTP_SETTINGS = '[organization] SAVE_ORDER_FTP_SETTINGS',
  GET_UNCONFIGURED_FTP_ORGANIZATIONS = '[organization] GET_UNCONFIGURED_FTP_ORGANIZATIONS',
  VALIDATE_FTP_ACCESS = '[organization] VALIDATE_FTP_ACCESS',
  VALIDATE_SFTP_ACCESS = '[organization] VALIDATE_SFTP_ACCESS',
  DELETE_ORDER_INTERFACE = '[organization] DELETE_ORDER_INTERFACE',
  GET_ORGANIZATION_ACCESS_TOKEN = '[organization] GET_ORGANIZATION_ACCESS_TOKEN',
  DELETE_ORGANIZATION_ACCESS_TOKEN = '[organization] DELETE_ORGANIZATION_ACCESS_TOKEN'
}

export interface OrganizationState {
  loading: boolean;
  errors?: string;
  myOrganization?: MyOrganizationModel;
  myIdentifiers: OrganizationIdentifier[];
  all: Organization[] | undefined;
  detail?: OrganizationModel;
  manageDetails?: OrganizationManageModel;
  connections: Organization[];
  connectionIdentifiers: ConnectionIdentifier[];
  unconfiguredFtp: Organization[];
  formData: RegisterAccountFormData;
  accessToken?: OrganizationAccessTokenModel;
}
