import * as React from 'react';
import { connect } from 'react-redux';
import XNavigation, { ILink } from 'src/components/layout/XNavigation';
import { XSideBar } from 'src/components/layout/XSideBar';
import { OrganizationType } from 'src/models/Organization';
import { ApplicationState } from 'src/store';
import { AuthenticationAccount } from 'src/store/authentication/types';
import i18n from 'src/i18n';

type StateProps = {
  account: AuthenticationAccount;
};

type AllProps = StateProps;

class StartSideBar extends React.Component<AllProps> {
  public render() {
    const routes: ILink[] = [];
    if (this.props.account.organizationType === OrganizationType.Retailer) {
      routes.push(
        {
          name: i18n.t('orders.actual'),
          route: '/app/dealer-portal',
          defaultActive: true
        },
        {
          name: i18n.t('orders.history'),
          route: '/app/dealer-portal/order-history',
          defaultActive: false
        }
      );
    }
    return (
      routes.length > 1 && (
        <XSideBar>
          <XNavigation routes={routes} callback={''} />
        </XSideBar>
      )
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    account: state.authentication.account
  };
};

export default connect(mapStateToProps, {})(StartSideBar);
