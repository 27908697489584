import styled from '@emotion/styled';
import * as React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import XForm from 'src/components/layout/XForm';
import XFormButtons from 'src/components/layout/XFormButtons';
import { Countries } from 'src/constants/Countries';
import { OrganizationType } from 'src/models/Organization';
import { showNotification } from 'src/store/layout/actions';
import { MessageType } from 'src/store/layout/types';
import { ApplicationState } from '../../store';
import * as accountActions from '../../store/accounts/actions';
import * as accountSelects from '../../store/accounts/selectors';
import { RegisterAccountFormData } from '../../store/accounts/types';
import { AdditionalInfoRoute } from './RegisterRouter';

type StateProps = { data: RegisterAccountFormData };

type DispatchProps = {
  storeInformation: (data: RegisterAccountFormData) => void;
  showNotification: (message: string, type: MessageType) => void;
};

type OwnProps = {};

type AllProps = StateProps & DispatchProps & OwnProps & RouteComponentProps;

type State = {
  formData: RegisterAccountFormData;
  inputErrors: any;
  waitingRegistration: boolean;
};

class RegisterOrganizationPage extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);

    this.state = {
      waitingRegistration: false,
      formData: props.data,
      inputErrors: {
        organizationType: false,
        name: false,
        street: false,
        houseNumber: false,
        postalCode: false,
        city: false,
        country: false
      }
    };
  }

  public componentDidMount() {
    if (!this.state.formData.email || this.state.formData.email === '')
      this.props.history.push('/register');
  }

  public handleFormChange = (name: string, value: string) => {
    const formData = Object.assign({}, this.state.formData);
    formData[name] = value;
    this.setState({ formData });
  };

  public previousPage = () => {
    this.props.history.goBack();
  };

  public storeForm = () => {
    const inputErrors = Object.assign({}, this.state.inputErrors);
    let valid = true;
    Object.keys(this.state.formData).map(key => {
      if (key in this.state.inputErrors) {
        if (
          !this.state.formData[key] ||
          this.state.formData[key] === '' ||
          this.state.formData[key] === 0
        ) {
          inputErrors[key] = true;
          valid = false;
        } else {
          inputErrors[key] = false;
        }
      }
    });

    this.setState({ inputErrors });
    this.props.storeInformation(this.state.formData);

    if (valid) {
      this.props.history.push(AdditionalInfoRoute.path);
    }
  };

  public render() {
    return (
      <Translation>
        {t => (
          <div>
            <XForm
              data={this.state.formData}
              margin={false}
              inputErrors={this.state.inputErrors}
              fields={[
                {
                  title: t('account.orgType'),
                  name: 'organizationType',
                  required: true,
                  type: 'select',
                  options: [
                    { name: t('common.supplier'), value: OrganizationType.Supplier },
                    { name: 'Retailer', value: OrganizationType.Retailer }
                  ]
                },
                { title: t('account.companyName'), name: 'name', required: true, maxLength: 255 },
                {
                  title: t('address.street'),
                  name: 'street',
                  fullWidth: false,
                  required: true,
                  maxLength: 255
                },
                {
                  title: t('address.houseNumber'),
                  name: 'houseNumber',
                  fullWidth: false,
                  required: true,
                  maxLength: 255
                },
                {
                  title: 'Postcode',
                  name: 'postalCode',
                  fullWidth: false,
                  required: true,
                  maxLength: 255
                },
                {
                  title: t('address.place'),
                  name: 'city',
                  fullWidth: false,
                  required: true,
                  maxLength: 255
                },
                {
                  title: t('address.country'),
                  name: 'country',
                  required: true,
                  type: 'select',
                  options: Countries
                },
                {
                  title: t('contact.phone'),
                  name: 'telephoneNumber',
                  required: false,
                  maxLength: 255
                },
                { title: 'Email', name: 'companyEmail', required: false, maxLength: 255 }
              ]}
              submitHandler={this.storeForm}
              callback={this.handleFormChange}
            />
            <XFormButtons
              onCancel={this.previousPage}
              onSave={this.storeForm}
              cancelText={t('tooltip.PREVIOUS')}
              style={{ padding: '15px 0 0 0' }}
              saveText={t('tooltip.next')}
              enabled={!this.state.waitingRegistration}
              loading={this.state.waitingRegistration}
            />
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  data: accountSelects.getRegisterState(state.account)
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  showNotification: (message: string, type: MessageType) =>
    dispatch(showNotification(message, type)),
  storeInformation: (data: RegisterAccountFormData) => dispatch(accountActions.storeNewData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterOrganizationPage);

export const RegisterLoginContent = styled('div')({
  margin: '25px',
  display: 'flex',
  flexDirection: 'column',
  height: '75%'
});
