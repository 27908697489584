import styled from '@emotion/styled';
import * as React from 'react';
import XButton, { XButtonStyle } from './XButton';
import { XPaper } from './XPaper';
import { XRow } from './XRow';

type OwnProps = {
  title: string;
  buttonSaveText?: string;
  buttonCancelText?: string;
  open: boolean;
  saveAvailable?: boolean;
  cancelOnPagClick?: boolean;
  bottomContent?: any;

  onSave?: () => void;
  onCancel?: () => void;
};

type State = {
  open: boolean;
};

class XPopup extends React.Component<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props);

    this.state = { open: this.props.open };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  public render() {
    return (
      this.state.open && (
        <PopupOuter>
          <PopupBackground
            onClick={() => {
              if (this.props.cancelOnPagClick && this.props.onCancel) this.props.onCancel();
            }}
          />
          <PopupWrapper>
            <PopupInner>
              <PopupTitle>{this.props.title}</PopupTitle>
              <PopupContent>{this.props.children}</PopupContent>
              {(this.props.buttonCancelText ||
                this.props.buttonSaveText ||
                this.props.bottomContent) && (
                <div>
                  {(this.props.buttonCancelText || this.props.buttonSaveText) && (
                    <div style={{ margin: '20px' }}>
                      <XRow space={true}>
                        {this.props.buttonCancelText && (
                          <XButton
                            staticcolor="#fff"
                            textcolor="#676767"
                            onClick={this.props.onCancel}>
                            {this.props.buttonCancelText}
                          </XButton>
                        )}
                        {this.props.buttonSaveText && (
                          <XButton
                            colorscheme={XButtonStyle.Primary}
                            onClick={this.props.onSave}
                            active={this.props.saveAvailable !== false}>
                            {this.props.buttonSaveText}
                          </XButton>
                        )}
                      </XRow>
                    </div>
                  )}
                  {this.props.bottomContent && this.props.bottomContent}
                </div>
              )}
            </PopupInner>
          </PopupWrapper>
        </PopupOuter>
      )
    );
  }
}

export default XPopup;

const PopupOuter = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
`;

const PopupBackground = styled.div`
  opacity: 0.4;
  width: 100%;
  height: 100%;
  background: #000;
`;

const PopupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
`;

const PopupInner = styled(XPaper)`
  flex: 1;
  max-width: 800px;
  margin: 40px;
  pointer-events: all;
`;

const PopupTitle = styled.div`
  font-weight: bold;
  padding: 0 20px;
`;

const PopupContent = styled.div`
  margin: 20px;
  min-height: 50px;
  overflow-y: auto;
  position: relative;
`;

const PopupButtons = styled.div``;
